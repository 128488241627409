import React, { useEffect, useState } from "react";
import style from "../messages/messages.module.css";
import zvyk from "../messages/img/music.mp3";
import SearchInput from "./messageComponents/messageList/SearchInput";
import ListItem from "./messageComponents/messageList/ListItem";
import { useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const Messag = ({ messageList, messagesList, setMessagesList}) => {
  const [nextPageUrl, setNextPageUrl] = useState(messageList.next_page_url);
  const [searchText, setSearchText] = useState();
  const [messsagesSearchList, setMessagesSearchList] = useState([]);
  const btnRef = useRef(null);
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const id = useParams();

  //Прогрузка новой страницы
  useEffect(() => {
    const btn = btnRef.current;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (nextPageUrl) {
          axios({
            method: "GET",
            url: `${nextPageUrl}`,
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token[1]}`,
              "User-Token": 1,
            },
          }).then((res) => {
            setNextPageUrl(res.data.next_page_url);
            setMessagesList((prevState) => [...prevState, ...res.data.data]);
          });
        }
      }
    });
    if (btn) observer.observe(btn);
    return () => {
      if (btn) observer.unobserve(btn);
    };
  }, [btnRef.current]);

  //Прогрузка нового сообщения
  window.startMessage = (message) => {
    setMessagesList((prevState) =>
      prevState.map((item) => {
        if (item.contact_id === message.data.from_id) {
          item.time = message.data.time;
          item.last_message.audio = message.data.audio;
          item.last_message.media = message.data.media;
          item.last_message.content = message.data.content;
          if (Number(id.id) !== message.data.from_id) {
            item.new_messages = item.new_messages + 1;
          }
        }
        return item;
      })
    );
  };

  return (
    <div className={style.messagList}>
      <SearchInput
        setMessagesSearchList={setMessagesSearchList}
        setSearchText={setSearchText}
        searchText={searchText}
      />
      {messsagesSearchList.length > 0 && searchText.length !== 0 ? (
        <div className={style.messagBox}>
          {messsagesSearchList.map((item) => {
            return (
              <ListItem
                item={item}
                key={item.id}
                search={true}
                searchText={searchText}
              />
            );
          })}
        </div>
      ) : messagesList.length > 0 ? (
        <div className={style.messagBox}>
          {messagesList.map((item) => {
            return (
              <ListItem
                item={item}
                key={item.id}
                setMessagesList={setMessagesList}
                messagesList={messagesList}
                search={false}
              />
            );
          })}
          {nextPageUrl !== null && (
            <button style={{ opacity: 0 }} ref={btnRef}>
              Показать еще
            </button>
          )}
        </div>
      ) : (
        <div className={style.messageUserNone}>
          Для создания чата найдите в поиске пользователя и отправьте ему
          сообщение.
        </div>
      )}
    </div>
  );
};

export default Messag;
