import React from "react";
import style from "./hashtags.module.css";
const Hashtags = ({ item, tags }) => {
  let result = item.text;

  tags?.map((tag) => {
    result = result.replaceAll(
      tag,
      '<a href="/wall/tags/' + tag.replace("#", "").trim() + '">' + tag + "</a>"
    );
  });
  return (
    <div className={style.content__text}>
      <h2 className={style.title}>{result}</h2>
    </div>
  );
};

export default Hashtags;
