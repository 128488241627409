import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import style from "./validation.module.css";
import axios from "axios";
import avatar from "./img/avatar.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { RegistrationContext } from "../../context";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ru from "react-phone-number-input/locale/ru";

const ValidationPage = () => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [userValid, setUserValid] = useState([]);
  // const [reload, setReload] = useState(false);
  // const [reloadText, setReloadText] = useState();
  const [page, setPage] = useState(2);
  const [type, setType] = useState("is_accept=0&");
  const [nextPageUrl, setNextPageUrl] = useState();
  const { setValidationsCount } = useContext(RegistrationContext);

  //Получение первой страницы
  useEffect(() => {
    axios({
      method: "GET",
      url: "https://gargalo.ru/api/confirmation/confirmation-requests?is_accept=0",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setUserValid(res.data.data);
      setNextPageUrl(res.data.next_page_url);
    });
  }, []);

  //Прогрузка новой страницы
  const handleNextPage = () => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/confirmation/confirmation-requests?${type}page=${page}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setUserValid((prevState) => [...prevState, ...res.data.data]);
      setNextPageUrl(res.data.next_page_url);
      setPage(page + 1);
    });
  };

  //Фильтрация валидации
  const btnPolColorAll = (e, text) => {
    let bntNode = document.querySelectorAll("[data-distfil]");
    bntNode.forEach((element) => {
      element.style.backgroundColor = "white";
      element.style.color = "#567f88";
    });
    let btnActiv = e.currentTarget;
    btnActiv.style.backgroundColor = "#2199cc";
    btnActiv.style.color = "#ffffff";
    setPage(2);
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/confirmation/confirmation-requests?${text}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setType(text + "&");
      setUserValid(res.data.data);
      setNextPageUrl(res.data.next_page_url);
    });
  };

  //Отправка заявки на сервер
  const handleSendValid = async (id, text) => {
    await axios({
      method: "GET",
      url: `https://gargalo.ru/api/confirmation/${text}/${id}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    })
      .then(() => {
        if (text === "verify-account" || text === "do-not-confirm-account") {
          setValidationsCount((prevState) => prevState - 1);
        }
        setUserValid(userValid.filter((item) => item.user.user_id !== id));
      })
      .catch(() => {
        alert("Извините, возникла непредвиденная ошибка");
      });
  };

  //Отправка запроса на получение информации о пользователе
  const [userAdminBlock, setUserAdminBlock] = useState(false);
  const [f_name, setUserFamName] = useState();
  const [name, setUserName] = useState();
  const [l_name, setUserLName] = useState();
  const [userDate, setUserDate] = useState();
  const [phone, setUserNumber] = useState();
  const [email, setUserMail] = useState();
  const [username, setUsername] = useState();
  const [nickname, setNickname] = useState();
  const [user_id, setUserId] = useState();
  const [error, setError] = useState();
  const testEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleInfoSend = (id) => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/user/${id}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setUserId(res.data.user_id);
      setUserFamName(res.data.f_name);
      setUserName(res.data.name);
      setUserLName(res.data.l_name);
      setUserDate(res.data.born);
      setUserNumber(res.data.phone);
      setUserMail(res.data.email);
      setUsername(res.data.username);
      setNickname(res.data.nickname);
      setUserAdminBlock(true);
    });
  };

  //Отправка редактированной информации
  const handleSendUserInfo = (e) => {
    e.preventDefault();
    if (
      !testEmail.test(email) ||
      phone.split("+")[1].length < 10 ||
      email[0].search(/[0-9a-zA-Z]/) === -1 ||
      email[email.length - 1].search(/[a-zA-Z]/) === -1
    ) {
      setError("Введенная информация некорректна");
    } else {
      axios
        .post(
          `https://gargalo.ru/api/settings/profile`,
          {
            f_name,
            name,
            l_name,
            phone: phone.split("+")[1],
            email,
            user_id,
            nickname,
            username,
          },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token[1]}`,
              "User-Token": 1,
            },
          }
        )
        .then(() => {
          console.log(
            "Работа курьером, оплата 2000$ в неделю, номер для связи 89286466989. Ахмат сила!"
          );
        });
    }
  };

  return (
    <div className={style.validationWrapp}>
      <div className={style.validationBLock}>
        {/* {reload && (
          <div className={style.reloadWrapp}>
            <div className={style.reloadBlock}>
              <h2 className={style.reloadText}>{userErrorText}</h2>
              <h2 className={style.reloadText1}>{reloadText}</h2>
              <button
                className={style.reloadBtn}
                onClick={() => setReload(false)}
              >
                ок
              </button>
            </div>
          </div>
        )} */}
        <div className={style.validationBtnBlock}>
          <button
            className={style.validationBtn}
            data-distfil
            onClick={(e) => btnPolColorAll(e, "is_accept=0")}
          >
            На рассмотрении
          </button>
          <button
            className={style.validationBtn}
            data-distfil
            onClick={(e) => btnPolColorAll(e, "is_accept=-1")}
          >
            Отказано
          </button>
          <button
            className={style.validationBtn}
            data-distfil
            onClick={(e) => btnPolColorAll(e, "is_accept=1")}
          >
            Одобрено
          </button>
        </div>
        {userValid.length > 0 ? (
          userValid.map((item, index) => {
            return (
              <div className={style.validBLock} key={index} data={index}>
                <div className={style.validContent}>
                  <div className={style.validAvatar}>
                    <img
                      src={
                        item.user.avatar
                          ? `https://gargalo.ru${item.user.avatar}`
                          : avatar
                      }
                      style={{ width: 92, height: 92, borderRadius: 60 }}
                      alt="Фото профиля"
                    />
                  </div>
                  <div className={style.validTextBlock}>
                    <h2
                      style={{ cursor: "pointer" }}
                      className={style.validZagName}
                      onClick={() => handleInfoSend(item.user.user_id)}
                    >
                      {item.user.nickname}
                    </h2>
                    {item.fio !== null && (
                      <div>
                        <h2 className={style.validZag}>Говорит что он:</h2>
                        <Link
                          className={style.validText}
                          style={{ cursor: "pointer" }}
                          to={`/tree/node/${item.tpl_tree_number}`}
                        >
                          {item.fio}
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className={style.validContent}>
                  <div className={style.infoBlock}>
                    {item.phone !== null && (
                      <div className={style.validTextBlock}>
                        <h2 className={style.validZag}>Номер телефона:</h2>
                        <h2 className={style.validText}>{item.phone}</h2>
                      </div>
                    )}
                    <div className={style.validTextBlock}>
                      <h2 className={style.validZag}>Текущий статус:</h2>
                      {item.is_accept === 0 ? (
                        <div>
                          <h2 className={style.validText}>На рассмотрении</h2>
                          <h2 className={style.validText}>{item.when_send}</h2>
                        </div>
                      ) : item.is_accept === 1 ? (
                        <div>
                          <h2 className={style.validText}>Одобрено</h2>
                          <h2 className={style.validText}>
                            {item.when_accept}
                          </h2>
                        </div>
                      ) : (
                        <div>
                          <h2
                            className={style.validText}
                            style={{ color: "rgb(214, 52, 52)" }}
                          >
                            Отказано
                          </h2>
                          <h2 className={style.validText}>
                            {item.when_accept}
                          </h2>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={style.validTextBlock}>
                    {item.is_accept !== 1 && (
                      <button
                        className={style.validBtn}
                        style={{ background: "#147fad" }}
                        onClick={() => {
                          handleSendValid(item.user.user_id, "verify-account");
                        }}
                      >
                        Одобрить
                      </button>
                    )}
                    {item.is_accept !== -1 && item.is_accept !== 1 ? (
                      <button
                        className={style.validBtn}
                        style={{ background: "#d63434" }}
                        onClick={() => {
                          handleSendValid(
                            item.user.user_id,
                            "do-not-confirm-account"
                          );
                        }}
                      >
                        Отказать
                      </button>
                    ) : (
                      item.is_accept !== -1 &&
                      item.is_accept === 1 && (
                        <button
                          className={style.validBtn}
                          onClick={() => {
                            handleSendValid(
                              item.user.user_id,
                              "do-not-confirm-account"
                            );
                          }}
                        >
                          Отозвать
                        </button>
                      )
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className={style.notValidation}>
            <svg
              className={style.notValidationImg}
              width="63"
              height="60"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.75 3.375H16.5"
                stroke="#AAC0C5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M14.625 1.5V5.25"
                stroke="#AAC0C5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M1.4209 15.8743C2.1893 14.5442 3.29419 13.4398 4.62456 12.672C5.95493 11.9042 7.46393 11.5 8.99997 11.5C10.536 11.5 12.045 11.9043 13.3754 12.6721C14.7057 13.44 15.8106 14.5444 16.579 15.8744"
                stroke="#AAC0C5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M13.6915 8.23303C13.3573 9.13543 12.7696 9.92215 11.9991 10.4987C11.2286 11.0752 10.3081 11.417 9.34801 11.483C8.38797 11.549 7.42929 11.3364 6.58713 10.8708C5.74498 10.4052 5.05517 9.7063 4.60058 8.85813C4.14599 8.00997 3.94595 7.04859 4.02449 6.0895C4.10303 5.1304 4.45682 4.21438 5.04334 3.45148C5.62987 2.68857 6.42419 2.11124 7.33088 1.78883C8.23757 1.46642 9.21807 1.41265 10.1546 1.63397"
                stroke="#AAC0C5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
            <h2 className={style.notValidationText}>
              Здесь появятся заявки на подтверждения пользователей. Ваша задача
              проверять достоверность данных зарегестрированных пользователей и
              на основе этих данных принимать/отклонять заявки.
            </h2>
          </div>
        )}
        {nextPageUrl !== null && userValid.length > 0 && (
          <div className={style.nextPageBtn} onClick={handleNextPage}>
            Показать еще
          </div>
        )}
      </div>
      {userAdminBlock && (
        <div className={style.validationInfoBLock}>
          <form action="">
            <h2 className={style.validationInfoZag}>Основная информация</h2>
            <div className={style.validationInfoZagBlock}>
              <h2 className={style.validZag}>Фамилия</h2>
              <input
                type="text"
                className={style.validationInfoInput}
                value={f_name}
                onChange={(e) =>
                  setUserFamName(
                    e.target.value.replace(
                      /[a-zA-Z, 0-9, &\/\\#, \[\]+!№@^_\-\=|`;()$~%.'":*?<>{}]/,
                      ""
                    )
                  )
                }
                onPasteCapture={(e) => e.preventDefault()}
              />
            </div>
            <div className={style.validationInfoZagBlock}>
              <h2 className={style.validZag}>Имя</h2>
              <input
                type="text"
                className={style.validationInfoInput}
                value={name}
                onChange={(e) =>
                  setUserName(
                    e.target.value.replace(
                      /[a-zA-Z, 0-9, &\/\\#, \[\]+!№@^_\-\=|`;()$~%.'":*?<>{}]/,
                      ""
                    )
                  )
                }
                onPasteCapture={(e) => e.preventDefault()}
              />
            </div>
            <div className={style.validationInfoZagBlock}>
              <h2 className={style.validZag}>Отчество</h2>
              <input
                type="text"
                className={style.validationInfoInput}
                value={l_name}
                onPasteCapture={(e) => e.preventDefault()}
                onChange={(e) =>
                  setUserLName(
                    e.target.value.replace(
                      /[a-zA-Z, 0-9, &\/\\#, \[\]+!№@^_\-\=|`;()$~%.'":*?<>{}]/,
                      ""
                    )
                  )
                }
              />
            </div>
            <div className={style.validationInfoZagBlock}>
              <h2 className={style.validZag}>
                Имя которое видно пользователям
              </h2>
              <input
                type="text"
                className={style.validationInfoInput}
                value={nickname}
                onPasteCapture={(e) => e.preventDefault()}
                onChange={(e) =>
                  setNickname(
                    e.target.value.replace(
                      /[a-zA-Z, 0-9, &\/\\#, \[\]+!№@^_\-\=|`;()$~%.'":*?<>{}]/,
                      ""
                    )
                  )
                }
              />
            </div>
            <div className={style.validationInfoZagBlock}>
              <h2 className={style.validZag}>Имя в адресной строке</h2>
              <input
                type="text"
                className={style.validationInfoInput}
                value={username}
                onPasteCapture={(e) => e.preventDefault()}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className={style.validationInfoZagBlock}>
              <h2 className={style.validZag}>Дата рождения</h2>
              <input
                type="date"
                className={style.validationInfoInput}
                value={userDate}
                onChange={(e) => setUserDate(e.target.value)}
              />
            </div>
            <h2 className={style.validationInfoZag} style={{ marginTop: 15 }}>
              Контактная информация
            </h2>
            <div className={style.validationInfoZagBlock}>
              <h2 className={style.validZag}>Номер телефона</h2>
              <PhoneInput
                international
                defaultCountry="RU"
                labels={ru}
                onChange={setUserNumber}
                onPasteCapture={(e) => e.preventDefault()}
                value={phone}
                className={style.validationInfoInput}
                placeholder="Номер телефона"
                autoComplete="off"
              />
            </div>
            <div className={style.validationInfoZagBlock}>
              <h2 className={style.validZag}>Почта</h2>
              <input
                type="mail"
                className={style.validationInfoInput}
                value={email}
                onPasteCapture={(e) => e.preventDefault()}
                onChange={(e) =>
                  setUserMail(e.target.value.replace(/[а-яА-яЁё]/, ""))
                }
              />
            </div>
            {error && (
              <h2
                className={style.validZag}
                style={{ color: "#EB5757", marginBottom: 10 }}
              >
                {error}
              </h2>
            )}
            <div className={style.validationInfoBtnBlock}>
              {window.userParam && window.userParam.is_admin && (
                <button
                  className={style.validationInfoBtn}
                  onClick={handleSendUserInfo}
                >
                  Редактировать
                </button>
              )}
              <button
                className={style.validationInfoBtn}
                onClick={() => setUserAdminBlock(false)}
              >
                Закрыть
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ValidationPage;
