import React, { useState, useEffect } from "react";
import styleEvents from "./EventGroup.module.css";
import chooseErrorImage from "./../img/Vector.svg";
import PostService from "../../../../API/postService";

const ErrorModal = ({ setActionsErrorBlock, eventId }) => {
    const [detailsError, setDetailErrors] = useState("");
    const [reason, setReason] = useState();
    const [reasonValue, setReasonValue] = useState({});

    useEffect(() => {
        PostService.getAllEventListReason().then((res) =>
            setReason(res.reasons)
        );
    }, []);

    const handleClickError = (key) => {
        setReasonValue({ [key]: true });
    };

    const handleChange = ({ target }) => {
        const { value } = target;
        setDetailErrors(value);
    };

    const handleClickSendRequest = () => {
        setActionsErrorBlock(false);
        document.querySelector("html").style.overflow = "auto";
        PostService.sendComplaint(
            Object.keys(reasonValue),
            eventId,
            "event",
            detailsError
        );
    };

    const handleClickClosedButton = () => {
        setActionsErrorBlock(false);
        document.querySelector("html").style.overflow = "auto";
    };

    // console.log(reason[0]);
    return (
        <div className={styleEvents.modal_block}>
            <div className={styleEvents.error_modal}>
                <h2 className={styleEvents.error_modal_h2}>
                    Сообщение об ошибке
                </h2>
                <div className={styleEvents.modal_div}>
                    {reason ? (
                        Object.values(reason).map((reas) => (
                            <div
                                className={styleEvents.error_modal_content}
                                onClick={() => handleClickError(reas.type)}
                            >
                                <div
                                    className={
                                        styleEvents.modal_content_image_border
                                    }
                                    style={{
                                        border: reasonValue[reas.type]
                                            ? "2px solid #054857"
                                            : "",
                                    }}
                                >
                                    {reasonValue[reas.type] && (
                                        <img
                                            src={chooseErrorImage}
                                            className={
                                                styleEvents.error_modal_image
                                            }
                                            alt=""
                                        />
                                    )}
                                </div>
                                <p className={styleEvents.error_modal_text}>
                                    {reas.text}
                                </p>
                            </div>
                        ))
                    ) : (
                        <div className={styleEvents.plug_reason_div}>
                            <div
                                className={styleEvents.reason_plug}
                                style={{ width: 185 }}
                            ></div>
                            <div
                                className={styleEvents.reason_plug}
                                style={{ width: 215 }}
                            ></div>
                            <div
                                className={styleEvents.reason_plug}
                                style={{ width: 160 }}
                            ></div>
                        </div>
                    )}
                </div>
                <input
                    className={styleEvents.error_modal_input}
                    onChange={handleChange}
                    placeholder="Опишите ошибку..."
                    type="text"
                />
                <div className={styleEvents.modal_div_buttons}>
                    <button
                        className={styleEvents.modal_buttons}
                        onClick={() => handleClickSendRequest()}
                    >
                        Отправить
                    </button>
                    <button
                        onClick={() => handleClickClosedButton()}
                        className={
                            styleEvents.modal_buttons +
                            " " +
                            styleEvents.buttons_two
                        }
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ErrorModal;
