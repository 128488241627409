import React, { useState } from "react";
import styleFollowers from "./followers.module.css";
import { Link } from "react-router-dom";
import PostService from "./../../../../../API/postService";

const FollowBlockList = ({
    changingLikesCounter,
    boolReused,
    user,
    item,
    index,
    setFollowBool,
}) => {
    const [bool, setBool] = useState();
    // Удаление и добавление подписок
    const unFollow = (item, id) => {
        // изменение состояния кнопки
        if (bool) {
            setBool(false);
        } else {
            setBool(true);
        }
        // запрос на удаление и добавление подписок
        PostService.getAllUsersSubscribeAndUnsubscribe(id).then((us) =>
            changingLikesCounter("follows", us.follows)
        );
        item.is_follow ? (item.is_follow = false) : (item.is_follow = true);
    };
    const handleClickUser = () => {
        setFollowBool(false);
    };
    return (
        <div key={index} className={styleFollowers.follower__block}>
            <Link
                to={
                    item.user.user_id !== user.user_id
                        ? `/account/${item.user.user_id}`
                        : `/profile/${user.user_id}`
                }
            >
                <div
                    className={styleFollowers.follow__info}
                    onClick={handleClickUser}
                >
                    <img
                        className={styleFollowers.follow__image}
                        src={`https://gargalo.ru/${item.user.avatar}`}
                    />
                    <h2 className={styleFollowers.follow__image__text}>
                        {item.user.nickname}
                    </h2>
                </div>
            </Link>
            {!boolReused && (
                <button
                    onClick={() => unFollow(item, item.user.user_id)}
                    className={styleFollowers.follow__button}
                >
                    {bool ? "Отмена" : "Отписаться"}
                </button>
            )}
        </div>
    );
};

export default FollowBlockList;
