import React from "react";
import style from "./itemChat.module.css";

const TypeText = ({ content, time, type }) => {
  return (
    <div className={style.typeTextWrapp}>
      <p
        className={style.contentText}
        style={{ color: type === "R" ? "#FFFFFF" : "#042026" }}
      >
        {content}
      </p>
      <h3
        className={style.timeText}
        style={{ color: type === "R" ? "#FFFFFF" : "#054857" }}
      >
        {time}
      </h3>
    </div>
  );
};

export default TypeText;
