// Создание элемента и присвоение ему аттрибутов
export function createTag(tag, attributes, event) {
  let key;
  let element = document.createElement(tag);
  for (key in attributes) {
    element.setAttribute(key, attributes[key]);
  }

  for (key in event) {
    element.addEventListener(key, event[key]);
  }

  return element;
}

// Создание ребенка в родстве
export function ultreechild(item) {
  let ul = createTag("ul", { class: "tree-child" });
  let li = createTag("li", { class: `tree-item li-tree-${item.id}` });
  let ul1 = createTag("ul", { class: "item-ul" });
  let li1 = createTag("li", { class: "tree-item" });
  let searf = createTag("div", { class: `searf` });
  let a = createTag("a", {
    class: `node spouce${item.spouce} teip${item.teip}
     level${item.level} id${item.id} parent${item.f_id} ia${item.is_approved} aui${item.add_user_id} 
     ui${item.user_id}`,
  });
  let genderDiv = createTag("div", { class: "genderDiv" });
  if (item.is_approved === -1 && item.user_id === 0) {
    genderDiv.style.backgroundColor = "#E3EFF1";
  } else if (item.pol === 1) {
    genderDiv.style.backgroundColor = "#9FE2FF";
  } else if (item.pol === 2) {
    genderDiv.style.backgroundColor = "#F8DCE6";
  }

  let iconDiv = createTag("div", { class: "iconDiv" });
  let icon1 = createTag("div", { class: "icon_one iconsBlock" });
  let icon2 = createTag(
    "div",
    { class: "icon_who iconsBlock"},
    { click: function (e) {
      e.stopPropagation();
      window.addPersonUser(item)
    } }
  );
  let icon3 = createTag("a", {
    class: "icon_whree iconsBlock",
    href: `/account/${item.user_id}`,
  });
  if (item.pol === 1) {
    icon1.classList.add("hoverMan");
    icon2.classList.add("hoverMan");
    icon3.classList.add("hoverMan");
  } else if (item.pol === 2) {
    icon1.classList.add("hoverWoman");
    icon2.classList.add("hoverWoman");
    icon3.classList.add("hoverWoman");
  }
  iconDiv.appendChild(icon1);
  iconDiv.appendChild(icon2);
  if (item.user_id > 0) {
    iconDiv.appendChild(icon3);
  }
  a.appendChild(iconDiv);

  a.appendChild(genderDiv);
  a.dataset.source = "";
  a.dataset.f_id = item.f_id;
  a.dataset.m_id = item.m_id;
  a.dataset.ci = item.current_id;
  a.dataset.aui = item.add_user_id;
  a.dataset.teip = item.teip;
  a.dataset.pol = item.pol;
  a.dataset.fullfio = item.fullfio;
  a.dataset.spouce = item.spouce;
  a.dataset.id = item.id;
  let span = createTag("span", { class: "tree_avatar" });
  let ellipse = createTag("div", { class: "ellipse_online" });
  span.appendChild(ellipse);
  let div = createTag("div", { class: "tree-node-content" });
  let h2 = createTag("h2", { class: "tree-node-name" });
  let h3 = createTag("h3", { class: "tree-node-data" });
  h2.textContent = item.fullfio;
  if (item.born !== null && item.die === null) {
    h3.textContent = item.born;
  } else if (item.born === null && item.die !== null) {
    h3.textContent = "Умер" + item.die;
  } else if (item.born !== null && item.die !== null) {
    h3.textContent = item.born + "-" + item.die;
  }
  let img = createTag("img", { src: `https://gargalo.ru${item.avatar}` });
  if (item.is_approved === -1 && item.user_id === 0) {
    a.style.border = "1px solid #E3EFF1";
    h2.style.color = "#AAC0C5";
    h3.style.color = "#AAC0C5";
  }
  div.appendChild(h2);
  div.appendChild(h3);
  span.appendChild(img);
  a.appendChild(span);
  a.appendChild(div);
  if (item.is_approved === 1) {
    a.appendChild(searf);
  }
  li1.appendChild(a);
  ul1.appendChild(li1);
  li.appendChild(ul1);
  ul.appendChild(li);
  return ul;
}

// Создание супруга в родстве
export function spouce(item) {
  let a = createTag("a", {
    class: `node spouce-item from-spouce spouce${item.spouce}
     teip${item.teip} level${item.level} id${item.id} parent${item.f_id} ia${item.is_approved} aui${item.add_user_id} ui${item.user_id}`,
  });

  let iconDiv = createTag("div", { class: "iconDiv" });
  let icon1 = createTag("div", { class: "icon_one iconsBlock" });
  let icon2 = createTag(
    "div",
    { class: "icon_who iconsBlock"},
    { click: function (e) {
      e.stopPropagation();
      window.addPersonUser(item)
    } }
  );
  let icon3 = createTag("a", {
    class: "icon_whree iconsBlock",
    href: `/account/${item.user_id}`,
  });
  if (item.pol === 1) {
    icon1.classList.add("hoverMan");
    icon2.classList.add("hoverMan");
    icon3.classList.add("hoverMan");
  } else if (item.pol === 2) {
    icon1.classList.add("hoverWoman");
    icon2.classList.add("hoverWoman");
    icon3.classList.add("hoverWoman");
  }
  iconDiv.appendChild(icon1);
  iconDiv.appendChild(icon2);
  if (item.user_id > 0) {
    iconDiv.appendChild(icon3);
  }
  a.appendChild(iconDiv);

  a.dataset.source = "";
  a.dataset.f_id = item.f_id;
  a.dataset.m_id = item.m_id;
  a.dataset.ci = item.current_id;
  a.dataset.aui = item.add_user_id;
  a.dataset.is_approved = item.is_approved;
  a.dataset.teip = item.teip;
  a.dataset.pol = item.pol;
  a.dataset.fullfio = item.fullfio;
  a.dataset.spouce = item.spouce;
  a.dataset.id = item.id;
  let genderDiv = createTag("div", { class: "genderDiv" });
  if (item.is_approved === -1 && item.user_id === 0) {
    genderDiv.style.backgroundColor = "#E3EFF1";
  } else if (item.pol === 1) {
    genderDiv.style.backgroundColor = "#9FE2FF";
  } else if (item.pol === 2) {
    genderDiv.style.backgroundColor = "#F8DCE6";
  }
  a.appendChild(genderDiv);
  let searf = createTag("div", { class: `searf` });
  let span = createTag("span", { class: "tree_avatar" });
  let ellipse = createTag("div", { class: "ellipse_online" });
  span.appendChild(ellipse);
  let div = createTag("div", { class: "tree-node-content" });
  let h2 = createTag("h2", { class: "tree-node-name" });
  let h3 = createTag("h3", { class: "tree-node-data" });
  h2.textContent = item.fullfio;
  if (item.born !== null && item.die === null) {
    h3.textContent = item.born;
  } else if (item.born === null && item.die !== null) {
    h3.textContent = "Умер" + item.die;
  } else if (item.born !== null && item.die !== null) {
    h3.textContent = item.born + "-" + item.die;
  }
  let img = createTag("img", { src: `https://gargalo.ru${item.avatar}` });
  if (item.is_approved === -1 && item.user_id === 0) {
    a.style.border = "1px solid #E3EFF1";
    h2.style.color = "#AAC0C5";
    h3.style.color = "#AAC0C5";
  }
  div.appendChild(h2);
  div.appendChild(h3);
  span.appendChild(img);
  a.appendChild(span);
  if (item.is_approved === 1) {
    a.appendChild(searf);
  }
  a.appendChild(div);
  return a;
}

// Создание братьев/систер в родстве
export function litreeitem(item) {
  let li = createTag("li", { class: `tree-item li-tree-${item.id}` });
  let searf = createTag("div", { class: `searf` });
  let a = createTag("a", {
    class: `node spouce${item.spouce} teip${item.teip} level${item.level} id${item.id} parent${item.f_id} ia${item.is_approved} aui${item.add_user_id} 
    ui${item.user_id}`,
  });

  let iconDiv = createTag("div", { class: "iconDiv" });
  let icon1 = createTag("div", { class: "icon_one iconsBlock" });
  let icon2 = createTag(
    "div",
    { class: "icon_who iconsBlock"},
    { click: function (e) {
      e.stopPropagation();
      window.addPersonUser(item)
    } }
  );
  let icon3 = createTag("a", {
    class: "icon_whree iconsBlock",
    href: `/account/${item.user_id}`,
  });
  if (item.pol === 1) {
    icon1.classList.add("hoverMan");
    icon2.classList.add("hoverMan");
    icon3.classList.add("hoverMan");
  } else if (item.pol === 2) {
    icon1.classList.add("hoverWoman");
    icon2.classList.add("hoverWoman");
    icon3.classList.add("hoverWoman");
  }
  iconDiv.appendChild(icon1);
  iconDiv.appendChild(icon2);
  if (item.user_id > 0) {
    iconDiv.appendChild(icon3);
  }
  a.appendChild(iconDiv);

  a.dataset.source = "";
  a.dataset.f_id = item.f_id;
  a.dataset.m_id = item.m_id;
  a.dataset.ci = item.current_id;
  a.dataset.aui = item.add_user_id;
  a.dataset.is_approved = item.is_approved;
  a.dataset.teip = item.teip;
  a.dataset.pol = item.pol;
  a.dataset.fullfio = item.fullfio;
  a.dataset.spouce = item.spouce;
  a.dataset.id = item.id;
  let genderDiv = createTag("div", { class: "genderDiv" });
  if (item.is_approved === -1 && item.user_id === 0) {
    genderDiv.style.backgroundColor = "#E3EFF1";
  } else if (item.pol === 1) {
    genderDiv.style.backgroundColor = "#9FE2FF";
  } else if (item.pol === 2) {
    genderDiv.style.backgroundColor = "#F8DCE6";
  }
  a.appendChild(genderDiv);
  let span = createTag("span", { class: "tree_avatar" });
  let ellipse = createTag("div", { class: "ellipse_online" });
  span.appendChild(ellipse);
  let div = createTag("div", { class: "tree-node-content" });
  let h2 = createTag("h2", { class: "tree-node-name" });
  let h3 = createTag("h3", { class: "tree-node-data" });
  h2.textContent = item.fullfio;
  if (item.born !== null && item.die === null) {
    h3.textContent = item.born;
  } else if (item.born === null && item.die !== null) {
    h3.textContent = "Умер" + item.die;
  } else if (item.born !== null && item.die !== null) {
    h3.textContent = item.born + "-" + item.die;
  }
  let img = createTag("img", { src: `https://gargalo.ru${item.avatar}` });
  if (item.is_approved === -1 && item.user_id === 0) {
    a.style.border = "1px solid #E3EFF1";
    h2.style.color = "#AAC0C5";
    h3.style.color = "#AAC0C5";
  }
  div.appendChild(h2);
  div.appendChild(h3);
  span.appendChild(img);
  a.appendChild(span);
  if (item.is_approved === 1) {
    a.appendChild(searf);
  }
  a.appendChild(div);
  li.appendChild(a);
  return li;
}

function addPersonChild(e) {
  document.getElementById("addForm").style.display = "block";
  let parent = e.currentTarget.parentElement;
  if (parent.getAttribute("data-pol") == 1) {
    document.getElementById("addFatherName").value =
      parent.getAttribute("data-fullfio");
    document.getElementById("fatherId").value = parent.getAttribute("data-id");
  } else {
    document.getElementById("addMatherName").value =
      parent.getAttribute("data-fullfio");
    document.getElementById("matherId").value = parent.getAttribute("data-id");
  }
  document.getElementById("source").value = "child";
  document.getElementById("sourceId").value = parent.getAttribute("data-id");
  document.getElementById("teip").value = parent.getAttribute("data-teip");
}

function addPersonParent(e) {
  let parent = e.currentTarget.parentElement;
  document.getElementById("addForm").style.display = "block";
  document.getElementById("source").value = "parent";
  document.getElementById("sourceId").value = parent.getAttribute("data-id");
  document.getElementById("teip").value = parent.getAttribute("data-teip");
}

function addPersonSpouce(e) {
  let parent = e.currentTarget.parentElement;
  document.getElementById("addForm").style.display = "block";
  document.getElementById("source").value = "spouce";
  document.getElementById("sourceId").value = parent.getAttribute("data-id");
  document.getElementById("addSpouceName").value =
    parent.getAttribute("data-fullfio");
  document.getElementById("spouceId").value = parent.getAttribute("data-id");
  document.getElementById("teip").value = parent.getAttribute("data-teip");
}

// Зумм и перемещение
export function init(id) {
  const container = document.getElementById(id);
  let is_down = false;
  // let scale =
  //   document.getElementById("treeWrap").getBoundingClientRect().width /
  //   document.getElementById(id).getBoundingClientRect().width;
  // scale = scale < 0.15 ? 0.15 : scale;
  // scale = scale > 1 ? 1 : scale;
  let scale = 0.5
  
  let translateX =
    scale < 1
      ? (-1 *
          document.getElementById(id).getBoundingClientRect().width *
          scale) /
        2
      : 0;
  const instance = renderer({
    minScale: 0.1,
    maxScale: 30,
    element: container,
    scaleSensitivity: 10,
    translateX: translateX,
    translateY: 0,
    scale: scale,
    height: document.getElementById(id).getBoundingClientRect().height,
  });
  container.addEventListener("wheel", (event) => {
    event.preventDefault();
    instance.zoom({
      deltaScale: Math.sign(event.deltaY) > 0 ? -1 : 1,
      x: event.pageX,
      y: event.pageY,
    });
  });
  // container.addEventListener("dblclick", () => {
  //   instance.panTo({
  //     originX: 0,
  //     originY: 0,
  //     scale: 1,
  //   });
  // });
  container.addEventListener("mousedown", () => {
    is_down = true;
  });
  container.addEventListener("mouseup", () => {
    is_down = false;
  });
  container.addEventListener("mousemove", (event) => {
    if (!is_down) {
      return;
    }
    event.preventDefault();

    instance.panBy({
      originX: event.movementX,
      originY: event.movementY,
    });
  });
}

//Рендер зумма и перемещения
const hasPositionChanged = ({ pos, prevPos }) => pos !== prevPos;

const valueInRange = ({ minScale, maxScale, scale }) =>
  scale <= maxScale && scale >= minScale;

const getTranslate =
  ({ minScale, maxScale, scale }) =>
  ({ pos, prevPos, translate }) =>
    valueInRange({ minScale, maxScale, scale }) &&
    hasPositionChanged({ pos, prevPos })
      ? translate + (pos - prevPos * scale) * (1 - 1 / scale)
      : translate;

const getScale = ({
  scale,
  minScale,
  maxScale,
  scaleSensitivity,
  deltaScale,
}) => {
  let newScale = scale + deltaScale / (scaleSensitivity / scale);
  newScale = Math.max(minScale, Math.min(newScale, maxScale));
  return [scale, newScale];
};

const getMatrix = ({ scale, translateX, translateY }) =>
  `matrix(${scale}, 0, 0, ${scale}, ${translateX}, ${translateY})`;

const pan = ({ state, originX, originY }) => {
  state.transformation.translateX += originX;
  state.transformation.translateY += originY;
  state.element.style.transform = getMatrix({
    scale: state.transformation.scale,
    translateX: state.transformation.translateX,
    translateY: state.transformation.translateY,
  });
};

const makePan = (state) => ({
  panBy: ({ originX, originY }) => pan({ state, originX, originY }),
  panTo: ({ originX, originY, scale }) => {
    state.transformation.scale = scale;
    pan({
      state,
      originX: originX - state.transformation.translateX,
      originY: originY - state.transformation.translateY,
    });
  },
});

const makeZoom = (state) => ({
  zoom: ({ x, y, deltaScale }) => {
    const { left, top } = state.element.getBoundingClientRect();
    const { minScale, maxScale, scaleSensitivity } = state;
    const [scale, newScale] = getScale({
      scale: state.transformation.scale,
      deltaScale,
      minScale,
      maxScale,
      scaleSensitivity,
    });
    const originX = x - left;
    const originY = y - top;
    const newOriginX = originX / scale;
    const newOriginY = originY / scale;
    const translate = getTranslate({ scale, minScale, maxScale });
    const translateX = translate({
      pos: originX,
      prevPos: state.transformation.originX,
      translate: state.transformation.translateX,
    });
    const translateY = translate({
      pos: originY,
      prevPos: state.transformation.originY,
      translate: state.transformation.translateY,
    });

    state.element.style.transformOrigin = `${newOriginX}px ${newOriginY}px`;
    state.element.style.transform = getMatrix({
      scale: newScale,
      translateX,
      translateY,
    });
    state.transformation = {
      originX: newOriginX,
      originY: newOriginY,
      translateX,
      translateY,
      scale: newScale,
    };
  },
});

const renderer = ({
  minScale,
  maxScale,
  element,
  scaleSensitivity = 10,
  translateX,
  translateY,
  scale,
  height,
}) => {
  translateY = scale < 1 ? -1 + (height * scale) / 2 : 0;
  const state = {
    element,
    minScale,
    maxScale,
    scaleSensitivity,
    transformation: {
      originX: 0,
      originY: 0,
      translateX: translateX,
      translateY: translateY,
      scale: scale,
    },
  };
  state.element.style.transform = getMatrix({
    scale: scale,
    translateX,
    translateY,
  });
  return Object.assign({}, makeZoom(state), makePan(state));
};
