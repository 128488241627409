import React, { useState, useEffect, useRef, useContext } from "react";
import style from "../band/band.module.css";
import PostService from "./../../../API/postService";
import Loader from "../Loader";
import loader from "../../content/img/loader.gif";
import Posts from "../band/postComponents/Posts";
import { RegistrationContext } from "../../context";
import { useParams } from "react-router-dom";
import ReTabs from "../../reusedComponents/ReTabs";
import AddPost from "../band/addPosts/AddPost";
import { rootControls } from "../../../controllers/RootConroller";

const TeypProfilePosts = () => {
  const { id } = useParams();

  const { user, data, setData } = useContext(RegistrationContext);
  const [waitPosts, setWaitPosts] = useState();
  const [filterPosts, setFilterPosts] = useState("Публикации тейпа");
  const [page, setPage] = useState(1);
  const [notPosts, setNotPosts] = useState(["true"]);
  const [newPosts, setNewPosts] = useState(false);
  const [addPost, setAddPost] = useState(false);
  const [proposed, setProposed] = useState(false);
  const [deviation, setDeviation] = useState(false);
  const btnRef = useRef(null);
  const plugText =
    "Пока нет публикаций у этого тейпа. Вы можете предложить свою публикацию.";

  useEffect(() => {
    setData();
    setFilterPosts("Публикации тейпа");
    PostService.getAllPostsUser(id, page).then(
      (res) => (setData(res.data), console.log(res))
    );
  }, [id]);
  useEffect(() => {
    if (filterPosts === "Предложенные") {
      setData();
      setProposed(true);
      setDeviation(false);
      PostService.getWaitTeipPosts("wait").then((res) => setData(res.data));
    } else if (filterPosts === "Публикации тейпа") {
      setProposed(false);
      setDeviation(false);
      setData();
      PostService.getAllPostsUser(id, page).then((res) => setData(res.data));
    } else if (filterPosts === "Отклоненные") {
      console.log("chanched");
      setData();
      setProposed(true);
      setDeviation(true);
      PostService.getWaitTeipPosts("ignore").then((res) => setData(res.data));
    }
  }, [filterPosts]);

  useEffect(() => {
    if (page > 1) {
      setNewPosts(true);
      PostService.getAllPostsUser(id, page).then((res) => {
        setData((prev) => [...prev, ...res.data]);
        setNotPosts(res.data);
        setNewPosts(false);
      });
    }
  }, [page]);

  useEffect(() => {
    const btn = btnRef.current;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((page) => page + 1);
      }
    });
    if (btn) observer.observe(btn);
    return () => {
      if (btn) observer.unobserve(btn);
    };
  }, [btnRef.current, data]);

  const scrollBarFunction = () => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  const offerCheck =
    rootControls("ActiveUserInTree") &&
    !rootControls("Administrator") &&
    !rootControls("TeipModerator", id);

  const handleAddPosts = () => {
    PostService.getAllPostsUser(id, page).then((res) => setData(res.data));
    if (!offerCheck) {
      setAddPost(true);
    }
  };

  const whereTitle = offerCheck
    ? "Предложить публикацию .."
    : "Добавить публикацию ..";
  const whereButton = offerCheck ? "Предложить" : "Опубликовать";
  const whereHeader = offerCheck
    ? "Предложение публикации"
    : "Добавление публикации";

  return (
    <>
      {!offerCheck && (
        <ReTabs
          text={filterPosts}
          data={[
            {
              title: "Публикации тейпа",
              active: filterPosts === "Публикации тейпа",
              handleClick: () => setFilterPosts("Публикации тейпа"),
            },
            {
              title: "Предложенные",
              active: filterPosts === "Предложенные",
              handleClick: () => setFilterPosts("Предложенные"),
            },
            {
              title: "Отклоненные",
              active: filterPosts === "Отклоненные",
              handleClick: () => setFilterPosts("Отклоненные"),
            },
          ]}
        />
      )}
      {deviation || proposed ? (
        ""
      ) : (
        <AddPost
          handleAddPosts={handleAddPosts}
          whereHeader={whereHeader}
          whereTitle={whereTitle}
          whereButton={whereButton}
          where="teip"
          teipId={offerCheck ? id : undefined}
        />
      )}
      {data ? (
        <>
          <Posts
            proposed={proposed}
            deviation={deviation}
            data={data}
            typePlug={plugText}
            addPostClass={addPost}
            where="teip"
          />

          {data.length > 9 && (
            <>
              {notPosts.length > 0 ? (
                <button style={{ opacity: 0 }} ref={btnRef}></button>
              ) : (
                <div className={style.no_posts_plug}>
                  <button
                    className={style.no_posts_plug_text}
                    onClick={scrollBarFunction}
                  >
                    Вы посмотрели все посты
                  </button>
                </div>
              )}
            </>
          )}
          {newPosts && (
            <div className={style.loader_div}>
              <div>
                <img style={{ width: 70, height: 70 }} src={loader} alt="" />
                <h3 className={style.loader_text}>Загрузка...</h3>
              </div>
            </div>
          )}
        </>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </>
  );
};

export default TeypProfilePosts;
