import React, { useState } from "react";
import style from "./adding.module.css";
import deleteImage from "./img/Delete.svg";

const AddindImage = ({
    index,
    srcId,
    data,
    setMediaState,
    setGlobalMediaState,
    globalMediaState,
}) => {
    const handleClcikImageDelete = () => {
        setMediaState(data.filter((item) => item !== srcId));
        if (srcId === globalMediaState) {
            setGlobalMediaState(false);
        }
    };
    const handleChooseClassesImage = () => {
        return srcId === globalMediaState
            ? style.new_image_content + " " + style.new_image_content_active
            : style.new_image_content;
    };

    return (
        <div key={index} className={handleChooseClassesImage()}>
            <img
                onClick={() => handleClcikImageDelete()}
                src={deleteImage}
                className={style.delete_image}
                alt=""
            />

            <img
                onClick={() => {setGlobalMediaState(srcId)}}
                src={URL.createObjectURL(srcId)}
                className={style.new_image}
                alt="Изображение не найдено"
            />
        </div>
    );
};

export default AddindImage;
