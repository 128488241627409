import React, { useState } from "react";
import bellImage from "../img/bell.svg";
import NotifyImage from "../img/Notify.svg";
import PostService from "../../../../API/postService";
import style from "./EventGroup.module.css";

const Notifications = ({ data }) => {
  const [notifi, setNotifi] = useState(data?.is_remind);

  const handleClickNotifications = () => {
    setNotifi(!notifi);
    PostService.addAndRemoveComplaints(data.id);
  };

  return (
    <div
      className={style.event_subtitle_content_one}
      onClick={() => handleClickNotifications()}
    >
      <img
        className={style.event_subtitle_bell}
        src={!notifi ? bellImage : NotifyImage}
        alt=""
      />
      <h3 className={style.event_subtitle_bell_text}>
        {!notifi ? "Напомнить" : "Откл. напоминание"}
      </h3>
    </div>
  );
};

export default Notifications;
