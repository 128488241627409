import React, { useState, useRef } from "react";
import style from "./adding.module.css";
import checkBoxImage from "./img/Checkbox.svg";
import checkBoxTrueImage from "./img/CheckboxTrue.svg";
import trueIcon from "./img/Vector.svg";
import { UseClosing } from "./../../../../hooks/useClosing";
import { rootControls } from "./../../../../controllers/RootConroller";
const AddingPostVisible = ({
  postVisible,
  commentVisible,
  setPostVisible,
  setCommentVisible,
  where,
  position,
}) => {
  const [visible, setVisible] = useState(false);
  const [commVisible, setCommVisible] = useState(false);
  const ref = useRef();
  UseClosing(ref, setVisible);
  UseClosing(ref, setCommVisible);

  const handleClickPostVisible = (key) => {
    if (key === "all") {
      setPostVisible((prev) => ({ [key]: !prev[key] }));
    } else {
      setPostVisible((prev) => ({
        ...prev,
        all: false,
        [key]: !prev[key],
      }));
    }
  };
  const handleChooseClasses = (key) => {
    return postVisible[key]
      ? style.checkbox_text
      : style.checkbox_text + " " + style.checkbox_text_disabled;
  };
  const handleChooseTitle = () => {
    let arrs = [];
    let title = "";

    Object.entries(postVisible).map((item) => {
      if (item[0] === "all" && item[1]) {
        return (title = "всем");
      } else if (item[1]) {
        arrs.push(item[0]);
        if (arrs.length === 1) {
          switch (arrs[0]) {
            case "followers":
              return (title = "подписч.");
            case "family":
              return (title = "семье");
            case "teip":
              return (title = "тейпу");

            default:
              break;
          }
        } else {
          return (title = `${arrs.length} групп.`);
        }
      }
    });
    return title;
  };
  const handleClickComment = (obj) => {
    setCommentVisible(obj);
    setCommVisible(false);
  };



  return (
    <div className={style.visible_div} ref={ref}>
      <div
        className={style.actions_div}
        style={{ alignItems: position === "top" ? "end" : "start" }}
      >
        {where !== "teip" && (
          <button
            className={style.post_visible_button}
            onClick={() => setVisible((prev) => !prev)}
            type="button"
          >
            Показывать {handleChooseTitle()}
          </button>
        )}
        {visible && (
          <div
            className={style.checkbox_div}
            style={{
              marginBottom: position === "top" && 20,
              marginTop: position !== "top" && 20,
            }}
          >
            {rootControls("ClosedAccount") && (
              <div
                className={style.checkbox_content}
                onClick={() => handleClickPostVisible("all")}
              >
                <img
                  src={postVisible.all ? checkBoxTrueImage : checkBoxImage}
                  alt="Меня держат тут насильно, помогите мне("
                  className={style.checkbox}
                />
                <span className={handleChooseClasses("all")}>
                  Всем пользователям
                </span>
              </div>
            )}
            <div
              className={style.checkbox_content}
              onClick={() => handleClickPostVisible("followers")}
            >
              <img
                src={postVisible.followers ? checkBoxTrueImage : checkBoxImage}
                alt="Меня держат тут насильно, помогите мне("
                className={style.checkbox}
              />
              <span className={handleChooseClasses("followers")}>
                Подписчикам
              </span>
            </div>
            <div
              className={style.checkbox_content}
              onClick={() => handleClickPostVisible("family")}
            >
              <img
                src={postVisible.family ? checkBoxTrueImage : checkBoxImage}
                alt="Меня держат тут насильно, помогите мне("
                className={style.checkbox}
              />
              <span className={handleChooseClasses("family")}>Моей семье</span>
            </div>
            <div
              className={style.checkbox_content}
              onClick={() => handleClickPostVisible("teip")}
            >
              <img
                src={postVisible.teip ? checkBoxTrueImage : checkBoxImage}
                alt="Меня держат тут насильно, помогите мне("
                className={style.checkbox}
              />
              <span className={handleChooseClasses("teip")}>Участникам тейпа</span>
            </div>
          </div>
        )}
      </div>
      <div
        className={style.actions_div}
        style={{ alignItems: position === "top" ? "end" : "start" }}
      >
        <button
          className={style.post_visible_button}
          onClick={() => setCommVisible((prev) => !prev)}
          type="button"
        >
          {commentVisible.title}
        </button>
        {commVisible && (
          <div
            className={style.checkbox_div + " " + style.checkbox_div_second}
            style={{
              marginBottom: position === "top" && 20,
              marginTop: position !== "top" && 20,
            }}
          >
            <div
              className={
                style.checkbox_content + " " + style.checkbox_content_second
              }
              onClick={() =>
                handleClickComment({
                  title: "Комментарии вкл.",
                  value: "1",
                })
              }
            >
              <span className={style.checkbox_text}>Вкл.</span>
              {commentVisible.value === "1" && (
                <img className={style.true_icon} src={trueIcon} alt="" />
              )}
            </div>

            <div
              className={
                style.checkbox_content + " " + style.checkbox_content_second
              }
              onClick={() =>
                handleClickComment({
                  title: "Комментарии выкл.",
                  value: "0",
                })
              }
            >
              <span className={style.checkbox_text}>Выкл.</span>
              {commentVisible.value === "0" && (
                <img className={style.true_icon} src={trueIcon} alt="" />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddingPostVisible;
