import React from "react";
import ConfirmUser from "../../../../sign/ConfirmUser";

const ConfirmUserBlock = () => {
  return (
    <div
      style={{
        display: "flex",
        marginLeft: 20,
        width: 666,
        border: "1px solid #e3eff1",
        padding: 20,
        borderRadius: 6
      }}
    >
      <ConfirmUser />
    </div>
  );
};

export default ConfirmUserBlock;
