import React, { useState, useEffect } from "react";
import LogoBack from "./LogoBack";
import style from "./sign.module.css";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import PostService from "./../../API/postService";
import LoaderBox from "./../content/loaders/LoadersBox";
import SendCodeBLock from "./SendCodeBLock";
import EyeClosed from "./img/EyeClosed.svg";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [number, setNumber] = useState("");
    const [token, setToken] = useState();
    const [loading, setLoading] = useState(false);
    const [userNotFound, setUserNotFound] = useState();
    const [codeRes, setCodeRes] = useState();
    const [passwordType, setPasswordType] = useState("password");
    const [passwordNoSimilar, setPasswordNoSimilar] = useState(false);
    const [modal, setModal] = useState();
    const [passwordValue, setPasswordValue] = useState({
        pass1: "",
        pass2: "",
    });

    const [codeNumber, setCodeNumber] = useState({
        number1: "",
        number2: "",
        number3: "",
        number4: "",
    });

    const handleClickSendCodeButton = async () => {
        setLoading(true);
        PostService.sendNumberOfCode("reset", number)
            .then((res) => {
                console.log("chert", res);
                if (res?.accept) {
                    setCodeRes(res.accept);
                    setLoading(false);
                    setUserNotFound(false);
                } else {
                    setUserNotFound(res);
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log(e, "fuck");
            });
    };
    const handleClickKeyEnter = (key) => {
        if (key.keyCode === 13) {
            handleClickSendCodeButton();
        }
    };
    const handleClickResetPassword = () => {
        if (passwordValue.pass1 === passwordValue.pass2) {
            PostService.throwPassword(token, passwordValue, number)
                .then((res) => {
                    if (res === undefined) {
                        setPasswordNoSimilar(true);
                    } else {
                        setModal(true);
                    }
                })
                .catch(() => setPasswordNoSimilar(true));
        } else {
            setPasswordNoSimilar(true);
        }
    };
    return (
        <div className={style.registrashionWrap}>
            {codeRes && (
                <div className={style.modal_code}>
                    <SendCodeBLock
                        codeForResetPass={true}
                        numberForResetPas={number}
                        codeNumber={codeNumber}
                        setCodeNumber={setCodeNumber}
                        setStep={setStep}
                        setCodeRes={setCodeRes}
                        setToken={setToken}
                    />
                </div>
            )}
            <LogoBack />
            <h1 className={style.regZag}>Сброс пароля</h1>
            <h2 className={style.regZagText} style={{ maxWidth: 440 }}>
                Введите номер телефона, который вы использовали при регистрации,
                и мы вышлем вам код для сброса пароля.
            </h2>
            <h3 className={style.regInfoText} style={{ maxWidth: 440 }}>
                В целях безопасности мы НЕ храним ваш пароль. Поэтому будьте
                уверены, что мы никогда не отправим ваш пароль по электронной
                почте.
            </h3>

            {step === 1 ? (
                <>
                    <div
                        style={{
                            display: "flex",
                            marginBottom: 24,
                        }}
                    >
                        <div className={style.user_not_found}>
                            <InputMask
                                type="number"
                                onChange={(e) => setNumber(e.target.value)}
                                onKeyDown={(e) => handleClickKeyEnter(e)}
                                value={number}
                                className={
                                    style.signInput + " " + style.resetInput
                                }
                                placeholder="Введите номер телефона"
                            />
                            {userNotFound && (
                                <span
                                    style={{ color: "#FF4B1F", marginTop: 5 }}
                                >
                                    {userNotFound}
                                </span>
                            )}
                        </div>
                        {loading && (
                            <div className={style.loader_box}>
                                <LoaderBox />
                            </div>
                        )}
                    </div>
                    {number.length < 12 ? (
                        <button
                            onClick={() => handleClickSendCodeButton()}
                            className={style.signBtn + " " + style.resetBtn}
                        >
                            Отправить код
                        </button>
                    ) : (
                        <button
                            className={style.signBtn + " " + style.resetBtn}
                            disabled
                        >
                            Отправить код
                        </button>
                    )}
                </>
            ) : (
                <>
                    {modal && (
                        <div className={style.modal}>
                            <div className={style.modal_content}>
                                <h2 className={style.modal_suptitle}>
                                    Вы изменили пароль!
                                </h2>
                                <h4 className={style.modal_subtitle}>
                                    Войдите в ваш аккаунт уже с новым паролем.
                                </h4>
                                <button
                                    className={style.modal_button}
                                    onClick={() => navigate("/")}
                                >
                                    Войти
                                </button>
                            </div>
                        </div>
                    )}
                    <div
                        className={style.regInputBlock}
                        style={{ marginBottom: 0 }}
                    >
                        <InputMask
                            type={passwordType}
                            value={passwordValue.pass1}
                            onChange={(e) =>
                                setPasswordValue((prev) => ({
                                    ...prev,
                                    pass1: e.target.value,
                                }))
                            }
                            maxLength="30"
                            autoComplete="off"
                            className={style.signInput + " " + style.regInput}
                            placeholder="Пароль"
                        />
                        <InputMask
                            type={passwordType}
                            value={passwordValue.pass2}
                            onChange={(e) =>
                                setPasswordValue((prev) => ({
                                    ...prev,
                                    pass2: e.target.value,
                                }))
                            }
                            maxLength="30"
                            autoComplete="off"
                            className={style.signInput + " " + style.regInput}
                            placeholder="Повторите пароль"
                        />
                        <img
                            src={EyeClosed}
                            alt="Показать пароль"
                            style={{
                                width: 24,
                                height: 24,
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                setPasswordType((prev) =>
                                    prev === "text" ? "password" : "text"
                                )
                            }
                        />
                    </div>
                    {passwordNoSimilar && (
                        <span style={{ color: "#FF4B1F", marginTop: 5 }}>
                            Проверьте корректность паролей
                        </span>
                    )}
                    <button
                        className={style.signBtn + " " + style.resetBtn}
                        onClick={handleClickResetPassword}
                        style={{ marginTop: 20 }}
                    >
                        Сбросить пароль
                    </button>
                </>
            )}
            <div
                className={style.signItemsBLock}
                style={{ marginTop: 36, justifyContent: "flex-start" }}
            >
                <h2 className={style.signText}>У вас нет аккаунта?</h2>
                <Link to={"/registrashion"} className={style.signLink}>
                    Зарегистрируйтесь
                </Link>
            </div>
        </div>
    );
};

export default ResetPassword;
