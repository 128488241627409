import React from "react";
import FamilyChilds from "./familyChilds";
import styleFamily from "./familyInformation.module.css";
import FamilyParents from "./familyParents";
import FamilySiblings from "./familySiblings";
import UserInformation from "./userInformation";
const FamilyInformation = ({ userInfo, user }) => {
    return (
        <>
            <div className={styleFamily.popup}>
                <div className={styleFamily.popupBlocks}>
                    <UserInformation userInfo = {userInfo} user = {user}/>
                    <FamilyParents userInfo={userInfo}/>
                </div>
                <div className={styleFamily.popupBlocks}>
                    <FamilySiblings userInfo = {userInfo} />
                    <FamilyChilds userInfo={userInfo} />
                </div>
            </div>
        </>
    );
};

export default FamilyInformation;
