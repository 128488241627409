import React, { useContext, useState } from "react";
import LogoBack from "./LogoBack";
import style from "./sign.module.css";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import TeypSearchPage from "./TeypSearchPage";
import EyeClosed from "./img/EyeClosed.svg";
import axios from "axios";
import SendCodeBLock from "./SendCodeBLock";
import { RegistrationContext } from "../context";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ru from "react-phone-number-input/locale/ru";

const Registrashion = () => {
  const { regInfo, setRegInfo } = useContext(RegistrationContext);
  const [step, setStep] = useState(1);
  const [error, setError] = useState();
  const [sendCodeBlock, setSendCodeBlock] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const testEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const [valuePhone, setValuePhone] = useState();
  const [typeDate, setTypeDate] = useState("text");
  const [codeNumber, setCodeNumber] = useState({
    number1: "",
    number2: "",
    number3: "",
    number4: "",
  });

  const genderBtnActiv = {
    backgroundColor: "#2199CC",
    color: "white",
  };

  //Кнопка далее
  const handleNextClick = (e) => {
    e.preventDefault();
    if (
      regInfo.f_name.length < 2 ||
      regInfo.name.length < 2 ||
      regInfo.l_name.length < 2 ||
      !regInfo.teip ||
      regInfo.born.length !== 10
    ) {
      setError("Не все поля заполненны");
    } else if (
      regInfo.born.split("-")[2] > "31" ||
      regInfo.born.split("-")[1] > "12" ||
      regInfo.born.split("-")[0] > "2022" ||
      regInfo.born.split("-")[2] <= "00" ||
      regInfo.born.split("-")[1] <= "00" ||
      regInfo.born.split("-")[0] <= "1900"
    ) {
      setError("Дата введена некорректно");
    } else {
      setError();
      setStep(2);
    }
  };

  //Показать пароль
  const handlePasswordClick = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  //Кнопка регистрация
  const handleRegistrashionClick = (e) => {
    e.preventDefault();
    // Со специальными символами
    // regInfo.password.search(/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9!@#$%^&*a-zA-Z]{8,}$/)
    if (
      regInfo.password.search(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*)[0-9a-zA-Z]{8,}$/
      ) === -1 ||
      regInfo.password !== regInfo.password_confirmation ||
      !testEmail.test(regInfo.email) ||
      valuePhone.split("+")[1].length < 10 ||
      regInfo.email[0].search(/[0-9a-zA-Z]/) === -1 ||
      regInfo.email[regInfo.email.length - 1].search(/[a-zA-Z]/) === -1
    ) {
      setError("Введенная информация некорректна");
    } else {
      setError();
      setRegInfo({
        ...regInfo,
        phone: valuePhone.split("+")[1],
      });
      axios
        .post(
          `https://gargalo.ru/api/sms/send-code`,
          { phone: valuePhone.split("+")[1], email: regInfo.email },
          {
            headers: {
              "content-type": "application/json",
              "User-Token": 1,
            },
          }
        )
        .then(() => {
          setError();
          setSendCodeBlock(true);
        })
        .catch((res) => {
          setError(res.response.data.errors);
        });
    }
  };
  return (
    <div className={style.registrashionWrap}>
      {sendCodeBlock && (
        <SendCodeBLock codeNumber={codeNumber} setCodeNumber={setCodeNumber} />
      )}
      <LogoBack />
      <h1 className={style.regZag}>Регистрация</h1>
      <h2 className={style.regZagText}>Информация должна быть достоверной</h2>
      <h3 className={style.regInfoText}>
        Все поля обязательны для заполнения, проверьте информацию на ошибки.
        После регистрации информация пойдет на модерацию.
      </h3>
      <form autoComplete="off">
        {step === 1 ? (
          <>
            <h2 className={style.regFormZag}>ФИО</h2>
            <div className={style.regInputBlock}>
              <InputMask
                type="text"
                onChange={(e) =>
                  setRegInfo({
                    ...regInfo,
                    f_name: e.target.value.replace(
                      /[a-zA-Z, 0-9, &\/\\#, \[\]+!№@^_\=|`;()$~%.'":*?<>{}]/,
                      ""
                    ),
                  })
                }
                value={regInfo.f_name}
                className={style.signInput + " " + style.regInput}
                placeholder="Фамилия"
                autoComplete="off"
                onPasteCapture={(e) => e.preventDefault()}
              />
              <InputMask
                type="text"
                onChange={(e) =>
                  setRegInfo({
                    ...regInfo,
                    name: e.target.value.replace(
                      /[a-zA-Z, 0-9, &\/\\#, \[\]+!№@^_\=|`;()$~%.'":*?<>{}]/,
                      ""
                    ),
                  })
                }
                value={regInfo.name}
                className={style.signInput + " " + style.regInput}
                placeholder="Имя"
                autoComplete="off"
                onPasteCapture={(e) => e.preventDefault()}
              />
              <InputMask
                type="text"
                onChange={(e) =>
                  setRegInfo({
                    ...regInfo,
                    l_name: e.target.value.replace(
                      /[a-zA-Z, 0-9, &\/\\#, \[\]+!№@^_\=|`;()$~%.'":*?<>{}]/,
                      ""
                    ),
                  })
                }
                value={regInfo.l_name}
                className={style.signInput + " " + style.regInput}
                placeholder="Отчество"
                autoComplete="off"
                onPasteCapture={(e) => e.preventDefault()}
              />
            </div>
            <h2 className={style.regFormZag}>Дополнительная информация</h2>
            <div className={style.regInputBlock} style={{ marginBottom: 0 }}>
              <TeypSearchPage setRegInfo={setRegInfo} regInfo={regInfo} />
              <button
                className={style.genderBtn}
                style={regInfo.gender === "male" ? genderBtnActiv : {}}
                onClick={(e) => {
                  e.preventDefault();
                  setRegInfo({
                    ...regInfo,
                    gender: "male",
                  });
                }}
              >
                Муж
              </button>
              <button
                className={style.genderBtn}
                style={regInfo.gender === "female" ? genderBtnActiv : {}}
                onClick={(e) => {
                  e.preventDefault();
                  setRegInfo({
                    ...regInfo,
                    gender: "female",
                  });
                }}
              >
                Жен
              </button>
              <input
                // type="date"
                // mask="99-99-9999"
                // maskChar=""
                type={typeDate}
                onMouseMove={() => {
                  if (typeDate !== "date") {
                    setTypeDate("date");
                  }
                }}
                // onMouseOut={() => {
                //   if (typeDate === "date") {
                //     setTypeDate("text");
                //   }
                // }}
                onChange={(e) =>
                  setRegInfo({
                    ...regInfo,
                    born: e.target.value,
                  })
                }
                value={regInfo.born}
                className={
                  style.signInput +
                  " " +
                  style.regInput +
                  " " +
                  style.regInputDate
                }
                placeholder="Дата рождения"
                autoComplete="off"
              />
            </div>
            {error && (
              <h2
                className={style.signText}
                style={{ color: "#EB5757", marginTop: 10 }}
              >
                {error}
              </h2>
            )}
            <div className={style.regInputBlock} style={{ marginTop: 48 }}>
              <button
                className={style.signBtn + " " + style.nextBtn}
                onClick={handleNextClick}
              >
                Далее
              </button>
              <h2 className={style.nextText + " " + style.nextTextInfo}>
                Уже есть аккаунт?
              </h2>
              <Link
                to={"/"}
                className={style.nextText + " " + style.nextTextLink}
              >
                Войдите
              </Link>
            </div>
          </>
        ) : (
          step === 2 && (
            <>
              <h2 className={style.regFormZag}>Контактная информация</h2>
              <div className={style.regInputBlock}>
                <InputMask
                  type="email"
                  onChange={(e) =>
                    setRegInfo({
                      ...regInfo,
                      email: e.target.value.replace(/[а-яА-яЁё]/, ""),
                    })
                  }
                  value={regInfo.email}
                  className={style.signInput + " " + style.regInput}
                  placeholder="Email"
                  autoComplete="off"
                />
                <PhoneInput
                  international
                  defaultCountry="RU"
                  labels={ru}
                  onChange={setValuePhone}
                  onPasteCapture={(e) => e.preventDefault()}
                  value={valuePhone}
                  className={style.signInput + " " + style.regInput}
                  placeholder="Номер телефона"
                  autoComplete="off"
                />
              </div>
              <h2 className={style.regFormZag}>Безопасность</h2>
              <div className={style.regInputBlock} style={{ marginBottom: 20 }}>
                <InputMask
                  type={passwordType}
                  maxLength="30"
                  onChange={(e) =>
                    setRegInfo({
                      ...regInfo,
                      password: e.target.value.replace(/[а-яА-яЁё]/, ""),
                    })
                  }
                  value={regInfo.password}
                  autoComplete="new-password"
                  className={style.signInput + " " + style.regInput}
                  placeholder="Пароль"
                />
                <InputMask
                  type={passwordType}
                  maxLength="30"
                  onChange={(e) =>
                    setRegInfo({
                      ...regInfo,
                      password_confirmation: e.target.value.replace(
                        /[а-яА-яЁё]/,
                        ""
                      ),
                    })
                  }
                  value={regInfo.password_confirmation}
                  autoComplete="off"
                  className={style.signInput + " " + style.regInput}
                  placeholder="Повторите пароль"
                />
                <img
                  src={EyeClosed}
                  alt="Показать пароль"
                  style={{
                    width: 24,
                    height: 24,
                    cursor: "pointer",
                  }}
                  onClick={handlePasswordClick}
                />
              </div>
              {error && (
                <h2
                  className={style.signText}
                  style={{
                    color: "#EB5757",
                    marginBottom: 10,
                  }}
                >
                  {error}
                </h2>
              )}
              <h3
                className={style.regInfoText}
                style={{ maxWidth: 385, marginBottom: 32 }}
              >
                *Пароль должен содержать не менее 8 знаков, включая строчные и
                прописные латинские буквы, и цифры.
              </h3>
              <button
                className={style.signBtn + " " + style.registrashionBtn}
                onClick={handleRegistrashionClick}
              >
                Зарегистрироваться
              </button>
              <h2 className={style.linkText}>
                Нажимая кнопку зарегистрироваться Вы принимаете{" "}
                <Link to={"/privacy"} target="_blank">
                  политику конфиденциальности
                </Link>{" "}
                и{" "}
                <Link to={"/agreement"} target="_blank">
                  пользовательское соглашение
                </Link>
                , а также даёте{" "}
                <Link to={"/personal_data"} target="_blank">
                  согласие на обработку персональных данных
                </Link>{" "}
              </h2>
            </>
          )
        )}
      </form>
    </div>
  );
};

export default Registrashion;
