import React, { useRef } from "react";
import logo from "../sign/img/logo.svg";
import icon from "../content/img/UserIcon.svg";
import sign from "../content/img/SignOut.svg";
import filter from "../content/img/Filter.svg";
import X from "./connection/img/x.svg";
import style from "./style.module.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GSearch from "../content/img/GSearch.svg";
import TSearch from "../content/img/TSearch.svg";
import profileTeyp from "../content/img/profile.svg";
import { useEffect } from "react";
import { renderer } from "../utils/renderer";
import Loader from "./Loader";
import "./connection/Connection.css";
import axios from "axios";
import { useContext } from "react";
import { RegistrationContext } from "../context";
import Logo_Notifications from "./img/logo_notifications.svg";
import help from "./img/Help.svg";
import setings from "./img/Setings.svg";
import profile from "./img/logo_profile.svg";
import CaretDown from "./img/CaretDown.svg";
import Down from "./img/Down.svg";
import Connectionnotfound from "./img/Connectionnotfound.svg";
import zvyk from "./messages/img/music.mp3";
import $ from "jquery";
import search from "./img/Search.svg";
import CloseHeader from "./img/CloseHeader.svg";
import ReactInputMask from "react-input-mask";

const Header = () => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const {
    setAuthorization,
    notificationsCount,
    setNotificationsCount,
    user,
    setUser,
  } = useContext(RegistrationContext);
  const [people, setPeople] = useState([]);
  const date = new Date();
  const [filterInfo, setFilterInfo] = useState({
    fio: "",
    filter: ["filter[]=teips&", "filter[]=members&", "filter[]=persons&"],
    pol: 0,
    polName: "Любой",
    from: 0,
    to: 150,
    yearsFrom: user.min ? user.min : "",
    yearsTo: user.max ? user.max : date.getFullYear(),
    usersOnly: "",
  });
  const navigation = useNavigate();
  const inputRef = useRef(null);
  const searchRef = useRef(null);
  const genderRef = useRef(null);
  const [openFilterBlock, setOpenFilterBlock] = useState(false);
  const [openSearchBlock, setOpenSearchBlock] = useState(false);
  const [genderBlock, setGenderBlock] = useState(false);

  const filters = [
    { filterValue: "filter[]=teips&", filterName: "Тейпы" },
    { filterValue: "filter[]=members&", filterName: "Пользователи" },
    { filterValue: "filter[]=persons&", filterName: "Участники древа" },
  ];
  const genders = [
    { genderValue: 0, genderName: "Любой" },
    { genderValue: 1, genderName: "Мужской" },
    { genderValue: 2, genderName: "Женский" },
  ];
  const filterStyle = {
    backgroundColor: "#E3EFF1",
    color: "#02191E",
    padding: "6px 12px",
  };

  //Получение списка пользователей при первом открытии
  const handleSearchClick = async () => {
    await axios({
      method: "GET",
      url: `https://gargalo.ru/api/getid?fio=${
        filterInfo.fio
      }&${filterInfo.filter.join("")}pol=${filterInfo.pol}&from=${
        filterInfo.yearsFrom
      }&to=${filterInfo.yearsTo}&${filterInfo.usersOnly}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setPeople(res.data.data);
    });
  };

  //Получение списка пользователей в поиске при пустом инпуте
  useEffect(() => {
    if (filterInfo.fio.length === 0 && openSearchBlock) {
      const setTimer = setTimeout(() => {
        axios({
          method: "GET",
          url: `https://gargalo.ru/api/getid?fio=${
            filterInfo.fio
          }&${filterInfo.filter.join("")}pol=${filterInfo.pol}&from=${
            filterInfo.yearsFrom
          }&to=${filterInfo.yearsTo}&${filterInfo.usersOnly}`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }).then((res) => {
          setPeople(res.data.data);
        });
      }, 200);
      return () => clearTimeout(setTimer);
    }
  }, [filterInfo, openSearchBlock]);

  //Получение списка пользователей в поиске
  useEffect(() => {
    if (filterInfo.fio.length > 0) {
      const setTimer = setTimeout(() => {
        axios({
          method: "GET",
          url: `https://gargalo.ru/api/getid?fio=${
            filterInfo.fio
          }&${filterInfo.filter.join("")}pol=${filterInfo.pol}&from=${
            filterInfo.yearsFrom
          }&to=${filterInfo.yearsTo}&${filterInfo.usersOnly}`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }).then((res) => {
          setPeople(res.data.data);
        });
      }, 200);
      return () => clearTimeout(setTimer);
    }
  }, [filterInfo]);

  //Открытие блока с фильтрацией
  const handleFilterChange = (e) => {
    e.stopPropagation();
    setOpenFilterBlock(!openFilterBlock);
  };

  //Закрытие блока поиска при клике на пустую область
  const closeBlock = () => {
    setOpenFilterBlock(false);
    setOpenSearchBlock(false);
  };

  useEffect(() => {
    if (searchRef.current) {
      const onClick = (e) =>
        searchRef.current.contains(e.target) || closeBlock();
      document.addEventListener("click", onClick);
      return () => document.removeEventListener("click", onClick);
    }
  }, [searchRef.current]);

  const closeGenderBlock = () => {
    setGenderBlock(false);
  };

  useEffect(() => {
    if (genderRef.current) {
      const onClick = (e) =>
        genderRef.current.contains(e.target) || closeGenderBlock();
      document.addEventListener("click", onClick);
      return () => document.removeEventListener("click", onClick);
    }
  }, [genderRef.current]);

  //Удаление файлов куки
  const id = document.cookie.match(/id=(.+?)(;|$)/);

  const CookiesDelete = () => {
    var cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      if (cookies[i].trim() !== "cookie=yes") {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        document.cookie =
          name + "=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        // document.cookie = `${cookies[i]}; max-age=-1`;
      }
    }
    if (window.fireBaseToken) {
      axios.post(
        `https://gargalo.ru/api/user/removetoken`,
        {
          token: window.fireBaseToken,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      );
    }
    setUser();
    window.userParam = null;
    setAuthorization(false);
    navigation("/");
  };

  //Добавление уведомлений
  window.startNotifCount = (notif) => {
    let audio = new Audio(zvyk);
    audio.play();
    setNotificationsCount(notif.data.count);
  };

  //Элементы фильтра
  const FilterItem = ({ filterValue, name }) => {
    //Проверка на наличие фильтра в массиве
    const handleToggleFilter = (e) => {
      e.stopPropagation();
      if (filterInfo.filter.includes(filterValue)) {
        setFilterInfo({
          ...filterInfo,
          filter: filterInfo.filter.filter((i) => i !== filterValue),
        });
      } else {
        setFilterInfo({
          ...filterInfo,
          filter: filterInfo.filter.concat(filterValue),
        });
      }
    };

    return (
      <div
        className={style.filterChek}
        style={!filterInfo.filter.includes(filterValue) ? filterStyle : {}}
        onClick={handleToggleFilter}
      >
        {name}
        {filterInfo.filter.includes(filterValue) && (
          <span className={style.filterChekActiv} />
        )}
      </div>
    );
  };

  //Согласие на обработку куки
  const [cookieBLock, setCookieBLock] = useState();
  const cookie = document.cookie.match(/cookie=(.+?)(;|$)/);

  const cookieAgreement = () => {
    if (!cookie || cookie[1] !== "yes") {
      setCookieBLock(true);
    }
  };

  const cookieAgreementAppend = () => {
    let date = new Date(Date.now() + 86400e3 * 7);
    date = date.toUTCString();
    document.cookie = "cookie=yes; secure; expires=" + date;
    setCookieBLock(false);
  };

  //Работа с построением родственных связей
  const [openKingship, setOpenKingship] = useState();
  const [dataReals, setDataReals] = useState();
  const [kinship, setKinship] = useState();
  const [whoId, setWhoId] = useState();
  const [kinshiploading, setKinshiploading] = useState(true);
  const [error, setError] = useState(false);

  //Запуск функции по отрисовке
  useEffect(() => {
    if (kinship) {
      if (dataReals) {
        window.showSpouceTree();
      } else {
        window.showTree();
      }
    }
  }, [kinship, dataReals]);

  //Кнопка поиска род. свзяи
  const handleOpenKinshipBlock = (peopleId) => {
    setError(false);
    if (openKingship) {
      setOpenKingship(false);
    } else {
      setOpenKingship(true);
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/searchrelated/${user.node_id}/${peopleId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      })
        .then((res) => {
          setDataReals(res.data.rels[0]);
          if (res.data.result.items === false) {
            axios
              .post(
                `https://gargalo.ru/api/loadrelatedtree?map=${JSON.stringify(
                  res.data.rels[0]
                )}&id1=${user.node_id}&id2=${peopleId}`,
                {
                  map: JSON.stringify(res.data.rels[0]),
                  id1: user.node_id,
                  id2: peopleId,
                },
                {
                  headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token[1]}`,
                    "User-Token": 1,
                  },
                }
              )
              .then((res) => {
                setKinshiploading(false);
                setKinship(res.data);
                setWhoId(peopleId);
              })
              .catch((error) => {
                setKinshiploading(false);
                setError(true);
              });
          } else {
            setKinshiploading(false);
            setKinship(res.data.result);
            setWhoId(peopleId);
          }
        })
        .catch((error) => {
          setKinshiploading(false);
          setError(true);
        });
    }
  };

  //Функция отрисовки
  const kinshipFun = () => {
    //Отрисовка линий
    const createLines = () => {
      $("div#related-result").show(0);
      var nodes = $(".node").not(".ignore");
      nodes.find("div").remove();
      //var nodes = $('.node').not('.ignore');
      var from_parent = $('<div class="from-parent"></div>');
      var to_child = $('<div class="to-child"></div>');
      var line = $('<div class="gor-line"></div>');
      var tree_par = $(".tree-root");

      nodes.each(function () {
        var el = $(this);

        var id = $(this).data("id");

        var childs = $(".parent" + id, tree_par);
        if (
          childs.length < 1 &&
          el.data("pol") > 1 &&
          el.parent().children(".gender1").length < 1
        ) {
          childs = $(".mather" + id, tree_par);
        }

        // if (childs.length == 1){
        // $(this).append(from_parent.clone());
        // childs.append(to_child.clone());
        // console.log($(this))
        // if ($(this).next().length > 0){
        // if ($(this).next().hasClass('spouce-item')){
        // $(this).find('.from-parent').addClass('left100')
        // }
        // }
        // }else if (childs.length > 1){
        if (childs.length > 0) {
          $(this).append(from_parent.clone().addClass("with_short_bottom"));
        }

        if (!el.hasClass("spouce-item")) {
          if (el.next().length > 0) {
            if (el.next().hasClass("spouce-item")) {
              el.find(".from-parent").addClass("left100");
            }
          }
        } else {
          if (el.prev().find(".from-parent").length > 0) {
            el.find(".from-parent").addClass("left-100");
          }
        }
        //Мои изменения
        if (!$(".from-parent", el)[0]) {
          return;
        }
        //конец моих изменений
        let pl = $(".from-parent", el).offset().left;

        childs.each(function () {
          var tc = to_child.clone();
          $(this).append(tc);
          var cl = $(".to-child", $(this)).offset().left;

          if (
            pl == cl ||
            (pl <= cl + 1 && pl >= cl - 1) ||
            pl + el.outerWidth() / 2 == cl
          ) {
            tc.addClass("with_high_top");
          } else if (pl > cl) {
            var append_line = line.clone().addClass("with_right_top_round");
            $(this).append(append_line);
            append_line.width(0);
            var w = pl - append_line.offset().left;
            append_line.width(w);
            // if (el.next().hasClass('spouce-item')){
            // w-=10
            // }else{
            // w-=9
            // }
            // w-=2
            tc.addClass("with_right_round_top");
          } else if (pl < cl) {
            var append_line = line.clone().addClass("with_left_top_round");
            $(this).append(append_line);
            append_line.width(0);
            var w = pl - append_line.offset().left;
            append_line.width(Math.abs(w));

            // w = cl-pl;
            // if (el.next().hasClass('spouce-item')){
            // w-=11;
            // }else{
            // w-=12
            // }
            tc.addClass("with_left_round_top");
          }

          if ($(this).find(".gor-line").length > 0) {
            var gl = $(this).find(".gor-line");
            var fp = el.find(".from-parent");

            if (gl.width() < 1) {
              if (gl.offset().left != fp.offset().left) {
                gl.css(
                  "right",
                  41 - Math.abs(gl.offset().left - fp.offset().left)
                );
              }
            }
          }
        });
        //}
        //}
      });
    };

    // Отрисовка дерева с картой
    window.showSpouceTree = () => {
      document.querySelector("html").style.overflow = "hidden";
      var tree = $(kinship);
      $("#kinshipContainer").append(tree);

      var teip = "";
      tree.find("ul.tree-root").each(function () {
        teip = $(this).find("a.node").filter(":first").data("teip");
        $(this).addClass("root-" + teip);
      });
      var map = JSON.parse(JSON.stringify(dataReals));

      let userBlock = document
        .getElementById("kinshipContainer")
        .querySelector(`[data-id="${user.node_id}"]`);
      let whoBlock = document
        .getElementById("kinshipContainer")
        .querySelector(`[data-id="${whoId}"]`);
      userBlock.classList.add("kinshipActiv");
      whoBlock.classList.add("kinshipActiv");

      var teips = new Array();

      for (let key in map) {
        if (teips.indexOf(map[key]["teips"][0]) < 0) {
          teips.push(map[key]["teips"][0]);
        }

        if (teips.indexOf(map[key]["teips"][1]) < 0) {
          teips.push(map[key]["teips"][1]);
        }

        if (teips.length == 3) {
          if (
            teips[0] == map[key]["teips"][1] ||
            teips[0] == map[key]["teips"][0]
          ) {
            var t = teips[0];
            teips[0] = teips[1];
            teips[1] = t;
          }
        }
      }

      for (let key in teips) {
        tree.append(tree.find(".root-" + teips[key]));
      }

      for (let key in map) {
        var node1 = $(".li-tree-" + map[key]["ids"][0]);
        var node2 = $(".li-tree-" + map[key]["ids"][1]);

        var node1_left = node1.position().left;
        var node2_left = node2.position().left;

        var from;
        var to;

        if (node1_left < node2_left) {
          node1.children("a").addClass("to-spouce");
          node2.children("a").addClass("from-spouce");
          from = node1;
          to = node2;
          /*if (Math.abs(node2_left-node1_left) > 150){
            if (node2.siblings().length > 0){
              
            }else{
              li_parent = node2.parent().parent()
              prev = li_parent.prev()
              li_parent.after(prev)
            }
            
          }*/
        } else {
          node1.children("a").addClass("from-spouce");
          node2.children("a").addClass("to-spouce");
          from = node2;
          to = node1;
          /*if (Math.abs(node1_left-node2_left) > 150){
            li_parent = node1.parent().parent()
            prev = li_parent.prev()
            li_parent.after(prev)
          }*/
        }

        var root = from.closest(".tree-root");
        var child = root.children("li").children("ul");

        if (
          child.children("li").eq(0).find(from).length ||
          child.children("li").eq(0).is(from)
        ) {
          child.append(child.children("li").eq(0));
        }

        root = to.closest(".tree-root");
        child = root.children("li").children("ul");
        if (
          child.children("li").eq(1).find(to).length ||
          child.children("li").eq(1).is(to)
        ) {
          child.prepend(child.children("li").eq(1));
        }
      }
      $(".tree-root").css("top", 0);
      var ts = tree.find(".to-spouce");
      for (var i = 0; i < ts.length + 1; i++) {
        ts.each(function () {
          var spouce_id = $(this).data("spouce");

          var current = $(this);

          var spouce_item = $(".id" + spouce_id, tree);

          var node1_top = current.position().top;
          var node2_top = spouce_item.position().top;
          var diff = 0;
          var from;

          if (node1_top > node2_top) {
            diff = Math.abs(node2_top - node1_top);
            from = current;
          } else if (node1_top < node2_top) {
            from = spouce_item;
            diff = Math.abs(node1_top - node2_top);
          }
          if (diff > 0) {
            from
              .closest(".tree-root")
              .css({ position: "relative", top: -1 * diff + "px" });
            //from.append($('<span class="vertical-spouce" style="height: '+(diff*2)+'px;"></span>'))
          }
        });

        if (ts.length == 1) break;
      }

      createLines();
      let container = document.getElementById("kinshipContainer");
      let is_down;
      let instance = renderer({
        minScale: 0.1,
        maxScale: 10,
        element: container,
        scaleSensitivity: 15,
        translateX: 0,
        translateY: 0,
        scale: 1,
        height: container.getBoundingClientRect().height,
      });

      container.addEventListener("wheel", (event) => {
        /*if (!event.ctrlKey) {
            return;
          }*/
        event.preventDefault();
        instance.zoom({
          deltaScale: Math.sign(event.deltaY) > 0 ? -1 : 1,
          x: event.pageX,
          y: event.pageY,
        });
      });
      container.addEventListener("dblclick", () => {
        instance.panTo({
          originX: 0,
          originY: 0,
          scale: 1,
        });
      });
      container.addEventListener("mousedown", () => {
        is_down = true;
      });
      container.addEventListener("mouseup", () => {
        is_down = false;
      });
      container.addEventListener("mousemove", (event) => {
        if (!is_down) {
          return;
        }
        event.preventDefault();
        instance.panBy({
          originX: event.movementX,
          originY: event.movementY,
        });
      });
    };

    //Отрисовка дерева без карты
    window.showTree = () => {
      document.querySelector("html").style.overflow = "hidden";
      var tree = $(kinship);
      $("#kinshipContainer").append(tree);

      
      let userBlock = document
        .getElementById("kinshipContainer")
        .querySelector(`[data-id="${user.node_id}"]`);
      let whoBlock = document
        .getElementById("kinshipContainer")
        .querySelector(`[data-id="${whoId}"]`);
      userBlock.classList.add("kinshipActiv");
      whoBlock.classList.add("kinshipActiv");

      createLines();

      let container = document.getElementById("kinshipContainer");
      let is_down;
      let instance = renderer({
        minScale: 0.1,
        maxScale: 10,
        element: container,
        scaleSensitivity: 15,
        translateX: 0,
        translateY: 0,
        scale: 1,
        height: container.getBoundingClientRect().height,
      });

      container.addEventListener("wheel", (event) => {
        /*if (!event.ctrlKey) {
            return;
          }*/
        event.preventDefault();
        instance.zoom({
          deltaScale: Math.sign(event.deltaY) > 0 ? -1 : 1,
          x: event.pageX,
          y: event.pageY,
        });
      });
      container.addEventListener("dblclick", () => {
        instance.panTo({
          originX: 0,
          originY: 0,
          scale: 1,
        });
      });
      container.addEventListener("mousedown", () => {
        is_down = true;
      });
      container.addEventListener("mouseup", () => {
        is_down = false;
      });
      container.addEventListener("mousemove", (event) => {
        if (!is_down) {
          return;
        }
        event.preventDefault();
        instance.panBy({
          originX: event.movementX,
          originY: event.movementY,
        });
      });
    };

    return (
      <div className={style.findKinship__block}>
        <div className={style.kinshipWrap} id="treeWrap">
          <div
            onClick={() => {
              setOpenKingship(false);
              setError(false);
              setKinshiploading(true);
              document.querySelector("html").style.overflow = "visible";
            }}
            style={{
              position: "absolute",
              right: 20,
              top: 20,
              width: 20,
              height: 20,
              cursor: "pointer",
              zIndex: 2,
            }}
          >
            <img src={X} />
          </div>
          {error && (
            <div className={style.errorKinshipWrapp}>
              <div className={style.errorKinshipBlock}>
                <img
                  src={Connectionnotfound}
                  alt="Connectionnotfound"
                  className={style.Connectionnotfound}
                />
                <h2 className={style.errorKinsipZag}>
                  Родственная связь не найдена
                </h2>
                <h2 className={style.errorKinsipTextP}>
                  Родственная связь между вами и целевым человеком не найдена.
                </h2>
              </div>
            </div>
          )}
          {kinshiploading ? (
            <div style={{ marginTop: 350 }}>
              <Loader />
            </div>
          ) : (
            <div className={style.kinshipContainer} id="kinshipContainer"></div>
          )}
        </div>
      </div>
    );
  };

  return (
    <header onLoad={cookieAgreement}>
      {cookieBLock && (
        <div className={style.cookieBlock}>
          <h2 className={style.cookieZag}>
            Согласие на обработку файлов cookie
          </h2>
          <h2 className={style.cookieText}>
            Сайт gargalo.ru использует файлы «cookie» с целью персонализации
            сервисов и повышения удобства пользования сайтом.
          </h2>
          <div className={style.agreementBlock}>
            <button className={style.cookieBtn} onClick={cookieAgreementAppend}>
              Ок
            </button>
          </div>
        </div>
      )}
      {openKingship && kinshipFun()}
      <div className="container_App">
        <div className={style.header}>
          <div className={style.header__find__and__logo}>
            <Link to="/wall">
              <img src={logo} style={{ width: 151, height: 20 }} alt="Logo" />
            </Link>
            <form
              autoComplete="off"
              style={{ position: "relative" }}
              ref={searchRef}
            >
              <div
                className={style.filter}
                onClick={async () => {
                  if (!openSearchBlock) {
                    await handleSearchClick();
                    setOpenSearchBlock(true);
                  }
                }}
              >
                <img
                  src={search}
                  alt="Search"
                  style={{ width: 24, header: 24 }}
                />
                <input
                  ref={inputRef}
                  placeholder="Что будете искать?"
                  type="text"
                  autoComplete="off"
                  className={style.headerSearchInput}
                  onChange={(e) => {
                    setFilterInfo({ ...filterInfo, fio: e.target.value });
                    if (!openSearchBlock) {
                      setOpenSearchBlock(true);
                    }
                  }}
                  value={filterInfo.fio}
                />
                {filterInfo.fio.length > 0 && (
                  <img
                    src={CloseHeader}
                    onClick={(e) => {
                      e.stopPropagation();
                      setFilterInfo({ ...filterInfo, fio: "" });
                      inputRef.current.focus();
                    }}
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: 8,
                      cursor: "pointer",
                    }}
                    alt="Очистить поле"
                  />
                )}
                <img
                  src={filter}
                  onClick={handleFilterChange}
                  style={{ width: 24, height: 24, cursor: "pointer" }}
                  alt="Фильтр"
                />
              </div>
              <div
                className={style.usersBox}
                style={{
                  visibility:
                    openSearchBlock || openFilterBlock ? "visible" : "hidden",
                }}
              >
                {openFilterBlock && (
                  <div className={style.filterWrapp}>
                    <div
                      className={style.filterBlock}
                      style={{ marginBottom: 12 }}
                    >
                      <h2 className={style.filterZag}>Категория</h2>
                      <div style={{ display: "flex" }}>
                        {filters.map((item) => {
                          return (
                            <FilterItem
                              filterValue={item.filterValue}
                              name={item.filterName}
                              key={item.filterValue}
                            />
                          );
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 16,
                      }}
                    >
                      <div
                        className={style.filterBlock}
                        style={{ width: 330, height: 100 }}
                      >
                        <h2 className={style.filterZag}>Год рождения</h2>
                        <div style={{ display: "flex" }}>
                          <div
                            className={style.filterDateWrapp}
                            style={{ marginRight: 8 }}
                          >
                            От
                            <ReactInputMask
                              mask="9999"
                              maskChar=""
                              type={"text"}
                              onChange={(e) =>
                                setFilterInfo({
                                  ...filterInfo,
                                  yearsFrom: e.target.value,
                                })
                              }
                              className={style.filterDate}
                              value={filterInfo.yearsFrom}
                            />
                          </div>
                          <div className={style.filterDateWrapp}>
                            До
                            <ReactInputMask
                              mask="9999"
                              maskChar=""
                              type={"text"}
                              onChange={(e) =>
                                setFilterInfo({
                                  ...filterInfo,
                                  yearsTo: e.target.value,
                                })
                              }
                              className={style.filterDate}
                              value={filterInfo.yearsTo}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={style.filterBlock}
                        style={{ width: 330, height: 100 }}
                      >
                        <h2 className={style.filterZag}>Пол</h2>
                        <div style={{ position: "relative" }} ref={genderRef}>
                          <div
                            className={style.filterGenderWrapp}
                            onClick={() => {
                              if (!genderBlock) {
                                setGenderBlock(true);
                              }
                            }}
                          >
                            {filterInfo.polName}
                            <img
                              src={Down}
                              alt="Down"
                              style={{ width: 24, height: 24 }}
                            />
                          </div>
                          {genderBlock && (
                            <div className={style.filterDropBox}>
                              {genders.map((item) => {
                                return (
                                  <h2
                                    className={style.genderItem}
                                    key={item.genderValue}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setFilterInfo({
                                        ...filterInfo,
                                        pol: item.genderValue,
                                        polName: item.genderName,
                                      });
                                      setGenderBlock(false);
                                    }}
                                  >
                                    {item.genderName}
                                  </h2>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <span className={style.filterBorder}></span>
                  </div>
                )}
                {people.length > 0 && openSearchBlock && (
                  <div className={style.userContainer}>
                    {people?.map((user, index) => {
                      return (
                        <div key={index} className={style.users}>
                          {user.filter === "teips" ? (
                            <Link
                              to={
                                user.moderator_id !== null &&
                                user.moderator_id !== 0 &&
                                `/profileTeyps/${user.moderator_id}`
                              }
                              onClick={closeBlock}
                              className={style.userProfile}
                            >
                              <div className={style.userImg}>
                                <img
                                  src={`https://gargalo.ru${user?.avatar}`}
                                />
                              </div>
                              <div className={style.userNameData}>
                                <h2 className={style.userNameSearch}>
                                  {user.fio}
                                </h2>
                                <span className={style.userData}>
                                  {user.COUNT} чел
                                </span>
                              </div>
                            </Link>
                          ) : (
                            <Link
                              to={
                                user.user_id > 0 &&
                                window.userParam.user_id !== user.user_id
                                  ? `/account/${user.user_id}`
                                  : user.user_id > 0
                                  ? `/profile/${user.user_id}`
                                  : user.user_id === 0 &&
                                    user.node_id > 0 &&
                                    `/tree/node/${user.node_id}`
                              }
                              onClick={closeBlock}
                              className={style.userProfile}
                            >
                              <div className={style.userImg}>
                                <img
                                  src={`https://gargalo.ru${user?.avatar}`}
                                />
                              </div>
                              <div className={style.userNameData}>
                                <h2 className={style.userNameSearch}>
                                  {user.fio}
                                </h2>
                                {user.born !== null &&
                                  user.born !== "--.--.----" && (
                                    <h2 className={style.userData}>
                                      {user.born}
                                    </h2>
                                  )}
                              </div>
                            </Link>
                          )}

                          {/* {user.father !== null && user.father !== " " && (
                            <div className={style.userFatherInfo}>
                              <h2 className={style.userNameFather}>Отец:</h2>
                              <Link
                                to={`/account/${user.f_id}`}
                                className={style.userFather}
                              >
                                {user.father}
                              </Link>
                            </div>
                          )} */}
                          {user.filter === "teips" ? (
                            <Link
                              to={`/tree/${user.id}`}
                              className={style.TSearchBtn}
                              onClick={closeBlock}
                            >
                              <img src={TSearch} />
                            </Link>
                          ) : user.node_id > 0 ? (
                            <img
                              src={GSearch}
                              className={style.GSearchBtn}
                              onClick={() => {
                                handleOpenKinshipBlock(user.id);
                                setKinship();
                                setKinshiploading(true);
                                closeBlock();
                              }}
                            />
                          ) : (
                            <img
                              src={GSearch}
                              style={{ opacity: 0.3, cursor: "default" }}
                              className={style.GSearchBtn}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className={style.headerBlock}>
            <Link to="/notifications">
              <div className={style.notificationWrapp}>
                <img
                  className={style.Logo_Icon}
                  src={Logo_Notifications}
                  style={{ width: 24, height: 24 }}
                  alt="ГарГало"
                />
                {notificationsCount > 0 && (
                  <div
                    className={
                      notificationsCount > 9
                        ? style.Sidebar_Count + " " + style.notificationCount
                        : style.Sidebar_Counts + " " + style.notificationCount
                    }
                  >
                    <h2>{notificationsCount}</h2>
                  </div>
                )}
              </div>
            </Link>
            <div className={style.user__info}>
              <img
                src={user?.avatar ? `https://gargalo.ru/${user?.avatar_small}` : icon}
                alt="UserIcon"
                className={style.usersAvatar}
              />
              <h2 className={style.userName}>
                {user?.nickname ? user?.nickname : user?.name && user.name}
              </h2>
              <img
                src={CaretDown}
                alt="CaretDown"
                style={{ width: 12, height: 12 }}
              />
              <div className={style.navbarHeaderWrapp}>
                <div className={style.navbarWrapp}>
                  <Link className={style.navbarLink} to={`/profile/${id[1]}`}>
                    <img src={profile} alt="navIcon" /> Мой профиль
                  </Link>
                  <Link
                    className={style.navbarLink}
                    to={`/settingsAccaunt/${id[1]}`}
                  >
                    <img src={setings} alt="navIcon" /> Настройки
                  </Link>
                  <Link className={style.navbarLink} to={"#"}>
                    <img src={help} alt="navIcon" /> Служба поддержки
                  </Link>
                  <div className={style.navbarLink} onClick={CookiesDelete}>
                    <img src={sign} alt="navIcon" /> Выйти
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
