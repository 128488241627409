import React, { useState, useContext } from "react";
import linkImage from "../../../img/linkIcon.svg";
import infoImage from "../../../img/Info.svg";
import style from "./actions.module.css";
import PostService from "../../../../../API/postService";
import PostsDisturbModal from "./postsModal/PostsDisturbModal";
import PostSuccessfulAction from "./postsModal/PostSuccessfulAction";
import PostsComplainModal from "./postsModal/PostsComplainModal";
import { RegistrationContext } from "../../../../context";
import { useNavigate } from "react-router-dom";
import ReDropDown from "../../../../reusedComponents/ReDropDown";

const AnotherActionsList = ({ item, type, setActions }) => {
    const { setData } = useContext(RegistrationContext);
    const navigate = useNavigate();
    const [subscribe, setSubscribe] = useState(true);
    const [blackListModal, setBlackListModal] = useState(false);
    const [disturbModal, setDisturbModal] = useState(false);
    const [complainModal, setComplainModal] = useState(false);
    const [complain, setComplain] = useState();
    const [complainSuccessful, setComplainSuccessful] = useState(false);

    // Подписатсья - отписаться
    const handleClickSubscribe = async () => {
        setSubscribe((prev) => !prev);

        try {
            const response =
                await PostService.getAllUsersSubscribeAndUnsubscribe(
                    item.user.user_id
                );
            response.is_followed ? setSubscribe(true) : setSubscribe(false);
        } catch (error) {
            setSubscribe((prev) => !prev);
        }
    };
    // Не беспокоить
    const handleClickNotDisturb = () => {
        setDisturbModal(true);
        setActions(false);
    };
    const handleClickCopyLink = () => {
        setActions(false);
        navigator.clipboard.writeText(
            `https://gargalo.ru/wall/post/${item.id}`
        );
    };
    const handleClickComplain = async () => {
        // setComplainSuccessful(true);
        setComplainModal(true);
        try {
            const { reasons } = await PostService.getAllReasonsComplain();
            setComplain(reasons);
        } catch (error) {
            console.log(error);
        }
    };
    const handleDeleteAnotherPost = async () => {
        try {
            if (type === "manyPosts") {
                setData((prev) =>
                    prev.filter((element) => element.id !== item.id)
                );
            } else {
                navigate(`/account/${item.id}`);
            }
        } catch (error) {
            console.log(error);
        }
        setActions(false);
    };
    const title = "Мы уже рассматриваем вашу жалобу!";
    return (
        <div className={style.edit_post_container}>
            <ReDropDown
                data={[
                    {
                        title: "Скопировать ссылку",
                        icon: linkImage,
                        handleClick: handleClickCopyLink,
                    },
                    {
                        title: "Пожаловаться",
                        icon: infoImage,
                        handleClick: handleClickComplain,
                    },
                ]}
            />
            {disturbModal && (
                <PostsDisturbModal setDisturbModal={setDisturbModal} />
            )}
            {complainModal && (
                <PostsComplainModal
                    data={complain}
                    item={item}
                    setComplainModal={setComplainModal}
                    handleDeleteAnotherPost={handleDeleteAnotherPost}
                />
            )}
            {complainSuccessful && (
                <PostSuccessfulAction
                    title={title}
                    setValue={setComplainSuccessful}
                />
            )}
        </div>
    );
};

export default AnotherActionsList;
