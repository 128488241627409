import React, { useState, useEffect } from "react";
import style from "./postBottomPanel.module.css";
import vector from "./img/Polygon 1.svg";
import defaultImage from "./img/Size=Small.svg";
import PostService from "./../../../../../API/postService";
import { useNavigate } from "react-router-dom";
import MouseModal from "../mouseOverAndOut/likes/MouseModal";

const LikesModal = ({ item, modalActive }) => {
    const navigate = useNavigate();
    const [likesModal, setLikesModal] = useState(false);
    const [likesUsers, setLikesUsers] = useState();

    useEffect(() => {
        setTimeout(() => {
            PostService.getAll(item.id).then((user) => setLikesUsers(user));
        }, 500);
    }, []);

    const handleClickUsers = (path) => {
        navigate(`/account/${path}`);
    };

    return (
        <>
            {likesModal && (
                <MouseModal
                    count={item.likes}
                    classes={true}
                    users={likesUsers.data}
                    title="Нравится"
                    setOpen={setLikesModal}
                />
            )}

            <div
                className={
                    style.likes_modal_div +
                    " " +
                    (modalActive ? style.modal_active : " ")
                }
            >
                <div className={style.likes_modal}>
                    {likesUsers ? (
                        likesUsers.data.map((user, index) => (
                            <>
                                {index < 3 && (
                                    <img
                                        onClick={() =>
                                            handleClickUsers(user.user.user_id)
                                        }
                                        src={`https://gargalo.ru${user.user.avatar}`}
                                        className={style.likes_icon}
                                    />
                                )}
                                {index >= 3 && index < 4 && (
                                    <div
                                        className={style.likes_plug}
                                        onClick={() => setLikesModal(true)}
                                    >
                                        +{item.likes - 3}
                                    </div>
                                )}
                            </>
                        ))
                    ) : (
                        <>
                            {item.likes > 0 && (
                                <img
                                    src={defaultImage}
                                    className={style.likes_icon}
                                />
                            )}
                            {item.likes > 1 && (
                                <img
                                    src={defaultImage}
                                    className={style.likes_icon}
                                />
                            )}
                            {item.likes > 2 && (
                                <img
                                    src={defaultImage}
                                    className={style.likes_icon}
                                />
                            )}
                            {item.likes > 3 && (
                                <img
                                    src={defaultImage}
                                    className={style.likes_icon}
                                />
                            )}
                        </>
                    )}
                </div>

                <img className={style.vector} src={vector} alt="" />
                <div
                    style={{
                        height: 27,
                        background: "trasparent",
                        width: 65,
                        position: "absolute",
                        top: 55,
                    }}
                ></div>
            </div>
        </>
    );
};

export default LikesModal;
