import React, { useContext, useState } from "react";
import LogoBack from "./LogoBack";
import style from "./sign.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { RegistrationContext } from "../context";

const BasePage = () => {
  let baseMass = useLocation();
  let navigate = useNavigate()
  const { regInfo, setRegInfo } = useContext(RegistrationContext);

  //Это я/пропустить
  const handleMeClick = (id) => {
    setRegInfo({
      ...regInfo,
      tpl_tree_number: id,
    });
    axios
      .post(
        `https://gargalo.ru/api/register`,
        {
          f_name: regInfo.f_name,
          name: regInfo.name,
          l_name: regInfo.l_name,
          teip: regInfo.teip,
          born: regInfo.born.split("-")[2] + "-" + regInfo.born.split("-")[1] + "-" + regInfo.born.split("-")[0],
          phone: regInfo.phone,
          gender: regInfo.gender,
          email: regInfo.email,
          password: regInfo.password,
          password_confirmation: regInfo.password_confirmation,
          submit: regInfo.submit,
          tpl_tree_number: id,
        },
        {
          headers: {
            "content-type": "application/json",
            "User-Token": 1,
          },
        }
      )
      .then((res) => {
        navigate("/activation", { state: res.data });
      })
      .catch(() => {
        alert("Произошла непредвиденная ошибка, повторите операцию позже.");
      });
  };

  return (
    <div className={style.registrashionWrap}>
      <LogoBack />
      <h1 className={style.baseZag}>ОБНАРУЖЕНЫ В БАЗЕ</h1>
      <div className={style.baseMassBLock}>
        {baseMass.state.map((item, index) => {
          return (
            <div className={style.baseMassItem} key={index}>
              <div className={style.baseMassItemBlock}>
                <img
                  src={`https://gargalo.ru${item.avatar}`}
                  alt="Аватар"
                  className={style.baseItemImg}
                />
                <div className={style.baseMassItemTextBlock}>
                  <h2 className={style.baseMassZagText}>{item.fio}</h2>
                  <h2
                    className={style.baseMassText}
                    style={{ color: "#567f88" }}
                  >
                    {item?.born}
                  </h2>
                </div>
                {item.father_fio && (
                  <div className={style.baseMassItemTextBlock}>
                    <h2
                      className={style.baseMassText}
                      style={{ color: "#567f88" }}
                    >
                      Отец:
                    </h2>
                    <h2
                      className={style.baseMassText}
                      style={{ color: "#2199CC" }}
                    >
                      {item.father_fio}
                    </h2>
                  </div>
                )}
              </div>
              <button
                className={style.signBtn + " " + style.baseBtn}
                onClick={() => handleMeClick(item.id)}
              >
                Это я
              </button>
            </div>
          );
        })}
      </div>
      <button className={style.signBtn + " " + style.baseNotBtn} onClick={() => handleMeClick(-1)}>
        Меня нет в списке
      </button>
    </div>
  );
};

export default BasePage;
