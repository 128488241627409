import React, { useState, useEffect } from "react";
import styleMouse from "../mouseOverWindow.module.css";
import { useNavigate } from "react-router-dom";
import MouseModal from "../likes/MouseModal";
import PostService from "../../../../../../API/postService";
const VoteModal = ({ modal, id, count, handleMouseOver, handleMouseOut }) => {
    const navigate = useNavigate();
    const [checkUsers, setCheckUsers] = useState(false);
    const [voteUsers, setVoteUsers] = useState();
    useEffect(() => {
        PostService.voteUsersList(id).then((us) => setVoteUsers(us.data));
    }, [count]);
    const getAddClassesFromMouse = () => {
        return modal
            ? styleMouse.vote_modal + " " + styleMouse.active
            : styleMouse.vote_modal;
    };
    const handleClickUsers = (path) => {
        navigate(`/account/${path}`);
    };
    const handleClickCheckUsers = () => {
        setCheckUsers(true);
    };
    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            className={getAddClassesFromMouse()}
            style={{ border: checkUsers ? "none" : "1px solid darkgrey" }}
        >
            <div className={styleMouse.mouse_over_text}>
                <span
                    className={
                        styleMouse.mouse_over_span +
                        " " +
                        styleMouse.vote__total
                    }
                >
                    Проголосовало
                </span>
                <span className={styleMouse.mouse_over_span_number}>
                    {count}
                </span>
            </div>

            <div className={styleMouse.mouse_over_icons}>
                {voteUsers && voteUsers[0] ? (
                    <img
                        onClick={() =>
                            handleClickUsers(voteUsers[0].user.user_id)
                        }
                        className={styleMouse.mouse_over_image}
                        src={`https://gargalo.ru${voteUsers[0].user.avatar}`}
                    />
                ) : (
                    <>{count >= 1 && <div className={styleMouse.plug}></div>}</>
                )}
                {voteUsers && voteUsers[1] ? (
                    <img
                        onClick={() =>
                            handleClickUsers(voteUsers[1].user.user_id)
                        }
                        className={styleMouse.mouse_over_image}
                        src={`https://gargalo.ru${voteUsers[1].user.avatar}`}
                    />
                ) : (
                    <>{count >= 2 && <div className={styleMouse.plug}></div>}</>
                )}
                {voteUsers && voteUsers[2] ? (
                    <img
                        onClick={() =>
                            handleClickUsers(voteUsers[2].user.user_id)
                        }
                        className={styleMouse.mouse_over_image}
                        src={`https://gargalo.ru${voteUsers[2].user.avatar}`}
                    />
                ) : (
                    <>{count >= 3 && <div className={styleMouse.plug}></div>}</>
                )}
                {voteUsers && voteUsers[3] ? (
                    <img
                        onClick={() =>
                            handleClickUsers(voteUsers[3].user.user_id)
                        }
                        className={styleMouse.mouse_over_image}
                        src={`https://gargalo.ru${voteUsers[3].user.avatar}`}
                    />
                ) : (
                    <>{count >= 4 && <div className={styleMouse.plug}></div>}</>
                )}
                {count > 4 && (
                    <div
                        onClick={handleClickCheckUsers}
                        className={
                            styleMouse.mouse_over_image +
                            " " +
                            styleMouse.default_image
                        }
                    >
                        + {count - 4}
                    </div>
                )}
            </div>

            {checkUsers && (
                <MouseModal
                    classes={modal}
                    count={count}
                    users={voteUsers}
                    handleClickUsers={handleClickUsers}
                    title="Проголосовало"
                />
            )}
        </div>
    );
};

export default VoteModal;
