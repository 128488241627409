import React from "react";
import style from "../../../messagPage.module.css";
import CaretDown from "../../../img/CaretDown.svg";
import inputSearch from "../../../img/inputSearch.svg";
import deleteX from "../../../../img/deleteX.svg";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

const SearchMessages = ({
  setSearchMessages,
  contact_id,
  messageIdMass,
  setMessageIdMass,
  messagesRef,
  setCurrentMesag,
  setMessages,
  messages,
  setPage,
  page,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [currentMesagCount, setCurrentMesagCount] = useState(1);
  const [nextCurrentMessagCount, setNextCurrentMessagCount] = useState(null);
  const [arrowBtn, setArrowBtn] = useState(true);
  const [nextMsg, setNextMsg] = useState(false);
  const [firstMsg, setFirstMsg] = useState(false);
  const [text, setText] = useState("");

  console.log(messageIdMass);

  //Поиск сообщений
  const handleSeacrh = () => {
    if (text.length > 0) {
      axios
        .post(
          "https://gargalo.ru/api/messages/search",
          { contact_id, text },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token[1]}`,
              "User-Token": 1,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          let newMass = [];
          res.data.forEach((element) => newMass.push(element.id));
          setMessageIdMass(newMass);
          setNextCurrentMessagCount(res.data.length - 1);
          setCurrentMesagCount(1);
        });
    }
  };

  //Прокрутка до первого найденного элемента
  useEffect(() => {
    if (
      messageIdMass.length > 0 &&
      messagesRef.querySelector(
        `[id="${messageIdMass[messageIdMass.length - 1]}"]`
      )
    ) {
      messagesRef
        .querySelector(`[id="${messageIdMass[messageIdMass.length - 1]}"]`)
        .scrollIntoView({ block: "center", behavior: "smooth" });
      setCurrentMesag(messageIdMass[messageIdMass.length - 1]);
    } else if (messageIdMass.length > 0) {
      handleFirstScroll();
    }
  }, [messageIdMass]);

  //Случай если первого найденного элемента не оказалось на странице
  const handleFirstScroll = async () => {
    await axios
      .post(
        `https://gargalo.ru/api/messages/getrangemessages?page=${page}`,
        {
          contact_id,
          from_id: messageIdMass[messageIdMass.length - 1],
          to_id: messages[messages.length - 1].id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      )
      .then((res) => {
        setPage(res.data.page);
        setMessages((prevState) => [...prevState, ...res.data.messages]);
      });
    setFirstMsg(true);
  };

  //Сам скролл
  useEffect(() => {
    if (firstMsg) {
      messagesRef
        .querySelector(`[id="${messageIdMass[messageIdMass.length - 1]}"]`)
        .scrollIntoView({ block: "center", behavior: "smooth" });
      setCurrentMesag(messageIdMass[messageIdMass.length - 1]);
      setFirstMsg(false);
    }
  }, [firstMsg]);

  // Поиск сообщений при нажатии на Enter
  const keyDownSeacrch = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSeacrh();
    }
  };

  //Скролл до предыдущего элемента
  const handleDownArrow = () => {
    setArrowBtn(true);
    messagesRef
      .querySelector(`[id="${messageIdMass[nextCurrentMessagCount + 1]}"]`)
      .scrollIntoView({ block: "center", behavior: "smooth" });
    setNextCurrentMessagCount((prevState) => prevState + 1);
    setCurrentMesagCount((prevState) => prevState - 1);
    setCurrentMesag(messageIdMass[nextCurrentMessagCount + 1]);
  };

  //Скролл до следующего элемента
  const handleUpArrow = async () => {
    setArrowBtn(false);
    if (
      !messagesRef.querySelector(
        `[id="${messageIdMass[nextCurrentMessagCount - 1]}"]`
      )
    ) {
      await axios
        .post(
          `https://gargalo.ru/api/messages/getrangemessages?page=${page}`,
          {
            contact_id,
            from_id: messageIdMass[nextCurrentMessagCount - 1],
            to_id: messages[messages.length - 1].id,
          },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token[1]}`,
              "User-Token": 1,
            },
          }
        )
        .then((res) => {
          setPage(res.data.page);
          setMessages((prevState) => [...prevState, ...res.data.messages]);
        });
    }
    setNextMsg(true);
  };

  //Сам скролл
  useEffect(() => {
    if (nextMsg) {
      messagesRef
        .querySelector(`[id="${messageIdMass[nextCurrentMessagCount - 1]}"]`)
        .scrollIntoView({ block: "center", behavior: "smooth" });
      setNextCurrentMessagCount((prevState) => prevState - 1);
      setCurrentMesagCount((prevState) => prevState + 1);
      setCurrentMesag(messageIdMass[nextCurrentMessagCount - 1]);
      setArrowBtn(true);
      setNextMsg(false);
    }
  }, [nextMsg]);

  return (
    <div className={style.searcheWrap}>
      <div className={style.seacrchMessageInputBlock}>
        <input
          type="text"
          placeholder="Введите текст сообщения"
          onChange={(e) => setText(e.target.value)}
          className={style.seacrchMessageInput}
          onKeyDown={keyDownSeacrch}
        />
        <img
          src={inputSearch}
          alt="Archive"
          style={{ width: 18, height: 18, padding: 10, cursor: "pointer" }}
          onClick={handleSeacrh}
        />
      </div>
      {messageIdMass.length === 0 ? (
        <h2 className={style.messageSeacrhTextNone}>Совпадений не найдено</h2>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={CaretDown}
            alt=""
            style={{
              width: 20,
              height: 20,
              padding: 10,
              cursor: "pointer",
            }}
            onClick={currentMesagCount > 1 ? handleDownArrow : null}
          />
          <h2 className={style.messageSeacrhText}>
            {currentMesagCount}/{messageIdMass.length}
          </h2>
          <img
            src={CaretDown}
            alt=""
            style={{
              width: 20,
              height: 20,
              padding: 10,
              transform: "rotate(180deg)",
              cursor: "pointer",
            }}
            onClick={
              currentMesagCount < messageIdMass.length && arrowBtn
                ? handleUpArrow
                : null
            }
          />
        </div>
      )}
      <img
        src={deleteX}
        alt=""
        style={{
          width: 30,
          height: 30,
          cursor: "pointer",
        }}
        onClick={() => {
          setMessageIdMass([]);
          setCurrentMesag();
          setCurrentMesagCount(1);
          setNextCurrentMessagCount(null);
          setSearchMessages(false);
        }}
      />
    </div>
  );
};

export default SearchMessages;
