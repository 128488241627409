import React, { useState, useRef } from "react";
import style from "./reinput.module.css";
import PropTypes from "prop-types";
import { Squircle } from "corner-smoothing";

const ReInput = ({
  CSmoothing, //corner smoothing степень закругления от 0.1 до 1
  location, // Где будет стоять иконка (начало/конец)
  location2, // Где будет стоять иконка если их 2 (начало/конец)
  iconStart, //Иконка инпута
  iconEnd, //Иконка инпута
  value, // Значение инпута
  placeholder, // Фоновый текст инпута
  disabled, // Активный ли инпут
  type, //тип элемента(кнопка, div и т.д.)
  handleClick, //функция
  handleClick2, //функция
  clickProps, //пропсы в функции
  handleChange, // handleChange инпута
  error, // Ошибка
  classesIcon,
  classesDiv,
  classesInput,
  onKeyDown,
  onMouseOver,
  onMouseOut,
  autoFocus,
}) => {
  const [backColor, setBackColor] = useState(false);

  const handleChooseClassesInput = (key) => {
    switch (key) {
      case "wrap":
        return (
          style.inputWrapp +
          " " +
          (backColor ? style.inputActive : " ") +
          " " +
          (disabled ? style.inputDisabled : " ")
        );
      case "input":
        return style.input;
      case "textArea":
        return style.input + " " + style.textArea;
      default:
        break;
    }
  };

  return (
    <div className={handleChooseClassesInput("wrap") + " " + classesDiv}>
      {(location === "start" || location2 === "start") && (
        <img
          className={
            style.inputIcon + " " + style.inputIconStart + " " + classesIcon
          }
          src={iconStart}
          disabled={disabled}
          onClick={handleClick}
          onMouseOver={() => onMouseOver()}
          onMouseOut={onMouseOut}
        />
      )}
      {type === "textarea" ? (
        <textarea
          autoFocus={autoFocus}
          onKeyDown={onKeyDown}
          type={type}
          className={handleChooseClassesInput("textArea")}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={
            clickProps ? (e) => handleChange(e, ...clickProps) : handleChange
          }
          onFocus={() => {
            setBackColor(true);
          }}
          onBlur={() => {
            setBackColor(false);
          }}
        />
      ) : (
        <input
          autoFocus={autoFocus}
          onKeyDown={onKeyDown}
          type={type}
          className={handleChooseClassesInput("input") + " " + classesInput}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={
            clickProps ? (e) => handleChange(e, ...clickProps) : handleChange
          }
          onFocus={() => {
            setBackColor(true);
          }}
          onBlur={() => {
            setBackColor(false);
          }}
        />
      )}
      {(location === "end" || location2 === "end") && (
        <img
          className={
            style.inputIcon + " " + style.inputIconEnd + " " + classesIcon
          }
          src={iconEnd}
          disabled={disabled}
          onClick={handleClick2}
        />
      )}
    </div>
  );
};

ReInput.defaultProps = {
  CSmoothing: 0,
};

ReInput.propTypes = {
  CSmoothing: PropTypes.number,
  location: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  clickProps: PropTypes.array,
};

export default ReInput;
