import React from "react";
import style from "./reDropDown.module.css";
import PropTypes from "prop-types";

const ReDropDown = ({
  data = [], // Массив данных
  location = "start", // Локация иконки
  onClick, // Функция клика кнопки
  clickProps, // Пропсы функции
  icon, // Фото иконка
  disabled, // Работае/не работает
}) => {
  return (
    <div className={style.drop_down}>
      {data.map((item, index) => (
        <div
          key={index}
          className={
            style.drop_down_div +
            " " +
            (location === "end" ? style.drop_down_div_end : "")
          }
          onClick={
            clickProps ? (e) => onClick(e, ...clickProps) : item.handleClick
          }
        >
          {location === "start" && item.icon && (
            <img
              className={style.drop_down_image + " " + style.image_left}
              src={item.icon}
              alt=""
            />
          )}
          {item.title}
          {location === "end" && item.icon && (
            <img
              className={style.drop_down_image + " " + style.image_right}
              src={item.icon}
              alt=""
            />
          )}
        </div>
      ))}
    </div>
  );
};
ReDropDown.propTypes = {
  data: PropTypes.array,
  location: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  clickProps: PropTypes.array,
};
export default ReDropDown;
