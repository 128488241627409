import React from "react";
import style from "./EventGroup.module.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { RegistrationContext } from "../../../context";

const Funeral = ({ data }) => {
  const { user } = useContext(RegistrationContext);

  const pathnameLink = (obj) => {
    if (!obj) {
      return "";
    }
    if (obj?.user_id > 0) {
      if (user.user_id !== obj?.user_id) {
        return `/account/${obj?.user_id}`;
      } else {
        return `/profile/${obj?.user_id}`;
      }
    }
    if (obj?.id > 0) {
      return `/tree/node/${obj?.id}`;
    }
    if (!obj.user_id && !obj.id) {
      return "";
    }
    if (
      (obj.user_id < 0 || obj.user_id === 0) &&
      (obj.id < 0 || obj.id === 0)
    ) {
      return "";
    }
  };

  return (
    <>
      <div className={style.eventTextWrapp}>
        <span className={style.eventName}>Умерший:</span>
        <Link className={style.eventTextZag} to={pathnameLink(data.dead)}>
          {data?.dead?.fio}
        </Link>
      </div>
      <div className={style.eventTextWrapp}>
        <span className={style.eventName}>Адрес:</span>
        <span className={style.eventTextZag}>{data.address}</span>
      </div>
      <div className={style.eventTextWrapp}>
        <span className={style.eventName}>Дата смерти:</span>
        <span className={style.eventTextZag}>{data.date}</span>
      </div>
    </>
  );
};

export default Funeral;
