import React from "react";
import style from "./user.module.css";
import UserAccount from "./UserAccount";

const UserAccountBlock = () => {
  return (
    <div className={style.band} style={{flexDirection: "column"}}ы>
      <UserAccount />
    </div>
  );
};

export default UserAccountBlock;
