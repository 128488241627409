import React from "react";
import laoder from "../content/img/loader.gif";
import style from "./style.module.css";

const Loader = () => {
    return (
        <div className={style.loader}>
            <img className={style.laoder_img} src={laoder} alt="" />
        </div>
    );
};

export default Loader;
