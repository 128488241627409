import React from "react";
import style from "./itemChat.module.css";
import TypeMedia from "./TypeMedia";
import TypeText from "./TypeText";
import TypeTextAndMedia from "./TypeTextAndMedia";
import TypeVoice from "./TypeVoice";
import circleActiv from "../../../img/CheckCircleActiv.svg";
import ParentMessag from "./ParentMessag";

const SenderMessag = ({
  messag,
  messageIdMass,
  searchMessages,
  currentMesag,
}) => {
  return (
    <div
      className={style.messagWrapp}
      style={{
        backgroundColor:
          messageIdMass.includes(messag.id) && currentMesag === messag.id
            ? "rgb(201 225 225)"
            : messageIdMass.includes(messag.id) && "#eff5f5",
      }}
    >
      <div
        className={style.messagCircleImg}
        style={{
          marginRight: 12,
          backgroundImage:
            messageIdMass.includes(messag.id) &&
            !searchMessages &&
            `url(${circleActiv})`,
          opacity: messageIdMass.includes(messag.id) && !searchMessages && 1,
        }}
      ></div>
      <div className={style.senderBlock}>
        {messag.parent_message && (
          <ParentMessag messag={messag.parent_message} type="L" />
        )}
        {!messag.audio && !messag.media && messag.content && (
          <TypeText content={messag.content} time={messag.time} type="L" />
        )}
        {!messag.audio && messag.media && !messag.content && (
          <TypeMedia media={messag.media} time={messag.time} />
        )}
        {!messag.audio && messag.media && messag.content && (
          <TypeTextAndMedia
            media={messag.media}
            content={messag.content}
            time={messag.time}
            type="L"
          />
        )}
        {messag.audio && !messag.media && !messag.content && (
          <TypeVoice audio={messag.audio} time={messag.time} type="L" />
        )}
      </div>
    </div>
  );
};

export default SenderMessag;
