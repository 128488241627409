import React, { useContext, useEffect, useRef } from "react";
import defaultAvatar from "../img/defaultAvatar.svg";
import close from "../img/Close.svg";
import axios from "axios";
import style from "./treeComponents.module.css";
import TreeSearchInput from "./TreeSearchInput";
import { useState } from "react";
import ReButton from "../../../../reusedComponents/ReButton";
import PostService from "../../../../../API/postService";
import { useInView } from "react-intersection-observer";
import ReSwitch from "../../../../reusedComponents/ReSwitch";
import ReCalendar from "../../../../reusedComponents/ReCalendar";
import { RegistrationContext } from "../../../../context";
import { Link } from "react-router-dom";

const TreeFormBlock = ({
  setRender,
  setCenter,
  center,
  wrappRef,
  addPersonInfo,
  setAddPersonInfo,
  setFormBlock,
  formBlock,
  setTreeMass,
  treeMass,
  buttonType,
  setAddingType,
  addingType,
  setModal,
  setModalType,
  setFirstAdd,
  firstAdd,
  setNotif,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [genderBlock, setGenderBlock] = useState(false);
  const [deleteAvatar, setDeleteAvatar] = useState(false);
  const [blockInput, setBlockInput] = useState({
    fio: false,
    pol: false,
    date: false,
  });
  const [existingUser, setExistingUser] = useState();
  const [dieBlock, setDieBlock] = useState({ date: false });
  const [newBranchTransform, setNewBranchTransform] = useState(null);
  const { ref: scrollRef, inView: scrollInView } = useInView({
    threshold: 1,
    initialInView: true,
  });
  const { user } = useContext(RegistrationContext);

  //Смещение в центр при создании новой ветки
  useEffect(() => {
    if (newBranchTransform) {
      const child = document
        .getElementById(`${newBranchTransform}`)
        .getBoundingClientRect();
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const translateX = wrappRef.state.positionX;
      const translateY = wrappRef.state.positionY;
      const transformX = translateX + (centerX - child.x - child.width / 2 - 5);
      const transformY =
        translateY + (centerY - child.y - child.height / 2 + 10);
      wrappRef.setTransform(transformX, transformY, 1);
    }
  }, [newBranchTransform]);

  //Заполнение полей при открытии
  useEffect(() => {
    if (addPersonInfo.connect_user_id > 0 && center.user_info) {
      setExistingUser({
        fio: center.fio,
        avatar: center.user_info.avatar_small,
        born: center.born && center.born !== "--.--.----" ? center.born : "",
      });
    }
  }, []);

  //Заполнение при клике в поиске
  useEffect(() => {
    if (addPersonInfo.fio) {
      setAddPersonInfo({
        ...addPersonInfo,
        f: addPersonInfo.fio.trim().split(" ")[0]
          ? addPersonInfo.fio.trim().split(" ")[0]
          : "",
        i: addPersonInfo.fio.trim().split(" ")[1]
          ? addPersonInfo.fio.trim().split(" ")[1]
          : "",
        o: addPersonInfo.fio.trim().split(" ")[2]
          ? addPersonInfo.fio.trim().split(" ")[2]
          : "",
      });
    }
  }, [addPersonInfo.fio]);

  //Кнопка добавления/редактирования
  const handleAddPerson = (e) => {
    e.preventDefault();
    if (
      addPersonInfo.f.trim().length > 2 &&
      addPersonInfo.i.trim().length > 2 &&
      addPersonInfo.pol > 0 &&
      addPersonInfo.teip > 0
    ) {
      //Удаление аватара
      if (deleteAvatar) {
        PostService.deleteNodeAvatar(center.id).then(() => {
          setAddPersonInfo({
            ...addPersonInfo,
            imgUrl: defaultAvatar,
          });
        });
      }

      //Основной запрос
      let form = new FormData();
      form.append(
        "fio",
        addPersonInfo.f.trim() +
          " " +
          addPersonInfo.i.trim() +
          " " +
          addPersonInfo.o.trim()
      );
      form.append("fio_id", addPersonInfo.fio_id);
      form.append("pol", addPersonInfo.pol);
      form.append("avatar", addPersonInfo.img ? addPersonInfo.img : "");
      if (buttonType === "add" || buttonType === "editVacuum") {
        form.append("source_id", addPersonInfo.source_id);
        form.append("source", addPersonInfo.source);
        form.append("teip", addPersonInfo.teip);
      } else if (buttonType === "edit") {
        form.append("source_id", center.id);
        form.append("teip", center.teip);
      } else {
        form.append("teip", center.teip);
      }
      form.append("f_id", addPersonInfo.f_id);
      form.append("m_id", addPersonInfo.m_id);
      form.append("connect_user_id", addPersonInfo.connect_user_id);
      form.append("spouce_id", addPersonInfo.spouce_id);
      form.append("born", addPersonInfo.full_born);
      form.append("die", addPersonInfo.full_die);
      form.append("mail", addPersonInfo.mail);
      form.append("phone", addPersonInfo.phone);
      form.append("bio", addPersonInfo.info);
      axios({
        method: "post",
        url: `https://gargalo.ru/api/tree/${buttonType}`,
        data: form,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      })
        .then((res) => {
          if (buttonType === "add") {
            if (addingType === "father") {
              setRender(true);
            } else {
              setTreeMass((prevState) => [...prevState, res.data]);
            }
            if (addingType === "newBranch") {
              setRender(true);
              if (wrappRef.state.scale !== 1) {
                wrappRef.resetTransform(0);
              }
              setNewBranchTransform(res.data.id);
            }
          } else if (buttonType === "edit") {
            setTreeMass(
              treeMass.map((item) => {
                if (item.id === res.data.id) {
                  item = res.data;
                }
                return item;
              })
            );
          }
          setNotif({
            target:
              addPersonInfo.f.trim() +
              " " +
              addPersonInfo.i.trim() +
              " " +
              addPersonInfo.o.trim(),
            event: buttonType,
          });
        })
        .catch(() => {
          alert("Произошла непредвиденная ошибка, повторите операцию позже.");
        });
      handleCloseAddPerson(e);
    }
  };

  //Кнопка добавить и дублировать
  const handleDuplicateClick = (e) => {
    e.preventDefault();
    //Удаление аватара
    if (deleteAvatar) {
      PostService.deleteAvatar(addPersonInfo.connect_user_id).then(() => {
        setAddPersonInfo({
          ...addPersonInfo,
          imgUrl: defaultAvatar,
        });
      });
    }

    if (
      addPersonInfo.f.trim().length > 2 &&
      addPersonInfo.i.trim().length > 2 &&
      addPersonInfo.pol > 0
    ) {
      let form = new FormData();
      form.append(
        "fio",
        addPersonInfo.f.trim() +
          " " +
          addPersonInfo.i.trim() +
          " " +
          addPersonInfo.o.trim()
      );
      form.append("avatar", addPersonInfo.img ? addPersonInfo.img : "");
      form.append("connect_user_id", addPersonInfo.connect_user_id);
      form.append("fio_id", addPersonInfo.fio_id);
      form.append("pol", addPersonInfo.pol);
      form.append("source_id", addPersonInfo.source_id);
      form.append("source", addPersonInfo.source);
      form.append("teip", addPersonInfo.teip);
      form.append("f_id", addPersonInfo.f_id);
      form.append("m_id", addPersonInfo.m_id);
      form.append("spouce_id", addPersonInfo.spouce_id);
      form.append("born", addPersonInfo.full_born);
      form.append("die", addPersonInfo.full_die);
      form.append("mail", addPersonInfo.mail);
      form.append("phone", addPersonInfo.phone);
      form.append("bio", addPersonInfo.info);
      axios({
        method: "post",
        url: `https://gargalo.ru/api/tree/add`,
        data: form,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      })
        .then((res) => {
          setTreeMass((prevState) => [...prevState, res.data]);
          setNotif({
            target:
              addPersonInfo.f.trim() +
              " " +
              addPersonInfo.i.trim() +
              " " +
              addPersonInfo.o.trim(),
            event: buttonType,
          });
          document.getElementById("addForm").reset();
          setDeleteAvatar(false);
          setAddPersonInfo({
            ...addPersonInfo,
            i: "",
            fio_id: "",
            connect_user_id: "",
            fio: "",
            spouce_id: "",
            s_fio: "",
            die: "",
            born: "",
            full_born: "",
            full_die: "",
            pol: "",
            phone: "",
            mail: "",
            info: "",
            imgUrl: null,
            img: null,
          });
        })
        .catch(() => {
          alert("Произошла непредвиденная ошибка, повторите операцию позже.");
        });
    }
  };

  //Закрытие формы
  const handleCloseAddPerson = (e) => {
    e.preventDefault();
    document.getElementById("addForm").reset();
    setDeleteAvatar(false);
    setFormBlock(false);
    setAddingType();
    setExistingUser();
    setBlockInput({ ...blockInput, fio: false, pol: false, date: false });
    setFirstAdd({
      ...firstAdd,
      f: "",
      i: "",
      o: "",
      connect_user_id: "",
      fio_id: "",
      fio: "",
      source_id: "",
      source: "",
      spouce_id: "",
      s_fio: "",
      f_id: "",
      f_fio: "",
      m_id: "",
      m_fio: "",
      die: "",
      born: "",
      full_born: "",
      full_die: "",
      pol: "",
      teip: "",
      phone: "",
      mail: "",
      info: "",
      imgUrl: null,
      img: null,
    });
    setAddPersonInfo({
      ...addPersonInfo,
      f: "",
      i: "",
      o: "",
      connect_user_id: "",
      fio_id: "",
      fio: "",
      source_id: "",
      source: "",
      spouce_id: "",
      s_fio: "",
      f_id: "",
      f_fio: "",
      m_id: "",
      m_fio: "",
      die: "",
      born: "",
      full_born: "",
      full_die: "",
      pol: "",
      teip: "",
      phone: "",
      mail: "",
      info: "",
      imgUrl: null,
      img: null,
    });
  };

  //Проверка на изменения, если есть, то всплывающее пердупреждение
  const handleResetModal = (e) => {
    e.preventDefault();
    if (JSON.stringify(firstAdd) !== JSON.stringify(addPersonInfo)) {
      setModalType(buttonType);
      setModal(true);
    } else {
      handleCloseAddPerson(e);
    }
  };

  //Закрытие блока пола при клике на пустую область
  const closeBlock = () => {
    setGenderBlock(false);
    window.removeEventListener("click", closeBlock);
  };

  return (
    <form id="addForm" className={style.appendForm} autoComplete="off">
      <div
        style={{ width: "100%", height: "100vh" }}
        onClick={handleResetModal}
      ></div>
      <div className={style.infoWrapp}>
        <div className={style.infoBlock}>
          {addingType && (
            <>
              {addingType === "brother" ? (
                <h2 className={style.addingZag}>Добавление брата \ сестры</h2>
              ) : addingType === "son" ? (
                <h2 className={style.addingZag}>Добавление сына \ дочери</h2>
              ) : addingType === "father" ? (
                <h2 className={style.addingZag}>Добавление отца</h2>
              ) : (
                <h2 className={style.addingZag}>
                  Добавить участника новой ветки
                </h2>
              )}
            </>
          )}
          {buttonType === "edit" && (
            <h2 className={style.addingZag}>Редактирование данных</h2>
          )}
          <div
            className={style.closeAppendBlock}
            style={{ left: -48, top: 20 }}
            onClick={handleResetModal}
          ></div>
          <h2 className={style.formZag}>Существующий пользователь</h2>
          {existingUser ? (
            <div className={style.existingWrapp}>
              <Link
                style={{ display: "flex", alignItems: "center" }}
                to={
                  addPersonInfo.connect_user_id === user.user_id
                    ? `/profile/${addPersonInfo.connect_user_id}`
                    : `/account/${addPersonInfo.connect_user_id}`
                }
                target="_blank"
              >
                <img
                  src={`https://gargalo.ru${existingUser?.avatar}`}
                  alt="avatar"
                  className={style.userAvatar}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h2 className={style.userFio}>{existingUser.fio}</h2>
                  <h2 className={style.userDate}>
                    {existingUser.born
                      ? existingUser.born.split("-")[2] +
                        "." +
                        existingUser.born.split("-")[1] +
                        "." +
                        existingUser.born.split("-")[0]
                      : ""}
                  </h2>
                </div>
              </Link>
              <img
                src={close}
                alt=""
                className={style.deleteExisting}
                onClick={() => {
                  setExistingUser();
                  setAddPersonInfo({
                    ...addPersonInfo,
                    connect_user_id: "",
                  });
                }}
              />
            </div>
          ) : (
            <TreeSearchInput
              setExistingUser={setExistingUser}
              searchType={"members"}
              filterUser={"no_node=1"}
              setAddPersonInfo={setAddPersonInfo}
              firstAdd={firstAdd}
              addPersonInfo={addPersonInfo}
              placeholder={"Найти пользователя"}
              valueKey={"fio"}
              valueId={"connect_user_id"}
              formBlock={formBlock}
              classes={"inputSearch"}
              pol={0}
              type={"search"}
            />
          )}
          <span className={style.borderForm}></span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <img
              src={
                addPersonInfo.imgUrl
                  ? addPersonInfo.imgUrl
                  : center?.avatar &&
                    buttonType === "edit" &&
                    center?.avatar.split("/")[4] !== "default"
                  ? `https://gargalo.ru${center?.avatar}`
                  : defaultAvatar
              }
              alt="Avatar"
              className={style.formPersonImage}
            />
            <input
              type="file"
              id="inputFile"
              hidden
              accept="image/*"
              onChange={(e) =>
                setAddPersonInfo({
                  ...addPersonInfo,
                  imgUrl: URL.createObjectURL(e.target.files[0]),
                  img: e.target.files[0],
                })
              }
            ></input>
            {(center?.avatar.split("/")[4] === "default" &&
              !addPersonInfo.imgUrl) ||
            buttonType === "add" ||
            (!center?.avatar && !addPersonInfo.imgUrl) ? (
              <>
                <label htmlFor="inputFile">
                  <ReButton
                    text={"Добавить фото"}
                    height={32}
                    classes={"third"}
                    font={"small"}
                    type={"div"}
                    mr={12}
                  />
                </label>
              </>
            ) : (
              <>
                <label htmlFor="inputFile">
                  <ReButton
                    height={32}
                    text={"Заменить фото "}
                    classes={"third"}
                    font={"small"}
                    type={"div"}
                    mr={12}
                  />
                </label>
                <h2
                  className={style.deleteAvatar}
                  onClick={() => {
                    setDeleteAvatar(true);
                    setAddPersonInfo({
                      ...addPersonInfo,
                      imgUrl: null,
                      img: null,
                    });
                    setCenter({
                      ...center,
                      avatar: "",
                    });
                  }}
                >
                  Удалить
                </h2>
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 20,
            }}
          >
            {existingUser || addPersonInfo.connect_user_id > 0 ? (
              <ReSwitch
                setState={setBlockInput}
                state={blockInput}
                stateProps={"fio"}
                zag={"ФИО"}
              />
            ) : (
              <h2 className={style.formZag}>ФИО</h2>
            )}
            <input
              type="text"
              className={style.formInput}
              style={{ marginBottom: 12 }}
              placeholder="Введите фамилию"
              disabled={
                !blockInput.fio &&
                (existingUser || addPersonInfo.connect_user_id) &&
                true
              }
              value={addPersonInfo.f}
              onChange={(e) =>
                setAddPersonInfo({
                  ...addPersonInfo,
                  f: e.target.value.replace(/[a-zA-Z]/, ""),
                })
              }
            />
            <input
              type="text"
              style={{ marginBottom: 12 }}
              className={style.formInput}
              placeholder="Введите имя"
              disabled={
                !blockInput.fio &&
                (existingUser || addPersonInfo.connect_user_id) &&
                true
              }
              value={addPersonInfo.i}
              onChange={(e) =>
                setAddPersonInfo({
                  ...addPersonInfo,
                  i: e.target.value.replace(/[a-zA-Z]/, ""),
                })
              }
            />
            <input
              type="text"
              className={style.formInput}
              placeholder="Введите отчество"
              disabled={
                !blockInput.fio &&
                (existingUser || addPersonInfo.connect_user_id) &&
                true
              }
              value={addPersonInfo.o}
              onChange={(e) =>
                setAddPersonInfo({
                  ...addPersonInfo,
                  o: e.target.value.replace(/[a-zA-Z]/, ""),
                })
              }
            />
            {(existingUser || addPersonInfo.connect_user_id) && (
              <h2 className={style.warnZag} style={{ marginBottom: 0 }}>
                Синхронизируется с данными пользователя
              </h2>
            )}
          </div>
          <div className={style.formInputBlock} style={{ marginBottom: 20 }}>
            {existingUser || addPersonInfo.connect_user_id ? (
              <ReSwitch
                setState={setBlockInput}
                state={blockInput}
                stateProps={"pol"}
                zag={"Пол"}
              />
            ) : (
              <h2 className={style.formZag}>Пол</h2>
            )}
            <div style={{ position: "relative" }}>
              <div
                tabIndex={0}
                className={style.genderWrapp}
                style={{
                  color:
                    addPersonInfo.pol !== 1 &&
                    addPersonInfo.pol !== 2 &&
                    "#AAC0C5",
                  backgroundColor:
                    ((!blockInput.pol &&
                      (existingUser || addPersonInfo.connect_user_id)) ||
                      addingType === "father") &&
                    "#E6E6E6",
                  opacity:
                    ((!blockInput.pol &&
                      (existingUser || addPersonInfo.connect_user_id)) ||
                      addingType === "father") &&
                    0.5,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    (!blockInput.pol &&
                      (existingUser || addPersonInfo.connect_user_id)) ||
                    addingType === "father"
                  ) {
                    return;
                  }
                  if (genderBlock) {
                    setGenderBlock(false);
                  } else {
                    window.addEventListener("click", closeBlock);
                    setGenderBlock(true);
                  }
                }}
              >
                {addPersonInfo.pol === 1
                  ? "Мужской"
                  : addPersonInfo.pol === 2
                  ? "Женский"
                  : "Не выбран"}
              </div>
              {genderBlock && (
                <div className={style.genderBlock}>
                  <span
                    className={style.genderBtn}
                    onClick={() => {
                      setGenderBlock(false);
                      setAddPersonInfo({
                        ...addPersonInfo,
                        pol: 1,
                      });
                    }}
                  >
                    Мужской
                  </span>
                  <span
                    className={style.genderBtn}
                    onClick={() => {
                      setGenderBlock(false);
                      setAddPersonInfo({
                        ...addPersonInfo,
                        pol: 2,
                      });
                    }}
                  >
                    Женский
                  </span>
                </div>
              )}
              {(existingUser || addPersonInfo.connect_user_id) && (
                <h2 className={style.warnZag}>
                  Синхронизируется с данными пользователя
                </h2>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 20,
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                className={style.formInputBlock}
                style={{ marginRight: 12, marginBottom: 0 }}
              >
                {existingUser || addPersonInfo.connect_user_id ? (
                  <ReSwitch
                    setState={setBlockInput}
                    state={blockInput}
                    stateProps={"born"}
                    zag={"Дата рождения"}
                  />
                ) : (
                  <h2 className={style.formZag}>Дата рождения</h2>
                )}
                <ReCalendar
                  disabled={
                    !blockInput.born &&
                    (existingUser || addPersonInfo.connect_user_id) &&
                    true
                  }
                  defaultDate={addPersonInfo.born}
                  state={addPersonInfo}
                  stateProps={"born"}
                  extraProps={"full_born"}
                  setState={setAddPersonInfo}
                />
              </div>
              <div className={style.formInputBlock} style={{ marginBottom: 0 }}>
                <ReSwitch
                  setState={setDieBlock}
                  state={dieBlock}
                  stateProps={"date"}
                  zag={"Дата смерти"}
                />
                <ReCalendar
                  calendarStyle={{ right: 0, left: "auto" }}
                  disabled={!dieBlock.date && true}
                  defaultDate={addPersonInfo.die}
                  state={addPersonInfo}
                  stateProps={"die"}
                  extraProps={"full_die"}
                  setState={setAddPersonInfo}
                />
              </div>
            </div>
            {(existingUser || addPersonInfo.connect_user_id) && (
              <h2 className={style.warnZag}>
                Синхронизируется с данными пользователя
              </h2>
            )}
          </div>
          <h2 className={style.formZag}>Отец</h2>
          <TreeSearchInput
            filterUser={""}
            searchType={"persons"}
            setAddPersonInfo={setAddPersonInfo}
            addPersonInfo={addPersonInfo}
            placeholder={"Введите ФИО отца"}
            valueKey={"f_fio"}
            valueId={"f_id"}
            formBlock={formBlock}
            classes={"genderWrapp"}
            defaultValue={addPersonInfo.f_fio}
            teip={addPersonInfo.teip}
            addingType={addingType}
            pol={1}
            type={"drop-down"}
          />
          <h2 className={style.formZag}>Мать</h2>
          <TreeSearchInput
            filterUser={""}
            searchType={"persons"}
            setAddPersonInfo={setAddPersonInfo}
            addPersonInfo={addPersonInfo}
            placeholder={"Введите ФИО матери"}
            valueKey={"m_fio"}
            valueId={"m_id"}
            formBlock={formBlock}
            classes={"genderWrapp"}
            defaultValue={addPersonInfo.m_fio}
            pol={2}
            type={"drop-down"}
          />
          <h2 className={style.formZag}>Супруг(-а)</h2>
          <TreeSearchInput
            filterUser={""}
            searchType={"persons"}
            setAddPersonInfo={setAddPersonInfo}
            addPersonInfo={addPersonInfo}
            placeholder={"Введите ФИО супруга"}
            valueKey={"s_fio"}
            valueId={"spouce_id"}
            formBlock={formBlock}
            classes={"genderWrapp"}
            defaultValue={addPersonInfo.s_fio}
            pol={0}
            type={"drop-down"}
          />
          <div className={style.formInputBlock} style={{ marginBottom: 8 }}>
            <h2 className={style.formZag}>Краткая биография</h2>
            <textarea
              className={style.formTextarea}
              onChange={(e) =>
                setAddPersonInfo({
                  ...addPersonInfo,
                  info: e.target.value.replace(/[a-zA-Z]/, ""),
                })
              }
              placeholder="Напишите краткую биографию"
              value={addPersonInfo.info}
            ></textarea>
          </div>
          <span ref={scrollRef} style={{ height: 0 }}></span>
        </div>
        <div
          className={style.formBtnWrapp}
          style={{
            boxShadow: !scrollInView && "12px -4px 20px rgba(0, 0, 0, 0.08)",
            border: scrollInView && "none",
          }}
        >
          <div style={{ display: "flex" }}>
            <ReButton
              height={40}
              text={
                buttonType === "add" || buttonType === "addFirst"
                  ? "Добавить"
                  : "Сохранить"
              }
              mr={8}
              handleClick={handleAddPerson}
              disabled={
                (addPersonInfo.f.trim().length < 2 ||
                  addPersonInfo.i.trim().length < 2 ||
                  addPersonInfo.pol === 0 ||
                  addPersonInfo.pol.length <= 0 ||
                  addPersonInfo.teip < 0 ||
                  addPersonInfo.teip.length <= 0) &&
                true
              }
            />
            <ReButton
              height={40}
              text={"Отмена"}
              classes={"third"}
              handleClick={handleResetModal}
            />
          </div>
          {buttonType === "edit" && (
            <h2
              className={style.deleteBtn}
              onClick={() => {
                setModal(true);
                setModalType("delete");
              }}
            >
              Удалить карточку
            </h2>
          )}
          {buttonType === "add" &&
            (addingType === "brother" || addingType === "son") && (
              <h2
                className={style.deleteBtn}
                style={{
                  opacity:
                    (addPersonInfo.f.trim().length < 2 ||
                      addPersonInfo.i.trim().length < 2 ||
                      addPersonInfo.pol === 0) &&
                    0.4,
                }}
                onClick={(e) => {
                  addPersonInfo.f.trim().length > 2 &&
                    addPersonInfo.i.trim().length > 2 &&
                    addPersonInfo.pol !== 0 &&
                    handleDuplicateClick(e);
                }}
              >
                Добавить и дублировать
              </h2>
            )}
        </div>
      </div>
    </form>
  );
};

export default TreeFormBlock;
