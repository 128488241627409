import React, { useContext, useState } from "react";
import LogoBack from "./LogoBack";
import style from "./sign.module.css";
import axios from "axios";
import actSend from "./img/actSend.svg";
import actAccept from "./img/actAccept.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { RegistrationContext } from "../context";
import { useEffect } from "react";
import { useRef } from "react";

const ProfileActivation = () => {
  let userInfo = useLocation();
  let navigation = useNavigate();
  const [actCount, setActCount] = useState(0);
  const [actSearch, setActSearch] = useState("");
  const [actMas, setActMas] = useState([]);
  const [actMasSeacrch, setActMasSeacrh] = useState([]);
  const { setAuthorization } = useContext(RegistrationContext);
  const [nextPageUrl, setNextPageUrl] = useState();
  const [page, setPage] = useState(1);
  const btnRef = useRef(null);

  //Весь список доверенных людей
  useEffect(() => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/trusted-users`,
      headers: {
        "Content-Type": "application/json",
        "User-Token": 1,
        Authorization: `Bearer ${userInfo.state.token}`,
      },
    }).then((res) => {
      setActMas(res.data.data);
      setNextPageUrl(res.data.next_page_url);
      res.data.data.forEach((element) => {
        if (element.is_send) {
          setActCount((prevState) => prevState + 1);
        }
      });
    });
  }, []);

  //Поиск людей
  const handleSeacrhPeaple = (e) => {
    setActSearch(e.target.value.trim());
    if (actSearch.length > 0) {
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/trusted-users/search?fio=${e.target.value.trim()}&page=1`,
        headers: {
          "Content-Type": "application/json",
          "User-Token": 1,
        },
      }).then((res) => {
        setActMasSeacrh(res.data.data);
      });
    }
  };

  //Отправить заявку на активацию
  const handleActSend = (e, index, id, type) => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/send-verify-request/${id}`,
      headers: {
        "Content-Type": "application/json",
        "User-Token": 1,
        Authorization: `Bearer ${userInfo.state.token}`,
      },
    }).then(() => {
      setActCount(actCount + 1);
      if (type === 1) {
        setActMasSeacrh(
          actMasSeacrch.map((item, indexOf) => {
            if (indexOf === index) {
              item.is_send = true;
            }
            return item;
          })
        );
      } else {
        setActMas(
          actMas.map((item, indexOf) => {
            if (indexOf === index) {
              item.is_send = true;
            }
            return item;
          })
        );
      }
    });
  };

  //Получение новой страницы
  useEffect(() => {
    if (nextPageUrl) {
      axios({
        method: "GET",
        url: `${nextPageUrl}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${userInfo.state.token}`,
          "User-Token": 1,
        },
      }).then((res) => {
        setActMas((prevState) => [...prevState, ...res.data.data]);
        setNextPageUrl(res.data.next_page_url);
      });
    }
  }, [page]);

  //Прогрузка новой страницы
  useEffect(() => {
    const btn = btnRef.current;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((page) => page + 1);
      }
    });
    if (btn) observer.observe(btn);
    return () => {
      if (btn) observer.unobserve(btn);
    };
  }, [btnRef.current]);

  //Кнопка далее
  const handleNext = async () => {
    if (window.fireBaseToken) {
      await axios.post(
        `https://gargalo.ru/api/user/savetoken`,
        {
          token: window.fireBaseToken,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.state.token}`,
            "User-Token": 1,
          },
        }
      );
    }
    let date = new Date(Date.now() + 86400e3 * 7);
    date = date.toUTCString();
    document.cookie = `token=${userInfo.state.token}; secure; expires=` + date;
    document.cookie =
      `nickname=${userInfo.state.nickname}; secure; expires=` + date;
    document.cookie = `id=${userInfo.state.user_id}; secure; expires=` + date;
    window.location.reload();
  };

  return (
    <div className={style.registrashionWrap}>
      <LogoBack />
      <h1 className={style.regZag} style={{ marginTop: 100 }}>
        Ваш Профиль неактивен
      </h1>
      <h2 className={style.regZagText}>
        Подтвердите аккаунт любым удобным для Вас способом:
      </h2>
      <ul className={style.actUl}>
        <li>
          <h3>
            Посетите наш офис по адресу:{" "}
            <a target="_blank" href="https://goo.gl/maps/hfAFMvP8gNVPDFFu5">
              Республика Ингушетия. Город Магас. Ул. Бота Гагиева
            </a>
          </h3>
        </li>
        <li>
          <h3>
            Позвоните нам на номер: <a href="tel:+79289390606">89289390606</a>
          </h3>
        </li>
        <li>
          <h3>
            Или отправьте запрос доверенным пользователям из числа Ваших
            зарегистрированных на сайте знакомых
          </h3>
        </li>
      </ul>
      <input
        type="text"
        className={style.signInput + " " + style.actInput}
        placeholder="Введите полное ФИО человека"
        onChange={(e) => handleSeacrhPeaple(e)}
      />
      <div className={style.actListBLock}>
        {actMas.length > 0 ? (
          <h2 className={style.actText}>
            {actCount} из {actMas.length}
          </h2>
        ) : (
          <h2 className={style.actText}>0 из 0</h2>
        )}
        <div className={style.actList}>
          {actMasSeacrch.length > 0 && actSearch.length > 0
            ? actMasSeacrch.map((item, index) => {
                return (
                  <div key={index} className={style.actItemBLock}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={`https://gargalo.ru${item.avatar}`}
                        alt="Аватар"
                        className={style.actItemAvatar}
                      />
                      <h2
                        className={style.regZagText}
                        style={{ marginBottom: 0 }}
                      >
                        {item.f_name} {item.name} {item.l_name}
                      </h2>
                    </div>
                    {item.is_send ? (
                      <button className={style.actAcceptBtn}>
                        Отправлено
                        <img src={actAccept} alt="" />
                      </button>
                    ) : (
                      <button
                        className={style.actSendBtn}
                        onClick={(e) => handleActSend(e, index, item.id, 1)}
                      >
                        Отправить
                        <img src={actSend} alt="" />
                      </button>
                    )}
                  </div>
                );
              })
            : actMas.map((item, index) => {
                return (
                  <div key={index} className={style.actItemBLock}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={`https://gargalo.ru${item.user.avatar}`}
                        alt="Аватар"
                        className={style.actItemAvatar}
                      />
                      <h2
                        className={style.regZagText}
                        style={{ marginBottom: 0 }}
                      >
                        {item.f_name} {item.name} {item.l_name}
                      </h2>
                    </div>
                    {item.is_send ? (
                      <button className={style.actAcceptBtn}>
                        Отправлено
                        <img src={actAccept} alt="" />
                      </button>
                    ) : (
                      <button
                        className={style.actSendBtn}
                        onClick={(e) => handleActSend(e, index, item.id, 2)}
                      >
                        Отправить
                        <img src={actSend} alt="" />
                      </button>
                    )}
                  </div>
                );
              })}
          {nextPageUrl !== null && (
            <button style={{ opacity: 0 }} ref={btnRef}>
              Показать еще
            </button>
          )}
        </div>
        <button
          className={style.signBtn + " " + style.actNextBtn}
          onClick={handleNext}
        >
          Далее
        </button>
      </div>
    </div>
  );
};

export default ProfileActivation;
