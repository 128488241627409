import React from "react";
import style from "./user.module.css";
import UserProfile from "./UserProfile";

const UserProfileBlock = () => {
    return (
        <div className={style.band} style={{ flexDirection: "column" }}>
            <UserProfile />
        </div>
    );
};

export default UserProfileBlock;
