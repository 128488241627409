import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "../App.css";
import Header from "./content/Header";
import { useEffect } from "react";
import Sidebar from "./content/Sidebar";
import { registerOffRoutes, registerOnRoutes } from "../routes/routes";
import RegistrationProvider from "../hooks/useRegistration";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PostService from "../API/postService";
import logo from "../components/sign/img/logo.svg";

function App() {
  const [authorization, setAuthorization] = useState(false);
  const [user, setUser] = useState(null);
  const token = document.cookie.match(/token=(.+?)(;|$)/);

  useEffect(() => {
    if (token) {
      setAuthorization(true);
      PostService.getUser().then((res) => {
        setUser(res);
        window.centrifugeUnit(res);
      });
    }
  }, []);

  if (!authorization) {
    return (
      <div className="container_App_sign">
        <RegistrationProvider
          authorization={authorization}
          setAuthorization={setAuthorization}
        >
          <Routes>
            {registerOffRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
          {/* <ToastContainer /> */}
        </RegistrationProvider>
      </div>
    );
  }
  return (
    <div className="App">
      <RegistrationProvider
        setAuthorization={setAuthorization}
        user={user}
        setUser={setUser}
      >
        {user ? (
          <>
            <Header />
            <div className="band">
              <Sidebar />
              <Routes>
                {registerOnRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Routes>
              {/* <ToastContainer /> */}
            </div>
          </>
        ) : (
          <div className="band_plug">
            <img className="band_plug_image" src={logo} alt="" />
          </div>
        )}
      </RegistrationProvider>
    </div>
  );
}

export default App;
