import React from "react";
import { useSelector } from "react-redux";
import ReDropDown from "../../../../reusedComponents/ReDropDown";
const PaginationScroll = ({ btnRef, page1, page2 }) => {
  const loading = useSelector((state) => state.users.loading);

  return (
    <div>
      <button style={{ opacity: 0 }} ref={btnRef}>
        Показать еще
      </button>
    </div>
  );
};

export default PaginationScroll;
