import React from "react";
import x from "../../img/x.svg";
import addImage from "../../img/addImage.svg";
import avatarDefault from "../../img/avatar.svg";
import axios from "axios";
import InputSearch from "./InputSearch";

const FormBlock = ({
  center,
  setAppenedBlock,
  addPersonInfo,
  setAddPersonInfo,
  setFormBlock,
  setNodes,
  nodes,
  setEdges,
  edges,
  addingType,
  buttonType,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);

  //Кнопка добавления/редактирования
  const handleAddPerson = (e) => {
    console.log(addPersonInfo);
    e.preventDefault();
    if (addPersonInfo.fio.trim().length > 2) {
      let form = new FormData();
      form.append("fio", addPersonInfo.fio);
      form.append("fio_id", addPersonInfo.fio_id);
      form.append("pol", addPersonInfo.pol);
      if (buttonType === "add" || buttonType === "editVacuum") {
        form.append("source_id", addPersonInfo.source_id);
        form.append("source", addPersonInfo.source);
        form.append("teip", addPersonInfo.teip);
      } else if (buttonType === "edit") {
        form.append("source_id", center.nodeId);
        form.append("teip", center.teip);
      } else {
        form.append("teip", center.teip);
      }
      form.append("f_id", addPersonInfo.f_id);
      form.append("m_id", addPersonInfo.m_id);
      form.append("spouce_id", addPersonInfo.spouce_id);
      form.append("born", addPersonInfo.full_born);
      form.append("die", addPersonInfo.full_die);
      form.append("mail", addPersonInfo.mail);
      form.append("phone", addPersonInfo.phone);
      axios({
        method: "post",
        url:
          buttonType === "editVacuum" || buttonType === "addFirst"
            ? `https://gargalo.ru/api/tree/add`
            : `https://gargalo.ru/api/tree/${buttonType}`,
        data: form,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      })
        .then((res) => {
          switch (buttonType) {
            case "add":
              switch (addingType) {
                case "sister":
                case "brother":
                  setNodes((prevState) => [
                    ...prevState,
                    {
                      height: 152,
                      width: 125,
                      f_id: String(res.data.f_id),
                      fullfio: res.data.fio,
                      id: String(res.data.id),
                      is_approved: res.data.is_approved,
                      m_id: String(res.data.m_id),
                      pol: res.data.pol,
                      spouce_id: res.data.spouce_id,
                      teip: res.data.teip,
                      user_id: res.data.user_id,
                      avatar: res.data.avatar,
                      die: addPersonInfo.die,
                      born: addPersonInfo.born,
                    },
                  ]);
                  setEdges((prevState) => [
                    ...prevState,
                    {
                      id: `${center.f_id}-${res.data.id}`,
                      from: `${center.f_id}`,
                      to: `${res.data.id}`,
                      fromPort: `${center.f_id}-from`,
                      toPort: `${res.data.id}-to`,
                    },
                  ]);
                  break;
                case "daughter":
                case "son":
                  setNodes((prevState) => [
                    ...prevState,
                    {
                      height: 152,
                      width: 125,
                      f_id: String(res.data.f_id),
                      fullfio: res.data.fio,
                      id: String(res.data.id),
                      is_approved: res.data.is_approved,
                      m_id: String(res.data.m_id),
                      pol: res.data.pol,
                      spouce_id: res.data.spouce_id,
                      teip: res.data.teip,
                      user_id: res.data.user_id,
                      avatar: res.data.avatar,
                      die: addPersonInfo.die,
                      born: addPersonInfo.born,
                    },
                  ]);
                  setEdges((prevState) => [
                    ...prevState,
                    {
                      id: `${center.nodeId}-${res.data.id}`,
                      from: `${center.nodeId}`,
                      to: `${res.data.id}`,
                      fromPort: `${center.nodeId}-from`,
                      toPort: `${res.data.id}-to`,
                    },
                  ]);
                  break;
                case "father":
                  setNodes((prevState) => [
                    ...prevState,
                    {
                      height: 152,
                      width: 125,
                      f_id: String(res.data.f_id),
                      fullfio: res.data.fio,
                      id: String(res.data.id),
                      is_approved: res.data.is_approved,
                      m_id: String(res.data.m_id),
                      pol: res.data.pol,
                      spouce_id: res.data.spouce_id,
                      teip: res.data.teip,
                      user_id: res.data.user_id,
                      avatar: res.data.avatar,
                      die: addPersonInfo.die,
                      born: addPersonInfo.born,
                    },
                  ]);
                  setEdges((prevState) => [
                    ...prevState,
                    {
                      id: `${res.data.id}-${center.nodeId}`,
                      from: `${res.data.id}`,
                      to: `${center.nodeId}`,
                      fromPort: `${res.data.id}-from`,
                      toPort: `${center.nodeId}-to`,
                    },
                  ]);
                  break;
                case "spouce":
                  setNodes(
                    nodes.map((element) => {
                      if (element.id === center.nodeId) {
                        element.width = 250;
                        element.spouceFio = res.data.fio;
                        element.spouceAvatar = res.data.avatar;
                        element.spouceBorn = res.data.born;
                        element.spouceDie = res.data.die;
                        element.spoucePol = res.data.pol;
                        element.spouce_user_id = res.data.user_id;
                        element.spouce_id = String(res.data.id);
                        element.spouce_f_id = String(res.data.f_id);
                        element.spouce_m_id = String(res.data.m_id);
                        element.spouce_approved = res.data.is_approved;
                      }
                      return element;
                    })
                  );
                  break;

                default:
                  break;
              }
              break;

            case "edit":
              setNodes(
                nodes.map((element) => {
                  if (element.id === center.nodeId) {
                    element.f_id = String(res.data.f_id);
                    element.fullfio = res.data.fio;
                    element.id = String(res.data.id);
                    element.is_approved = res.data.is_approved;
                    element.m_id = String(res.data.m_id);
                    element.pol = res.data.pol;
                    element.spouce_id = res.data.spouce_id;
                    element.teip = res.data.teip;
                    element.user_id = res.data.user_id;
                    element.avatar = res.data.avatar;
                    element.die = addPersonInfo.die;
                    element.born = addPersonInfo.born;
                  } else if (element.spouce_id === center.nodeId) {
                    element.spouceFio = res.data.fio;
                    element.spouceAvatar = res.data.avatar;
                    element.spouceBorn = res.data.born;
                    element.spouceDie = res.data.die;
                    element.spoucePol = res.data.pol;
                    element.spouce_user_id = res.data.user_id;
                    element.spouce_id = String(res.data.id);
                    element.spouce_f_id = String(res.data.f_id);
                    element.spouce_m_id = String(res.data.m_id);
                    element.spouce_approved = res.data.is_approved;
                  }
                  return element;
                })
              );
              break;

            case "editVacuum":
              setNodes(
                nodes.map((element) => {
                  if (element.current_id === Number(center.nodeId)) {
                    element.current_id = res.data.id;
                  } else if (
                    element.spouce_current_id === Number(center.nodeId)
                  ) {
                    element.spouce_current_id = res.data.id;
                  }
                  if (element.id === center.nodeId) {
                    element.f_id = String(res.data.f_id);
                    element.fullfio = res.data.fio;
                    element.id = String(res.data.id);
                    element.is_approved = res.data.is_approved;
                    element.m_id = String(res.data.m_id);
                    element.pol = res.data.pol;
                    element.spouce_id = res.data.spouce_id;
                    element.teip = res.data.teip;
                    element.user_id = res.data.user_id;
                    element.avatar = res.data.avatar;
                    element.die = addPersonInfo.die;
                    element.born = addPersonInfo.born;
                  } else if (element.spouce_id === center.nodeId) {
                    element.spouceFio = res.data.fio;
                    element.spouceAvatar = res.data.avatar;
                    element.spouceBorn = res.data.born;
                    element.spouceDie = res.data.die;
                    element.spoucePol = res.data.pol;
                    element.spouce_user_id = res.data.user_id;
                    element.spouce_id = String(res.data.id);
                    element.spouce_f_id = String(res.data.f_id);
                    element.spouce_m_id = String(res.data.m_id);
                    element.spouce_approved = res.data.is_approved;
                  }
                  return element;
                })
              );
              setEdges(
                edges.map((element) => {
                  if (element.from === center.nodeId) {
                    element.from = `${res.data.id}`;
                    element.fromPort = `${res.data.id}-from`;
                    element.id = `${res.data.id}-${element.id.split("-")[2]}`;
                  } else if (element.to === center.nodeId) {
                    element.to = `${res.data.id}`;
                    element.toPort = `${res.data.id}-to`;
                    element.id = `${element.id.splice("-")[0]}-${res.data.id}`;
                  }
                  return element;
                })
              );
              break;
            case "addFirst":
              setNodes((prevState) => [
                ...prevState,
                {
                  height: 152,
                  width: 125,
                  f_id: String(res.data.f_id),
                  fullfio: res.data.fio,
                  id: String(res.data.id),
                  is_approved: res.data.is_approved,
                  m_id: String(res.data.m_id),
                  pol: res.data.pol,
                  spouce_id: res.data.spouce_id,
                  teip: res.data.teip,
                  user_id: res.data.user_id,
                  avatar: res.data.avatar,
                  die: addPersonInfo.die,
                  born: addPersonInfo.born,
                },
              ]);
              break;
            default:
              break;
          }
        })
        .catch(() => {
          alert("Произошла непредвиденная ошибка, повторите операцию позже.");
        });
    }
    handleCloseAddPerson();
  };

  //Кнопка удаления человека
  const handleDeletePerson = (e) => {
    e.preventDefault();
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/tree/delete/${center.nodeId}`,
      headers: {
        "content-type": "application/json",
        "User-Token": 1,
        Authorization: `Bearer ${token[1]}`,
      },
    }).then(() => {
      setEdges(edges.filter((element) => element.from !== center.nodeId));
      setEdges(edges.filter((element) => element.to !== center.nodeId));
      setNodes(nodes.filter((element) => element.id !== center.nodeId));
      handleCloseAddPerson();
    });
  };

  //Закрытие формы
  const handleCloseAddPerson = () => {
    document.getElementById("addForm").reset();
    setAppenedBlock(false);
    setFormBlock(false);
    setAddPersonInfo({
      ...addPersonInfo,
      fio_id: "",
      fio: "",
      source_id: "",
      source: "",
      spouce_id: "",
      s_fio: "",
      f_id: "",
      f_fio: "",
      m_id: "",
      m_fio: "",
      die: "",
      born: "",
      full_born: "",
      full_die: "",
      pol: "",
      teip: "",
      phone: "",
      mail: "",
      info: "",
      imgUrl: avatarDefault,
      img: null,
    });
  };

  return (
    <form
      action="https://gargalo.ru/tree/add"
      method="post"
      encType="multipart/form-data"
      className="addForm"
      id="addForm"
      style={{ visibility: "visible" }}
    >
      <div className="addPerson">
        <img
          className="CloseAddPerson"
          src={x}
          alt="x"
          onClick={handleCloseAddPerson}
        />
        <div className="addingImgAndFio">
          <div className="addingImgAndDate" style={{ marginRight: 46 }}>
            <div className="imgBlock">
              <img src={addPersonInfo.imgUrl} alt="" className="imgPerson" />
              <input
                type="file"
                name="mediaInput[]"
                id="inputFile"
                className="inputImg"
                accept="image/*"
                onChange={(e) =>
                  setAddPersonInfo({
                    ...addPersonInfo,
                    imgUrl: URL.createObjectURL(e.target.files[0]),
                    img: e.target.files[0],
                  })
                }
              ></input>
              <label htmlFor="inputFile" className="inputWrapper">
                <img className="inputIcon" src={addImage} alt="Выбрать файл" />
              </label>
            </div>
          </div>
          <div className="addingFIO">
            <h2 className="addingInfoText">Основная информация</h2>
            <InputSearch
              setAddPersonInfo={setAddPersonInfo}
              addPersonInfo={addPersonInfo}
              placeholder="ФИО"
              valueKey="fio"
              valueId="fio_id"
              pol="0"
            />
            <InputSearch
              setAddPersonInfo={setAddPersonInfo}
              addPersonInfo={addPersonInfo}
              placeholder="ФИО супруга(и)"
              valueKey="s_fio"
              valueId="spouce_id"
              pol="0"
            />
            <h2 className="addingInfoTextDate">Дата рождения</h2>
            <input
              className="addingInfoDate"
              type="date"
              name="born"
              onChange={(e) =>
                setAddPersonInfo({
                  ...addPersonInfo,
                  full_born: e.target.value,
                  born: e.target.value,
                })
              }
              value={addPersonInfo.full_born}
            />
            <h2 className="addingInfoTextDate">Дата смерти</h2>
            <input
              className="addingInfoDate"
              type="date"
              name="die"
              placeholder="Дата смерти"
              onChange={(e) =>
                setAddPersonInfo({
                  ...addPersonInfo,
                  full_die: e.target.value,
                  die: e.target.value,
                })
              }
              value={addPersonInfo.full_die}
              style={{ marginBottom: 0 }}
            />
          </div>
        </div>
        {buttonType === "add" && (
          <>
            <h2 className="addingInfoText">Родители</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <InputSearch
                setAddPersonInfo={setAddPersonInfo}
                addPersonInfo={addPersonInfo}
                placeholder="ФИО отца"
                valueKey="f_fio"
                valueId="f_id"
                pol="1"
              />
              <InputSearch
                setAddPersonInfo={setAddPersonInfo}
                addPersonInfo={addPersonInfo}
                placeholder="ФИО матери"
                valueKey="m_fio"
                valueId="m_id"
                pol="2"
              />
            </div>
          </>
        )}
        <h2 className="addingInfoText">Контактная информация</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <input
            className="addingInfoDate"
            type="mail"
            placeholder="Email"
            name="mail"
            onChange={(e) =>
              setAddPersonInfo({
                ...addPersonInfo,
                mail: e.target.value.replace(/[а-яА-яЁё]/, ""),
              })
            }
            value={addPersonInfo.mail}
          />
          <input
            className="addingInfoDate"
            type="number"
            placeholder="Номер телефона"
            name="phone"
            onChange={(e) =>
              setAddPersonInfo({
                ...addPersonInfo,
                phone: e.target.value.replace(/[^0-9]/g, ""),
              })
            }
            value={addPersonInfo.phone}
          />
        </div>
        {(buttonType === "edit" || buttonType === "addFirst") && (
          <>
            <h2 className="addingInfoText">Пол</h2>
            <div style={{ display: "flex" }}>
              <button
                className={
                  addPersonInfo.pol === 1
                    ? "userGender userGenderMan"
                    : "userGender"
                }
                onClick={(e) => {
                  e.preventDefault();
                  setAddPersonInfo({
                    ...addPersonInfo,
                    pol: 1,
                  });
                }}
              >
                Мужчина
              </button>
              <button
                className={
                  addPersonInfo.pol === 2
                    ? "userGender userGenderMan"
                    : "userGender"
                }
                onClick={(e) => {
                  e.preventDefault();
                  setAddPersonInfo({
                    ...addPersonInfo,
                    pol: 2,
                  });
                }}
              >
                Женщина
              </button>
            </div>
          </>
        )}
        <h2 className="addingInfoText">Краткая биография</h2>
        <textarea
          className="addingAddition"
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Детали и комментарии"
          onChange={(e) =>
            setAddPersonInfo({
              ...addPersonInfo,
              info: e.target.value.replace(/[a-zA-Z]/, ""),
            })
          }
          value={addPersonInfo.info}
        ></textarea>
        <div className="btnWrapp">
          <button
            className="formBtn"
            onClick={handleAddPerson}
            disabled={addPersonInfo.fio.trim().length <= 1 && true}
          >
            {buttonType === "add" || buttonType === "addFirst"
              ? "Добавить"
              : buttonType === "edit"
              ? "Редактировать"
              : "Сохранить"}
          </button>
          {buttonType === "edit" && (
            <button className="formBtn" onClick={handleDeletePerson}>
              Удалить
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default FormBlock;
