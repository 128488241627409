import React, { useRef } from "react";
import style from "../user.module.css";
import check from "../img/CheckCircle.svg";
import WarningImage from "../img/CheckCircleWarning.svg";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Camera from "../img/Camera.svg";
import Loader from "../../Loader";
import deleteImage from "../img/Delete.svg";
import cameraIcon from "../img/CameraIcon.svg";
import image from "../img/Image.svg";
import UserSettingsMain from "./UserSettingsMain";
import { useState } from "react";
import UserSettingsSafety from "./UserSettingsSafety";
import UserSettingsNotifications from "./UserSettingsNotifications";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import PostService from "../../../../API/postService";
import ReDropDown from "../../../reusedComponents/ReDropDown";
import { UseClosing } from "../../../../hooks/useClosing";

const UserSettings = (category) => {
  const [user, setUser] = useState();
  const { userId } = useParams();
  const [avatar, setAvatar] = useState();
  const [categorySettings, setCategorySettings] = useState();
  const [imageServ, setImageServ] = useState();
  const [reDropdownActive, setReDropdownActive] = useState(false);

  const inputRef = useRef();

  const modalRef = useRef();
  UseClosing(modalRef, setReDropdownActive);

  const bornDate = new Date(user?.born);

  useEffect(() => {
    PostService.getAllUser(userId).then((res) => setUser(res));
  }, []);
  useEffect(() => {
    setCategorySettings(category.category);
  }, [category]);

  const getClassesForHeader = () => {
    return user?.is_real
      ? style.settings__header
      : style.settings__header + " " + style.settings__header_warning;
  };

  const handleClickChangeAvatar = (e) => {
    e.stopPropagation();
    inputRef.current.click();
  };

  const handleOnChange = (element) => {
    PostService.addAvatar(userId, element).then(() =>
      setAvatar(URL.createObjectURL(element))
    );
  };
  const handleClickDeleteAvatar = () => {
    PostService.deleteAvatar(userId);
  };
  const handleClick = () => {};
  console.log(user);
  return (
    <>
      {user ? (
        <div className={style.settings__box}>
          <div className={getClassesForHeader()}>
            <div className={style.settings__wrap}>
              <div className={style.head__info}>
                <div
                  onMouseEnter={() => setReDropdownActive(true)}
                  onMouseLeave={() => setReDropdownActive(false)}
                >
                  <img
                    className={style.profile__avatar}
                    src={avatar ? avatar : `https://gargalo.ru${user?.avatar}`}
                    alt=""
                  />
                  <div
                    ref={modalRef}
                    style={{ position: "absolute" }}
                  >
                    {reDropdownActive && (
                      <ReDropDown
                        data={[
                          {
                            title: "Заменить фото",
                            icon: cameraIcon,
                            handleClick: handleClickChangeAvatar,
                          },
                          {
                            title: "Удалить фото",
                            icon: deleteImage,
                            handleClick: handleClickDeleteAvatar,
                          },
                        ]}
                      />
                    )}
                  </div>
                </div>
                <input
                  ref={inputRef}
                  type="file"
                  id="inputImgProfile"
                  onChange={(e) => handleOnChange(e.target.files[0])}
                  className={style.inputFileImg}
                />
                <div className={style.settings__username}>
                  <div>
                    <h2>{user?.nickname}</h2>
                    <span>{bornDate.toLocaleDateString("ru-RU")}</span>
                  </div>
                  <div style={{ display: "flex" }}>
                    {user?.is_real ? (
                      <div className={style.settings__check}>
                        <img
                          style={{
                            width: 24,
                            height: 24,
                          }}
                          src={check}
                          alt=""
                        />
                        <h2>Активирован</h2>
                      </div>
                    ) : (
                      <Link to="/confirmUser">
                        <div className={style.settings__check}>
                          <img
                            style={{
                              width: 24,
                              height: 24,
                            }}
                            src={WarningImage}
                            alt=""
                          />
                          <h2>Не активирован</h2>
                        </div>
                      </Link>
                    )}
                    {user?.node_id ? (
                      <div className={style.settings__check}>
                        <img
                          style={{
                            width: 24,
                            height: 24,
                          }}
                          src={check}
                          alt=""
                        />
                        <h2>Подтвержден</h2>
                      </div>
                    ) : (
                      <Link to="/confirmUser">
                        <div
                          className={style.settings__check}
                          style={{ width: "157px" }}
                        >
                          <img
                            style={{
                              width: 24,
                              height: 24,
                            }}
                            src={WarningImage}
                            alt=""
                          />
                          <h2>Не подтвержден</h2>
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {categorySettings === 1 ? (
            <UserSettingsMain avatar={imageServ} />
          ) : category.category === 3 ? (
            <UserSettingsSafety />
          ) : category.category === 4 ? (
            <UserSettingsNotifications />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div style={{ width: 670 }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default UserSettings;
