import React, { useState, useContext } from "react";
import style from "./StyleAddEvent.module.css";
import arrowImage from "../img/ArrowsLeftRight.svg";
import ReusedInput from "../../../reusedComponents/ReusedInput";
import PostService from "./../../../../API/postService";
import ReusedDropDownList from "../../../reusedComponents/ReusedDropDownList";
import { RegistrationContext } from "../../../context";
import ReCalendar from "../../../reusedComponents/ReCalendar";
import { useDetectClickOutside } from "react-detect-click-outside";

const EventsWedding = ({ keys, setOpen, setEventList, setModeratorReview }) => {
  const [event, setEvent] = useState({
    groomId: "",
    groom: "",
    wifeId: "",
    wife: "",
    adress: "",
    date: "",
    details: "",
  });
  const [groom, setGroom] = useState([]);
  const [wife, setWife] = useState([]);

  // запросы
  const handleChange = (target) => {
    const { name } = target;
    const { value } = target;
    setEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (value.length > 0) {
      if (name === "groom" || name === "wife") {
        PostService.searchUsers(
          name === "groom" ? event.groom : event.wife,
          `&filter[]=persons&pol=${name === "groom" ? 1 : 2}`
        ).then((res) =>
          name === "groom" ? setGroom(res?.data) : setWife(res?.data)
        );
      }
    } else {
      setGroom([]);
      setWife([]);
    }
  };

  // задаваемые стили для переиспользуемых компонентов
  const styleForReusedInputBetrothed = {
    width: 282,
  };
  const styleForReusedInputAdress = {
    width: 634,
  };

  // добавление события
  const { user } = useContext(RegistrationContext);

  const handleClick = async (e) => {
    e.preventDefault();
    if (
      user.is_admin ||
      user.is_moder ||
      keys.permission === "family" ||
      keys.permission === "followers"
    ) {
      const data = await PostService.sendEvents(event, keys);
      setEventList((prev) => [data, ...prev]);
      setOpen(false);
    } else {
      PostService.sendEvents(event, keys);
      setOpen(false);
      setModeratorReview(true);
    }
  };

  //Закрытие выпадающего списка
  const handleOpenBlock = (state, setState, name) => {
    if (!state) {
      PostService.searchUsers(
        "",
        `&filter[]=persons&pol=${name === "groom" ? 1 : 2}`
      ).then((res) => {
        name === "groom" ? setGroom(res?.data) : setWife(res?.data);
        setState(true);
      });
    }
  };
  const [openListBlock, setOpenListBlock] = useState(true);

  const handleCloseListBlock = () => {
    setOpenListBlock(false);
  };

  const filterListRef = useDetectClickOutside({
    onTriggered: handleCloseListBlock,
  });

  const [openListWifeBlock, setOpenListWifeBlock] = useState(true);

  const handleCloseListWifeBlock = () => {
    setOpenListWifeBlock(false);
  };

  const filterListWifeRef = useDetectClickOutside({
    onTriggered: handleCloseListWifeBlock,
  });

  //Проверка на заполнение
  const isButtonDisabled =
    Object.values(event).some((value) => value === "") || keys.title === "";

  return (
    <div className={style.wedding}>
      <div className={style.content__betrothed}>
        <div className={style.content__groom}>
          <h2 className={style.content__suptitle}>Жених</h2>
          <div
            style={{ position: "relative" }}
            ref={filterListRef}
            onClick={() => {
              handleOpenBlock(openListBlock, setOpenListBlock, "groom");
            }}
          >
            <ReusedInput
              name="groom"
              type="data"
              reusedInputStyle={styleForReusedInputBetrothed}
              placeholder="Введите ФИО жениха"
              value={event.groom}
              onChange={handleChange}
            />
            {groom?.length > 0 && openListBlock && (
              <div className={style.groom__list}>
                <ReusedDropDownList
                  data={groom}
                  id="groomId"
                  setState={setEvent}
                  name="groom"
                  setOpenComp={setGroom}
                />
              </div>
            )}
          </div>
        </div>
        <img className={style.content__arrow__image} src={arrowImage} alt="" />
        <div className={style.content__wife}>
          <h2 className={style.content__suptitle}>Невеста</h2>
          <div
            style={{ position: "relative" }}
            ref={filterListWifeRef}
            onClick={() => {
              handleOpenBlock(openListWifeBlock, setOpenListWifeBlock, "wife");
            }}
          >
            <ReusedInput
              name="wife"
              reusedInputStyle={styleForReusedInputBetrothed}
              placeholder="Введите ФИО невесты"
              value={event.wife}
              onChange={handleChange}
            />
            {wife?.length > 0 && openListWifeBlock && (
              <div className={style.groom__list}>
                <ReusedDropDownList
                  id="wifeId"
                  data={wife}
                  setState={setEvent}
                  name="wife"
                  setOpenComp={setWife}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Адрес</h2>
        <ReusedInput
          name="adress"
          type="text"
          reusedInputStyle={styleForReusedInputAdress}
          placeholder="Введите адрес проведения мероприятия"
          value={event.adress}
          onChange={handleChange}
        />
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Дата</h2>
        <ReCalendar
          setState={setEvent}
          state={event}
          stateProps={"date"}
          calendarStyle={{ right: 0, left: "auto" }}
          width={"100%"}
          placeholderText={"Введите дату проведения мероприятия"}
        />
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Подробности</h2>
        <textarea
          name="details"
          id="details"
          className={style.content__textarea}
          placeholder="Напишите подробности мероприятия"
          value={event.details}
          onChange={(e) =>
            handleChange({ name: e.target.name, value: e.target.value })
          }
        ></textarea>
      </div>
      <div className={style.content}>
        <button
          onClick={() => setOpen(false)}
          className={style.content__button__cancel}
        >
          Отмена
        </button>
        <button
          onClick={handleClick}
          className={style.content__button}
          disabled={isButtonDisabled}
        >
          Добавить
        </button>
        <h4 className={style.content__moder__text}>
          * Событие для тейпа будет опубликовано после расмотрения модератором
        </h4>
      </div>
    </div>
  );
};

export default EventsWedding;
