import React, { useState, useRef, useContext } from "react";
import style from "./addpost.module.css";
import Adding from "./Adding";
import cameraImage from "./img/Camera.svg";
import { RegistrationContext } from "../../../context";
import { UseClosing } from "../../../../hooks/useClosing";

const AddPost = ({
  handleAddPosts,
  where,
  whereTitle,
  whereButton,
  whereHeader,
  teipId,
}) => {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState(0);
  const { user } = useContext(RegistrationContext);

  const modalRef = useRef();

  const handleClickOpen = () => {
    setOpen((prev) => !prev);
  };

  UseClosing(modalRef, setOpen);

  return (
    <div ref={modalRef}>
      {!open && (
        <div
          className={style.AddPost_MainDiv}
          onClick={() => handleClickOpen()}
        >
          <div>
            <img
              className={style.add_post_image}
              src={`https://gargalo.ru/${user?.avatar_small}`}
              alt=""
            />

            <span className={style.add_post_text}>{whereTitle}</span>
          </div>
          <img
            className={style.add_post_camera_image}
            src={cameraImage}
            alt=""
          />
        </div>
      )}

      <Adding
        open={open}
        setErrors={setErrors}
        handleClickOpen={handleClickOpen}
        handleAddPosts={handleAddPosts}
        whereHeader={whereHeader}
        where={where}
        whereButton={whereButton}
        teipId={teipId}
      />
    </div>
  );
};

export default AddPost;
