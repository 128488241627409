import React, { useContext } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { editPassword } from "../../../../redux/features/users";
import ReButton from "../../../reusedComponents/ReButton";
import style from "../user.module.css";
import Group from "../img/Group.svg";
import GroupTwo from "../img/GroupTwo.svg";
import Switch from "../../../reusedComponents/Switch";
import ReSwitch from "../../../reusedComponents/ReSwitch";
import { RegistrationContext } from "../../../context";
import PostService from "../../../../API/postService";

let error;
const UserSettingsSafety = () => {
  const { user, setUser } = useContext(RegistrationContext);
  const dispatch = useDispatch();
  const [oldPass, setOldPass] = useState();
  const [newPass, setNewPass] = useState();
  const [newPassTwo, setNewPassTwo] = useState();
  const [notifiState, setNotifiState] = useState({ switch: !user.is_open });
  // const passInfo = useSelector((state) => state.users.password)

  const checkPass = (pass) => {
    console.log(pass);
    if (pass._status !== 200) {
      error = `*${pass.errors}`;
    } else {
      error = "*Пароль успшено изменен";
    }
  };

  const handleChange = () => {
    oldPass &&
      newPass &&
      newPassTwo &&
      dispatch(editPassword(oldPass, newPass, newPassTwo, checkPass));
    if (user.is_open !== notifiState.switch) {
      PostService.closedAndOpenAccount().then(() => {
        setUser({ ...user, is_open: notifiState.switch });
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div className={style.settings__fio__block}>
      <div className={style.settings__fio__notify}>
        <div className={style.settings__fio__accaunt}>
          <h2>Закрытый аккаунт</h2>
          <div>
            <ReSwitch
              state={notifiState}
              setState={setNotifiState}
              stateProps={"switch"}
            />
          </div>
        </div>
        <span>
          Открытый аккаунт позволяет подписываться на Вас без Вашего согласия.
          Любой пользователь сможет просматривать Ваши моменты и события
          открытые для подписчиков.
        </span>
      </div>
      <h2>Смена пароля</h2>
      <div className={style.settings__pass}>
        <input
          value={oldPass}
          onChange={(e) => setOldPass(e.target.value)}
          type="password"
          placeholder="Ввдеите старый пароль"
        />
        <input
          value={newPass}
          onChange={(e) => setNewPass(e.target.value)}
          type="password"
          placeholder="Введите новый пароль"
        />
        <input
          value={newPassTwo}
          onChange={(e) => setNewPassTwo(e.target.value)}
          type="password"
          placeholder="Повторите новый пароль"
        />
      </div>
      <div>
        <ReButton
          type={"button"}
          classes="main"
          height={40}
          width={120}
          handleClick={() => handleChange()}
          FWeight={600}
          FSize={14}
          text={"Сохранить"}
        />
      </div>

      <div className={style.settings__moder}>
        <span
          style={{
            marginLeft: 0,
            color: error !== "*Пароль успшено изменен" ? "red" : "green",
          }}
        >
          {error}
        </span>
      </div>
    </div>
  );
};

export default UserSettingsSafety;
