import React from "react";
import style from "./adding.module.css";
import addVoteImage from "../../img/plus.svg";
import deleteImage from "./img/X.svg";
import ReInput from "../../../reusedComponents/ReInput";

const AddingVote = ({ voteCount, setVoteCount }) => {
  // Удалить доп ответ
  const handleDeleteVote = (id) => {
    setVoteCount((prev) => [...prev].filter((item) => item.id !== id));
    setVoteCount((prev) => {
      prev.map((item, index) => (item.title = `Ответ ${index + 1}`));
      return [...prev];
    });
  };

  // Изменить значение инпута
  const handleChangeVote = (e, index) => {
    setVoteCount((prev) => {
      prev[index].value = e.target.value;
      return [...prev];
    });
  };

  // Добавить доп ответ
  const handleClickAddVote = () => {
    setVoteCount((prev) => [
      ...prev,
      {
        title: `Ответ ${prev.length + 1}`,
        value: "",
        id: Date.now(),
        deleted: true,
      },
    ]);
  };
 
  return (
    <div className={style.poll}>
      <div className={style.pollVars}>
        {voteCount.map((item, index) => (
          <div className={style.vote_div}>
            <ReInput
              type="text"
              className={style.pollInput}
              handleChange={(e) => handleChangeVote(e, index)}
              clickProps={[item.id]}
              value={item.value}
              placeholder={item.title}
            />

            {item.deleted && (
              <img
                className={style.delete_vote}
                src={deleteImage}
                onClick={() => handleDeleteVote(item.id)}
                alt=""
              />
            )}
          </div>
        ))}
      </div>
      {voteCount.length < 8 && (
        <button
          onClick={handleClickAddVote}
          className={style.pollBtn}
          type="button"
        >
          Добавить вариант
        </button>
      )}
    </div>
  );
};

export default AddingVote;
