import React, { useState, useEffect, useRef, useContext } from "react";
import style from "./EventsList.module.css";
import calendar from "../img/Calendar.svg";
import AddEventBlock from "../addEvent/AddEventBlock";
import Event from "../eventGroup/Event";
import PostService from "../../../../API/postService";
import PaginationScroll from "../../user/userComponents/paginationScroll/paginationScroll";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RegistrationContext } from "../../../context";
import ReButton from "../../../reusedComponents/ReButton";
import ReTabs from "../../../reusedComponents/ReTabs";
import { useInView } from "react-intersection-observer";
import arrowSlide from "../img/arrowSlide.svg";
import Chekbox from "../img/Chekbox.svg";
import ChekboxActiv from "../img/ChekboxActiv.svg";
import Camera from "../img/Camera.svg";
import Filter from "../../img/Filter.svg";

const filtersMass = [
  {
    name: "Отцовская линия",
    value: "lines[]=father&",
  },
  {
    name: "Материнская линия",
    value: "lines[]=mather&",
  },
  {
    name: "Супружеская линия",
    value: "lines[]=spouce&",
  },
  {
    name: "Семья",
    value: "lines[]=family&",
  },
  {
    name: "Подписки",
    value: "lines[]=subscribers&",
  },
  {
    name: "Мои события",
    value: "lines[]=my&",
  },
];

const EventsList = () => {
  const navigate = useNavigate();
  const { user } = useContext(RegistrationContext);
  const [moderatorReview, setModeratorReview] = useState(false);
  const [open, setOpen] = useState();
  const [eventList, setEventList] = useState([]);
  const [nextPage, setNextPage] = useState();
  const [page, setPage] = useState(2);
  const btnRef = useRef(null);
  const loading = useSelector((state) => state.post.loading);
  const [filters, setFilters] = useState([
    "lines[]=father&",
    "lines[]=mather&",
    "lines[]=spouce&",
    "lines[]=family&",
    "lines[]=subscribers&",
    "lines[]=my&",
  ]);
  const [filterBlock, setFilterBlock] = useState(false);
  const [tabs, setTabs] = useState({ name: "Все события", value: "all" });
  const { ref: leftArrow, inView: scrollInViewLeft } = useInView({
    threshold: 1,
    initialInView: true,
  });
  const { ref: rightArrow, inView: scrollInViewRight } = useInView({
    threshold: 1,
    initialInView: true,
  });
  const rightArrowRef = useRef(null);
  const leftArrowRef = useRef(null);

  // запрос изначальных событий
  useEffect(() => {
    async function fetchData() {
      const { data, next_page_url } = await PostService.getAllEventList(
        tabs.value,
        1,
        filters
      );
      setEventList(data);
      setNextPage(next_page_url);
    }
    fetchData();
  }, [tabs, filters]);

  //запрос новых событий при пагинации
  useEffect(() => {
    async function fetchData() {
      const { data, next_page_url } = await PostService.getAllEventList(
        tabs.value,
        page,
        filters
      );
      setEventList((prev) => [...prev, ...data]);
      setNextPage(next_page_url);
    }
    fetchData();
  }, [page]);

  //пагинация
  useEffect(() => {
    const btn = btnRef.current;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prev) => prev + 1);
      }
    });
    if (btn) observer.observe(btn);
    return () => {
      if (btn) observer.unobserve(btn);
    };
  }, [btnRef.current]);

  //Закрытие блока фильтра
  const handleCloseFilter = () => {
    setFilterBlock(false);
  };

  const filterRef = useDetectClickOutside({
    onTriggered: handleCloseFilter,
  });

  return (
    <>
      {eventList && (
        <>
          <div className={style.container}>
            <div className={style.header}>
              {user && (
                <div style={{ position: "relative" }}>
                  {!scrollInViewLeft && (
                    <div
                      className={style.leftArrow}
                      onClick={() => {
                        leftArrowRef.current.scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                        });
                      }}
                    >
                      <img src={arrowSlide} alt="left" />
                    </div>
                  )}
                  {!scrollInViewRight && (
                    <div
                      className={style.rightArrow}
                      onClick={() => {
                        rightArrowRef.current.scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                        });
                      }}
                    >
                      <img src={arrowSlide} alt="right" />
                    </div>
                  )}
                  <div className={style.tabsWrapp}>
                    <div
                      ref={(node) => {
                        leftArrowRef.current = node;
                        leftArrow(node);
                      }}
                      style={{ width: 1 }}
                    ></div>
                    <ReTabs
                      width={776}
                      text={tabs.name}
                      mb={0}
                      data={[
                        {
                          title: "Все события",
                          active: tabs.value === "all",
                          handleClick: () => {
                            setTabs({
                              name: "Все события",
                              value: "all",
                            });
                          },
                        },
                        {
                          title: "Свадьбы",
                          active: tabs.value === "wedding",
                          handleClick: () => {
                            setTabs({
                              name: "Свадьбы",
                              value: "wedding",
                            });
                          },
                        },
                        {
                          title: "Мероприятия",
                          active: tabs.value === "2",
                          handleClick: () => {
                            setTabs({
                              name: "Мероприятия",
                              value: "2",
                            });
                          },
                        },
                        {
                          title: "Рождение",
                          active: tabs.value === "birth",
                          handleClick: () => {
                            setTabs({
                              name: "Рождение",
                              value: "birth",
                            });
                          },
                        },
                        {
                          title: "Похороны",
                          active: tabs.value === "funeral",
                          handleClick: () => {
                            setTabs({
                              name: "Похороны",
                              value: "funeral",
                            });
                          },
                        },
                        {
                          title: "Сбор средств",
                          active: tabs.value === "fundraising",
                          handleClick: () => {
                            setTabs({
                              name: "Сбор средств",
                              value: "fundraising",
                            });
                          },
                        },
                        {
                          title: "Другое",
                          active: tabs.value === "other",
                          handleClick: () => {
                            setTabs({
                              name: "Другое",
                              value: "other",
                            });
                          },
                        },
                      ]}
                    />
                    <div
                      ref={(node) => {
                        rightArrowRef.current = node;
                        rightArrow(node);
                      }}
                      style={{ width: 1 }}
                    ></div>
                  </div>
                </div>
              )}
              <div className={style.filterWrapp} ref={filterRef}>
                <img
                  src={Filter}
                  alt="Filter"
                  className={style.filterIcon}
                  onClick={() => {
                    if (!filterBlock) {
                      setFilterBlock(true);
                    } else {
                      setFilterBlock(false);
                    }
                  }}
                />
                {filterBlock && (
                  <div className={style.filterMapWrapp}>
                    {filtersMass.map((filter) => {
                      return (
                        <div
                          key={filter.value}
                          className={style.filterItem}
                          onClick={() => {
                            if (filters.includes(filter.value)) {
                              setFilters(
                                filters.filter((item) => item !== filter.value)
                              );
                            } else {
                              setFilters([...filters, filter.value]);
                            }
                          }}
                        >
                          <img
                            src={
                              filters.includes(filter.value)
                                ? ChekboxActiv
                                : Chekbox
                            }
                            alt="Check"
                            className={style.filterItemIcon}
                          />
                          {filter.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {open ? (
              <AddEventBlock
                setModeratorReview={setModeratorReview}
                setOpen={setOpen}
                setEventList={setEventList}
              />
            ) : (
              <div
                className={style.addEventsWrapp}
                onClick={() => setOpen(true)}
              >
                <div className={style.addEventAvatarText}>
                  <img src={`https://gargalo.ru/${user.avatar_small}`} alt="Avatar" />
                  Расскажите о событии ...
                </div>
                <img src={Camera} alt="Camera" className={style.addEventIcon} />
              </div>
            )}
            {eventList.length > 0 ? (
              <div className={style.content}>
                {eventList.map((evs, index) => (
                  <Event data={evs} key={index} />
                ))}
              </div>
            ) : (
              <>
                <div className={style.plug}>
                  <div className={style.plug_div}>
                    <div>
                      <img className={style.plug_image} src={calendar} alt="" />
                    </div>
                    <div>
                      <p className={style.plug_text}>
                        Пока нет событий. Здесь появятся события Ваших
                        родственников, близких и друзей. Также вы можете
                        добавить свое событие.
                      </p>
                    </div>
                  </div>
                </div>
                {!user?.is_real && (
                  <div className={style.user_not_activated}>
                    <div className={style.user_not_activated_content}>
                      <p className={style.user_not_activated_text}>
                        ВАШ ПРОФИЛЬ НЕАКТИВЕН
                        <br /> Активируйте профиль любым удобным
                        <br /> для Вас способом.
                      </p>
                      <button
                        onClick={() => navigate("/confirmUser")}
                        className={style.user_not_activated_button}
                      >
                        Активировать
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {nextPage && (
            <PaginationScroll
              loading={loading}
              btnRef={btnRef}
              account="wall"
            />
          )}
        </>
      )}
    </>
  );
};

export default React.memo(EventsList);
