import React, { useState } from "react";
import check from "../img/VieChekbox.svg";
import checkActiv from "../img/VieChekboxActiv.svg";
import Info from "../img/Info.svg";
import ArrowInfo from "../img/ArrowInfo.svg";
import style from "./StyleAddEvent.module.css";
import { useContext } from "react";
import { RegistrationContext } from "../../../context";

const checkedVar = [
  {
    name: "Тейпу",
    value: "teip",
    description:
      "Показывается родственникам по отцовской, материнской и супружеской линии",
    width: 355,
  },
  {
    name: "Семье",
    value: "family",
    description: "Показывается всем участникам раздела семья",
    width: 259,
  },
  {
    name: "Подписчикам",
    value: "followers",
    description: "Показывается всем вашим подписчикам",
    width: 180,
  },
];

const EventsShow = ({ show, setShow }) => {
  const { user } = useContext(RegistrationContext);

  const handleClickShow = (key) => {
    setShow(key);
  };

  return (
    <div className={style.events_show_div}>
      {user && user.group.name === "teips" && (
        <div
          className={style.events_show}
          onClick={() => handleClickShow("all_teyp")}
        >
          <img
            className={style.events_show_radio_image}
            src={show === "all_teyp" ? checkActiv : check}
            alt=""
          />
          Всему тейпу (только модераторы)
          <div className={style.events_info_image}>
            <img src={Info} alt="" />
            <div
              className={style.infoWrapp}
              style={{ width: 259, marginLeft: -130 }}
            >
              Показывается всем участникам тейпа (от лица тейпа)
              <img
                src={ArrowInfo}
                alt="ArrowInfo"
                className={style.ArrowInfo}
              />
            </div>
          </div>
        </div>
      )}
      {checkedVar.map((item) => {
        return (
          <div
            className={style.events_show}
            onClick={() => handleClickShow(item.value)}
            key={item.value}
          >
            <img
              className={style.events_show_radio_image}
              src={show === item.value ? checkActiv : check}
              alt="Checked"
            />
            {item.name}
            <div className={style.events_info_image}>
              <img src={Info} alt="" />
              <div
                className={style.infoWrapp}
                style={{ width: item.width, marginLeft: -item.width / 2 }}
              >
                {item.description}
                <img
                  src={ArrowInfo}
                  alt="ArrowInfo"
                  className={style.ArrowInfo}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EventsShow;
