import React from "react";
import style from "../events/Events.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import avatar from "../events/img/avatar.svg";
import celendar from "./img/Calendar.svg";
import Down from "./img/Down.svg";
import { useRef } from "react";
import { useContext } from "react";
import { RegistrationContext } from "../../context";

const UnapprovedEventsPage = () => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [events, setEvents] = useState([]);
  const [nextPage, setNextPage] = useState();
  const [type, setType] = useState("approved=0&");
  const [page, setPage] = useState(1);
  const btnRef = useRef(null);
  const { setUnApprovedEventsCount } = useContext(RegistrationContext);

  useEffect(() => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/events/moderate?approved=0`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    })
      .then((res) => {
        setNextPage(res.data.next_page_url);
        setEvents(res.data.data);
      })
      .catch(() => {});
  }, []);

  //Одобрение или удаление события
  const handleSendEvent = (id, text, index) => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/events/moderate/${id}/${text}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then(() => {
      setEvents(events.filter((item) => item !== events[index]));
      setUnApprovedEventsCount((prevState) => prevState - 1);
    });
  };

  //Смена цвета кнопок && фильтрация событий
  const btnPolColor = (e, type) => {
    let bntNode = document.querySelectorAll("[data-distfil]");
    bntNode.forEach((element) => {
      element.style.backgroundColor = "white";
      element.style.color = "#567f88";
    });
    let btnActiv = e.currentTarget;
    btnActiv.style.backgroundColor = "#2199cc";
    btnActiv.style.color = "#ffffff";

    axios({
      method: "GET",
      url: `https://gargalo.ru/api/events/moderate?approved${type}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    })
    .then((res) => {
        setType("approved" + type + "&");
        setNextPage(res.data.next_page_url);
        setEvents(res.data.data);
      })
      .catch(() => {});
  };

  const btnPolColorAll = (e) => {
    let bntNode = document.querySelectorAll("[data-distfil]");
    bntNode.forEach((element) => {
      element.style.backgroundColor = "white";
      element.style.color = "#567f88";
    });
    let btnActiv = e.currentTarget;
    btnActiv.style.backgroundColor = "#2199cc";
    btnActiv.style.color = "#ffffff";

    axios({
      method: "GET",
      url: `https://gargalo.ru/api/events/moderate`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    })
      .then((res) => {
        setType("");
        setNextPage(res.data.next_page_url);
        setEvents(res.data.data);
      })
      .catch(() => {});
  };

  //Открыие дополнительной информации
  const [eventsId, setEventsId] = useState([]);

  const openDetails = (e, id) => {
    let img = e.currentTarget.querySelector("img");
    img.classList.toggle("activArrowEvents");
    if (eventsId.includes(id)) {
      setEventsId(eventsId.filter((item) => item !== id));
    } else {
      setEventsId([...eventsId, id]);
    }
  };

  //Получение новой страницы
  useEffect(() => {
    if (nextPage) {
      axios({
        method: "GET",
        url: `${nextPage.split("?")[0] + "?" + type + nextPage.split("?")[1]}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      }).then((res) => {
        setNextPage(res.data.next_page_url);
        setEvents((prevState) => [...prevState, ...res.data.data]);
      });
    }
  }, [page]);

  //Прогрузка новой страницы
  useEffect(() => {
    const btn = btnRef.current;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((page) => page + 1);
      }
    });
    if (btn) observer.observe(btn);
    return () => {
      if (btn) observer.unobserve(btn);
    };
  }, [btnRef.current]);

  return (
    <div className={style.eventsList}>
      <div className={style.unapprovedBtnBlock}>
        <button
          className={style.unapprovedBtn}
          data-distfil
          onClick={(e) => btnPolColorAll(e)}
        >
          Все
        </button>
        <button
          className={style.unapprovedBtn}
          data-distfil
          onClick={(e) => btnPolColor(e, "=0")}
        >
          На рассмотрении
        </button>
        <button
          className={style.unapprovedBtn}
          data-distfil
          onClick={(e) => btnPolColor(e, "=1")}
        >
          Одобренные
        </button>
        <button
          className={style.unapprovedBtn}
          data-distfil
          onClick={(e) => btnPolColor(e, "=-1")}
        >
          Отклоненные
        </button>
      </div>
      {events.length !== 0 ? (
        <>
          {events.map((item, index) => {
            return (
              <div key={index} className={style.eventsItem}>
                <div className={style.eventsTop}>
                  <div className={style.topAvaAndName}>
                    <div className={style.topAva}>
                      <Link to="/events">
                        <img
                          src={avatar}
                          style={{ width: 40, height: 40 }}
                          alt="Фото профиля"
                        />
                      </Link>
                    </div>
                    <div className={style.topName}>
                      <Link to="!#">
                        <h3>{item.teip.title}</h3>
                      </Link>
                      <span>
                        {item.line === "father"
                          ? "Отцовская линия"
                          : item.line === "mather"
                          ? "Материнская линия"
                          : "Прочая линия"}
                      </span>
                    </div>
                  </div>
                  <div className={style.topMenu}>
                    <span
                      className={style.event__date_pub}
                      style={{ marginRight: 0 }}
                    >
                      {item.date_pub}
                    </span>
                  </div>
                </div>
                <div className={style.eventsInfo}>
                  {item.action === "wedding" ? (
                    <div>
                      <div className={style.eventsBlock}>
                        <div style={{ display: "flex", marginRight: 15 }}>
                          <h2 className={style.eventsZag}>Жених:</h2>
                          <Link to="" className={style.eventsText}>
                            {item.groom?.fio}
                          </Link>
                        </div>
                        <div style={{ display: "flex" }}>
                          <h2 className={style.eventsZag}>Невеста:</h2>
                          <Link to="" className={style.eventsText}>
                            {item.wife.fio}
                          </Link>
                        </div>
                      </div>
                      <div className={style.eventsBlock}>
                        <h2 className={style.eventsZag}>Адрес:</h2>
                        <h2 className={style.eventsTexDate}>{item.address}</h2>
                      </div>
                      <div className={style.eventsBlock}>
                        <h2 className={style.eventsZag}>Дата проведения:</h2>
                        <h2 className={style.eventsTexDate}>{item.date}</h2>
                      </div>
                    </div>
                  ) : item.action === "funeral" ? (
                    <div>
                      <div className={style.event__text}>
                        <div className={style.eventsBlock}>
                          <h2 className={style.eventsZag}>Умерший:</h2>
                          <Link to="" className={style.eventsText}>
                            {item.dead.fio}
                          </Link>
                        </div>
                        <div className={style.eventsBlock}>
                          <h2 className={style.eventsZag}>Адрес:</h2>
                          <h2 className={style.eventsTexDate}>
                            {item.address}
                          </h2>
                        </div>
                        <div className={style.eventsBlock}>
                          <h2 className={style.eventsZag}>Дата cмерти:</h2>
                          <h2 className={style.eventsTexDate}>{item.date}</h2>
                        </div>
                      </div>
                    </div>
                  ) : item.action === "fundraising" ? (
                    <div>
                      <div className={style.eventsBlock}>
                        <h2 className={style.eventsZag}>Цель:</h2>
                        <h2 className={style.eventsZag1}>{item.details}</h2>
                      </div>
                      <div className={style.eventsBlock}>
                        <h2 className={style.eventsZag}>Организатор:</h2>
                        <Link to="" className={style.eventsText}>
                          {item.organizer.fio}
                        </Link>
                      </div>
                      <div className={style.eventsBlock}>
                        <h2 className={style.eventsZag}>Срок до:</h2>
                        <h2 className={style.eventsTexDate}>{item.date}</h2>
                      </div>
                    </div>
                  ) : item.action === "birth" ? (
                    <div>
                      <div className={style.eventsBlock}>
                        <h2 className={style.eventsZag}>Родился:</h2>
                        <Link to="" className={style.eventsText}>
                          {item.born_fio}
                        </Link>
                      </div>
                      {item.father || item.mather ? (
                        <div className={style.eventsBlock}>
                          {item.father && (
                            <div style={{ display: "flex", marginRight: 15 }}>
                              <h2 className={style.eventsZag}>Отец:</h2>
                              <h2 className={style.eventsText}>
                                {item.father.fio}
                              </h2>
                            </div>
                          )}
                          {item.mather && (
                            <div style={{ display: "flex" }}>
                              <h2 className={style.eventsZag}>Мать:</h2>
                              <h2 className={style.eventsText}>
                                {item.mather.fio}
                              </h2>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className={style.eventsBlock}>
                        <h2 className={style.eventsZag}>Дата рождения:</h2>
                        <h2 className={style.eventsTexDate}>{item.date}</h2>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {item.details && (
                  <div className={style.event__more__wrap}>
                    <div
                      className={style.event__more__block}
                      onClick={(e) => openDetails(e, item.id)}
                    >
                      <h2>Подробности</h2>
                      <img src={Down} />
                    </div>
                    {eventsId.map((eventId) => {
                      return (
                        <div key={eventId}>
                          {eventId === item.id && (
                            <h2 className={style.more__text}>{item.details}</h2>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                <div
                  style={{
                    backgroundColor:
                      item.action === "wedding"
                        ? "#9FE2FF"
                        : item.action === "funeral"
                        ? "#000000"
                        : item.action === "fundraising"
                        ? "#054857"
                        : item.action === "birth"
                        ? "#73C5D7"
                        : "#AAC0C5",
                  }}
                  className={style.event__button__action__unapproved}
                >
                  <button style={{ cursor: "default" }}>
                    {item.action === "wedding"
                      ? "Свадьба"
                      : item.action === "funeral"
                      ? "Похороны"
                      : item.action === "fundraising"
                      ? "Сбор средств"
                      : item.action === "birth"
                      ? "Рождение"
                      : "Другое"}
                  </button>
                </div>
                <div className={style.eventsBtnBlock}>
                  {item.status === "accept" || item.status === "ignore" ? (
                    <button
                      className={style.eventsBtn}
                      style={{ background: "#d63434" }}
                      onClick={() => handleSendEvent(item.id, "default", index)}
                    >
                      Отозвать
                    </button>
                  ) : (
                    <div>
                      <button
                        className={style.eventsBtn}
                        style={{ background: "#d63434" }}
                        onClick={() =>
                          handleSendEvent(item.id, "ignore", index)
                        }
                      >
                        Отклонить
                      </button>
                      <button
                        style={{ background: "#147fad" }}
                        className={style.eventsBtn}
                        onClick={() =>
                          handleSendEvent(item.id, "accept", index)
                        }
                      >
                        Одобрить
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className={style.noEvents__block}>
          <div>
            <img src={celendar} alt="" />
          </div>
          <div>
            <span>
              Здесь появятся события Ваших родственников и близких, которые
              нужно подтвердить. Только после подтверждения они появятся в
              событиях у других пользователей.
            </span>
          </div>
        </div>
      )}
      {nextPage !== null && (
        <button style={{ opacity: 0 }} ref={btnRef}>
          Показать еще
        </button>
      )}
    </div>
  );
};

export default UnapprovedEventsPage;
