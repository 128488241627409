import React from "react";
import EventsWedding from "./EventsWedding";
import EventsFuneral from "./EventsFuneral";
import EventsChild from "./EventsChild";
import EventsCollecting from "./EventsCollecting";
import EventsOther from "./EventsOther";

const EventsBlockList = ({
  keys,
  setOpen,
  setEventList,
  setModeratorReview,
}) => {
  switch (keys.action) {
    case "wedding":
      return (
        <EventsWedding
          keys={keys}
          setOpen={setOpen}
          setEventList={setEventList}
          setModeratorReview={setModeratorReview}
        />
      );
    case "funeral":
      return (
        <EventsFuneral
          keys={keys}
          setOpen={setOpen}
          setEventList={setEventList}
          setModeratorReview={setModeratorReview}
        />
      );
    case "birth":
      return (
        <EventsChild
          keys={keys}
          setOpen={setOpen}
          setEventList={setEventList}
          setModeratorReview={setModeratorReview}
        />
      );
    case "fundraising":
      return (
        <EventsCollecting
          keys={keys}
          setOpen={setOpen}
          setEventList={setEventList}
          setModeratorReview={setModeratorReview}
        />
      );

    case "other":
      return (
        <EventsOther
          keys={keys}
          setOpen={setOpen}
          setEventList={setEventList}
          setModeratorReview={setModeratorReview}
        />
      );
    default:
      break;
  }
};

export default EventsBlockList;
