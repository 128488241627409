import React, { useEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import style from "./recalendar.module.css";
import calendar from "../content/img/calendar.svg";
import Calendar from "./calendar/Calendar";

const ReCalendar = ({
  disabled, //disabled инпута
  defaultDate, //изначальная дата
  state, //главный state
  stateProps, //пропс
  extraProps, //дополнительный пропс
  setState, //состояние стейта
  calendarStyle, //стили для блока календаря
  width, //ширина поля ввода
  placeholderText, //placeholder
}) => {
  //Приводим к общему виду дефолтную дату
  const [defaultDateState, setDefaultDateState] = useState();

  useEffect(() => {
    if (defaultDate && defaultDate.length === 10 && defaultDate.includes("-")) {
      setDefaultDateState({
        day: Number(defaultDate.split("-")[2]),
        mounth: Number(defaultDate.split("-")[1] - 1),
        year: Number(defaultDate.split("-")[0]),
      });
    }
    if (defaultDate && defaultDate.length === 10 && defaultDate.includes(".")) {
      setDefaultDateState({
        day: Number(defaultDate.split(".")[0]),
        mounth: Number(defaultDate.split(".")[1] - 1),
        year: Number(defaultDate.split(".")[2]),
      });
    }
  }, [defaultDate]);

  //Открытие календаря
  const [calendarBlock, setCalendarBlock] = useState(false);

  const handleOpenCalendar = () => {
    if (disabled) {
      return;
    }
    if (calendarBlock) {
      closeCalendar();
    } else {
      setCalendarBlock(true);
    }
  };

  //Закрытие календаря при клике на пустую область
  const calendarRef = useRef(null);

  const closeCalendar = () => {
    setCalendarBlock(false);
  };

  useEffect(() => {
    if (calendarRef.current) {
      const onClick = (e) =>
        calendarRef.current.contains(e.target) || closeCalendar();
      document.addEventListener("click", onClick);
      return () => document.removeEventListener("click", onClick);
    }
  }, [calendarRef.current]);

  //Конвертаци при вводе в правильный формат
  useEffect(() => {
    if (state[stateProps].length === 10 && state[stateProps].includes(".")) {
      setState({
        ...state,
        [stateProps]: state[stateProps].split(".").reverse().join("-"),
        [extraProps]: state[stateProps].split(".").reverse().join("-"),
      });
    }
  }, [state[stateProps]]);

  return (
    <div
      className={style.calendarWrapp}
      ref={calendarRef}
      style={{
        backgroundColor: disabled && "var(--back-color-gainsborough)",
        opacity: disabled && 0.5,
        width: width && width,
      }}
    >
      <ReactInputMask
        disabled={disabled}
        className={style.calendarInput}
        placeholder={placeholderText ? placeholderText : "Введите дату"}
        type="text"
        mask="99.99.9999"
        maskChar=""
        value={
          state[stateProps] && state[stateProps].split("-")
            ? state[stateProps].split("-")[2] +
              "." +
              state[stateProps].split("-")[1] +
              "." +
              state[stateProps].split("-")[0]
            : state[stateProps]
        }
        onClick={closeCalendar}
        onChange={(e) => {
          setState({
            ...state,
            [stateProps]: e.target.value,
            [extraProps]: e.target.value,
          });
        }}
      ></ReactInputMask>
      <img
        src={calendar}
        alt="Календарь"
        className={style.calendarIcon}
        onClick={handleOpenCalendar}
      />
      {calendarBlock && (
        <Calendar
          calendarStyle={calendarStyle}
          defaultDate={defaultDateState}
          state={state}
          setState={setState}
          stateProps={stateProps}
          extraProps={extraProps}
          setCalendarBlock={setCalendarBlock}
        />
      )}
    </div>
  );
};

export default ReCalendar;
