import React from "react";
import style from "./EventGroup.module.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { RegistrationContext } from "../../../context";

const Fundraising = ({ data }) => {
  const { user } = useContext(RegistrationContext);

  const pathnameLink = (obj) => {
    if (!obj) {
      return "";
    }
    if (obj?.user_id > 0) {
      if (user.user_id !== obj?.user_id) {
        return `/account/${obj?.user_id}`;
      } else {
        return `/profile/${obj?.user_id}`;
      }
    }
    if (obj?.id > 0) {
      return `/tree/node/${obj?.id}`;
    }
    if (!obj.user_id && !obj.id) {
      return "";
    }
    if (
      (obj.user_id < 0 || obj.user_id === 0) &&
      (obj.id < 0 || obj.id === 0)
    ) {
      return "";
    }
  };

  return (
    <>
      <div className={style.eventTextWrapp}>
        <span className={style.eventName}>Цель:</span>
        <p className={style.eventTextZag}>{data.info}</p>
      </div>
      <div className={style.eventTextWrapp}>
        <span className={style.eventName}>Организатор:</span>
        <Link className={style.eventTextZag} to={pathnameLink(data.organizer)}>
          {data?.organizer?.fio}
        </Link>
      </div>
      <div className={style.eventTextWrapp}>
        <span className={style.eventName}>Сумма:</span>
        <span className={style.eventTextZag}>{data.price}</span>
      </div>
    </>
  );
};

export default Fundraising;
