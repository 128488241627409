import React from "react";
import { useState } from "react";
import style from "../../messagPage.module.css";
import barSeacrh from "../../img/barSearch.svg";
import barImage from "../../img/barImage.svg";
import barTrash from "../../img/barTrash.svg";
import BellSlash from "../../img/BellSlash.svg";
import Warning from "../../img/Warning.svg";
import Back from "../../img/Back.svg";
import Stop from "../../img/Stop.svg";
import axios from "axios";

const HeaderMenu = ({
  contact_id,
  setModalType,
  setModalChat,
  setModalMediaChat,
  setComplainChat,
  setSearchMessages,
  setMessageIdMass,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [mutList, setMutList] = useState(false);
  const [mutListName, setMutListName] = useState([
    { nameRus: "1 час", nameEng: "hours", active: false },
    { nameRus: "день", nameEng: "day", active: false },
    { nameRus: "неделя", nameEng: "week", active: false },
    { nameRus: "год", nameEng: "year", active: false },
    { nameRus: "отмена", nameEng: "none", active: false },
  ]);
  const mutBtnStyleActive = {
    backgroundColor: "#2199cc",
    color: "#ffffff",
  };
  const mutBtnStyle = {
    backgroundColor: "white",
    color: "#567f88",
  };

  const handleOpenMutBlock = () => {
    if (mutList) {
      setMutList(false);
    } else {
      setMutList(true);
    }
  };

  //Не беспокоить
  const handleMutBtn = (e, period) => {
    e.preventDefault();
    axios
      .post(
        "https://gargalo.ru/api/messages/mute",
        { contact_id, period },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      )
      .then(() => {
        setMutListName(
          mutListName.map((item) => {
            if (item.nameEng === period) {
              item.active = true;
            } else {
              item.active = false;
            }
            return item;
          })
        );
      });
  };

  return (
    <ul>
      <li onClick={() => {setSearchMessages(true); setMessageIdMass([])}}>
        <img
          src={barSeacrh}
          alt="Поиск"
          style={{ width: 15, height: 15, marginRight: 19 }}
        />
        Поиск
      </li>
      <li onClick={() => setModalMediaChat(true)}>
        <img
          src={barImage}
          alt="Медиа"
          style={{
            width: 14,
            height: 14,
            marginRight: 19,
          }}
        />
        Медиа
      </li>
      <li className={style.disturbLi}>
        <div className={style.disturbLiBlock} onClick={handleOpenMutBlock}>
          <img
            src={BellSlash}
            alt="Не беспокоить"
            style={{
              width: 13,
              height: 16,
              marginRight: 19,
              marginLeft: -5,
            }}
          />
          Не беспокоить
          <img
            src={Back}
            alt="Arrow"
            style={{
              width: 14,
              height: 7,
              marginLeft: 200,
            }}
          />
        </div>
        {mutList && (
          <div className={style.disturbLiBtnBlock}>
            {mutListName.map((item) => {
              return (
                <button
                  className={style.disturbLiBtn}
                  key={item.nameEng}
                  onClick={(e) => {
                    handleMutBtn(e, item.nameEng);
                  }}
                  style={item.active ? mutBtnStyleActive : mutBtnStyle}
                >
                  {item.nameRus}
                </button>
              );
            })}
          </div>
        )}
      </li>
      <li
        onClick={() => {
          setModalType("block");
          setModalChat(true);
        }}
      >
        <img
          src={Stop}
          alt="Заблокировать"
          style={{
            width: 16,
            height: 16,
            marginRight: 19,
          }}
        />
        Заблокировать
      </li>
      <li onClick={() => setComplainChat(true)}>
        <img
          src={Warning}
          alt="Пожаловаться"
          style={{
            width: 16,
            height: 16,
            marginRight: 19,
          }}
        />
        Пожаловаться
      </li>
      <li
        onClick={() => {
          setModalType("deleteChat");
          setModalChat(true);
        }}
      >
        <img
          src={barTrash}
          alt="Удалить чат"
          style={{
            width: 14,
            height: 15,
            marginRight: 19,
            marginLeft: 2,
          }}
        />
        Удалить чат
      </li>
    </ul>
  );
};

export default HeaderMenu;
