import React, { useState, useRef, createRef } from "react";
import EventsBlockList from "./EventsBlockList";
import style from "./StyleAddEvent.module.css";
import { UseClosing } from "../../../../hooks/useClosing";

import EventsShow from "./EventsShow";

const AddEventBlock = ({ setOpen, setEventList, setModeratorReview }) => {
  const [events, setEvents] = useState({ title: "Свадьба", name: "wedding" });
  const [eventState, setEventState] = useState(false);
  const [show, setShow] = useState("followers");
  const [titleText, setTitleText] = useState("");
  const eventsArray = [
    { title: "Свадьба", name: "wedding" },
    { title: "Похороны", name: "funeral" },
    { title: "Рождение ребенка", name: "birth" },
    { title: "Сбор средств", name: "fundraising" },
    { title: "Другое", name: "other" },
  ];

  // изменение мероприятия
  const handleEventChoose = (value) => {
    setEvents(value);
    setEventState(false);
  };
  const getAddArrowClasses = () => {
    return (
      style.afterAndBefore +
      " " +
      (eventState ? style.afterAndBefore_active : " ")
    );
  };

  const listRef = useRef();
  UseClosing(listRef, setEventState);

  return (
    <div className={style.events}>
      <h2 className={style.event_info_text_suptitle}>Добавление события</h2>
      <h2 className={style.events__suptitle}>Показывать</h2>
      <EventsShow show={show} setShow={setShow} />
      <h2 className={style.events__suptitle}>Название события</h2>
      <input
        type="text"
        className={style.events__button}
        placeholder="Введите название события"
        style={{ marginBottom: 20 }}
        value={titleText}
        onChange={(e) => setTitleText(e.target.value)}
      />
      <h2 className={style.events__suptitle}>Тип события</h2>
      <div ref={listRef} style={{ position: "relative" }}>
        <button
          className={style.events__button}
          onClick={() => setEventState((prev) => !prev)}
        >
          <div className={getAddArrowClasses()}>{events.title}</div>
        </button>
        {eventState && (
          <div className={style.events__div}>
            {eventsArray.map((value) => (
              <div
                key={value.title}
                className={style.events__div__button}
                onClick={() => handleEventChoose(value)}
              >
                {value.title}
              </div>
            ))}
          </div>
        )}
      </div>
      <form autoComplete="off">
        <EventsBlockList
          keys={{ permission: show, action: events.name, title: titleText }}
          setOpen={setOpen}
          setEventList={setEventList}
          setModeratorReview={setModeratorReview}
        />
      </form>
    </div>
  );
};

export default AddEventBlock;
