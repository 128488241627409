import React, { useContext, useEffect, useState } from "react";
import style from "../messages/messagPage.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import HeaderChat from "./messageComponents/chats/HeaderChat";
import ModalChat from "./messageComponents/chats/ModalChat";
import MediaChat from "./messageComponents/chats/MediaChat";
import ForwardChat from "./messageComponents/chats/ForwardChat";
import ComplainChat from "./messageComponents/chats/ComplainChat";
import FormChat from "./messageComponents/chats/FormChat";
import ItemChat from "./messageComponents/chats/ItemChat";
import DeleteMessages from "./messageComponents/chats/DeleteMessages";
import { RegistrationContext } from "../../context";
import IsAcceptDialog from "./messageComponents/chats/IsAcceptDialog";
import AddSelfie from "./messageComponents/chats/AddSelfie";

const MessagPage = ({ chatList, setMessagesList }) => {
  const [messages, setMessages] = useState([]);
  const id = useParams();
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [contactSettings, setContactSettings] = useState();
  const [mySettings, setMySettings] = useState();
  const [modalChat, setModalChat] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalMediaChat, setModalMediaChat] = useState(false);
  const [сomplainChat, setComplainChat] = useState(false);
  const [сomplainClose, setComplainClose] = useState(false);
  const [forwardChat, setForwardChat] = useState(false);
  const [forwardType, setForwardType] = useState();
  const [deleteMessages, setDeleteMessages] = useState(false);
  const [answerBlock, setAnswerBlock] = useState(false);
  const [messageIdMass, setMessageIdMass] = useState([]);
  const [scroll, setScroll] = useState(false);
  const [searchMessages, setSearchMessages] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState();
  const [currentMesag, setCurrentMesag] = useState();
  const [parent_id, setParent_id] = useState(-1);
  const [page, setPage] = useState(1);
  const [addSelfie, setAddSelfie] = useState(false);

  useEffect(() => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/chat/${id.id}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setContactSettings(res.data.contact_settings);
      setMySettings(res.data.my_settings);
      setMessages(res.data.messages.data);
      setNextPageUrl(res.data.messages.next_page_url);
      handleClearFun();
    });
  }, [id.id]);

  //Обнуление всего при прогрузке другого чата
  const handleClearFun = () => {
    setPage(1);
    setMessageIdMass([]);
    setParent_id(-1);
    setAnswerBlock(false);
    setModalChat(false);
    setDeleteMessages(false);
    setModalMediaChat(false);
    setForwardChat(false);
    setComplainChat(false);
    setComplainClose(false);
    setSearchMessages(false);
    setScroll(false);
    setAddSelfie(false);
    setCurrentMesag();
  };

  //Отправка прочитанных сообщений
  const { setMessagesCount } = useContext(RegistrationContext);

  useEffect(() => {
    messages.forEach((element) => {
      if (element.is_new === 1 && element.from_id === Number(id.id)) {
        axios
          .post(
            "https://gargalo.ru/api/messages/setreaded",
            { message_id: element.id, contact_id: id.id },
            {
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token[1]}`,
                "User-Token": 1,
              },
            }
          )
          .then(() => {
            setMessagesCount((prevState) => prevState - 1);
          });
      }
    });
  }, [messages]);

  //Прогрузка нового сообщения
  window.addNewMessage = (newMessage) => {
    if (newMessage.data.from_id === Number(id.id)) {
      setMessages((prevState) => [newMessage.data, ...prevState]);
    }
  };

  return (
    <div className={style.messagPageBox}>
      <HeaderChat
        mySettings={mySettings}
        setModalType={setModalType}
        setModalChat={setModalChat}
        setModalMediaChat={setModalMediaChat}
        setComplainChat={setComplainChat}
        setSearchMessages={setSearchMessages}
        setMessageIdMass={setMessageIdMass}
      />
      {modalChat && (
        <ModalChat
          setModalChat={setModalChat}
          contact_id={mySettings?.contact_id}
          modalType={modalType}
          setMySettings={setMySettings}
          setMessages={setMessages}
        />
      )}
      {deleteMessages && (
        <DeleteMessages
          setDeleteMessages={setDeleteMessages}
          setMessageIdMass={setMessageIdMass}
          messageIdMass={messageIdMass}
          messages={messages}
          setMessages={setMessages}
        />
      )}
      {modalMediaChat && (
        <MediaChat
          contact_id={mySettings?.contact_id}
          setModalMediaChat={setModalMediaChat}
          setForwardChat={setForwardChat}
          setForwardType={setForwardType}
          setMessageIdMass={setMessageIdMass}
        />
      )}
      {forwardChat && (
        <ForwardChat
          chatList={chatList}
          messageIdMass={messageIdMass}
          setMessageIdMass={setMessageIdMass}
          setForwardChat={setForwardChat}
          forwardType={forwardType}
        />
      )}
      {сomplainChat && (
        <ComplainChat
          setComplainChat={setComplainChat}
          setComplainClose={setComplainClose}
          setMySettings={setMySettings}
          contact_id={mySettings?.contact_id}
        />
      )}
      {contactSettings?.is_accept === 0 &&
        mySettings?.whoasked === mySettings?.contact_id && (
          <IsAcceptDialog
            contact_id={mySettings?.contact_id}
            setContactSettings={setContactSettings}
            setMySettings={setMySettings}
            setMessagesList={setMessagesList}
          />
        )}
      {addSelfie && (
        <AddSelfie
          setAddSelfie={setAddSelfie}
          contact_id={mySettings?.contact_id}
          parent_id={parent_id}
          setParent_id={setParent_id}
          setScroll={setScroll}
          setMessageIdMass={setMessageIdMass}
          setMessages={setMessages}
          setContactSettings={setContactSettings}
          setAnswerBlock={setAnswerBlock}
        />
      )}
      <ItemChat
        nextPageUrl={nextPageUrl}
        setNextPageUrl={setNextPageUrl}
        messages={messages}
        setMessages={setMessages}
        page={page}
        setPage={setPage}
        contact_id={mySettings?.contact_id}
        messageIdMass={messageIdMass}
        setMessageIdMass={setMessageIdMass}
        setForwardChat={setForwardChat}
        setForwardType={setForwardType}
        setDeleteMessages={setDeleteMessages}
        setAnswerBlock={setAnswerBlock}
        setParent_id={setParent_id}
        setScroll={setScroll}
        scroll={scroll}
        searchMessages={searchMessages}
        setSearchMessages={setSearchMessages}
        currentMesag={currentMesag}
        setCurrentMesag={setCurrentMesag}
      />
      <FormChat
        setAddSelfie={setAddSelfie}
        contact_id={mySettings?.contact_id}
        contact_blacklist={contactSettings?.blacklist}
        contact_is_accept={contactSettings?.is_accept}
        my_blacklist={mySettings?.blacklist}
        my_whoasked={mySettings?.whoasked}
        setMySettings={setMySettings}
        setContactSettings={setContactSettings}
        parent_id={parent_id}
        setParent_id={setParent_id}
        answerBlock={answerBlock}
        setAnswerBlock={setAnswerBlock}
        messages={messages}
        setMessages={setMessages}
        messageIdMass={messageIdMass}
        setMessageIdMass={setMessageIdMass}
        setScroll={setScroll}
        setModalChat={setModalChat}
        setModalType={setModalType}
      />
      {сomplainClose && (
        <div className={style.blockedDisplay}>
          <div className={style.sendDaangerBox}>
            <h2 className={style.sendDaangerZag}>
              Спасибо Вам за внимательность!
            </h2>
            <h2 className={style.sendDaangerText}>
              Мы уже рассматриваем вашу жалобу!
            </h2>
            <button
              className={style.sendDaangerBtn}
              onClick={() => setComplainClose(false)}
            >
              Закрыть
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(MessagPage);
