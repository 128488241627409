import axios from "axios";
import React from "react";
import ReButton from "../../../../reusedComponents/ReButton";
import style from "./treeComponents.module.css";

const TreeModal = ({
  setRender,
  firstAdd,
  setFirstAdd,
  setNotif,
  modalType,
  setModal,
  modal,
  center,
  setTreeMass,
  treeMass,
  setFormBlock,
  setAddingType,
  setAddPersonInfo,
  addPersonInfo,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);

  //Удаление
  const handleDelete = (e) => {
    e.preventDefault();
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/tree/delete/${center.id}`,
      headers: {
        "content-type": "application/json",
        "User-Token": 1,
        Authorization: `Bearer ${token[1]}`,
      },
    }).then(() => {
      setNotif({
        target:
          addPersonInfo?.f.trim() +
          " " +
          addPersonInfo?.i.trim() +
          " " +
          addPersonInfo?.o.trim(),
        event: "delete",
      });
      // setTreeMass(treeMass.filter((item) => item.id !== center.id));
      setRender(true);
      zeroingFun();
    });
  };

  //Обнуление
  const zeroingFun = () => {
    document.getElementById("addForm").reset();
    setFormBlock(false);
    setModal(false);
    setAddingType();
    setFirstAdd({
      ...firstAdd,
      f: "",
      i: "",
      o: "",
      connect_user_id: "",
      fio_id: "",
      fio: "",
      source_id: "",
      source: "",
      spouce_id: "",
      s_fio: "",
      f_id: "",
      f_fio: "",
      m_id: "",
      m_fio: "",
      die: "",
      born: "",
      full_born: "",
      full_die: "",
      pol: "",
      teip: "",
      phone: "",
      mail: "",
      info: "",
      imgUrl: null,
      img: null,
    });
    setAddPersonInfo({
      ...addPersonInfo,
      f: "",
      i: "",
      o: "",
      connect_user_id: "",
      fio_id: "",
      fio: "",
      source_id: "",
      source: "",
      spouce_id: "",
      s_fio: "",
      f_id: "",
      f_fio: "",
      m_id: "",
      m_fio: "",
      die: "",
      born: "",
      full_born: "",
      full_die: "",
      pol: "",
      teip: "",
      phone: "",
      mail: "",
      info: "",
      imgUrl: null,
      img: null,
    });
  };

  return (
    <div
      className={style.appendWrapp}
      style={{
        visibility: modal ? "visible" : "hidden",
        opacity: modal ? "1" : "0",
        zIndex: 15,
      }}
    >
      <div className={style.modalWrapp}>
        <h2 className={style.modalZag}>
          {modalType === "delete"
            ? "Удаление карточки"
            : modalType === "edit"
            ? "Сброс редактирования"
            : "Сброс добавления"}
        </h2>
        <p className={style.modalText}>
          {modalType === "delete"
            ? "Вы уверены, что хотите удалить даную карточку? Все данные будут потеряны."
            : modalType === "edit"
            ? "Вы уверены, что хотите сбросить редактирование? Все изменения будут потеряны."
            : "Вы уверены, что хотите сбросить добавление? Все введенные данные будут потеряны."}
        </p>
        <div style={{ display: "flex" }}>
          <ReButton
            width={152}
            height={40}
            text={"Отмена"}
            classes={"third"}
            mr={12}
            handleClick={() => {
              setModal(false);
            }}
          />
          <ReButton
            height={40}
            width={152}
            text={modalType === "delete" ? "Удалить" : "Сбросить"}
            handleClick={modalType === "delete" ? handleDelete : zeroingFun}
          />
        </div>
      </div>
    </div>
  );
};

export default TreeModal;
