import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styleActions from "./actions.module.css";
import tree from "../../../teyps/img/tree.svg";
import ChatCircleText from "../../img/ChatCircleText.svg";
import Connectionnotfound from "../../../img/Connectionnotfound.svg";
import PostService from "./../../../../../API/postService";
import X from "../../../connection/img/x.svg";
import Loader from "./../../../Loader";
import axios from "axios";
import $ from "jquery";
import { renderer } from "../../../../utils/renderer";
import { RegistrationContext } from "../../../../context";
import ReButton from "../../../../reusedComponents/ReButton";
const ActionsTrio = ({ userInfo, changingLikesCounter }) => {
  const user = useContext(RegistrationContext);
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const navigate = useNavigate();
  const [subscribe, setSubscribe] = useState();
  const [sendSubscribe, setSendSubscribe] = useState();

  useEffect(() => {
    setSendSubscribe(userInfo?.profile.is_send_follow_request);
    setSubscribe(userInfo?.profile.is_follow);
  }, [userInfo]);

  //Отправка запроса на разрешение отправки сообщений
  const handleNavigateButton = () => {
    if (!userInfo.profile.is_contact) {
      PostService.getSendMessages(userInfo.profile.user_id).then(() =>
        navigate(`/messages/chat/${userInfo.profile.user_id}`)
      );
    } else {
      navigate(`/messages/chat/${userInfo.profile.user_id}`);
    }
  };

  const handleSendFollow = (id) => {
    setSendSubscribe((e) => !e);
    if (subscribe) {
      setSendSubscribe(false);
    }
    PostService.getAllUsersSubscribeAndUnsubscribe(id);
    PostService.EventSubscribe(userInfo.profile.node_id);
    if (userInfo?.profile.is_follow) {
      changingLikesCounter("desc");
      userInfo.profile.is_follow = false;
    } else {
      changingLikesCounter("asc");
      userInfo.profile.is_follow = true;
    }
  };
  // console.log(userInfo);

  //Работа с построением родственных связей
  const [openKingship, setOpenKingship] = useState();
  const [dataReals, setDataReals] = useState();
  const [kinship, setKinship] = useState();
  const [whoId, setWhoId] = useState();
  const [kinshiploading, setKinshiploading] = useState(true);
  const [error, setError] = useState();

  //Запуск функции по отрисовке
  useEffect(() => {
    if (kinship) {
      if (dataReals) {
        window.showSpouceTree();
      } else {
        window.showTree();
      }
    }
  }, [kinship, dataReals]);

  //Кнопка поиска род. свзяи
  const handleOpenKinshipBlock = (peopleId) => {
    console.log(user.node_id);
    setError(false);
    if (openKingship) {
      setOpenKingship(false);
    } else {
      setOpenKingship(true);
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/searchrelated/${user.user.node_id}/${peopleId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      })
        .then((res) => {
          setDataReals(res.data.rels[0]);
          if (res.data.result.items === false) {
            axios
              .post(
                `https://gargalo.ru/api/loadrelatedtree?map=${JSON.stringify(
                  res.data.rels[0]
                )}&id1=${user.node_id}&id2=${peopleId}`,
                {
                  map: JSON.stringify(res.data.rels[0]),
                  id1: user.node_id,
                  id2: peopleId,
                },
                {
                  headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token[1]}`,
                    "User-Token": 1,
                  },
                }
              )
              .then((res) => {
                setKinshiploading(false);
                setKinship(res.data);
                setWhoId(peopleId);
              })
              .catch((error) => {
                setKinshiploading(false);
                setError(true);
              });
          } else {
            setKinshiploading(false);
            setKinship(res.data.result);
            setWhoId(peopleId);
          }
        })
        .catch((error) => {
          setKinshiploading(false);
          setError(true);
        });
    }
  };

  //Функция отрисовки
  const kinshipFun = () => {
    //Отрисовка линий
    const createLines = () => {
      $("div#related-result").show(0);
      var nodes = $(".node").not(".ignore");
      nodes.find("div").remove();
      //var nodes = $('.node').not('.ignore');
      var from_parent = $('<div class="from-parent"></div>');
      var to_child = $('<div class="to-child"></div>');
      var line = $('<div class="gor-line"></div>');
      var tree_par = $(".tree-root");

      nodes.each(function () {
        var el = $(this);

        var id = $(this).data("id");

        var childs = $(".parent" + id, tree_par);
        if (
          childs.length < 1 &&
          el.data("pol") > 1 &&
          el.parent().children(".gender1").length < 1
        ) {
          childs = $(".mather" + id, tree_par);
        }

        // if (childs.length == 1){
        // $(this).append(from_parent.clone());
        // childs.append(to_child.clone());
        // console.log($(this))
        // if ($(this).next().length > 0){
        // if ($(this).next().hasClass('spouce-item')){
        // $(this).find('.from-parent').addClass('left100')
        // }
        // }
        // }else if (childs.length > 1){
        if (childs.length > 0) {
          $(this).append(from_parent.clone().addClass("with_short_bottom"));
        }

        if (!el.hasClass("spouce-item")) {
          if (el.next().length > 0) {
            if (el.next().hasClass("spouce-item")) {
              el.find(".from-parent").addClass("left100");
            }
          }
        } else {
          if (el.prev().find(".from-parent").length > 0) {
            el.find(".from-parent").addClass("left-100");
          }
        }
        //Мои изменения
        if (!$(".from-parent", el)[0]) {
          return;
        }
        //конец моих изменений
        let pl = $(".from-parent", el).offset().left;

        childs.each(function () {
          var tc = to_child.clone();
          $(this).append(tc);
          var cl = $(".to-child", $(this)).offset().left;

          if (
            pl == cl ||
            (pl <= cl + 1 && pl >= cl - 1) ||
            pl + el.outerWidth() / 2 == cl
          ) {
            tc.addClass("with_high_top");
          } else if (pl > cl) {
            var append_line = line.clone().addClass("with_right_top_round");
            $(this).append(append_line);
            append_line.width(0);
            var w = pl - append_line.offset().left;
            append_line.width(w);
            // if (el.next().hasClass('spouce-item')){
            // w-=10
            // }else{
            // w-=9
            // }
            // w-=2
            tc.addClass("with_right_round_top");
          } else if (pl < cl) {
            var append_line = line.clone().addClass("with_left_top_round");
            $(this).append(append_line);
            append_line.width(0);
            var w = pl - append_line.offset().left;
            append_line.width(Math.abs(w));

            // w = cl-pl;
            // if (el.next().hasClass('spouce-item')){
            // w-=11;
            // }else{
            // w-=12
            // }
            tc.addClass("with_left_round_top");
          }

          if ($(this).find(".gor-line").length > 0) {
            var gl = $(this).find(".gor-line");
            var fp = el.find(".from-parent");

            if (gl.width() < 1) {
              if (gl.offset().left != fp.offset().left) {
                gl.css(
                  "right",
                  41 - Math.abs(gl.offset().left - fp.offset().left)
                );
              }
            }
          }
        });
        //}
        //}
      });
    };

    // Отрисовка дерева с картой
    window.showSpouceTree = () => {
      document.querySelector("html").style.overflow = "hidden";
      var tree = $(kinship);
      $("#kinshipContainer").append(tree);

      var teip = "";
      tree.find("ul.tree-root").each(function () {
        teip = $(this).find("a.node").filter(":first").data("teip");
        $(this).addClass("root-" + teip);
      });
      var map = JSON.parse(JSON.stringify(dataReals));

      let userBlock = document
        .getElementById("kinshipContainer")
        .querySelector(`[data-id="${user.user.node_id}"]`);
      let whoBlock = document
        .getElementById("kinshipContainer")
        .querySelector(`[data-id="${whoId}"]`);
      userBlock.classList.add("kinshipActiv");
      whoBlock.classList.add("kinshipActiv");

      var teips = new Array();

      for (let key in map) {
        if (teips.indexOf(map[key]["teips"][0]) < 0) {
          teips.push(map[key]["teips"][0]);
        }

        if (teips.indexOf(map[key]["teips"][1]) < 0) {
          teips.push(map[key]["teips"][1]);
        }

        if (teips.length == 3) {
          if (
            teips[0] == map[key]["teips"][1] ||
            teips[0] == map[key]["teips"][0]
          ) {
            var t = teips[0];
            teips[0] = teips[1];
            teips[1] = t;
          }
        }
      }

      for (let key in teips) {
        tree.append(tree.find(".root-" + teips[key]));
      }

      for (let key in map) {
        var node1 = $(".li-tree-" + map[key]["ids"][0]);
        var node2 = $(".li-tree-" + map[key]["ids"][1]);

        var node1_left = node1.position().left;
        var node2_left = node2.position().left;

        var from;
        var to;

        if (node1_left < node2_left) {
          node1.children("a").addClass("to-spouce");
          node2.children("a").addClass("from-spouce");
          from = node1;
          to = node2;
          /*if (Math.abs(node2_left-node1_left) > 150){
            if (node2.siblings().length > 0){
              
            }else{
              li_parent = node2.parent().parent()
              prev = li_parent.prev()
              li_parent.after(prev)
            }
            
          }*/
        } else {
          node1.children("a").addClass("from-spouce");
          node2.children("a").addClass("to-spouce");
          from = node2;
          to = node1;
          /*if (Math.abs(node1_left-node2_left) > 150){
            li_parent = node1.parent().parent()
            prev = li_parent.prev()
            li_parent.after(prev)
          }*/
        }

        var root = from.closest(".tree-root");
        var child = root.children("li").children("ul");

        if (
          child.children("li").eq(0).find(from).length ||
          child.children("li").eq(0).is(from)
        ) {
          child.append(child.children("li").eq(0));
        }

        root = to.closest(".tree-root");
        child = root.children("li").children("ul");
        if (
          child.children("li").eq(1).find(to).length ||
          child.children("li").eq(1).is(to)
        ) {
          child.prepend(child.children("li").eq(1));
        }
      }
      $(".tree-root").css("top", 0);
      var ts = tree.find(".to-spouce");
      for (var i = 0; i < ts.length + 1; i++) {
        ts.each(function () {
          var spouce_id = $(this).data("spouce");

          var current = $(this);

          var spouce_item = $(".id" + spouce_id, tree);

          var node1_top = current.position().top;
          var node2_top = spouce_item.position().top;
          var diff = 0;
          var from;

          if (node1_top > node2_top) {
            diff = Math.abs(node2_top - node1_top);
            from = current;
          } else if (node1_top < node2_top) {
            from = spouce_item;
            diff = Math.abs(node1_top - node2_top);
          }
          if (diff > 0) {
            from.closest(".tree-root").css({
              position: "relative",
              top: -1 * diff + "px",
            });
            //from.append($('<span class="vertical-spouce" style="height: '+(diff*2)+'px;"></span>'))
          }
        });

        if (ts.length == 1) break;
      }

      createLines();
      let container = document.getElementById("kinshipContainer");
      let is_down;
      let instance = renderer({
        minScale: 0.1,
        maxScale: 10,
        element: container,
        scaleSensitivity: 15,
        translateX: 0,
        translateY: 0,
        scale: 1,
        height: container.getBoundingClientRect().height,
      });

      container.addEventListener("wheel", (event) => {
        /*if (!event.ctrlKey) {
            return;
          }*/
        event.preventDefault();
        instance.zoom({
          deltaScale: Math.sign(event.deltaY) > 0 ? -1 : 1,
          x: event.pageX,
          y: event.pageY,
        });
      });
      container.addEventListener("dblclick", () => {
        instance.panTo({
          originX: 0,
          originY: 0,
          scale: 1,
        });
      });
      container.addEventListener("mousedown", () => {
        is_down = true;
      });
      container.addEventListener("mouseup", () => {
        is_down = false;
      });
      container.addEventListener("mousemove", (event) => {
        if (!is_down) {
          return;
        }
        event.preventDefault();
        instance.panBy({
          originX: event.movementX,
          originY: event.movementY,
        });
      });
    };

    //Отрисовка дерева без карты
    window.showTree = () => {
      document.querySelector("html").style.overflow = "hidden";
      var tree = $(kinship);
      $("#kinshipContainer").append(tree);

      let userBlock = document
        .getElementById("kinshipContainer")
        .querySelector(`[data-id="${user.user.node_id}"]`);
      let whoBlock = document
        .getElementById("kinshipContainer")
        .querySelector(`[data-id="${whoId}"]`);
      userBlock.classList.add("kinshipActiv");
      whoBlock.classList.add("kinshipActiv");

      createLines();

      let container = document.getElementById("kinshipContainer");
      let is_down;
      let instance = renderer({
        minScale: 0.1,
        maxScale: 10,
        element: container,
        scaleSensitivity: 15,
        translateX: 0,
        translateY: 0,
        scale: 1,
        height: container.getBoundingClientRect().height,
      });

      container.addEventListener("wheel", (event) => {
        /*if (!event.ctrlKey) {
            return;
          }*/
        event.preventDefault();
        instance.zoom({
          deltaScale: Math.sign(event.deltaY) > 0 ? -1 : 1,
          x: event.pageX,
          y: event.pageY,
        });
      });
      container.addEventListener("dblclick", () => {
        instance.panTo({
          originX: 0,
          originY: 0,
          scale: 1,
        });
      });
      container.addEventListener("mousedown", () => {
        is_down = true;
      });
      container.addEventListener("mouseup", () => {
        is_down = false;
      });
      container.addEventListener("mousemove", (event) => {
        if (!is_down) {
          return;
        }
        event.preventDefault();
        instance.panBy({
          originX: event.movementX,
          originY: event.movementY,
        });
      });
    };

    return (
      <div className={styleActions.findKinship__block}>
        <div className={styleActions.kinshipWrap} id="treeWrap">
          <div
            onClick={() => {
              setOpenKingship(false);
              setError(false);
              setKinshiploading(true);
              document.querySelector("html").style.overflow = "visible";
            }}
            style={{
              position: "absolute",
              right: 20,
              top: 20,
              width: 20,
              height: 20,
              cursor: "pointer",
              zIndex: 2,
            }}
          >
            <img src={X} />
          </div>
          {error && (
            <div className={styleActions.errorKinshipWrapp}>
              <div className={styleActions.errorKinshipBlock}>
                <img
                  src={Connectionnotfound}
                  alt="Connectionnotfound"
                  className={styleActions.Connectionnotfound}
                />
                <h2 className={styleActions.errorKinsipZag}>
                  Родственная связь не найдена
                </h2>
                <h2 className={styleActions.errorKinsipTextP}>
                  Родственная связь между вами и целевым человеком не найдена.
                </h2>
              </div>
            </div>
          )}
          {kinshiploading ? (
            <div style={{ marginTop: 350 }}>
              <Loader />
            </div>
          ) : (
            <div
              className={styleActions.kinshipContainer}
              id="kinshipContainer"
            ></div>
          )}
        </div>
      </div>
    );
  };

  console.log(userInfo?.profile);
  return (
    <>
      {openKingship && kinshipFun()}
      <div className={styleActions.actionsTrio}>
        {user?.node_id !== -1 && userInfo?.profile.node_id !== -1 && (
          <button
            className={styleActions.relationship}
            onClick={() => {
              handleOpenKinshipBlock(userInfo.item.node_id);
              setKinship();
              setKinshiploading(true);
            }}
          >
            <img src={tree} style={{ width: 24, height: 24 }} alt="Поиск" />
            <span>Родство</span>
          </button>
        )}
        <ReButton
          classes="third"
          padding={"10px 20px"}
          handleClick={handleNavigateButton}
          height={40}
          text={"Написать"}
        />
        {/* 
        {subscribe ? (
          <ReButton
            classes="fourth"
            padding={"10px 20px"}
            handleClick={() => handleSendFollow(userInfo?.profile.user_id)}
            height={40}
            type={"div"}
            borderWidth={1}
            text={subscribe.is_followed ? subscribe.message : subscribe.message}
          />
        ) : (
          <ReButton
            classes="third"
            padding={"10px 20px"}
            handleClick={() => handleSendFollow(userInfo?.profile.user_id)}
            height={40}
            text={userInfo?.profile.is_follow ? "Подписан" : "Подписаться"}
          />
        )} */}
        {subscribe ? (
          <ReButton
            classes="third"
            padding={"10px 20px"}
            handleClick={() => handleSendFollow(userInfo?.profile.user_id)}
            height={40}
            text={"Подписан"}
          />
        ) : sendSubscribe ? (
          <ReButton
            classes="fourth"
            padding={"10px 20px"}
            handleClick={() => handleSendFollow(userInfo?.profile.user_id)}
            height={40}
            borderWidth={1}
            type={"div"}
            text={"Запрос отправлен"}
          />
        ) : (
          <ReButton
            classes="third"
            padding={"10px 20px"}
            handleClick={() => handleSendFollow(userInfo?.profile.user_id)}
            height={40}
            text={"Подписаться"}
          />
        )}
      </div>
    </>
  );
};

export default ActionsTrio;
