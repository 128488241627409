import React from "react";
import style from "./information.module.css";

const CookiePolicy = () => {
  return (
    <div className={style.band}>
      <div className={style.infoWrapp}>
        <div>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta httpEquiv="Content-Style-Type" content="text/css" />
          <meta name="generator" content="Aspose.Words for .NET 22.7.0" />
          <title />
          <div>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "right",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                Приложение № 2{" "}
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "right",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                к политике конфиденциальности проекта «gargalo.ru»
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "right",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                Дата публикации и вступления в силу: «01» июля 2022 г.
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span
                style={{
                  fontFamily: '"Montserrat"',
                  fontWeight: "bold",
                  AwImport: "ignore",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span
                style={{ fontFamily: '"Montserrat"', fontWeight: "bold" }}
              >
                ПОЛИТИКА ОБРАБОТКИ ФАЙЛОВ COOKIE
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
                backgroundColor: "#ffffff",
              }}
            >
              <span
                style={{ fontFamily: '"Montserrat"', color: "#212529" }}
              >
                «Политика обработки файлов cookie» (далее – Политика) –
                документ, определяющий порядок использования файлов cookie –
                фрагменты данных Пользователя, отправленных сервером и хранимых
                Администрацией для аутентификациии, сохранения настроек
                Пользователя, сбора статистики о Пользователях на
                интернет-сайте, доступном по уникальному доменному имени{" "}
              </span>
              <span style={{ fontFamily: '"Montserrat"' }}>
                gargalo.ru
              </span>
              <span
                style={{ fontFamily: '"Montserrat"', color: "#212529" }}
              >
                {" "}
                (далее – Сайт).
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
                backgroundColor: "#ffffff",
              }}
            >
              <span
                style={{
                  fontFamily: '"Montserrat"',
                  color: "#212529",
                  AwImport: "ignore",
                }}
              >
                &nbsp;
              </span>
            </p>
            <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  marginLeft: "32pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  paddingLeft: "4pt",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  color: "#212529",
                  backgroundColor: "#ffffff",
                }}
              >
                <span>
                  Политика является неотъемлемой частью политики
                  конфиденциальности Сайта, которая, в свою очередь, – является
                  неотъемлемой частью пользовательского соглашения Сайта (далее
                  – Соглашения). Принимая Соглашение, политику
                  конфиденциальности Пользователь дает свое безоговорочное
                </span>
                <span>&nbsp;</span>
                <span>
                  согласие Администрации на использование его файлов cookie.
                </span>
              </li>
              <li
                style={{
                  marginLeft: "32pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  paddingLeft: "4pt",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  color: "#212529",
                  backgroundColor: "#ffffff",
                }}
              >
                <span style={{ color: "#000000" }}>
                  В зависимости от настроек веб-браузера Пользователя{" "}
                </span>
                <span>Администрация</span>
                <span style={{ color: "#000000" }}>
                  {" "}
                  собирает информацию, которая автоматически передается
                  браузером, когда Пользователь посещает Сайт. Эта передача
                  осуществляется посредством cookie-файлов. Данная информация,
                  как правило, включает IP-адрес, в настоящее время присвоенный
                  устройству Пользователя, тип операционной системы и
                  используемого веб-браузера.
                </span>
              </li>
              <li
                style={{
                  marginLeft: "32pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  paddingLeft: "4pt",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  color: "#212529",
                  backgroundColor: "#ffffff",
                }}
              >
                <span style={{ color: "#000000" }}>
                  Cookie – это небольшой текстовый файл, который веб-сервер
                  размещает в памяти устройства Пользователя. Различают
                  «сеансовые» и «постоянные» cookie-файлы.
                </span>
              </li>
              <li
                style={{
                  marginLeft: "32pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  paddingLeft: "4pt",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  color: "#212529",
                  backgroundColor: "#ffffff",
                }}
              >
                <span style={{ color: "#000000" }}>
                  Сеансовые cookie-файлы используются Администрацией для
                  присвоения уникального идентификационного номера устройству
                  Пользователя каждый раз, как он посещает Сайт. Сеансовые
                  cookie-файлы удаляются после закрытия браузера. Они
                  используются для поддержания функциональности Сайта и для
                  анализа работы Пользователя Сайтом, например, какие страницы
                  посещает Пользователь, какие ссылки использует и как долго
                  остается на каждой странице.
                </span>
              </li>
              <li
                style={{
                  marginLeft: "32pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  paddingLeft: "4pt",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  color: "#212529",
                  backgroundColor: "#ffffff",
                }}
              >
                <span style={{ color: "#000000" }}>Когда это допустимо, </span>
                <span>Администрация</span>
                <span style={{ color: "#000000" }}>
                  {" "}
                  использует постоянные cookie, которые не удаляются сразу после
                  закрытия веб-браузера, но сохраняются на компьютере в течение
                  определенного периода времени или пока Пользователь их не
                  удалит самостоятельно. Каждый раз, как Пользователь посещает
                  Сайт, веб-сервер распознает постоянные cookie, хранящиеся в
                  памяти устройства Пользователя. Присваивая устройству
                  Пользователя уникальный идентификатор, создается база данных
                  действий и предпочтений Пользователя. Этот уникальный
                  идентификатор также позволяет определять:{" "}
                </span>
              </li>
            </ol>
            <div style={{ marginLeft: "46.35pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <span style={{ fontFamily: '"Montserrat"' }}>
                  как часто Пользователь посещает Сайт,
                </span>
              </p>
            </div>
            <div style={{ marginLeft: "46.35pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <span style={{ fontFamily: '"Montserrat"' }}>
                  с какой периодичностью возвращается на Сайт,
                </span>
              </p>
            </div>
            <div style={{ marginLeft: "46.35pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <span style={{ fontFamily: '"Montserrat"' }}>
                  как он использует Сайт,
                </span>
              </p>
            </div>
            <div style={{ marginLeft: "46.35pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <span style={{ fontFamily: '"Montserrat"' }}>
                  насколько эффективны мероприятия по продвижению Сайта.
                </span>
              </p>
            </div>
            <ol
              start={6}
              type={1}
              style={{ margin: "0pt", paddingLeft: "0pt" }}
            >
              <li
                style={{
                  marginLeft: "32pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  paddingLeft: "4pt",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  backgroundColor: "#ffffff",
                }}
              >
                <span>
                  Cookie-файлы не содержат Персональные данные, а лишь фиксируют
                  действия на устройстве Пользователя. Если Пользователь
                  переходит на Сайт по ссылке, которая была отправлена на его
                  электронную почту, или если Пользователь создает
                  «идентификатор пользователя» во время одного из посещений,
                  информация, предоставленная cookie-файлами Сайта или
                  cookie-файлами сторонних поставщиков услуг, может быть связана
                  с информацией в записях, которая сделает возможной личную
                  идентификацию Пользователя.
                </span>
              </li>
              <li
                style={{
                  marginLeft: "32pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  paddingLeft: "4pt",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  backgroundColor: "#ffffff",
                }}
              >
                <span>
                  Сеансовые cookie-файлы не требуют предварительного согласия
                  Пользователя на их использование, так как они необходимы для
                  работы Сайта и будут удалены после закрытия веб-браузера.
                </span>
              </li>
              <li
                style={{
                  marginLeft: "32pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  paddingLeft: "4pt",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  backgroundColor: "#ffffff",
                }}
              >
                <span>
                  Постоянные cookie-файлы, которые отслеживают прошлые действия
                  и предпочтения Пользователя, но не являются необходимыми для
                  работы Сайта, требуют предварительного согласия Пользователя.
                  Посещая Сайт, Пользователь соглашается на использование
                  постоянных cookie-файлов, если не изменит настройки своего
                  устройства, чтобы эти cookie-файлы не сохранялись. То же самое
                  относится к cookie-файлам третьих сторон.
                </span>
              </li>
              <li
                style={{
                  marginLeft: "32pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  paddingLeft: "4pt",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  color: "#212529",
                  backgroundColor: "#ffffff",
                }}
              >
                <span>
                  Основной целью использования файлов cookie является устройство
                  Сайта более удобным для Пользователя. Чтобы Пользователь
                  использовал Сайт без задержек, Администрация при повторном (и
                  каждом последующем) использовании Пользователем Сайта
                  использует данные браузера Пользователя о результатах проверки
                  подлинности при входе в защищенные разделы Сайта, информацию о
                  посещенных страницах Сайта, предпочтениях и настройках
                  Пользователя и другую подобную информацию. Администрация не
                  осуществляет сбор, хранение, обработку и передачу третьим
                  лицам файлов cookie Пользователя, за исключением
                  статистических целей поисковых систем.
                </span>
              </li>
              <li
                style={{
                  marginLeft: "36pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  backgroundColor: "#ffffff",
                }}
              >
                <span>
                  Если Пользователь решит отказаться от файлов cookie, он не
                  сможет использовать некоторые возможности Сайта.
                </span>
              </li>
              <li
                style={{
                  marginLeft: "36pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  backgroundColor: "#ffffff",
                }}
              >
                <span>
                  Настройка веб-браузера – бесплатный и эффективный способ
                  управления cookie-файлами. Пользователь может принять одно из
                  следующих решений:
                </span>
              </li>
            </ol>
            <div style={{ marginLeft: "46.35pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <span style={{ fontFamily: '"Montserrat"' }}>
                  разрешить использование всех cookie-файлов, интегрированных в
                  страницы. Примечание: с одной стороны, доступ к этим
                  cookie-файлам получат лишь их издатели; с другой стороны, этот
                  процесс не является необратимым, и впоследствии Пользователь
                  всегда может удалить эти cookie-файлы (процедура управления
                  cookie-файлами различается в зависимости от используемого
                  браузера);
                </span>
              </p>
            </div>
            <div style={{ marginLeft: "46.35pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <span style={{ fontFamily: '"Montserrat"' }}>
                  заблокировать cookie-файлы на устройстве Пользователя. В таком
                  случае Пользователь предупрежден, что навигация по Сайту будет
                  затруднена. Некоторые функции требуют использования
                  cookie-файлов (например, для распознавания операционной
                  системы и предпочитаемого языка Пользователя). Следовательно,{" "}
                </span>
                <span
                  style={{ fontFamily: '"Montserrat"', color: "#212529" }}
                >
                  Администрация
                </span>
                <span style={{ fontFamily: '"Montserrat"' }}>
                  {" "}
                  не несет ответственности за неудобства, вызванные ненадлежащей
                  работой сервисов Сайта из-за отключения cookie-файлов;
                </span>
              </p>
            </div>
            <div style={{ marginLeft: "46.35pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <span style={{ fontFamily: '"Montserrat"' }}>
                  включить использование cookie-файлов по запросу в каждом
                  конкретном случае;
                </span>
              </p>
            </div>
            <div style={{ marginLeft: "46.35pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <span style={{ fontFamily: '"Montserrat"' }}>
                  принимать или отклонять cookie-файлы в зависимости от их
                  издателя.
                </span>
              </p>
            </div>
            <ol
              start={12}
              type={1}
              style={{ margin: "0pt", paddingLeft: "0pt" }}
            >
              <li
                style={{
                  marginLeft: "36pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: '"Montserrat"',
                  fontSize: "12pt",
                  backgroundColor: "#ffffff",
                }}
              >
                <span>
                  Процедура управления cookie-файлами различается в зависимости
                  от используемого браузера. Более подробные инструкции доступны
                  по ссылкам ниже:
                </span>
              </li>
            </ol>
            <div style={{ marginLeft: "36pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <a
                  href="http://windows.microsoft.com/en-US/windows7/Block-enable-or-allow-cookies"
                  target="_blank"
                  title="Internet Explorer"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontFamily: '"Montserrat"',
                      textDecoration: "underline",
                      color: "#000000",
                    }}
                  >
                    Internet Explorer
                  </span>
                </a>
                <span style={{ fontFamily: '"Montserrat"' }}>;</span>
              </p>
            </div>
            <div style={{ marginLeft: "36pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <a
                  href="http://support.google.com/chrome/bin/answer.py?hl=en&hlrm=en&answer=95647"
                  target="_blank"
                  title="Chrome"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontFamily: '"Montserrat"',
                      textDecoration: "underline",
                      color: "#000000",
                    }}
                  >
                    Chrome
                  </span>
                </a>
                <span style={{ fontFamily: '"Montserrat"' }}>; </span>
              </p>
            </div>
            <div style={{ marginLeft: "36pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <a
                  href="http://help.opera.com/Windows/10.20/en/cookies.html"
                  target="_blank"
                  title="Opera"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontFamily: '"Montserrat"',
                      textDecoration: "underline",
                      color: "#000000",
                    }}
                  >
                    Opera
                  </span>
                </a>
                <span style={{ fontFamily: '"Montserrat"' }}>;</span>
              </p>
            </div>
            <div style={{ marginLeft: "36pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <a
                  href="http://support.apple.com/kb/HT1677?viewlocale=en_US"
                  target="_blank"
                  title="Safari"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontFamily: '"Montserrat"',
                      textDecoration: "underline",
                      color: "#000000",
                    }}
                  >
                    Safari
                  </span>
                </a>
                <span style={{ fontFamily: '"Montserrat"' }}>;</span>
              </p>
            </div>
            <div style={{ marginLeft: "36pt", backgroundColor: "#ffffff" }}>
              <p
                style={{
                  marginTop: "0pt",
                  marginLeft: "18pt",
                  marginBottom: "0pt",
                  textIndent: "-18pt",
                  lineHeight: "150%",
                  fontSize: "12pt",
                  AwImport: "list-item",
                  AwListLevelNumber: 0,
                  AwListNumberFormat: '""',
                  AwListNumberStyles: '"bullet"',
                  AwListPaddingSml: "6pt",
                }}
              >
                <span style={{ AwImport: "ignore" }}>
                  <span style={{ fontFamily: "Symbol" }}></span>
                  <span
                    style={{
                      width: "6pt",
                      font: '7pt "Montserrat"',
                      display: "inline-block",
                      AwImport: "spaces",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <a
                  href="http://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  target="_blank"
                  title="Firefox"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontFamily: '"Montserrat"',
                      textDecoration: "underline",
                      color: "#000000",
                    }}
                  >
                    Firefox
                  </span>
                </a>
                <span style={{ fontFamily: '"Montserrat"' }}>.</span>
              </p>
            </div>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span
                style={{ fontFamily: '"Montserrat"', AwImport: "ignore" }}
              >
                &nbsp;
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
