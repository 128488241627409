import React from "react";
import style from "./footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={style.footerWrapp}>
      <Link to="/privacy" className={style.footerLink}>
        Политика конфиденциальности
      </Link>
      <Link
        to="/agreement"
        className={style.footerLink}
        style={{ marginBottom: 12 }}
      >
        Пользовательское соглашение
      </Link>
      <h2 className={style.footerVersion}>© GARGALO, 2023, v 0.1.54</h2>
    </div>
  );
};

export default Footer;
