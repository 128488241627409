import React, { useState, useEffect, useRef, useContext } from "react";
import Posts from "./postComponents/Posts";
import style from "./band.module.css";
import AddPost from "./addPosts/AddPost";
import PostService from "./../../../API/postService";
import Loader from "../Loader";
import loader from "../../content/img/loader.gif";
import { RegistrationContext } from "../../context";
import ReDropDown from "./../../reusedComponents/ReDropDown";
import { axios } from "axios";

const Band = () => {
  const { data, setData, user } = useContext(RegistrationContext);

  const [page, setPage] = useState(1);
  const [notPosts, setNotPosts] = useState(["true"]);
  const [newPosts, setNewPosts] = useState(false);
  const [addPost, setAddPost] = useState(false);
  const btnRef = useRef(null);
  const plugText =
    "Здесь появятся моменты, которые опубликуют ваши подписчики. Делитесь важными событиями и сохраняйте памятные моменты с Gargalo.";

  const handleAddPosts = (res) => {
    setData((prev) => [res, ...prev]);
    setAddPost(true);
  };

  useEffect(() => {
    setData();
    PostService.getAllPosts(page).then((res) => setData(res.data));
  }, []);
  useEffect(() => {
    if (page > 1) {
      setNewPosts(true);
      PostService.getAllPosts(page).then((res) => {
        setData((prev) => [...prev, ...res.data]);
        setNotPosts(res.data);
        setNewPosts(false);
      });
    }
  }, [page]);

  useEffect(() => {
    const btn = btnRef.current;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((page) => page + 1);
      }
    });
    if (btn) observer.observe(btn);
    return () => {
      if (btn) observer.unobserve(btn);
    };
  }, [btnRef.current, data]);

  const scrollBarFunction = () => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={style.contentBox}>
      {user.is_real ? (
        <AddPost
          handleAddPosts={handleAddPosts}
          whereTitle="Поделитесь моментом с близкими .."
          whereButton="Опубликовать"
          whereHeader={"Добавление публикации"}
        />
      ) : (
        ""
      )}

      {data ? (
        <>
          <Posts data={data} typePlug={plugText} addPostClass={addPost} />

          {data.length > 9 && (
            <>
              {notPosts.length > 0 ? (
                <button style={{ opacity: 0 }} ref={btnRef}></button>
              ) : (
                <div className={style.no_posts_plug}>
                  <button
                    className={style.no_posts_plug_text}
                    onClick={scrollBarFunction}
                  >
                    Вы посмотрели все посты
                  </button>
                </div>
              )}
            </>
          )}
          {newPosts && (
            <div className={style.loader_div}>
              <div>
                <img style={{ width: 70, height: 70 }} src={loader} alt="" />
                <h3 className={style.loader_text}>Загрузка...</h3>
              </div>
            </div>
          )}
        </>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Band;
