import React, { useState, useContext } from "react";
import { RegistrationContext } from "../../../context";
import ReusedInput from "../../../reusedComponents/ReusedInput";
import style from "./StyleAddEvent.module.css";
import PostService from "../../../../API/postService";
import ReusedDropDownList from "../../../reusedComponents/ReusedDropDownList";
import ReCalendar from "../../../reusedComponents/ReCalendar";
import { useDetectClickOutside } from "react-detect-click-outside";

const EventsChild = ({ keys, setOpen, setEventList, setModeratorReview }) => {
  const [event, setEvent] = useState({
    fatherId: "",
    motherId: "",
    child: "",
    father: "",
    mother: "",
    adress: "",
    date: "",
    details: "",
  });
  const [father, setFather] = useState([]);
  const [mother, setMother] = useState([]);

  const handleChange = (target) => {
    const { name } = target;
    const { value } = target;
    setEvent((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    if (value.length > 0) {
      if (name === "father" || name === "mother") {
        PostService.searchUsers(
          name === "father" ? event.father : event.mother,
          `&filter[]=persons&pol=${name === "father" ? 1 : 2}`
        ).then((res) =>
          name === "father" ? setFather(res?.data) : setMother(res?.data)
        );
      }
    }
  };

  //Закрытие выпадающего списка
  const handleOpenBlock = (state, setState, name) => {
    if (!state) {
      PostService.searchUsers(
        "",
        `&filter[]=persons&pol=${name === "father" ? 1 : 2}`
      ).then((res) => {
        name === "father" ? setFather(res?.data) : setMother(res?.data);
        setState(true);
      });
    }
  };

  const [openListBlock, setOpenListBlock] = useState(true);

  const handleCloseListBlock = () => {
    setOpenListBlock(false);
  };

  const filterListRef = useDetectClickOutside({
    onTriggered: handleCloseListBlock,
  });

  const [openListMatherBlock, setOpenListMatherBlock] = useState(true);

  const handleCloseListMatherBlock = () => {
    setOpenListMatherBlock(false);
  };

  const filterListMatherRef = useDetectClickOutside({
    onTriggered: handleCloseListMatherBlock,
  });

  // задаваемые стили для переиспользуемых компонентов
  const styleForReusedInputBetrothed = {
    width: "100%",
    boxSizing: "border-box",
  };
  const styleForReusedInputAdress = {
    width: 634,
  };

  // добавление события
  const { user } = useContext(RegistrationContext);

  const handleClick = async (e) => {
    e.preventDefault();
    if (
      user.is_admin ||
      user.is_moder ||
      keys.permission === "family" ||
      keys.permission === "followers"
    ) {
      const data = await PostService.sendEvents(event, keys);
      setEventList((prev) => [data, ...prev]);
      setOpen(false);
    } else {
      PostService.sendEvents(event, keys);
      setOpen(false);
      setModeratorReview(true);
    }
  };

  //Проверка на заполнение
  const isButtonDisabled =
    Object.values(event).some((value) => value === "") || keys.title === "";

  return (
    <div className={style.wedding}>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Ребенок</h2>
        <ReusedInput
          name="child"
          type="text"
          reusedInputStyle={styleForReusedInputAdress}
          placeholder="Введите ФИО ребенка"
          value={event.child}
          onChange={handleChange}
        />
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Дата рождения</h2>
        <ReCalendar
          setState={setEvent}
          state={event}
          stateProps={"date"}
          width={"100%"}
          placeholderText="Введите дату рождения ребенка"
          calendarStyle={{ right: 0, left: "auto" }}
        />
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Отец</h2>
        <div
          style={{ position: "relative" }}
          ref={filterListRef}
          onClick={() => {
            handleOpenBlock(openListBlock, setOpenListBlock, "father");
          }}
        >
          <ReusedInput
            name="father"
            type="text"
            reusedInputStyle={styleForReusedInputBetrothed}
            placeholder="Введите ФИО отца"
            value={event.father}
            onChange={handleChange}
          />
          {father?.length > 0 && openListBlock && (
            <div className={style.groom__list}>
              <ReusedDropDownList
                data={father}
                id="fatherId"
                setState={setEvent}
                name="father"
                setOpenComp={setFather}
              />
            </div>
          )}
        </div>
        <h2 className={style.content__suptitle} style={{ marginTop: 20 }}>
          Мать
        </h2>
        <div
          style={{ position: "relative" }}
          ref={filterListMatherRef}
          onClick={() => {
            handleOpenBlock(
              openListMatherBlock,
              setOpenListMatherBlock,
              "mather"
            );
          }}
        >
          <ReusedInput
            name="mother"
            type="text"
            reusedInputStyle={styleForReusedInputBetrothed}
            placeholder="Введите ФИО матери"
            value={event.mother}
            onChange={handleChange}
          />
          {mother?.length > 0 && openListMatherBlock && (
            <div className={style.groom__list}>
              <ReusedDropDownList
                data={mother}
                id="motherId"
                setState={setEvent}
                name="mother"
                setOpenComp={setMother}
              />
            </div>
          )}
        </div>
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Подробности</h2>
        <textarea
          name="details"
          id="details"
          className={style.content__textarea}
          placeholder="Напишите подробности мероприятия"
          value={event.details}
          onChange={(e) =>
            handleChange({ name: e.target.name, value: e.target.value })
          }
        ></textarea>
      </div>
      <div className={style.content}>
        <button
          onClick={() => setOpen(false)}
          className={style.content__button__cancel}
        >
          Отмена
        </button>
        <button
          onClick={handleClick}
          className={style.content__button}
          disabled={isButtonDisabled}
        >
          Добавить
        </button>
        <h4 className={style.content__moder__text}>
          * Событие для тейпа будет опубликовано после расмотрения модератором
        </h4>
      </div>
    </div>
  );
};

export default EventsChild;
