import React from "react";
import style from "../teyps/teyp.module.css";
import axios from "axios";
import teypAvatar from "./img/teyp_avatar.svg";
import addImage from "./img/addImage.svg";
import down from "./img/down.svg";
import { useState } from "react";
import { useEffect } from "react";
import ReButton from "../../reusedComponents/ReButton";
import { useInView } from "react-intersection-observer";
import { useRef } from "react";

const AddTeypPage = ({
  setAddTeypBlock,
  addTeypBlock,
  currentTeyp,
  setTeypList,
  teypList,
}) => {
  const [teypInfo, setTeypInfo] = useState({
    name: currentTeyp.title,
    moderator: currentTeyp.moderator_nickname
      ? currentTeyp.moderator_nickname
      : "",
    parentName: currentTeyp.parent_title ? currentTeyp.parent_title : "",
    img: currentTeyp.photo
      ? `https://gargalo.ru/${currentTeyp.photo}`
      : teypAvatar,
  });
  const [moderatorMas, setModeratorMas] = useState([]);
  const [teypsMas, setTeypsMas] = useState([]);
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [teypAdd, setTeypAdd] = useState({
    title: currentTeyp.title,
    parent_id: currentTeyp.parent_id,
    moderator_id: currentTeyp.moderator_id,
    photo: currentTeyp.photo,
  });

  useEffect(() => {
    if (teypInfo.moderator.length > 3) {
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/getid?fio=${teypInfo.moderator}&filter=members&pol=0&from=0&to=150&years-from=1800&years-to=2022&users_only`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      }).then((res) => {
        setModeratorMas(res.data.data);
      });
    }
  }, [teypInfo.moderator]);

  useEffect(() => {
    if (teypInfo.parentName.length > 2) {
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/teips/search?title=${teypInfo.parentName}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      }).then((res) => {
        setTeypsMas(res.data.data);
      });
    }
  }, [teypInfo.parentName]);

  //Открытие и закрытие блоков с поиском
  const [openModerBlock, setOpenModerBlock] = useState(false);
  const [openTeypBlock, setOpenTeypBlock] = useState(false);
  const [moderName, setModerName] = useState(
    currentTeyp.moderator_nickname ? currentTeyp.moderator_nickname : ""
  );
  const [teypName, setTeypName] = useState(
    currentTeyp.parent_title ? currentTeyp.parent_title : ""
  );
  const [nextPageUrl, setNextPageUrl] = useState();
  const { ref: paginRef, inView } = useInView({
    threshold: 1,
    initialInView: true,
  });

  //Первый клик по инпуту с выбором модератора
  const handleopenModerBlock = (e) => {
    e.stopPropagation();
    if (!openModerBlock) {
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/getid?fio=&filter=members&pol=0&from=0&to=150&years-from=1800&years-to=2022`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      }).then((res) => {
        setModeratorMas(res.data.data);
      });
      setOpenModerBlock(true);
    }
  };

  //Первый клик по инпуту с выбором родительского тейпа
  const handleopenTeypBlock = (e) => {
    e.stopPropagation();
    if (!openTeypBlock) {
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/teips?page=1&paginate`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      }).then((res) => {
        setTeypsMas(res.data.data);
        setNextPageUrl(res.data.next_page_url);
      });
      setOpenTeypBlock(true);
    }
  };

  //Пагинация тейпов
  useEffect(() => {
    if (inView && nextPageUrl) {
      axios({
        method: "GET",
        url: `${nextPageUrl}&paginate`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      }).then((res) => {
        setTeypsMas([...teypsMas, ...res.data.data]);
        setNextPageUrl(res.data.next_page_url);
      });
    }
  }, [inView]);

  //Добавление тейпа
  const handleAddTeyp = () => {
    let form = new FormData();
    form.append("title", teypAdd.title);
    form.append("parent_id", teypAdd.parent_id);
    if (!teypAdd.moderator_id) {
      form.append("moderator_id", "");
    } else {
      form.append("moderator_id", teypAdd.moderator_id);
    }
    // form.append("photo", teypAdd.photo);
    if (currentTeyp.type === "edit") {
      form.append("teip_id", currentTeyp.teyp_id);
    }
    axios({
      method: "post",
      url: "https://gargalo.ru/api/teips",
      data: form,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    })
      .then((res) => {
        setAddTeypBlock(false);
        if (currentTeyp.type === "edit") {
          setTeypList(
            teypList.map((element) => {
              if (element.id === currentTeyp.teyp_id) {
                element.title = teypAdd.title;
                // element.photo = res.data.photo;
              }
              return element;
            })
          );
        } else {
          setTeypList([...teypList, res.data]);
        }
      })
      .catch(() => {
        alert("Произошла непредвиденная ошибка, повторите операцию позже.");
      });
  };

  //Отслеживание при онфокусе инпута
  useEffect(() => {
    if (teypInfo.parentName !== teypName && !openTeypBlock) {
      setTeypInfo({
        ...teypInfo,
        parentName: teypName,
      });
    }
  }, [teypInfo.parentName, openTeypBlock]);

  useEffect(() => {
    if (teypInfo.moderator !== moderName && !openModerBlock) {
      setTeypInfo({
        ...teypInfo,
        moderator: moderName,
      });
    }
  }, [teypInfo.moderator, openModerBlock]);

  //Закрытие выпадающих списков при клике вне области
  const teypsRef = useRef(null);
  const moderatorRef = useRef(null);

  const closeBlock = () => {
    setOpenTeypBlock(false);
    setOpenModerBlock(false);
  };

  useEffect(() => {
    if (teypsRef.current) {
      const onClick = (e) =>
        teypsRef.current.contains(e.target) || closeBlock();
      document.addEventListener("click", onClick);
      return () => document.removeEventListener("click", onClick);
    }
  }, [teypsRef.current]);

  useEffect(() => {
    if (moderatorRef.current) {
      const onClick = (e) =>
        moderatorRef.current.contains(e.target) || closeBlock();
      document.addEventListener("click", onClick);
      return () => document.removeEventListener("click", onClick);
    }
  }, [moderatorRef.current]);

  //Закрытие дроп даунов при нажатии на Esc
  const handleEscDown = (e) => {
    if (e.key !== "Escape") {
      return;
    }
    if (openTeypBlock) {
      setOpenTeypBlock(false);
      return;
    }
    if (addTeypBlock) {
      setAddTeypBlock(false);
      return;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEscDown);
    return () => window.removeEventListener("keydown", handleEscDown);
  }, [openTeypBlock, addTeypBlock]);

  //Удаление тейпа
  const [deleteBlock, setDeleteBlock] = useState(false);

  const handleDeleteTeyp = () => {
    axios({
      method: "delete",
      url: `https://gargalo.ru/api/teips?teip_id=${currentTeyp.teyp_id}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then(() => {
      setAddTeypBlock(false);
      setTeypList(teypList.filter((item) => item.id !== currentTeyp.teyp_id));
    });
  };

  return (
    <div
      className={style.blockedBlock}
      style={{ backgroundColor: deleteBlock && "transparent" }}
    >
      <div
        className={style.backWrapp}
        onClick={() => setAddTeypBlock(false)}
      ></div>
      {deleteBlock && (
        <div className={style.blockedBlock} style={{ zIndex: 20 }}>
          <div
            className={style.backWrapp}
            style={{ zIndex: 21 }}
            onClick={() => setDeleteBlock(false)}
          ></div>
          <div className={style.addTeypWrap} style={{ zIndex: 22, width: 380 }}>
            <div
              className={style.closeAppendBlock}
              onClick={() => setDeleteBlock(false)}
            ></div>
            <h2 className={style.addTeypZag}>Удаление тейпа</h2>
            <p className={style.deleteTeypText}>
              Вы уверены, что хотите удалить тейп? Все данные по тейпу будут
              утеряны.
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ReButton
                height={40}
                width={152}
                text={"Отмена"}
                handleClick={() => setDeleteBlock(false)}
                classes={"third"}
                mr={12}
              />
              <ReButton
                height={40}
                width={152}
                text={"Удалить"}
                handleClick={handleDeleteTeyp}
              />
            </div>
          </div>
        </div>
      )}
      <div className={style.addTeypWrap}>
        {!deleteBlock && (
          <div
            className={style.closeAppendBlock}
            onClick={() => setAddTeypBlock(false)}
          ></div>
        )}
        <h2 className={style.addTeypZag}>
          {currentTeyp.type === "edit" ? "Редактирование тейпа" : "Новый тейп"}
        </h2>
        <h3 className={style.addTeypInputZag}>Название тейпа</h3>
        <input
          type="text"
          className={style.addTeypInput}
          onChange={(e) => {
            setTeypInfo({
              ...teypInfo,
              name: e.target.value.replace(/[a-zA-Z,0-9]/, ""),
            });
            setTeypAdd({
              ...teypAdd,
              title: e.target.value.replace(/[a-zA-Z,0-9]/, ""),
            });
          }}
          placeholder="Название тейпа"
          value={teypInfo.name}
        />
        <h3 className={style.addTeypInputZag}>Родительский тейп</h3>
        <div className={style.searchBlock} ref={teypsRef}>
          <div className={style.addTeypInput} onClick={handleopenTeypBlock}>
            <input
              type="text"
              className={style.addTeypInputSearch}
              placeholder="Введите родительский тейп"
              onChange={(e) =>
                setTeypInfo({
                  ...teypInfo,
                  parentName: e.target.value.replace(/[a-zA-Z, 0-9]/, ""),
                })
              }
              value={teypInfo.parentName}
            />
            {teypAdd.parent_id > 1 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  setTeypInfo({
                    ...teypInfo,
                    parentName: "",
                  });
                  setTeypAdd({
                    ...teypAdd,
                    parent_id: null,
                  });
                  setTeypName("");
                }}
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M17.9996 6.59961L6.59961 17.9996"
                  stroke="#AAC0C5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.9996 17.9996L6.59961 6.59961"
                  stroke="#AAC0C5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <img
                src={down}
                alt=""
                style={{ width: 24, height: 24, cursor: "pointer" }}
              />
            )}
          </div>
          {openTeypBlock && (
            <div className={style.itemsMassWrapp} style={{ zIndex: 3 }}>
              {teypsMas.length > 0 &&
                teypsMas.map((item) => {
                  return (
                    <div
                      className={style.itemBlock}
                      key={item.id}
                      onClick={() => {
                        setTeypName(item.title);
                        setOpenTeypBlock(false);
                        setTeypAdd({
                          ...teypAdd,
                          parent_id: item.id,
                        });
                        setTeypInfo({ ...teypInfo, parentName: item.title });
                      }}
                    >
                      <h2>{item.title}</h2>
                    </div>
                  );
                })}
              <div style={{ height: 1 }} ref={paginRef}></div>
            </div>
          )}
        </div>
        <h3 className={style.addTeypInputZag}>Модератор тейпа</h3>
        <div className={style.searchBlock} ref={moderatorRef}>
          <div
            className={style.addTeypInput}
            style={{ background: "#E6E6E6", opacity: 0.5 }}
            //  onClick={handleopenModerBlock}
          >
            <input
              disabled
              type="text"
              className={style.addTeypInputSearch}
              // onChange={(e) =>
              //   setTeypInfo({
              //     ...teypInfo,
              //     moderator: e.target.value.replace(/[a-zA-Z,0-9]/, ""),
              //   })
              // }
              value={teypInfo.moderator}
              placeholder="Введите модератора тейпа"
            />
            {teypAdd.moderator_id > 0 ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                // onClick={() => {
                //   setTeypInfo({
                //     ...teypInfo,
                //     moderator: "",
                //   });
                //   setTeypAdd({
                //     ...teypAdd,
                //     moderator_id: null,
                //   });
                //   setModerName("");
                // }}
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M17.9996 6.59961L6.59961 17.9996"
                  stroke="#AAC0C5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.9996 17.9996L6.59961 6.59961"
                  stroke="#AAC0C5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <img
                src={down}
                alt=""
                style={{ width: 24, height: 24, cursor: "pointer" }}
              />
            )}
          </div>
          {openModerBlock && (
            <div className={style.itemsMassWrapp}>
              {moderatorMas.length > 0 &&
                moderatorMas.map((item) => {
                  return (
                    <div
                      className={style.itemBlock}
                      key={item.user_id}
                      onClick={() => {
                        setModerName(item.fio);
                        setOpenModerBlock(false);
                        setTeypAdd({
                          ...teypAdd,
                          moderator_id: item.user_id,
                        });
                        setTeypInfo({ ...teypInfo, moderator: item.fio });
                      }}
                    >
                      <img
                        src={`https://gargalo.ru${item.avatar}`}
                        alt=""
                        className={style.itemBlockImg}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h2>{item.fio}</h2>
                        <h2 className={style.userBorn}>{item.born}</h2>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className={style.addTeypBlock}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ReButton
              height={40}
              text={"Отмена"}
              handleClick={() => setAddTeypBlock(false)}
              classes={"third"}
              mr={8}
            />
            <ReButton
              height={40}
              text={currentTeyp.typeName}
              handleClick={handleAddTeyp}
              disabled={teypAdd.title.length < 2 && true}
            />
          </div>
          {currentTeyp.type === "edit" && (
            <h2
              className={style.deleteTeypBtn}
              onClick={() => setDeleteBlock(true)}
            >
              Удалить тейп
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddTeypPage;
