import React from "react";
import { Link } from "react-router-dom";
import styleFamily from "./familyInformation.module.css";

const FamilyParents = ({ userInfo }) => {
    return (
        <>
            {userInfo.family?.father || userInfo.family?.mather ? (
                <div className={styleFamily.popupBlock}>
                    <div className={styleFamily.infoDiv}>
                        <h2 className={styleFamily.infoZagBlock}>Родители</h2>
                        {userInfo.family?.father && (
                            <Link
                                className={styleFamily.itemBLock}
                                to={`/tree/node/${userInfo.family.father.id}`}
                            >
                                <img
                                    src={`https://gargalo.ru/${userInfo.family.father.avatar}`}
                                    alt=""
                                    className={styleFamily.itemAvatar}
                                />
                                <h2 className={styleFamily.infoText}>
                                    {userInfo.family.father.fio}
                                </h2>
                            </Link>
                        )}
                        {userInfo.family?.mather && (
                            <Link
                                className={styleFamily.itemBLock}
                                to={`/tree/node/${userInfo.family.mather.id}`}
                            >
                                <img
                                    src={`https://gargalo.ru/${userInfo.family.mather.avatar}`}
                                    alt=""
                                    className={styleFamily.itemAvatar}
                                />
                                <h2 className={styleFamily.infoText}>
                                    {userInfo.family.mather.fio}
                                </h2>
                            </Link>
                        )}
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default FamilyParents;
