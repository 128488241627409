import React from "react";
import style from "../teyps/teypprofile.module.css";
import TreePageInTeyp from "./TreePageInTeyp";

const TeypPageInTeypBLock = () => {
  return (
    <div className={style.band}>
      <TreePageInTeyp />
    </div>
  );
};

export default TeypPageInTeypBLock;
