import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Link, useLocation } from "react-router-dom";
import Logo_Lenta from "./img/logo_lenta.svg";
import Logo_Message from "./img/logo_message.svg";
import calendar from "./img/logo_calendar.svg";
import family from "./img/Family.svg";
import teypIcon from "./img/teyp.svg";
import zvyk from "./messages/img/music.mp3";
import Footer from "./Footer";
import Warning from "./img/Warning.svg";
import Verification from "./img/Verification.svg";
import Approval from "./img/Approval.svg";
import { useContext } from "react";
import { RegistrationContext } from "../context";

const Sidebar = () => {
  const {
    messagesCount,
    setMessagesCount,
    validationsCount,
    setValidationsCount,
    setNotificationsCount,
    eventsCount,
    setEventsCount,
    unApprovedEventsCount,
    setUnApprovedEventsCount,
    complaintsCount,
    setComplaintsCount,
    user,
    setUser,
  } = useContext(RegistrationContext);

  //Зарпет скролла в древе тейпов
  const pathname = useLocation().pathname;
  useEffect(() => {
    if (pathname.split("/")[1] === "tree") {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
      document.body.style.overflow = "hidden";
      document.getElementById("root").style.overflow = "hidden";
      document.querySelector(".App").style.overflow = "hidden";
      document.getElementsByTagName("html")[0].style.height = "100%";
      document.body.style.height = "100%";
      document.getElementById("root").style.height = "100%";
      document.querySelector(".App").style.height = "100%";
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "visible";
      document.body.style.overflow = "visible";
      document.getElementById("root").style.overflow = "visible";
      document.querySelector(".App").style.overflow = "visible";
      document.getElementById("root").style.height = "auto";
      document.querySelector(".App").style.height = "auto";
    }
  }, [pathname]);

  useEffect(() => {
    setUnApprovedEventsCount(user.on_moderate_events_count);
    setMessagesCount(user.mc);
    setNotificationsCount(user.nc);
    setValidationsCount(user.verify_count);
    setComplaintsCount(user.complaints_count);
    setEventsCount(user.events_count);
  }, []);

  window.startMessageCount = () => {
    let audio = new Audio(zvyk);
    audio.play();
    setMessagesCount((prevState) => prevState + 1);
  };

  window.startModerCount = (notif) => {
    let audio = new Audio(zvyk);
    audio.play();
    setUnApprovedEventsCount(notif.data.count);
  };

  window.startVerifyCount = (notif) => {
    let audio = new Audio(zvyk);
    audio.play();
    setValidationsCount(notif.data.count);
  };

  window.startComplaintsCount = (notif) => {
    let audio = new Audio(zvyk);
    audio.play();
    setComplaintsCount(notif.data.count);
  };

  window.startEventsCount = (notif) => {
    let audio = new Audio(zvyk);
    audio.play();
    setEventsCount(notif.data.count);
  };

  window.startModerateEventsCount = (notif) => {
    let audio = new Audio(zvyk);
    audio.play();
    setUnApprovedEventsCount(notif.data.count);
  };

  return (
    <nav className={style.Sidebar_Menu}>
      <ul className={style.Sidebar_Menu_Jest}>
        <li className={style.Sidebar_item}>
          <Link className={style.sidebarLink} to="/wall">
            <div className={style.sidebarBox}>
              <img
                className={style.Logo_Icon}
                src={Logo_Lenta}
                alt="Sidebar Icon"
              />
              <h2 className={style.sidebarZag}>Лента</h2>
            </div>
          </Link>
        </li>
        <li className={style.Sidebar_item}>
          <Link className={style.sidebarLink} to="/events">
            <div className={style.sidebarBox}>
              <img
                className={style.Logo_Icon}
                src={calendar}
                alt="Sidebar Icon"
              />
              <h2 className={style.sidebarZag}>События</h2>
            </div>
            {eventsCount > 0 && (
              <div
                className={
                  eventsCount > 9 ? style.Sidebar_Count : style.Sidebar_Counts
                }
              >
                <h2>{eventsCount}</h2>
              </div>
            )}
          </Link>
        </li>
        <li className={style.Sidebar_item}>
          <Link className={style.sidebarLink} to="/messages">
            <div className={style.sidebarBox}>
              <img
                className={style.Logo_Icon}
                src={Logo_Message}
                alt="Sidebar Icon"
              />
              <h2 className={style.sidebarZag}>Сообщения</h2>
            </div>
            {messagesCount > 0 && (
              <div
                className={
                  messagesCount > 9 ? style.Sidebar_Count : style.Sidebar_Counts
                }
              >
                <h2>{messagesCount}</h2>
              </div>
            )}
          </Link>
        </li>
        <li className={style.Sidebar_item}>
          <Link className={style.sidebarLink} to="/connection">
            <div className={style.sidebarBox}>
              <img
                className={style.Logo_Icon}
                src={family}
                alt="Sidebar Icon"
              />
              <h2 className={style.sidebarZag}>Семья</h2>
            </div>
          </Link>
        </li>
        {(user.group.name === "teips" || user.node_id > 0) && (
          <li className={style.Sidebar_item}>
            <Link
              className={style.sidebarLink}
              to={`/profileTeyps/${user.teip_moderator_id}`}
            >
              <div className={style.sidebarBox}>
                <img
                  className={style.Logo_Icon}
                  src={teypIcon}
                  alt="Sidebar Icon"
                />
                <h2 className={style.sidebarZag}>Тейп</h2>
              </div>
            </Link>
          </li>
        )}
        {user && !user.is_admin && !user.is_moder && user.is_trusted && (
          <>
            <div className={style.moderBlock}>
              <h2 className={style.sidebarModerZag}>Модерация</h2>
            </div>
            <li className={style.Sidebar_item}>
              <Link className={style.sidebarLink} to="/validation">
                <div className={style.sidebarBox}>
                  <img
                    className={style.Logo_Icon}
                    src={Verification}
                    alt="Sidebar Icon"
                  />
                  <h2 className={style.sidebarZag}>Верификация</h2>
                </div>
                {validationsCount > 0 && (
                  <div
                    className={
                      validationsCount > 9
                        ? style.Sidebar_Count
                        : style.Sidebar_Counts
                    }
                  >
                    <h2>{validationsCount}</h2>
                  </div>
                )}
              </Link>
            </li>
          </>
        )}
        {user && (user.is_admin || user.is_moder) && (
          <>
            <div className={style.moderBlock}>
              <h2 className={style.sidebarModerZag}>Модерация</h2>
            </div>
            <li className={style.Sidebar_item}>
              <Link className={style.sidebarLink} to="/validation">
                <div className={style.sidebarBox}>
                  <img
                    className={style.Logo_Icon}
                    src={Verification}
                    alt="Sidebar Icon"
                  />
                  <h2 className={style.sidebarZag}>Верификация</h2>
                </div>
                {validationsCount > 0 && (
                  <div
                    className={
                      validationsCount > 9
                        ? style.Sidebar_Count
                        : style.Sidebar_Counts
                    }
                  >
                    <h2>{validationsCount}</h2>
                  </div>
                )}
              </Link>
            </li>
            {user.group.name === "teips" && (
              <li className={style.Sidebar_item}>
                <Link className={style.sidebarLink} to="/approval">
                  <div className={style.sidebarBox}>
                    <img
                      className={style.Logo_Icon}
                      src={Approval}
                      alt="Sidebar Icon"
                    />
                    <h2 className={style.sidebarZag}>Одобрение</h2>
                  </div>
                  {/* {validationsCount > 0 && (
                    <div
                      className={
                        validationsCount > 9
                          ? style.Sidebar_Count
                          : style.Sidebar_Counts
                      }
                    >
                      <h2>{validationsCount}</h2>
                    </div>
                  )} */}
                </Link>
              </li>
            )}
            <li className={style.Sidebar_item}>
              <Link className={style.sidebarLink} to="/unapprovedEvents">
                <div className={style.sidebarBox}>
                  <img
                    className={style.Logo_Icon}
                    src={calendar}
                    alt="Sidebar Icon"
                  />
                  <h2 className={style.sidebarZag}>События</h2>
                </div>
                {unApprovedEventsCount > 0 && (
                  <div
                    className={
                      unApprovedEventsCount > 9
                        ? style.Sidebar_Count
                        : style.Sidebar_Counts
                    }
                  >
                    <h2>{unApprovedEventsCount}</h2>
                  </div>
                )}
              </Link>
            </li>
            <li className={style.Sidebar_item}>
              <Link className={style.sidebarLink} to="/complaints">
                <div className={style.sidebarBox}>
                  <img
                    className={style.Logo_Icon}
                    src={Warning}
                    alt="Sidebar Icon"
                  />
                  <h2 className={style.sidebarZag}>Жалобы</h2>
                </div>
                {complaintsCount > 0 && (
                  <div
                    className={
                      complaintsCount > 9
                        ? style.Sidebar_Count
                        : style.Sidebar_Counts
                    }
                  >
                    <h2>{complaintsCount}</h2>
                  </div>
                )}
              </Link>
            </li>
            {user.is_admin && (
              <li className={style.Sidebar_item}>
                <Link className={style.sidebarLink} to="/teyps">
                  <div className={style.sidebarBox}>
                    <img
                      className={style.Logo_Icon}
                      src={teypIcon}
                      alt="Sidebar Icon"
                    />
                    <h2 className={style.sidebarZag}>Тейпы</h2>
                  </div>
                </Link>
              </li>
            )}
          </>
        )}
      </ul>
      <Footer />
    </nav>
  );
};

export default Sidebar;
