import React from "react";
import logo from "./img/logo.svg";
import back from "./img/Back.svg";
import { Link } from "react-router-dom";

const LogoBack = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: 28 }}>
      <Link to={"/"} style={{ width: 10, height: 20, marginRight: 25}}>
        <img
          src={back}
          alt="Назад"
          style={{ width: 8, height: 14}}
        />
      </Link>
      <img src={logo} alt="Логотип" style={{ width: 120, height: 16 }} />
    </div>
  );
};

export default LogoBack;
