import React from "react";
import style from "../user.module.css";
import Loader from "../../Loader";
import { useState } from "react";
import { useEffect } from "react";
import PostService from "./../../../../API/postService";
import UserNotificationsGroup from "./UserNotificationsGroup";
import ReButton from "../../../reusedComponents/ReButton";

const UserSettingsNotifications = () => {
  const [notifi, setNotifi] = useState();
  const [notifiGroup, setNotifiGroup] = useState();
  const [notifiResponse, setNotifiResponse] = useState(false);
  const [notifiFail, setNotifiFail] = useState(false);

  useEffect(() => {
    PostService.getAllNotifications().then((res) => setNotifi(res));
    PostService.getAllNotificationsListGroup().then((res) =>
      setNotifiGroup(res)
    );
  }, []);

  const handleSendData = async () => {
    try {
      const data = await PostService.sendNotifications(notifi);
      if (data) {
        setNotifiResponse(true);
        setTimeout(() => {
          setNotifiResponse(false);
        }, 5000);
      } else {
        setNotifiFail(true);
        setTimeout(() => {
          setNotifiFail(false);
        }, 5000);
      }
    } catch (error) {
      setNotifiFail(true);
      setTimeout(() => {
        setNotifiFail(false);
      }, 5000);
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <>
      {notifiGroup && notifi ? (
        <div className={style.settings__notification__block}>
          {notifiGroup.map((notifiList) => (
            <UserNotificationsGroup
              key={notifiList.id}
              notifiList={notifiList}
              notifi={notifi}
              setNotifi={setNotifi}
            />
          ))}

          <div>
            {notifiResponse && (
              <p className={style.settings__button__title}>
                *Изменения были сохранены
              </p>
            )}
            {notifiFail && (
              <p className={style.settings__button__title}>
                *Изменения не сохранены, повторите попытку позже
              </p>
            )}
            <ReButton
              type={"button"}
              classes="main"
              height={40}
              width={120}
              handleClick={() => handleSendData()}
              FWeight={600}
              FSize={14}
              text={"Сохранить"}
              mt={16}
            />
          </div>
          <div className={style.settings__moder}>
            {/* <span style={{ marginLeft: 0, color: check !== "*Настройки сохранены" ? "red" : "green" }}>
                        {check}
                    </span> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UserSettingsNotifications;
