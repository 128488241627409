import React from "react";
import { Link, useParams } from "react-router-dom";
import style from "../messages/messages.module.css";
import Messag from "./Messag.jsx";
import MessagPage from "./MessagPage";
import messageNone from "../messages/img/MessageNone.svg";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../img/loader.gif";

const Messages = () => {
  const id = useParams();
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [messageList, setMessageList] = useState();
  const [messagesList, setMessagesList] = useState();
  const [activProfile, setActivProfile] = useState(false);

  useEffect(() => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/v2/chats`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    })
      .then((res) => {
        setMessageList(res.data);
        setMessagesList(res.data.data);
      })
      .catch((err) => {
        setActivProfile(true);
      });
  }, []);

  return (
    <div className={style.messagesWrap}>
      {messageList ? (
        <>
          <Messag
            messageList={messageList}
            messagesList={messagesList}
            setMessagesList={setMessagesList}
          />
          {id.id ? (
            <MessagPage
              chatList={messageList}
              messagesList={messagesList}
              setMessagesList={setMessagesList}
            />
          ) : (
            <div className={style.messageNoneBox}>
              <img
                src={messageNone}
                alt="Сообщений нет"
                style={{ width: 56, height: 56 }}
              />
              <span>Здесь пока нет сообщений.</span>
            </div>
          )}
        </>
      ) : !activProfile ? (
        <div className={style.loaderBLock}>
          <img src={Loader} alt="Loader" />
        </div>
      ) : (
        <div className={style.activBlock}>
          <p className={style.activBlockText}>
            ВАШ ПРОФИЛЬ НЕАКТИВЕН Активируйте профиль любым удобным для Вас
            способом.
          </p>
          <Link to="/confirmUser" className={style.activBlockLink}>
            Активировать
          </Link>
        </div>
      )}
    </div>
  );
};

export default Messages;
