import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import style from "../../messagPage.module.css";
import VectorForCheckbox from "../../../img/VectorForCheckbox.svg";

const ComplainChat = ({
  setComplainChat,
  contact_id,
  setComplainClose,
  setMySettings,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [complainList, setComplainList] = useState([]);
  const [complainType, setComplainType] = useState("");
  const [reason, setReason] = useState([]);

  //Прогрузка списка жалоб
  useEffect(() => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/complaint/reasons/events`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setComplainList(res.data.reasons);
    });
  }, []);

  //Отправка жалобы
  const handleSendCompain = () => {
    axios
      .post(
        `https://gargalo.ru/api/messages/contactcomplain/${contact_id}`,
        { reason },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      )
      .then(() => {
        setComplainChat(false);
        setMySettings((prevState) => ({ ...prevState, blacklist: 1 }));
        setComplainClose(true);
      });
  };

  return (
    <>
      {complainList.length > 0 && (
        <div className={style.blockedDisplay}>
          <div className={style.complain_container}>
            <div className={style.complain_title}>
              <h2>ПРИЧИНА ЖАЛОБЫ</h2>
            </div>
            {complainList.map((category) => {
              return (
                <div
                  key={category.type}
                  onClick={() => {
                    setComplainType(category.type);
                    setReason([category.type]);
                  }}
                  className={style.complain_categoryes__block}
                >
                  <div className={style.complain_categoryes}>
                    <div
                      style={{
                        backgroundImage:
                          category.type === complainType &&
                          `url(${VectorForCheckbox})`,
                      }}
                      className={style.checkbox}
                    ></div>
                    <h2>{category.text}</h2>
                  </div>
                </div>
              );
            })}
            <div className={style.complain_finish}>
              <button
                disabled={complainType === "" ? true : false}
                onClick={handleSendCompain}
                className={style.complain_send}
              >
                Отправить
              </button>
              <button
                onClick={() => setComplainChat(false)}
                className={style.complain_cancel}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ComplainChat;
