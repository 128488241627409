import React from "react";
import { Link } from "react-router-dom";
import style from "../notifications/notifications.module.css";
import avatar from "../notifications/img/avatar.svg";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import NoneNotificationsList from "./NoneNotificationsList";
import { useContext } from "react";
import { RegistrationContext } from "../../context";

const NotificationsList = () => {
  const [notifications, setNotifications] = useState([]);
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [nextPageUrl, setNextPageUrl] = useState();
  const { setNotificationsCount } = useContext(RegistrationContext);

  useEffect(() => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/notifications`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      if (res.data.errors) {
        return;
      }
      setNextPageUrl(res.data.next_page_url);
      setNotifications(res.data.data);
    });
  }, []);

  //Отправка прочитанных уведомлений
  useEffect(() => {
    if (notifications.length > 0) {
      notifications.forEach((element) => {
        if (element.is_new === 1) {
          axios
            .post(
              `https://gargalo.ru/api/notifications/setreaded`,
              { list: [element.id] },
              {
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${token[1]}`,
                  "User-Token": 1,
                },
              }
            )
            .then(() => {
              setNotificationsCount((prevState) => prevState - 1);
            });
        }
      });
    }
  }, [notifications]);

  //Нажатие кнопки в уведомлениях
  const handleReplyEvent = (controller, action, params, index) => {
    let paramsMass = params.join("/");
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/${controller}/${action}/${paramsMass}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setNotifications(
        notifications.filter((item) => item !== notifications[index])
      );
    });
  };

  //Получение новой страницы
  const handlePaginClick = () => {
    if (nextPageUrl) {
      axios({
        method: "GET",
        url: `${nextPageUrl}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      }).then((res) => {
        setNextPageUrl(res.data.next_page_url);
        setNotifications((prevState) => [...prevState, ...res.data.data]);
      });
    }
  };

  return (
    <div className={style.notifList}>
      {notifications.length > 0 ? (
        notifications.map((items, indexNotif) => {
          return (
            <div key={indexNotif}>
              {!items.error ? (
                <div className={style.notifBlock}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={style.notifAvatar}>
                      <img
                        src={
                          items.avatar
                            ? `https://gargalo.ru${items.avatar}`
                            : avatar
                        }
                        style={{
                          width: 52,
                          height: 52,
                          borderRadius: 60,
                        }}
                        alt="Фото профиля"
                      />
                    </div>
                    <div className={style.NameAndTime}>
                      <div className={style.NameAndText}>
                        {items.text_tokens.map((item, index) => {
                          return (
                            <div key={index}>
                              {item.action !== null ? (
                                <Link
                                  to={item.action}
                                  className={style.itemLink}
                                >
                                  {item.text}
                                </Link>
                              ) : (
                                <h2 className={style.itemText}>{item.text}</h2>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <h2 className={style.itemText}>{items.pubdate}</h2>
                    </div>
                  </div>
                  <div className={style.btnBlock}>
                    {items?.buttons &&
                      items.buttons.map((item, index) => {
                        return (
                          <button
                            key={index}
                            id={item.class}
                            className={style.itemBtn}
                            onClick={() =>
                              handleReplyEvent(
                                item.controller,
                                item.action,
                                item.params,
                                indexNotif
                              )
                            }
                          ></button>
                        );
                      })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })
      ) : (
        <NoneNotificationsList />
      )}
      {nextPageUrl && (
        <button onClick={handlePaginClick} className={style.btnPagin}>
          Показать еще
        </button>
      )}
    </div>
  );
};

export default NotificationsList;
