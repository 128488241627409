import React from "react";
import stylePersons from "./ReusedDropDownList.module.css";

const ReusedDropDownList = ({
  id,
  data,
  setState,
  name,
  setOpenComp,
  reusedDropDownListStyle,
}) => {
  const handleClick = (personName, personId) => {
    setState((prevState) => ({
      ...prevState,
      [name]: personName,
      [id]: personId,
    }));
    setOpenComp(false);
  };

  return (
    <>
      {data.map((person) => (
        <div
          className={stylePersons.persons__div}
          onClick={() => handleClick(person.fio, person.node_id)}
          key={person.node_id}
        >
          <img
            className={stylePersons.persons__image}
            src={"https://gargalo.ru" + person.avatar}
            alt=""
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h2
              className={stylePersons.search__user__nickname}
              style={reusedDropDownListStyle}
            >
              {person.fio}
            </h2>
            <h2 className={stylePersons.search__user__born}>
              {person.born
                ? person.born.split("-")[2] +
                  "." +
                  person.born.split("-")[1] +
                  "." +
                  person.born.split("-")[0]
                : ""}
            </h2>
          </div>
        </div>
      ))}
    </>
  );
};

export default ReusedDropDownList;
