import React from "react";
import Actions from "./Actions";
import actionsStyle from "./actions.module.css";
import { useNavigate } from "react-router-dom";
const PostTopPanel = ({ item, type, where }) => {
    const navigate = useNavigate();
    const handleClickUserNickname = () => {
        navigate(`/account/${item.user.user_id}`);
    };

    return (
        <div className={actionsStyle.post__info}>
            <div className={actionsStyle.post__img__and__name}>
                <div
                    className={actionsStyle.post__profile}
                    onClick={handleClickUserNickname}
                >
                    <img src={`https://gargalo.ru${item.user.avatar}`} alt="" />
                </div>
                <div>
                    <p
                        className={actionsStyle.nickname}
                        onClick={handleClickUserNickname}
                    >
                        {item.user.nickname}
                    </p>
                    <p className={actionsStyle.time}>{item.pubdate}</p>
                </div>
            </div>

            <Actions where={where} item={item} type={type} />
        </div>
    );
};

export default PostTopPanel;
