import React from "react";
import style from "../../messagPage.module.css";
import Camera from "../../img/Camera.svg";
import Send from "../../img/sendSelfie.svg";
import { useState } from "react";
import { useRef } from "react";
import axios from "axios";
import closeMsg from "../../img/close.svg";

const AddSelfie = ({
  setAddSelfie,
  contact_id,
  parent_id,
  setParent_id,
  setScroll,
  setMessageIdMass,
  setMessages,
  setContactSettings,
  setAnswerBlock,
}) => {
  const [selfieSrc, setSelfieSrc] = useState();
  const [sendMessag, setSendMessag] = useState(false);
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [recorder, setRecorder] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const constraints = {
    video: {
      width: 630,
      height: 500,
      frameRate: 60,
    },
  };

  //Старт видеопотока
  const handleStartVideo = async () => {
    const videoBlock = videoRef.current;
    if (videoBlock) {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoBlock.srcObject = stream;
    }
  };

  //Снимок
  const handleClickSelfie = () => {
    const canvasBlock = canvasRef.current;
    const videoBlock = videoRef.current;
    canvasBlock.width = videoBlock.videoWidth;
    canvasBlock.height = videoBlock.videoHeight;
    canvasBlock.getContext("2d").drawImage(videoBlock, 0, 0);
    setSelfieSrc(canvasBlock.toDataURL("image/jpeg"));
  };

  //Преобразование base64 в blob
  function dataURLtoBlob(dataURL) {
    let array, binary, i, len;
    binary = atob(dataURL.split(",")[1]);
    array = [];
    i = 0;
    len = binary.length;
    while (i < len) {
      array.push(binary.charCodeAt(i));
      i++;
    }
    return new Blob([new Uint8Array(array)], {
      type: "image/png",
    });
  }

  //Отправка селфи
  const handleSendSelfie = () => {
    setSendMessag(true);
    let fd = new FormData();
    fd.append("media[]", dataURLtoBlob(selfieSrc));
    fd.append("msg", "");
    fd.append("contact_id", contact_id);
    fd.append("_method", "PUT");
    fd.append("parent_id", parent_id);
    axios({
      method: "post",
      url: "https://gargalo.ru/api/messages/send",
      data: fd,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    })
      .then((res) => {
        setSendMessag(false);
        setMessageIdMass([]);
        setParent_id(-1);
        setAnswerBlock(false);
        setMessages((prevState) => [res.data, ...prevState]);
        setScroll(true);
        setAddSelfie(false);
      })
      .catch((error) => {
        if (
          error.response.data.errors[0] ===
          "Этот пользователь добавил вас в черный список"
        ) {
          setContactSettings((prevState) => ({
            ...prevState,
            blacklist: 1,
          }));
        }
      });
  };

  return (
    <div className={style.blockedDisplay} onLoad={handleStartVideo}>
      <div className={style.selfieWrapp}>
        <div className={style.selfieBlock}>
          <video ref={videoRef} controls autoPlay muted></video>
          <canvas ref={canvasRef} style={{ opacity: 0 }}></canvas>
          {selfieSrc && (
            <img src={selfieSrc} alt="Selfie" className={style.selfiePrevie} />
          )}
        </div>
        <div
          className={style.selfieBtnBlock}
          style={{ marginLeft: -70, backgroundColor: "white" }}
          onClick={() => setAddSelfie(false)}
        >
          <img
            src={closeMsg}
            alt="Photo"
            onClick={handleClickSelfie}
            style={{ width: 18, height: 18 }}
          />
        </div>
        <div className={style.selfieBtnBlock}>
          <img src={Camera} alt="Photo" onClick={handleClickSelfie} />
        </div>
        {selfieSrc && (
          <button
            className={style.selfieBtnBlock}
            style={{ marginLeft: 30, border: "none" }}
            onClick={handleSendSelfie}
            disabled={sendMessag}
          >
            <img
              src={Send}
              alt="Send Photo"
              style={{ width: 17, height: 18 }}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default AddSelfie;
