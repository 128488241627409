import React from 'react';
import style from "../notifications/noneNotifications.module.css";

const NoneNotificationsList = () => {
    return (
        <div className={style.mainDiv}>
            <div className={style.textContainer}>
                <h2>Пока нет уведомлений!</h2>
                <p>Подписки - отписки, отметки «Нравится» и многое другое — здесь будут показаны все взаимодействия с контентом.</p>
            </div>
        </div>
    );
};

export default NoneNotificationsList;