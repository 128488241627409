import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import style from "../../messagPage.module.css";

const DeleteMessages = ({
  setDeleteMessages,
  setMessageIdMass,
  messageIdMass,
  setMessages,
  messages,
}) => {
  const [forAll, setForAll] = useState(false);
  const [deleteActiv, setDeleteActiv] = useState(false);
  const token = document.cookie.match(/token=(.+?)(;|$)/);

  //Проверка на удаление у всех
  useEffect(() => {
    if (messageIdMass.length === 1) {
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/messages/delete/${String(messageIdMass)}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      })
        .then((res) => {
          if (res.data.for_all === 1) {
            setForAll(true);
          }
          setDeleteActiv(true);
        })
        .catch(() => {
          setDeleteActiv(true);
        });
    } else {
      setDeleteActiv(true);
    }
  }, []);

  //Удалить
  const handleDeletClick = (type) => {
    if (messageIdMass.length === 1) {
      axios
        .post(
          `https://gargalo.ru/api/messages/delete`,
          {
            message_id: String(messageIdMass),
            delete_mode: type,
          },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token[1]}`,
              "User-Token": 1,
            },
          }
        )
        .then(() => {
          setMessages(messages.filter((item) => item.id !== messageIdMass[0]));
          setDeleteMessages(false);
          setMessageIdMass([]);
        });
    } else {
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/messages/delete?ids=${String(
          messageIdMass
        )}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      }).then(() => {
        messageIdMass.forEach((element) => {
          setMessages((prevState) => [
            ...prevState.filter((item) => item.id !== element),
          ]);
        });
        setDeleteMessages(false);
        setMessageIdMass([]);
      });
    }
  };

  return (
    <>
      {deleteActiv && messageIdMass.length > 0 && (
        <div className={style.blockedDisplay}>
          <div className={style.deleteWrap}>
            <h2 className={style.confirmBlockZag}>Вы уверены?</h2>
            <h2 className={style.confirmBlockText}>
              Удалить без возможности восстановления?
            </h2>
            <div className={style.deleteButtonBlock}>
              <button onClick={() => handleDeletClick("remove_from_yourself")}>
                Удалить у меня
              </button>
              {forAll && messageIdMass.length === 1 && (
                <button
                  onClick={() => handleDeletClick("remove_from_everyone")}
                >
                  Удалить у всех
                </button>
              )}
              <button
                onClick={() => {
                  setDeleteMessages(false);
                  setMessageIdMass([]);
                }}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteMessages;
