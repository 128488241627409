import React, { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import style from "../teyps/currentTeyp.module.css";
import treeFork from "../teyps/img/TreeIcon.svg";
import chatCircleText from "../teyps/img/message.svg";
import gerbDef from "../teyps/img/gerbDef.svg";
import Lock from "../teyps/img/Lock.svg";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import TeypProfilePosts from "./TeypProfilePosts";
import PostService from "../../../API/postService";
import ReButton from "../../reusedComponents/ReButton";
import { rootControls } from "../../../controllers/RootConroller";
import { RegistrationContext } from "../../context";

const TeypProfileBlock = () => {
  const { id } = useParams();
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [teypInfo, setTeypInfo] = useState();
  const { user } = useContext(RegistrationContext);
  const navigate = useNavigate();

  useEffect(() => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/user/${id}/info`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setTeypInfo(res.data);
    });
  }, [id]);

  //Отправка запроса на разрешение отправки сообщений
  const handleNavigateButton = () => {
    if (!teypInfo?.profile.is_contact) {
      PostService.getSendMessages(teypInfo?.profile.user_id).then(() =>
        navigate(`/messages/chat/${teypInfo?.profile.user_id}`)
      );
    } else {
      navigate(`/messages/chat/${teypInfo?.profile.user_id}`);
    }
  };

  //Добавление/Удаление обложки
  const [cover, setCover] = useState();

  const handleFontChange = (e, type) => {
    let form = new FormData();
    if (type === "delete") {
      form.append("delete_cover", 1);
    } else {
      form.append("cover", e.target.files[0]);
    }
    form.append("teip_id", teypInfo.teip_item.id);
    axios({
      method: "post",
      url: `https://gargalo.ru/api/teips`,
      data: form,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then(() => {
      if (type === "delete") {
        setCover();
        setTeypInfo({
          ...teypInfo,
          teip_item: { ...teypInfo.teip_item, cover: null },
        });
      } else {
        setCover(URL.createObjectURL(e.target.files[0]));
      }
    });
  };

  //Добавление/Удаление герба
  const [gerb, setGerb] = useState();

  const handleChangeGerb = (e, type) => {
    let form = new FormData();
    if (type === "delete") {
      form.append("delete_photo", 1);
    } else {
      form.append("photo", e.target.files[0]);
    }
    form.append("teip_id", teypInfo.teip_item.id);
    axios({
      method: "post",
      url: `https://gargalo.ru/api/teips`,
      data: form,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then(() => {
      if (type === "delete") {
        setGerb(gerbDef);
      } else {
        setGerb(URL.createObjectURL(e.target.files[0]));
      }
    });
  };

  return (
    <div style={{ display: "flex", marginLeft: 20 }}>
      <div>
        {teypInfo && (
          <>
            <div className={style.teypProfileWrapp}>
              <div
                className={style.teypProfileBack}
                style={{
                  backgroundImage: cover
                    ? `url(${cover})`
                    : `url(https://gargalo.ru/${teypInfo.teip_item.cover})`,
                }}
              >
                {(rootControls("Administrator") ||
                  rootControls("TeipModerator", teypInfo.profile.user_id)) && (
                  <>
                    {!teypInfo.teip_item.cover && !cover ? (
                      <>
                        <input
                          type="file"
                          id="coverFile"
                          hidden
                          className={style.inputImg}
                          accept="image/*"
                          onChange={(e) => handleFontChange(e, "add")}
                        ></input>
                        <label
                          htmlFor="coverFile"
                          className={style.teypProfileBackBtn}
                        >
                          <ReButton
                            height={32}
                            padding={"8px 16px"}
                            font="small"
                            type={"div"}
                            classes="main"
                            text={"Добавить обложку"}
                          />
                        </label>
                      </>
                    ) : (
                      <div
                        style={{ display: "flex" }}
                        className={style.teypProfileBackBtn}
                      >
                        <input
                          type="file"
                          id="coverUpdateFile"
                          hidden
                          className={style.inputImg}
                          accept="image/*"
                          onChange={(e) => handleFontChange(e, "add")}
                        ></input>
                        <label htmlFor="coverUpdateFile">
                          <ReButton
                            height={32}
                            padding={"8px 16px"}
                            mr={8}
                            font="small"
                            type={"div"}
                            classes="third"
                            text={"Заменить обложку"}
                          />
                        </label>
                        <ReButton
                          height={32}
                          padding={"8px 16px"}
                          type={"div"}
                          font="small"
                          classes="third"
                          handleClick={handleFontChange}
                          clickProps={["delete"]}
                          text={"Удалить обложку"}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={style.profile_maindiv}>
                <div className={style.teyp__info__block}>
                  <h2 className={style.teyp__title}>
                    Тейп {teypInfo.teip_item.title}
                  </h2>
                  <div className={style.teyp__info__count}>
                    <div className={style.counterBlock}>
                      {teypInfo?.profile.count ? (
                        <h2>{teypInfo?.profile.count}</h2>
                      ) : (
                        <h2>0</h2>
                      )}
                      <span>Участников</span>
                    </div>
                    <div className={style.counterBlock}>
                      {teypInfo?.profile.posts ? (
                        <h2>{teypInfo?.profile.posts}</h2>
                      ) : (
                        <h2>0</h2>
                      )}
                      <span>Публикаций</span>
                    </div>
                  </div>
                  <div className={style.teyp__info__buttons}>
                    <ReButton
                      type="Link"
                      to={`/tree/${teypInfo?.profile.teip}`}
                      classes={"main"}
                      icon={treeFork}
                      IHeight={24}
                      IWidth={24}
                      IRight={4}
                      mr={4}
                      height={40}
                      text={"Древо"}
                    />
                    {!rootControls(
                      "TeipModerator",
                      teypInfo.profile.user_id
                    ) && (
                      <ReButton
                        classes="main"
                        icon={chatCircleText}
                        IHeight={24}
                        IWidth={24}
                        IRight={4}
                        handleClick={handleNavigateButton}
                        height={40}
                        text={"Написать модератору"}
                      />
                    )}
                  </div>
                </div>
                <div className={style.teyp__info__avatar}>
                  <div className={style.teipAvatarWrapp}>
                    <img
                      src={
                        gerb
                          ? gerb
                          : `https://gargalo.ru${teypInfo.teip_item.photo}`
                      }
                      alt="Teyp avatar"
                      className={style.gerbTeyp}
                    />
                    {(rootControls("Administrator") ||
                      rootControls(
                        "TeipModerator",
                        teypInfo.profile.user_id
                      )) && (
                      <div className={style.teipAvatarBtnBlock}>
                        {teypInfo.teip_item.photo.split("/")[4] === "default" &&
                        !gerb ? (
                          <>
                            <input
                              type="file"
                              id="inputGerb"
                              hidden
                              className={style.inputImg}
                              accept="image/*"
                              onChange={(e) => handleChangeGerb(e, "add")}
                            ></input>
                            <label
                              htmlFor="inputGerb"
                              className={style.teypProfileGerbBtn}
                            >
                              Загрузить герб
                            </label>
                          </>
                        ) : (
                          <>
                            <input
                              type="file"
                              id="inputUpdateGerb"
                              hidden
                              className={style.inputImg}
                              accept="image/*"
                              onChange={(e) => handleChangeGerb(e, "add")}
                            ></input>
                            <label
                              htmlFor="inputUpdateGerb"
                              className={style.teypProfileGerbBtn}
                            >
                              Заменить герб
                            </label>
                            <div
                              className={style.teypProfileGerbBtn}
                              onClick={(e) => handleChangeGerb(e, "delete")}
                            >
                              Удалить герб
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {rootControls("Administrator") ||
            rootControls("TeipModerator", teypInfo.profile.user_id) ||
            (user.node_id > 0 && user.teip === teypInfo.teip_item.id) ? (
              <TeypProfilePosts />
            ) : (
              <div className={style.lockWrapp}>
                <img src={Lock} alt="Закрытый тейп" />
                <p>
                  Просматривать публикации могут только участники данного тейпа.
                </p>
              </div>
            )}
          </>
        )}
      </div>
      {teypInfo && (
        <div className={style.dop__info__block}>
          <div className={style.dopInfoBlock}>
            <h2 className={style.dopInfoZag}>Модераторы</h2>
            <span className={style.dopInfoBorder}></span>
            <Link
              className={style.dop__info__sernames}
              to={
                teypInfo?.profile?.user_id !== window.userParam.id
                  ? `/account/${teypInfo?.profile.user_id}`
                  : `/profile/${teypInfo?.profile.user_id}`
              }
            >
              <img
                src={`https://gargalo.ru/${teypInfo?.profile?.avatar_small}`}
                alt="Avatar"
                className={style.dopInfoAvatar}
              />
              <h2 className={style.dopInfoUserName}>
                {teypInfo?.profile.nickname
                  ? teypInfo?.profile.nickname
                  : teypInfo?.item.fio}
              </h2>
            </Link>
          </div>
          <div className={style.dopInfoBlock}>
            <h2 className={style.dopInfoZag}>Дочерние тейпы</h2>
            <span className={style.dopInfoBorder}></span>
            <div className={style.teipsList}>
              {teypInfo?.profile.childs &&
              teypInfo?.profile.childs.length > 0 ? (
                <>
                  {teypInfo?.profile.childs.map((obj, index) => {
                    return (
                      <Link
                        key={index}
                        className={style.dop__info__sernames}
                        to={
                          obj.moderator_id !== null && obj.moderator_id !== 0
                            ? `/profileTeyps/${obj.moderator_id}`
                            : ""
                        }
                      >
                        <img
                          src={`https://gargalo.ru/${obj.avatar}`}
                          alt="Avatar"
                          className={style.dopInfoAvatar}
                        />
                        <h2 className={style.dopInfoUserName}>{obj.title}</h2>
                      </Link>
                    );
                  })}
                </>
              ) : (
                <h2 className={style.dopInfoUserNameNull}>Отсутствуют</h2>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeypProfileBlock;
