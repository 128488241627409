import React from "react";
import { Link } from "react-router-dom";
import style from "../../messages.module.css";
import check from "../../img/check.svg";
import x from "../../img/x.svg";
import axios from "axios";
import { useContext } from "react";
import { RegistrationContext } from "../../../../context";

const ListItem = ({
  item,
  setMessagesList,
  messagesList,
  search,
  searchText,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const { setMessagesCount } = useContext(RegistrationContext);

  //Принять диалог
  const handleAcceptDialog = (id) => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/messages/acceptcontact/${id}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then(() => {
      setMessagesList((prevState) =>
        prevState.map((item) => {
          if (item.contact_id === id) {
            item.is_accept = true;
          }
          return item;
        })
      );
    });
  };

  //Последнее сообщение
  const lastMessage = (item) => {
    return (
      <>
        {item?.last_message?.content !== "" ? (
          <h2 className={style.messagUser}>{item?.last_message?.content}</h2>
        ) : item?.last_message?.media !== null ? (
          <h2 className={style.messagUser}>Медиа файл</h2>
        ) : (
          <h2 className={style.messagUser}>Голосовое сообщение</h2>
        )}
      </>
    );
  };

  //Выделение сообщения
  const selection = (msg) => {
    return (
      <>
        {msg.split("").map((letter) => {
          return (
            <>
              {searchText.split("").includes(letter) ? (
                <span style={{ backgroundColor: "#9fe2ff70" }}>{letter}</span>
              ) : (
                <span>{letter}</span>
              )}
            </>
          );
        })}
      </>
    );
  };

  return (
    <Link
      to={`/messages/chat/${item.contact_id}`}
      onClick={() => {
        setMessagesList((prevState) =>
          prevState.map((obj) => {
            if (obj.contact_id === item.contact_id) {
              obj.new_messages = 0;
            }
            return obj;
          })
        );
        messagesList.forEach((element) => {
          if (element.contact_id === item.contact_id) {
            setMessagesCount((prevState) => prevState - element.new_messages);
          }
        });
      }}
    >
      <div className={style.messagesPeople}>
        <div className={style.messagesPeopleImgAndText}>
          <div className={style.PeopleImgs}>
            <div className={style.PeopleImg}>
              <img
                src={`https://gargalo.ru/${item?.user?.avatar}`}
                alt="Avatar"
              />
            </div>
            {item.is_online ? (
              <span style={{ backgroundColor: "#27ae60" }}></span>
            ) : (
              <span style={{ backgroundColor: "#EB5757" }}></span>
            )}
          </div>
          <div className={style.PeopleName}>
            <h2 className={style.NameUser}>{item?.user?.nickname}</h2>
            {search ? (
              <h2 className={style.messagRequest}>{selection(item.content)}</h2>
            ) : (
              <>
                {item.last_message ? (
                  <div>{lastMessage(item)}</div>
                ) : item.whoasked === item.user.user_id && !item.is_accept ? (
                  <h2 className={style.messagRequest}>
                    Заявку еще не подтвердили
                  </h2>
                ) : (
                  !item.is_accept && (
                    <h2 className={style.messagRequest}>
                      Хочет начать с вами диалог
                    </h2>
                  )
                )}
              </>
            )}
          </div>
        </div>
        {!search && (
          <>
            {item.last_message && (
              <div className={style.TimeAndAmount}>
                <h2 className={style.messagItem}>
                  {item.time.split(":")[0]}:{item.time.split(":")[1]}
                </h2>
                <span
                  style={
                    item.new_messages >= 1
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                >
                  <h2 className={style.AmountText}>{item.new_messages}</h2>
                </span>
              </div>
            )}
            {/* : (
              item.whoasked !== item.user.user_id &&
              !item.is_accept && (
                <div className={style.acceptBtnBLock}>
                  <div onClick={() => handleAcceptDialog(item.contact_id)}>
                    <img
                      src={check}
                      alt="Принять"
                      style={{
                        width: 15,
                        height: 10,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div
                    onClick={() => {
                      setMessagesList((prevState) =>
                        prevState.map((item) => {
                          if (item.contact_id === item.contact_id) {
                            item.blacklist = true;
                          }
                          return item;
                        })
                      );
                    }}
                  >
                    <img
                      src={x}
                      alt="Отклонить"
                      style={{
                        width: 12,
                        height: 12,
                        marginLeft: 22,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              )
            )} */}
          </>
        )}
        <div className={style.BorderMessag} />
      </div>
    </Link>
  );
};

export default ListItem;
