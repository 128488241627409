import React from "react";
import woman from "../../img/woman.svg";
import man from "../../img/man.svg";
import axios from "axios";

const AppenedBLock = ({
  center,
  setAppenedBlock,
  setAddPersonInfo,
  addPersonInfo,
  setFormBlock,
  setAddingType,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);

  //Добавление человека
  const handleAddPerson = (e, pol, type) => {
    setAddingType(type);
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/tree/node/${center.nodeId}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      addPersonInfo.pol = pol;
      addPersonInfo.teip = center.teip;
      if (type === "sister" || type === "brother") {
        setAddPersonInfo({
          ...addPersonInfo,
          source_id: res.data.family.father?.id,
          source: "child",
          f_id: res.data.family.father?.id ? res.data.family.father?.id : "",
          f_fio: res.data.family.father?.fio ? res.data.family.father?.fio : "",
        });
      }
      if (type === "daughter" || type === "son") {
        setAddPersonInfo({
          ...addPersonInfo,
          source_id: center.nodeId,
          source: "child",
          f_id: center.nodeId,
          f_fio: res.data.item.fio,
        });
      }
      if (type === "father") {
        setAddPersonInfo({
          ...addPersonInfo,
          source: "parent",
          source_id: center.nodeId,
        });
      }
      if (type === "spouce") {
        setAddPersonInfo({
          ...addPersonInfo,
          source: "spouce",
          source_id: center.nodeId,
          s_fio: center.fio,
        });
      }
    });
    setFormBlock(true);
  };
  //Закрытие блока
  const handleCloseBlock = (e) => {
    e.stopPropagation();
    setAppenedBlock(false);
  };

  return (
    <div className="blackoutActiv" onClick={handleCloseBlock}>
      <div className="nodesBlock connectionNodesBlock">
        <img
          src={`https://gargalo.ru/${center.avatar}`}
          alt=""
          className="nodesBlockImg"
        />
        <h2 className="nodesBlockFio">{center.fio}</h2>
        {center.born && center.die ? (
          <h2 className="nodesBlockDate">
            {center.born}
            {-center.die}
          </h2>
        ) : !center.born && center.die ? (
          <h2 className="nodesBlockDate">{center.die}</h2>
        ) : center.born && !center.die ? (
          <h2 className="nodesBlockDate">{center.born}</h2>
        ) : (
          <h2 className="nodesBlockDate"></h2>
        )}
        <span className="lineNode lineBottom" />
        {center.f_id === "null" ? (
          <>
            <span className="lineNode lineTop" />
            <button
              className="nodeBtn addFamily4 addFatherKinship"
              onClick={(e) => handleAddPerson(e, 1, "father")}
            >
              <img src={man} alt="" />
              Добавить отца
            </button>
          </>
        ) : (
          <>
            <span className="lineNode lineRight" />
            <button
              className="nodeBtn addFamily1"
              onClick={(e) => handleAddPerson(e, 2, "sister")}
            >
              <img src={woman} alt="" />
              Добавить сестру
            </button>
            <button
              className="nodeBtn addFamily2"
              onClick={(e) => handleAddPerson(e, 1, "brother")}
            >
              <img src={man} alt="" />
              Добавить брата
            </button>
          </>
        )}
        {center.type === 0 && center.pol === 1 && center.spouce === "null" && (
          <>
            <span className="lineNode lineLeft" />
            <button
              className="nodeBtn addFamily7"
              onClick={(e) => handleAddPerson(e, 2, "spouce")}
            >
              <img src={woman} alt="" />
              Добавить жену
            </button>
          </>
        )}
        <button
          className="nodeBtn addFamily5"
          onClick={(e) => handleAddPerson(e, 2, "daughter")}
        >
          <img src={woman} alt="" />
          Добавить дочь
        </button>
        <button
          className="nodeBtn addFamily6"
          onClick={(e) => handleAddPerson(e, 1, "son")}
        >
          <img src={man} alt="" />
          Добавить сына
        </button>
      </div>
    </div>
  );
};

export default AppenedBLock;
