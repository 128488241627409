import React from "react";
import FollowersList from "./FollowersList";
import style from "../teyps/followers.module.css";

const Followers = () => {
  return (
    <div className={style.maindiv}>
      <FollowersList />
    </div>
  );
};

export default Followers;
