import axios from "axios";
import React from "react";
import style from "../../messagPage.module.css";
import deleteX from "../../../img/deleteX.svg";
import { useEffect } from "react";
import { useState } from "react";
import Share from "../../img/Share.svg";

const MediaChat = ({
  contact_id,
  setModalMediaChat,
  setForwardChat,
  setMessageIdMass,
  setForwardType,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [media, setMedia] = useState();
  const [zoomMedia, setZoomMedia] = useState({
    url: null,
    id: null,
    active: false,
  });

  useEffect(() => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/messages/loadContactMedia/${contact_id}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setMedia(res.data);
    });
  }, []);

  return (
    <>
      <>
        {zoomMedia.active && (
          <div className={style.mediaFilesZoom}>
            <div className={style.backgroundBlock}></div>
            <div className={style.mediaFilesZoomBlock}>
              <img
                src={`https://gargalo.ru/${zoomMedia.url}`}
                alt=""
                className={style.mediaFilesZoomImg}
              />
              <div
                className={style.mediaFilesZoomClose}
                onClick={() => {
                  setZoomMedia({ url: null, id: null, active: false });
                }}
              >
                <img src={deleteX} alt="" />
              </div>
              <div
                className={style.mediaFilesZoomShare}
                onClick={() => {
                  setMessageIdMass([zoomMedia.id]);
                  setForwardType("forwardmedia?message");
                  setForwardChat(true);
                }}
              >
                <img src={Share} alt="" />
              </div>
            </div>
          </div>
        )}
      </>
      {media && (
        <div className={style.mediaWrap}>
          <div className={style.mediaAmountBlock}>
            <div style={{ display: "flex", marginLeft: 10 }}>
              <h2 className={style.mediaAmount}>Фотографии: {media.photos}</h2>
              <h2 className={style.mediaAmount}>Видео: {media.videos}</h2>
            </div>
            <div
              className={style.closeMedia}
              onClick={() => setModalMediaChat(false)}
            >
              <img src={deleteX} alt="" />
            </div>
          </div>
          <div className={style.mediaFilesBlock}>
            {media.messages?.map((mediaFile) => {
              return (
                <div
                  key={mediaFile.id}
                  className={style.mediaFiles}
                  onClick={() => {
                    setZoomMedia({
                      url: mediaFile.media[0].original,
                      id: mediaFile.id,
                      active: true,
                    });
                  }}
                >
                  {mediaFile.media[0]?.type === "image" ? (
                    <img
                      src={`https://gargalo.ru/${mediaFile.media[0]?.medium}`}
                      alt=""
                    />
                  ) : (
                    <video
                      src={`https://gargalo.ru/${mediaFile.media[0]?.medium}`}
                      alt=""
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default MediaChat;
