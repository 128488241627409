import React, { useState } from "react";
import style from "./postsModal.module.css";

const PostSuccessfulAction = ({ title, setValue }) => {
    // Закрытие модалки при нажатии на область вне модалки
    const handleClickDeleteModal = () => {
        // setDeleteModal(false);
        setValue(false);
        // setActions(false);
    };
    // Остановить закрытие модалки если нажато на модалку
    const handleClickDeleteModalContent = (e) => {
        e.stopPropagation();
    };
    const handleClickCloseModal = () => {
        setValue(false);
    };

    return (
        <>
            <div className={style.modal} onClick={handleClickDeleteModal}>
                <div
                    className={style.modal_content}
                    onClick={handleClickDeleteModalContent}
                >
                    <h2 className={style.modal_suptitle}>
                        Спасибо Вам за <br /> внимательность!
                    </h2>

                    <h4 className={style.modal_subtitle_ssuccesful}>{title}</h4>
                    <button
                        className={
                            style.modal_button_successful +
                            " " +
                            style.button_second
                        }
                        onClick={handleClickCloseModal}
                    >
                        Закрыть
                    </button>
                </div>
            </div>
        </>
    );
};
export default PostSuccessfulAction;
