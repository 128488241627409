import React, { useState, useContext } from "react";
import { RegistrationContext } from "../../../context";
import ReCalendar from "../../../reusedComponents/ReCalendar";
import style from "./StyleAddEvent.module.css";
import ReusedInput from "../../../reusedComponents/ReusedInput";
import PostService from "./../../../../API/postService";
import ReusedDropDownList from "../../../reusedComponents/ReusedDropDownList";
import { useDetectClickOutside } from "react-detect-click-outside";

const EventsFuneral = ({ keys, setOpen, setEventList, setModeratorReview }) => {
  const [event, setEvent] = useState({
    deceasedId: "",
    deceased: "",
    adress: "",
    date: "",
    details: "",
  });
  const [deceased, setDeceased] = useState([]);

  const handleChange = (target) => {
    const { name } = target;
    const { value } = target;
    setEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value.length > 0) {
      if (name === "deceased") {
        PostService.searchUsers(value, `&filter[]=persons`).then((res) =>
          setDeceased(res?.data)
        );
      }
    }
  };

  //Закрытие выпадающего списка
  const handleOpenBlock = (state, setState) => {
    if (!state) {
      PostService.searchUsers("", `&filter[]=persons`).then((res) => {
        setDeceased(res?.data);
        setState(true);
      });
    }
  };
  const [openListBlock, setOpenListBlock] = useState(true);

  const handleCloseListBlock = () => {
    setOpenListBlock(false);
  };

  const filterListRef = useDetectClickOutside({
    onTriggered: handleCloseListBlock,
  });

  // задаваемые стили для переиспользуемых компонентов
  const styleForReusedInputAdress = {
    width: 634,
  };
  const styleReusedDropDownList = {
    width: 400,
  };
  const { user } = useContext(RegistrationContext);

  const handleClick = async (e) => {
    e.preventDefault();
    if (
      user.is_admin ||
      user.is_moder ||
      keys.permission === "family" ||
      keys.permission === "followers"
    ) {
      const data = await PostService.sendEvents(event, keys);
      setEventList((prev) => [data, ...prev]);
      setOpen(false);
    } else {
      PostService.sendEvents(event, keys);
      setOpen(false);
      setModeratorReview(true);
    }
  };

  //Проверка на заполнение
  const isButtonDisabled =
    Object.values(event).some((value) => value === "") || keys.title === "";

  return (
    <div className={style.wedding}>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Умерший</h2>
        <div
          style={{ position: "relative" }}
          ref={filterListRef}
          onClick={() => {
            handleOpenBlock(openListBlock, setOpenListBlock);
          }}
        >
          <ReusedInput
            name="deceased"
            type="text"
            placeholder="Введите ФИО умершего"
            value={event.deceased}
            reusedInputStyle={styleForReusedInputAdress}
            onChange={handleChange}
          />
          {deceased?.length > 0 && openListBlock && (
            <div className={style.groom__list}>
              <ReusedDropDownList
                reusedDropDownListStyle={styleReusedDropDownList}
                data={deceased}
                id="deceasedId"
                name="deceased"
                setState={setEvent}
                setOpenComp={setDeceased}
              />
            </div>
          )}
        </div>
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Дата смерти</h2>
        <ReCalendar
          setState={setEvent}
          state={event}
          stateProps={"date"}
          width={"100%"}
          calendarStyle={{ right: 0, left: "auto" }}
          placeholderText="Введите дату смерти"
        />
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Адрес</h2>
        <ReusedInput
          name="adress"
          type="text"
          width={634}
          placeholder="Введите адрес проведения мероприятия"
          value={event.adress}
          reusedInputStyle={styleForReusedInputAdress}
          onChange={handleChange}
        />
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Подробности</h2>
        <textarea
          name="details"
          id="details"
          className={style.content__textarea}
          placeholder="Напишите подробности мероприятия"
          value={event.details}
          onChange={(e) =>
            handleChange({ name: e.target.name, value: e.target.value })
          }
        ></textarea>
      </div>
      <div className={style.content}>
        <button
          onClick={() => setOpen(false)}
          className={style.content__button__cancel}
        >
          Отмена
        </button>
        <button onClick={handleClick} className={style.content__button} disabled={isButtonDisabled}>
          Добавить
        </button>
        <h4 className={style.content__moder__text}>
          * Событие для тейпа будет опубликовано после расмотрения модератором
        </h4>
      </div>
    </div>
  );
};

export default EventsFuneral;
