import React, { useContext, useState } from "react";
import threePointImage from "../img/add.svg";
import commentsImage from "../img/comment.svg";
import Share from "../img/Share.svg";
import actionsLink from "../img/link.svg";
import actionsError from "../img/Warning.svg";
import Wedding from "./Wedding";
import { Link, useNavigate } from "react-router-dom";
import Fundraising from "./Fundraising";
import Birth from "./Birth";
import Funeral from "./Funeral";
import Other from "./Other";
import ErrorModal from "./ErrorsModal";
import style from "./EventGroup.module.css";
import Notifications from "./Notifications";
import { RegistrationContext } from "../../../context";

const gargalo_src = "https://gargalo.ru";

const Event = ({ data, current }) => {
  const navigate = useNavigate();
  const [eventActions, setEventActions] = useState();
  const { user } = useContext(RegistrationContext);
  const [actionsErrorBlock, setActionsErrorBlock] = useState();
  const textLineFamyle = (value) => {
    switch (value) {
      case "father":
        return "Отцовская линия";
      case "mather":
        return "Материнская линия";
      case "spouce":
        return "Супружеская линия";
      case "subscribers":
        return "Подписчики";
      case "family":
        return "Семья";
      default:
        break;
    }
  };
  const textTypeEvent = (value) => {
    switch (value) {
      case "wedding":
        return " · Свадьба";
      case "fundraising":
        return " · Сбор средств";
      case "birth":
        return " · Рождение";
      case "funeral":
        return " · Похороны";
      case "other":
        return " · Другое";
      default:
        break;
    }
  };

  // Нажатие на три точки евента
  const handleClickComments = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const handleClickLink = (id) => {
    setEventActions(false);
    navigator.clipboard.writeText(`https://react.gargalo.ru/event/${id}`);
  };

  const handleClickErrorsBlock = () => {
    setActionsErrorBlock(actionsErrorBlock ? false : true);
    setEventActions(false);
    document.querySelector("html").style.overflow = "hidden";
  };

  const createDate = (value) => {
    if (!value) {
      return;
    }
    const monthNames = [
      "янв",
      "фев",
      "мар",
      "апр",
      "мая",
      "июня",
      "июля",
      "авг",
      "сен",
      "окт",
      "нояб",
      "дек",
    ];
    const dateApproved = new Date(value);
    let d = dateApproved;
    d = [
      d.getDate(),
      monthNames[d.getMonth() + 1],
      d.getFullYear() + "г",
      d.getHours(),
      d.getMinutes(),
    ];
    return d.slice(0, 3).join(" ") + " в " + d.slice(3).join(":");
  };

  //Маршрутизатор ссылок
  const pathnameLink = (obj) => {
    if (!obj) {
      return "";
    }
    if (obj?.user_id > 0) {
      if (user.user_id !== obj?.user_id) {
        return `/account/${obj?.user_id}`;
      } else {
        return `/profile/${obj?.user_id}`;
      }
    }
    if (obj?.id > 0) {
      return `/tree/node/${obj?.id}`;
    }
    if (!obj.user_id && !obj.id) {
      return "";
    }
    if (
      (obj.user_id < 0 || obj.user_id === 0) &&
      (obj.id < 0 || obj.id === 0)
    ) {
      return "";
    }
  };

  const currentStyle = {
    padding: 0,
    border: "none",
    width: "100%",
    marginBottom: 24,
  };

  return (
    <div className={style.event} style={current && currentStyle}>
      <div className={style.event_suptitle}>
        <div className={style.event_info}>
          <h2 className={style.event_info_text_suptitle}>{data.title}</h2>
          <p className={style.event_info_text_line}>
            {textLineFamyle(data.line)}
            {textTypeEvent(data.action)}
          </p>
        </div>
        <div className={style.event__three_div}>
          <img
            className={style.event_three_point}
            src={threePointImage}
            alt="Kebab"
          />
          <div className={style.actions_list_wrapp}>
            <div className={style.actions_list}>
              <div
                className={style.actions_list_act}
                onClick={() => handleClickLink(data.id)}
              >
                <img
                  src={actionsLink}
                  alt=""
                  className={style.actions_list_image}
                />
                Скопировать ссылку
              </div>
              <div
                className={style.actions_list_act}
                onClick={() => handleClickErrorsBlock()}
              >
                <img
                  src={actionsError}
                  alt=""
                  className={style.actions_list_image}
                />
                Пожаловаться
              </div>
            </div>
          </div>
        </div>
        {actionsErrorBlock && (
          <ErrorModal
            setEventActions={setEventActions}
            setActionsErrorBlock={setActionsErrorBlock}
            eventId={data.id}
          />
        )}
      </div>
      <Link to={pathnameLink(data.user)} className={style.userOrgWrapp}>
        <img
          src={gargalo_src + data?.user?.avatar}
          alt="avatar"
          className={style.event_info_image}
        />
        <div className={style.event_info_text}>
          <h2 className={style.event_info_user_zag}>{data?.user?.nickname}</h2>
          <span className={style.event_title_date}>
            {createDate(data.date_pub)}
          </span>
        </div>
      </Link>
      {data.action === "wedding" && <Wedding data={data} />}
      {data.action === "fundraising" && <Fundraising data={data} />}
      {data.action === "birth" && <Birth data={data} />}
      {data.action === "funeral" && <Funeral data={data} />}
      {data.action === "other" && <Other data={data} />}
      {data.contacts && (
        <>
          <span className={style.eventName} style={{ marginBottom: 4 }}>
            Контактная информация:
          </span>
          <p className={style.eventTextZag} style={{ marginBottom: 8 }}>
            {data.contacts}
          </p>
        </>
      )}
      {data?.details && (
        <>
          <span className={style.eventName} style={{ marginBottom: 4 }}>
            Подробности
          </span>
          <p className={style.eventTextZag} style={{ marginBottom: 8 }}>
            {data.details}
          </p>
        </>
      )}
      <div className={style.event_subtitle}>
        <Notifications data={data} />
        <div
          className={style.event_subtitle_content_two}
          onClick={() => handleClickComments(data.id)}
        >
          <img
            className={style.event_subtitle_bell}
            src={commentsImage}
            alt="Комментарии"
          />
          <h3 className={style.event_subtitle_bell_text}>
            {data.comments_count}
          </h3>
        </div>
        {/* <img
          className={style.event_subtitle_bell}
          style={{ marginLeft: 24, cursor: "pointer" }}
          src={Share}
          alt="Поделиться"
        /> */}
      </div>
    </div>
  );
};

export default Event;
