import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import post from "../redux/features/post";
import users from "./features/users";

export const store = createStore(
  combineReducers({
    post,
    users,
  }),
  composeWithDevTools(applyMiddleware(thunk))
);
