import React, { useState, useContext } from "react";
import style from "./posts.module.css";
import Comments from "../../comments/Comments";
import PlugImage from "../../img/avatar_original.svg";
import likeFalseImage from "../../comments/img/LikeDefault.svg";
import likeHoverImage from "../../comments/img/LikeHover.svg";
import likeTrueImage from "../../comments/img/LikeTrue.svg";
import PostService from "./../../../../API/postService";
import { RegistrationContext } from "../../../context";
import ReInput from "./../../../reusedComponents/ReInput";
import smileImage from "../../img/Emoji.svg";
import sendMessageImage from "../../img/Send.svg";
import Picker from "emoji-picker-react";
import PostUnderComment from "./PostUnderComment";
import { dateConverter } from "../../../utils/dateСonverter";
import ImageKebabMenu from "../../img/Kebab menu.svg";
import ReDropDown from "../../../reusedComponents/ReDropDown";
import editImage from "../../img/Edit.svg";
import deleteImage from "../../img/Delete.svg";
import CloseImage from "../../img/close.svg";
import CheckImage from "../../img/Check.svg";
import { useNavigate } from "react-router-dom";

const PostOneComments = ({ item, type, commentCount, setCommentCount }) => {
  const { user } = useContext(RegistrationContext);
  const [comments, setComments] = useState(item.comment ? item.comment : []);
  const [udnerComments, setUdnerComments] = useState(false);
  const [newCommentTitle, setNewCommentTitle] = useState("");
  const [likeHover, setLikeHover] = useState(false);
  const [emoji, setEmoji] = useState();
  const [emoji2, setEmoji2] = useState();
  const [dropDown, setDropDown] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [editCommentTitle, setEditCommentTitle] = useState("");
  const [commentVisible, setCommentsVisible] = useState(
    type === "manyPosts" ? false : true
  );

  const navigate = useNavigate();

  const handleAddAndDeleteLikes = (type, id) => {
    try {
      PostService.sendCommentLike(type, id);
      setComments((prev) => {
        type === "like" ? (prev[0].likes += 1) : (prev[0].likes -= 1);
        prev[0].is_liked = !prev[0].is_liked;
        return [...prev];
      });
    } catch (error) {
      console.log(error);
    }
  };
  const onEmojiClick = (event, emojiObject) => {
    setNewCommentTitle((prevState) => prevState + emojiObject.emoji);
  };
  const onEmojiClick2 = (event, emojiObject) => {
    setEditCommentTitle((prevState) => prevState + emojiObject.emoji);
  };

  const dataDeclination = (number) => {
    if (number === 1) {
      return number + " ответ";
    } else if (number > 1 && number <= 4) {
      return number + " ответа";
    } else if (number > 4) {
      return number + " ответов";
    }
  };

  const handleClickDeleteComment = () => {
    try {
      PostService.deleteComments(comments[0].id, "post");
      setCommentCount((prev) => prev - 1);
      setComments((prev) =>
        [...prev].filter((item) => item.id !== comments[0].id)
      );
    } catch (error) {}
  };

  const handleClickEditComment = () => {
    setEditComment(true);
    setEditCommentTitle(comments[0].text);
  };

  const handleClickEditCommentTrue = () => {
    setEditComment(false);
    setComments((prev) => {
      prev[0].text = editCommentTitle;
      return [...prev];
    });
    PostService.SendComments(
      editCommentTitle,
      0,
      "post",
      item.id,
      "update",
      comments[0].id
    );
  };
  // Отправить сообшение при клике на enter, а так же если зажать shift то ничего не выйдет

  const onKeyDownForAddComment = (e, parentId) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSendComments();
    }
  };

  const handleSendComments = async () => {
    if (newCommentTitle.length > 0) {
      try {
        const content = await PostService.SendComments(
          newCommentTitle,
          0,
          "post",
          item.id
        );
        setCommentsVisible(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleClickOnNickname = () => {
    if (user.user_id === comments[0].user.user_id) {
      navigate("/profile/" + comments[0].user.user_id);
    } else {
      navigate("/account/" + comments[0].user.user_id);
    }
  };

  return (
    <>
      {commentVisible ? (
        <Comments
          commentID={item.id}
          commentType="post"
          commentCount={commentCount}
          setCommentCount={setCommentCount}
        />
      ) : (
        <>
          {item.comments_count > 0 && comments[0] && (
            <>
              {editComment ? (
                <div style={{ marginTop: 30 }}>
                  {editComment && (
                    <div className={style.comment_edit}>
                      <img
                        className={style.comment_image}
                        src={`https://gargalo.ru/${user.avatar}`}
                        alt=""
                        style={{ marginRight: 12 }}
                      />
                      <ReInput
                        location="start"
                        iconStart={smileImage}
                        classesIcon={style.UnderclassesIcon}
                        classesInput={style.classesInput}
                        classesDiv={style.classesDiv}
                        handleChange={(e) =>
                          setEditCommentTitle(e.target.value)
                        }
                        value={editCommentTitle}
                        placeholder="Написать комментарий"
                        onMouseOver={() => setEmoji2(true)}
                        onMouseOut={() => setEmoji2(false)}
                      />
                      <div className={style.comment_edit_div}>
                        <img
                          className={style.comment_edit_image}
                          onClick={() => setEditComment(false)}
                          src={CloseImage}
                          alt=""
                        />
                        <img
                          style={{ marginLeft: 8 }}
                          onClick={handleClickEditCommentTrue}
                          className={style.comment_edit_image}
                          src={CheckImage}
                          alt=""
                        />
                      </div>
                      {emoji2 && (
                        <div
                          onMouseOver={() => setEmoji2(true)}
                          onMouseOut={() => setEmoji2(false)}
                          style={{ top: "-320px" }}
                          className={
                            style.emoji_content +
                            " " +
                            (emoji2 ? style.emoji_content_active : " ")
                          }
                        >
                          <Picker onEmojiClick={onEmojiClick2} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className={style.comment}>
                  <img
                    onClick={() => handleClickOnNickname()}
                    className={style.comment_image}
                    src={`https://gargalo.ru/${comments[0].user.avatar}`}
                    alt="1"
                  />
                  <div className={style.comment_content}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: 20,
                      }}
                    >
                      <p
                        className={style.comment_name}
                        onClick={() => handleClickOnNickname()}
                      >
                        {comments[0].user.nickname}
                      </p>
                      {user.user_id === comments[0].user.user_id && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "start",
                            position: "relative",
                            width: 40,
                          }}
                          onMouseOver={() => setDropDown(true)}
                          onMouseOut={() => setDropDown(false)}
                        >
                          <img
                            className={style.comment_kebab_menu}
                            src={ImageKebabMenu}
                            alt=""
                          />
                          {dropDown && (
                            <div
                              className={style.ReDropDown}
                              onMouseOver={() => setDropDown(true)}
                              onMouseOut={() => setDropDown(false)}
                            >
                              <ReDropDown
                                data={[
                                  {
                                    title: "Редактировать",
                                    icon: editImage,
                                    handleClick: handleClickEditComment,
                                  },
                                  {
                                    title: "Удалить",
                                    icon: deleteImage,
                                    handleClick: handleClickDeleteComment,
                                  },
                                ]}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <p className={style.comment_text}>{comments[0].text}</p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 12,
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {user?.is_real && (
                          <button
                            className={style.comment_button}
                            onClick={() => setUdnerComments((prev) => !prev)}
                          >
                            Ответить
                          </button>
                        )}
                        {comments[0].childs_count > 0 && (
                          <p
                            onClick={() => setUdnerComments((prev) => !prev)}
                            style={{ cursor: "pointer" }}
                            className={style.comment_time}
                            // onClick={() => handleAddUnderComments()}
                          >
                            {dataDeclination(comments[0].childs_count)}
                          </p>
                        )}
                        <p className={style.comment_time}>
                          {dateConverter(comments[0].created_at)}
                        </p>
                      </div>

                      <div className={style.comment_likes_div}>
                        {comments[0].likes > 0 ? (
                          <>
                            <img
                              onClick={() =>
                                handleAddAndDeleteLikes(
                                  comments[0].is_liked ? "unlike" : "like",
                                  comments[0].id
                                )
                              }
                              src={
                                comments[0].is_liked
                                  ? likeTrueImage
                                  : likeHoverImage
                              }
                              className={style.comment_likes}
                              alt=""
                            />
                            <p className={style.comments_likes_count}>
                              {comments[0].likes}
                            </p>
                          </>
                        ) : (
                          <img
                            onClick={() =>
                              handleAddAndDeleteLikes(
                                comments[0].is_liked ? "unlike" : "like",
                                comments[0].id
                              )
                            }
                            onMouseOver={() => setLikeHover(true)}
                            onMouseOut={() => setLikeHover(false)}
                            src={
                              comments[0].is_liked
                                ? likeTrueImage
                                : likeHover
                                ? likeHoverImage
                                : likeFalseImage
                            }
                            className={style.comment_likes}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {udnerComments && (
                <>
                  {" "}
                  <PostUnderComment
                    source_id={comments[0].source_id}
                    comment_id={comments[0].id}
                    underCount={comments[0].childs_count}
                  />
                  {/* кнопка скрыть вдруг Джабраил скажет "Она же там и так  была" */}
                  {/* <div
                    style={{ marginTop: 15 }}
                    className={style.under_comment_close}
                    onClick={() => setUdnerComments(false)}
                  >
                    Скрыть
                  </div> */}
                </>
              )}

              <>
                {commentCount > 1 && (
                  <button
                    className={style.comment_button}
                    onClick={() => setCommentsVisible(true)}
                    style={{ marginTop: 12 }}
                  >
                    Посмотреть другие комментарии ({commentCount - 1})
                  </button>
                )}
              </>
            </>
          )}
          <div className={style.re_input_div}>
            <img
              className={style.comment_image}
              src={`https://gargalo.ru/${user?.avatar_small}`}
              alt="lol"
              style={{ marginRight: 12 }}
            />
            <ReInput
              location="start"
              location2="end"
              iconStart={smileImage}
              iconEnd={sendMessageImage}
              classesIcon={style.classesIcon}
              onKeyDown={(e) => onKeyDownForAddComment(e, item.id)}
              handleChange={(e) => setNewCommentTitle(e.target.value)}
              value={newCommentTitle}
              placeholder="Написать комментарий"
              onMouseOver={() => setEmoji(true)}
              onMouseOut={() => setEmoji(false)}
              handleClick2={() => handleSendComments(item.id)}
            />
            {emoji && (
              <div
                onMouseOver={() => setEmoji(true)}
                onMouseOut={() => setEmoji(false)}
                className={
                  style.emoji_content +
                  " " +
                  (emoji ? style.emoji_content_active : " ")
                }
              >
                <Picker onEmojiClick={onEmojiClick} />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PostOneComments;
