import React, { useState, useEffect } from "react";
import WhiteCheck from "../../../img/whiteCheck.svg";
import loader from "../../../img/loader.gif";
import style from "./postsVote.module.css";
import PostService from "./../../../../../API/postService";

const VoteList = ({ data, setData, vote }) => {
  const [procent, setProcent] = useState();
  const [loading, setLoading] = useState(false);
  let itemVarId = data.user_vote?.var_id;
  let voteVarId = vote?.var_id;
  useEffect(() => {
    setProcent(Math.round((vote.votes * 100) / data.total));
  }, [data]);

  const handleChooseVoteProcent = () => {
    return `linear-gradient(to right, #CFE3E7 ${procent + "%"}, #EFF5F5 0%)`;
  };

  const handleClickVote = async () => {
    setLoading(true);
    if (!itemVarId) {
      try {
        const response = await PostService.addVote(vote.var_id);
        if (response) {
          setData((prev) => ({ ...prev, ...response }));
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };
  const handleChooseClassesImg = () => {
    return itemVarId === voteVarId
      ? style.vote__check + " " + style.vote__check_active
      : style.vote__check;
  };
  return (
    <div
      className={style.vote__block}
      style={{ background: handleChooseVoteProcent() }}
      onClick={handleClickVote}
    >
      <div className={style.vote}>
        <div className={handleChooseClassesImg()}>
          {itemVarId === voteVarId && (
            <img className={style.vote_image} src={WhiteCheck} alt="Голос" />
          )}
        </div>
        <span className={style.vote_span}>{vote.title}</span>
      </div>
      <span className={style.vote_span}>{procent ? procent : 0}%</span>
    </div>
  );
};

export default VoteList;
