import React from "react";
import style from "./itemChat.module.css";

const ParentMessag = ({ messag, type }) => {
  return (
    <div
      className={style.parentMessagWrapp}
      style={{
        backgroundColor:
          type === "R"
            ? "rgba(37, 137, 159, 0.31)"
            : "rgba(170, 192, 197, 0.13)",
      }}
    >
      {messag.media && messag?.media[0]?.type === "image" ? (
        <img
          src={`https://gargalo.ru/${messag?.media[0]?.path}`}
          alt="Image"
          className={style.parentMedia}
        />
      ) : (
        ""
      )}
      <div className={style.parentBlock}>
        <h2
          className={style.parentNickname}
          style={{ color: type === "R" ? "#FFFFFF" : "#25899F" }}
        >
          {messag.user.nickname}
        </h2>
        {!messag.audio && !messag.media && messag.content && (
          <p
            className={style.parentMessagText}
            style={{ color: type === "R" ? "#CFE3E7" : "#AAC0C5" }}
          >
            {messag.content}
          </p>
        )}
        {!messag.audio && messag.media && !messag.content && (
          <p
            className={style.parentMessagText}
            style={{ color: type === "R" ? "#CFE3E7" : "#AAC0C5" }}
          >
            Медиа
          </p>
        )}
        {!messag.audio && messag.media && messag.content && (
          <p
            className={style.parentMessagText}
            style={{ color: type === "R" ? "#CFE3E7" : "#AAC0C5" }}
          >
            {messag.content}
          </p>
        )}
        {messag.audio && !messag.media && !messag.content && (
          <p
            className={style.parentMessagText}
            style={{ color: type === "R" ? "#FFFFFF" : "#25899F" }}
          >
            Голосове сообщение
          </p>
        )}
      </div>
    </div>
  );
};

export default ParentMessag;
