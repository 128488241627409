import React from "react";
import style from "./validation.module.css";
import ValidationPage from "./ValidationPage";

const Validation = () => {
  return (
    <div className={style.band}>
      <ValidationPage />
    </div>
  );
};

export default Validation;
