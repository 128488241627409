import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import style from "../teyps/teyp.module.css";
import teyp_avatar from "../teyps/img/teyp_avatar.svg";
import Sort from "../teyps/img/Sort.svg";
import Ok from "../teyps/img/Ok.svg";
import axios from "axios";
import { useRef } from "react";
import AddTeypPage from "./AddTeypPage";
import ReButton from "../../reusedComponents/ReButton";
import { useDetectClickOutside } from "react-detect-click-outside";
import dots from "../teyps/img/MeatballsMenu.svg";
import ExternalLink from "../teyps/img/ExternalLink.svg";
import tree from "../teyps/img/TreeLink.svg";
import Edit from "../teyps/img/Edit.svg";
import { useInView } from "react-intersection-observer";

const filters = [
  { name: 1, text: "По имени" },
  { name: 2, text: "По убыванию людей" },
  { name: 3, text: "По возрастанию людей" },
];

const TeypsList = () => {
  const [teypList, setTeypList] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState();
  const [inputValue, setInputValue] = useState("");
  const [teypListSearch, setTeypListSearch] = useState([]);
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [currentTeyp, setCurrentTeyp] = useState({
    type: "add",
    typeName: "Добавить",
    title: "",
    moderator_id: "",
    photo: null,
    parent_id: 1,
    teyp_id: null,
  });
  const { ref: paginRef, inView } = useInView({
    threshold: 1,
    initialInView: true,
  });

  //Получение первой страницы
  useEffect(() => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/teips?page=1&paginate`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setTeypList(res.data.data);
      setNextPageUrl(res.data.next_page_url);
    });
  }, []);

  //Пагинация тейпов
  useEffect(() => {
    if (inView && nextPageUrl && inputValue.length === 0) {
      axios({
        method: "GET",
        url: `${nextPageUrl}&paginate`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      }).then((res) => {
        setTeypList([...teypList, ...res.data.data]);
        setNextPageUrl(res.data.next_page_url);
      });
    }
  }, [inView]);

  //Поиск по тейпам
  useEffect(() => {
    if (inputValue.length > 0) {
      const searchTime = setTimeout(() => {
        axios({
          method: "GET",
          url: `https://gargalo.ru/api/teips/search?title=${inputValue}`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }).then((res) => {
          setTeypListSearch(res.data.data);
        });
      }, 200);
      return () => clearTimeout(searchTime);
    }
  }, [inputValue]);

  //Добавление тейпа
  const [addTeypBlock, setAddTeypBlock] = useState(false);

  const handleOpenAddBlock = () => {
    if (addTeypBlock) {
      setAddTeypBlock(false);
    } else {
      setCurrentTeyp({
        type: "add",
        typeName: "Добавить",
        title: "",
        moderator_id: "",
        photo: null,
        parent_id: 1,
        teyp_id: null,
        moderator_nickname: "",
        parent_title: "",
      });
      setAddTeypBlock(true);
    }
  };

  //Открытие блока с фильтрацией
  const [teypsFilter, setTeypsFilter] = useState({
    block: false,
    type: 1,
  });

  const activTypeText = filters.find(
    (filter) => filter.name === teypsFilter.type
  ).text;

  const handleCloseBlock = () => {
    setTeypsFilter({ ...teypsFilter, block: false });
  };

  const filterRef = useDetectClickOutside({ onTriggered: handleCloseBlock });

  //Шаблон карточки тейпа
  const TeipElement = ({ item }) => {
    return (
      <div className={style.Wrap_teyp} key={item.id}>
        <Link
          to={`/profileTeyps/${item.moderator_id}`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            className={style.teyp_img}
            src={
              item.photo === null
                ? teyp_avatar
                : `https://gargalo.ru${item.photo}`
            }
            alt="Символ тейпа"
          />
          <div className={style.teyp_members}>
            <h2 className={style.teyp_name}>{item.title}</h2>
            <span className={style.teyp_count}>{item.count} чел</span>
          </div>
        </Link>
        <div className={style.teypBarWrapp}>
          <img src={dots} alt="Еще" className={style.teypListDots} />
          <div className={style.teypHoverBar}>
            <ul className={style.teypBarBlock}>
              <li>
                <Link
                  to={`/profileTeyps/${item.moderator_id}`}
                  className={style.teypBarItem}
                >
                  <img
                    src={ExternalLink}
                    alt="Страница"
                    className={style.teypBarIcon}
                  />
                  Страница тейпа
                </Link>
              </li>
              <li>
                <Link to={`/tree/${item.id}`} className={style.teypBarItem}>
                  <img src={tree} alt="Древо" className={style.teypBarIcon} />
                  Древо тейпа
                </Link>
              </li>
              <li
                className={style.teypBarItem}
                onClick={() => {
                  setCurrentTeyp({
                    type: "edit",
                    typeName: "Сохранить",
                    title: item.title,
                    moderator_id: item.moderator_id,
                    photo: item.photo,
                    parent_id: item.parent_id,
                    teyp_id: item.id,
                    moderator_nickname: item.moderator_nickname,
                    parent_title: item.parent_title,
                  });
                  setAddTeypBlock(true);
                }}
              >
                <img
                  src={Edit}
                  alt="Редактирование"
                  className={style.teypBarIcon}
                />
                Редактировать
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={style.teyps_map}>
      <div className={style.single_teyp}>
        <div className={style.teypSearchBlock}>
          <input
            type="text"
            placeholder="Поиск тейпа"
            autoComplete="off"
            className={style.inputSeacrch}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
          <ReButton
            height={40}
            handleClick={handleOpenAddBlock}
            text={"Добавить тейп"}
          />
        </div>
        <div className={style.teypSearchBlock}>
          <h2 className={style.teipSearchZag}>
            Тейпов:{" "}
            {inputValue.length ? teypListSearch.length : teypList.length}
          </h2>
          <div className={style.teipFilterWrapp}>
            <div
              onClick={() => setTeypsFilter({ ...teypsFilter, block: true })}
              ref={filterRef}
              className={style.teipFilterBlock}
            >
              <img src={Sort} alt="Фильтр" className={style.teipFilter} />
              <h2 className={style.teipFilterText}>{activTypeText}</h2>
            </div>
            {teypsFilter.block && (
              <ul className={style.filterDropDown}>
                {filters.map((item) => {
                  return (
                    <li
                      className={style.dropItem}
                      key={item.name}
                      onClick={() =>
                        setTeypsFilter({ block: false, type: item.name })
                      }
                    >
                      <h3 className={style.teipFilterTextDrop}>{item.text}</h3>
                      {item.name === teypsFilter.type && (
                        <img src={Ok} alt="Ok" className={style.filterIcon} />
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        {addTeypBlock && (
          <AddTeypPage
            setAddTeypBlock={setAddTeypBlock}
            addTeypBlock={addTeypBlock}
            currentTeyp={currentTeyp}
            setTeypList={
              inputValue && inputValue.length > 0
                ? setTeypListSearch
                : setTeypList
            }
            teypList={
              inputValue && inputValue.length > 0 ? teypListSearch : teypList
            }
          />
        )}
        {(inputValue.length ? teypListSearch : teypList).map((item) => (
          <TeipElement key={item.id} item={item} />
        ))}
        {nextPageUrl !== null && (
          <div style={{ height: 1 }} ref={paginRef}></div>
        )}
      </div>
    </div>
  );
};

export default TeypsList;
