import React, { useState, useEffect, useRef, useContext } from "react";
import style from "../user.module.css";
import { useDispatch, useSelector } from "react-redux";
import { editUser } from "../../../../redux/features/users";
import ReactInputMask from "react-input-mask";
import styleUser from "./userSettings.module.css";
import TeypSearchPage from "./../../../sign/TeypSearchPage";
import PostService from "./../../../../API/postService";
import UserSearchInput from "./UserSearchInput";
import { useParams } from "react-router-dom";
import { UseClosing } from "./../../../../hooks/useClosing";
import { RegistrationContext } from "../../../context";
import ReButton from "../../../reusedComponents/ReButton";

const UserSettingsMain = ({ avatar }) => {
  const { userId } = useParams();
  const { user } = useContext(RegistrationContext);
  const dispatch = useDispatch();
  const [userAll, setUserAll] = useState();
  const [surname, setSurname] = useState();
  const [name, setName] = useState();
  const [lastname, setLastname] = useState();
  const [nickName, setNickName] = useState();
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [foundUser, setFoundUser] = useState();
  const [answer, setAnswer] = useState("");
  const [answerBoolean, setAnswerBoolean] = useState(false);
  const [teyp, setTeyp] = useState();
  const [gender, setGender] = useState();
  const [dateInput, setDateInput] = useState();
  const [genderBtn, setGenderBtn] = useState(false);
  const [nodeId, setNodeId] = useState();
  const [searchUser, setSearchUser] = useState();

  useEffect(() => {
    PostService.getAllUser(userId).then((res) => setUserAll(res));
  }, [userId]);

  useEffect(() => {
    setSurname(userAll?.f_name);
    setName(userAll?.name);
    setLastname(userAll?.l_name);
    setNickName(userAll?.nickname);
    setUserName(userAll?.username);
    setGender(userAll?.gender);
    setEmail(userAll?.email);
    setPhone(userAll?.phone);
    setFoundUser(
      `${userAll?.node_id} ${userAll?.f_name} ${userAll?.name} ${userAll?.l_name}`
    );
    setDateInput(userAll?.born);
    console.log(userAll);
  }, [userAll, userId]);

  // Пусть будет
  const checkSettings = (obj) => {
    if (obj._status === 200) {
      setAnswer("Заявка отправлена, ждите расмотрение модератора");
      setAnswerBoolean(true);
    } else {
      setAnswer("Не коректные данные");
      setAnswerBoolean(false);
    }
  };

  // Дата рождения
  const handleChangeInput = ({ target }) => {
    setDateInput(target.value);
  };

  // Запрос пользователей для привязки к древу
  const handaleChangeTree = ({ target }) => {
    const { value } = target;
    setFoundUser(value);
    PostService.searchUsers(value).then((res) => setSearchUser(res?.data));
  };

  // Привязка человека к древу

  const handleSend = async () => {
    await dispatch(
      editUser(
        userAll.user_id,
        userName,
        nickName,
        surname,
        name,
        lastname,
        email,
        phone,
        avatar,
        checkSettings,
        teyp,
        gender,
        dateInput,
        nodeId
      )
    );

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const modalRef = useRef();
  UseClosing(modalRef, setGenderBtn);

  return (
    <>
      {userAll && (
        <div>
          <div className={style.settings__fio__block}>
            <h2>ФИО</h2>
            <div className={style.settings__fio}>
              <input
                disabled
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                type="text"
                placeholder="Фамилия"
              />
              <input
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Имя"
              />
              <input
                disabled
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                type="text"
                placeholder="Отчество"
              />
            </div>
          </div>
          <div className={style.settings__contact__block}>
            <h2 style={{ display: "flex" }}>Никнейм</h2>
            <div className={style.settings__nickname}>
              <div style={{ marginRight: 8 }}>
                <input
                  value={nickName}
                  onChange={(e) => setNickName(e.target.value)}
                  type="text"
                  placeholder="Никнейм"
                />
              </div>
            </div>
          </div>
          <div className={styleUser.settings__dop__info}>
            <h2 className={styleUser.settings__dop__info__text}>Пол</h2>
            <div className={styleUser.settings__dop__info__content}>
              <div
                style={{ position: "relative", paddingBottom: 12 }}
                ref={modalRef}
              >
                <button
                  className={styleUser.settings__select}
                  onClick={() => setGenderBtn(genderBtn ? false : true)}
                >
                  {gender == "male" ? "Мужской" : "Женский"}
                </button>
                {genderBtn && (
                  <>
                    <div className={styleUser.settings__button__div}>
                      <span
                        onClick={() => {
                          setGender("male");
                          setGenderBtn(false);
                        }}
                      >
                        Мужской
                      </span>
                      <span
                        onClick={() => {
                          setGender("female");
                          setGenderBtn(false);
                        }}
                      >
                        Женский
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={style.settings__contact__block}>
            <h2 style={{ display: "flex" }}>Дата рождения</h2>
            <div className={styleUser.settings__dop__info__content}>
              <input
                disabled
                className={styleUser.settings__date}
                onChange={handleChangeInput}
                value={dateInput}
                type="date"
              />
            </div>
          </div>
          <div className={style.settings__contact__block}>
            <h2>Телефон</h2>
            <div className={style.settings__contact}>
              <div>
                <ReactInputMask
                  disabled
                  onChange={(e) => setPhone(e.target.value)}
                  maskChar=""
                  type="text"
                  value={phone}
                  className={style.input}
                  mask="+7 (999) 999-99-99"
                  placeholder="Номер телефона"
                />
              </div>
            </div>
          </div>
          <div className={style.settings__contact__block}>
            <h2>E-mail</h2>
            <div className={style.settings__contact}>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="text"
                placeholder="Email"
                disabled
              />
            </div>
          </div>
          <div className={style.settings__contact__block}>
            <h2 style={{ display: "flex" }}>
              {userAll.is_admin && <div>Адрес страницы</div>}
            </h2>
            <div className={style.settings__nickname}>
              <div>
                <input
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  type="text"
                  placeholder="Адрес страницы"
                />
              </div>
            </div>
          </div>
          <div>
            <ReButton
              type={"button"}
              classes="main"
              height={40}
              width={120}
              handleClick={() => handleSend()}
              FWeight={600}
              FSize={14}
              text={"Сохранить"}
              ml={20}
              mt={16}
            />
          </div>

          <div className={style.settings__moder}>
            <span style={{ color: answerBoolean ? "green" : "red" }}>
              {answer}
            </span>
          </div>
          <div className={style.settings__moder}>
            <span>
              Изменения будут внесены только после расмотрения модератором
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default UserSettingsMain;
