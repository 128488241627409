import React, { useState } from "react";
import { RegistrationContext } from "../components/context";

const RegistrationProvider = ({
  children,
  authorization,
  setAuthorization,
  user,
  setUser,
}) => {
  const [data, setData] = useState();
  const [messagesCount, setMessagesCount] = useState(null);
  const [validationsCount, setValidationsCount] = useState(null);
  const [notificationsCount, setNotificationsCount] = useState(null);
  const [eventsCount, setEventsCount] = useState(null);
  const [unApprovedEventsCount, setUnApprovedEventsCount] = useState(null);
  const [complaintsCount, setComplaintsCount] = useState(null);

  const [regInfo, setRegInfo] = useState({
    f_name: "",
    name: "",
    l_name: "",
    teip: null,
    born: "",
    tpl_tree_number: 0,
    phone: "",
    gender: "male",
    email: "",
    password: "",
    password_confirmation: "",
    submit: "1",
  });

  return (
    <RegistrationContext.Provider
      value={{
        regInfo,
        setRegInfo,
        authorization,
        setAuthorization,
        messagesCount,
        setMessagesCount,
        validationsCount,
        setValidationsCount,
        notificationsCount,
        setNotificationsCount,
        eventsCount,
        setEventsCount,
        unApprovedEventsCount,
        setUnApprovedEventsCount,
        complaintsCount,
        setComplaintsCount,
        user,
        setUser,
        data,
        setData,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationProvider;
