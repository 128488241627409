import React, { useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import style from "../teyps/teypprofile.module.css";
import styleUser from "./user.module.css";
import { useEffect } from "react";
import { useState } from "react";
import Posts from "./../band/postComponents/Posts";
import PostService from "./../../../API/postService";
import FamilyInformation from "./userComponents/informationUserFamily/familyInformation";
import ProfilePanel from "./userComponents/profilePanel/profilePanel";
import Loader from "../Loader";
import loader from "../../content/img/loader.gif";
import plugImage from "./img/Lock.svg";
import { RegistrationContext } from "../../context";

const UserAccount = () => {
  const [userInfo, setUserInfo] = useState();
  const [user, setUser] = useState();
  const { id } = useParams();

  const { data, setData } = useContext(RegistrationContext);
  const [page, setPage] = useState(1);
  const [notPosts, setNotPosts] = useState(["true"]);
  const [newPosts, setNewPosts] = useState(false);
  const btnRef = useRef(null);
  useEffect(() => {
    setData();
    PostService.getAllUsersInfo(id)
      .then((us) => {
        setUser(us);
        return us
      })
      .then((us) => {
        // console.log(user);
        if (us?.profile.is_access) {
          PostService.getAllPostsUser(id, page).then((res) =>
            setData(res.data)
          );
        }
      });
  }, [id]);
  useEffect(() => {
    setUserInfo(user);
  }, [user]);
  const plugText =
    "Здесь появятся моменты, которые опубликует пользователь. Делитесь важными событиями и сохраняйте памятные моменты с Gargalo.";
  useEffect(() => {
    if (page > 1) {
      setNewPosts(true);
      PostService.getAllPostsUser(id, page).then((res) => {
        setData((prev) => [...prev, ...res.data]);
        setNotPosts(res.data);
        setNewPosts(false);
      });
    }
  }, [page]);



  useEffect(() => {
    const btn = btnRef.current;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((page) => page + 1);
      }
    });
    if (btn) observer.observe(btn);
    return () => {
      if (btn) observer.unobserve(btn);
    };
  }, [btnRef.current, data]);

  const scrollBarFunction = () => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {userInfo ? (
        <div className={style.profile_wrapp}>
          <ProfilePanel user={user.profile} bool={true} />
        </div>
      ) : (
        <div className={styleUser.plug}></div>
      )}
      {user?.profile.is_access ? (
        data ? (
          <>
            <Posts data={data} typePlug={plugText} />

            {data.length > 9 && (
              <>
                {notPosts.length > 0 ? (
                  <div>
                    <button style={{ opacity: 0 }} ref={btnRef}></button>
                  </div>
                ) : (
                  <div className={styleUser.no_posts_plug}>
                    <button
                      className={styleUser.no_posts_plug_text}
                      onClick={scrollBarFunction}
                    >
                      Вы посмотрели все посты
                    </button>
                  </div>
                )}
              </>
            )}
            {newPosts && (
              <div className={styleUser.loader_div}>
                <div>
                  <img style={{ width: 70, height: 70 }} src={loader} alt="" />
                  <h3 className={styleUser.loader_text}>Загрузка...</h3>
                </div>
              </div>
            )}
          </>
        ) : (
          <div>
            <Loader />
          </div>
        )
      ) : (
        <div className={styleUser.plug}>
          <img className={styleUser.plug__image} src={plugImage} alt="" />
          <h3 className={styleUser.plug__head}>Это закрытый аккаунт!</h3>
          <p className={styleUser.plug__text}>
            Подпишитесь на этот аккаунт, чтобы смотреть моменты и события этого
            пользователя.
          </p>
        </div>
      )}
    </>
  );
};

export default UserAccount;
