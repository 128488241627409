import React from "react";
import style from "../teyps/teyp.module.css";
import TeypsList from "./TeypsList";

const Teyp = () => {
  return (
    <div className={style.band}>
      <TeypsList />
    </div>
  );
};

export default Teyp;
