import React from "react";
import style from "./treeComponents.module.css";
import { Squircle } from "corner-smoothing";
import PostService from "../../../../../API/postService";

const TreeAppendBlock = ({
  center,
  setAppenedBlock,
  setAddPersonInfo,
  addPersonInfo,
  setFormBlock,
  appenedBlock,
  setAddingType,
  setFirstAdd,
  firstAdd,
}) => {
  //Добавление человека
  const handleAddPerson = (pol, type) => {
    setAddingType(type);
    if (center.user_id > 0) {
      PostService.getAllUsersInfo(center.user_id).then((res) => {
        switch (type) {
          case "sister":
          case "brother":
            setAddPersonInfo({
              ...addPersonInfo,
              f: res.family?.father?.fio.split(" ")[0]
                ? res.family?.father?.fio.split(" ")[0]
                : "",
              source_id: center.f_id ? center.f_id : "",
              source: "child",
              f_id: center.f_id ? center.f_id : "",
              f_fio: res.family.father ? res.family.father.fio : "",
              pol: pol,
              teip: center.teip,
            });
            setFirstAdd({
              ...firstAdd,
              f: res.family?.father?.fio.split(" ")[0]
                ? res.family?.father?.fio.split(" ")[0]
                : "",
              source_id: center.f_id ? center.f_id : "",
              source: "child",
              f_id: center.f_id ? center.f_id : "",
              f_fio: res.family.father ? res.family.father.fio : "",
              pol: pol,
              teip: center.teip,
            });
            break;
          case "daughter":
          case "son":
            setAddPersonInfo({
              ...addPersonInfo,
              f: center.fio.split(" ")[0] ? center.fio.split(" ")[0] : "",
              source_id: center.id ? center.id : "",
              source: "child",
              f_id: center.id ? center.id : "",
              f_fio: center.fio ? center.fio : "",
              pol: pol,
              teip: center.teip,
            });
            setFirstAdd({
              ...firstAdd,
              f: center.fio.split(" ")[0] ? center.fio.split(" ")[0] : "",
              source_id: center.id ? center.id : "",
              source: "child",
              f_id: center.id ? center.id : "",
              f_fio: center.fio ? center.fio : "",
              pol: pol,
              teip: center.teip,
            });
            break;
          case "father":
            setAddPersonInfo({
              ...addPersonInfo,
              f: center.fio.split(" ")[0] ? center.fio.split(" ")[0] : "",
              source: "parent",
              source_id: center.id ? center.id : "",
              pol: pol,
              teip: center.teip,
            });
            setFirstAdd({
              ...firstAdd,
              f: center.fio.split(" ")[0] ? center.fio.split(" ")[0] : "",
              source: "parent",
              source_id: center.id ? center.id : "",
              pol: pol,
              teip: center.teip,
            });
            break;
          default:
            setAppenedBlock(false);
            break;
        }
        setFormBlock(true);
      });
    } else if (center.id > 0) {
      PostService.getAllUsersTeyps(center.id).then((res) => {
        switch (type) {
          case "sister":
          case "brother":
            setAddPersonInfo({
              ...addPersonInfo,
              f: res.family?.father?.fio.split(" ")[0]
                ? res.family?.father?.fio.split(" ")[0]
                : "",
              source_id: center.f_id ? center.f_id : "",
              source: "child",
              f_id: center.f_id ? center.f_id : "",
              f_fio: res.family.father ? res.family.father.fio : "",
              pol: pol,
              teip: center.teip,
            });
            setFirstAdd({
              ...firstAdd,
              f: res.family?.father?.fio.split(" ")[0]
                ? res.family?.father?.fio.split(" ")[0]
                : "",
              source_id: center.f_id ? center.f_id : "",
              source: "child",
              f_id: center.f_id ? center.f_id : "",
              f_fio: res.family.father ? res.family.father.fio : "",
              pol: pol,
              teip: center.teip,
            });
            break;
          case "daughter":
          case "son":
            setAddPersonInfo({
              ...addPersonInfo,
              f: center.fio.split(" ")[0] ? center.fio.split(" ")[0] : "",
              source_id: center.id ? center.id : "",
              source: "child",
              f_id: center.id ? center.id : "",
              f_fio: center.fio ? center.fio : "",
              pol: pol,
              teip: center.teip,
            });
            setFirstAdd({
              ...firstAdd,
              f: center.fio.split(" ")[0] ? center.fio.split(" ")[0] : "",
              source_id: center.id ? center.id : "",
              source: "child",
              f_id: center.id ? center.id : "",
              f_fio: center.fio ? center.fio : "",
              pol: pol,
              teip: center.teip,
            });
            break;
          case "father":
            setAddPersonInfo({
              ...addPersonInfo,
              f: center.fio.split(" ")[0] ? center.fio.split(" ")[0] : "",
              source: "parent",
              source_id: center.id ? center.id : "",
              pol: pol,
              teip: center.teip,
            });
            setFirstAdd({
              ...firstAdd,
              f: center.fio.split(" ")[0] ? center.fio.split(" ")[0] : "",
              source: "parent",
              source_id: center.id ? center.id : "",
              pol: pol,
              teip: center.teip,
            });
            break;
          default:
            setAppenedBlock(false);
            break;
        }
        setFormBlock(true);
      });
    }
  };

  //Закрытие блока
  const handleCloseBlock = (e) => {
    e.stopPropagation();
    setAppenedBlock(false);
  };

  return (
    <div
      className={style.appendWrapp}
      onClick={handleCloseBlock}
      style={{
        visibility: appenedBlock ? "visible" : "hidden",
        opacity: appenedBlock ? "1" : "0",
      }}
    >
      {center && (
        <div className={style.appendBlock}>
          <div className={style.closeAppendBlock}></div>
          <button
            onClick={() => !center.f_id && handleAddPerson(1, "father")}
            className={style.appendBtn}
            disabled={!center.f_id ? false : true}
            style={{ marginBottom: 80 }}
          >
            Добавить
            <br />
            отца
          </button>
          <div className={style.appendBlocks} style={{ marginBottom: 60 }}>
            <button
              className={style.appendBtn}
              onClick={() => center.f_id && handleAddPerson(0, "brother")}
              disabled={center.f_id ? false : true}
            >
              Добавить
              <br /> брата \ сестру
            </button>
            <div
              className={style.itemWrapp}
              style={{ border: "2px solid #CFE3E7", width: 132, height: 80 }}
            >
              {center.user_id > 0 && (
                <span className={style.itemExisting}></span>
              )}
              <img
                src={`https://gargalo.ru${center.avatar}`}
                alt="Avatar"
                className={style.itemAvatar}
              />
              <h2 className={style.itemFio}>
                {center.fullfio.split(" ")[0]}
                <br />
                {center.fullfio.split(" ")[1]}
              </h2>
              <span className={style.lineNode + " " + style.lineBottom} />
              <span className={style.lineNode + " " + style.lineTop} />
              <span className={style.lineNode + " " + style.lineRight} />
              <span className={style.lineNode + " " + style.lineLeft} />
            </div>
            <button disabled className={style.appendBtn}>
              Добавить
              <br />
              жену
            </button>
          </div>
          <button
            className={style.appendBtn}
            onClick={() => handleAddPerson(0, "son")}
            disabled={center.pol !== 2 ? false : true}
          >
            Добавить <br />
            сына \ дочь
          </button>
        </div>
        // <div className={style.appendBlock}>
        //   <div className={style.closeAppendBlock}></div>
        //   <Squircle
        //     cornerSmoothing={0.6}
        //     cornerRadius={12}
        //     className={style.appendParent}
        //   >
        //     <button
        //       onClick={() => !center.f_id && handleAddPerson(1, "father")}
        //       className={style.appendBtn}
        //       disabled={!center.f_id ? false : true}
        //     >
        //       Добавить
        //       <br />
        //       отца
        //     </button>
        //     <button
        //       // onClick={() => handleAddPerson(1, "mather")}
        //       disabled
        //       className={style.appendBtn}
        //     >
        //       Добавить
        //       <br />
        //       мать
        //     </button>
        //   </Squircle>
        //   <div className={style.appendBlocks}>
        //     <Squircle
        //       cornerSmoothing={0.6}
        //       cornerRadius={12}
        //       className={style.appendBrother}
        //     >
        //       <button
        //         className={style.appendBtn}
        //         onClick={() => center.f_id && handleAddPerson(1, "brother")}
        //         disabled={center.f_id ? false : true}
        //       >
        //         Добавить
        //         <br /> брата
        //       </button>
        //       <button
        //         className={style.appendBtn}
        //         onClick={() => center.f_id && handleAddPerson(2, "sister")}
        //         disabled={center.f_id ? false : true}
        //       >
        //         Добавить
        //         <br /> сестру
        //       </button>
        //     </Squircle>
        //     <div className={style.itemWrapp}>
        //       <img
        //         src={`https://gargalo.ru${center.avatar}`}
        //         alt="Avatar"
        //         className={style.itemAvatar}
        //       />
        //       <Squircle
        //         cornerSmoothing={0.6}
        //         cornerRadius={12}
        //         className={style.appendBlockBorder}
        //       >
        //         <Squircle
        //           cornerSmoothing={0.6}
        //           cornerRadius={12}
        //           className={style.appendBlockCorner}
        //         >
        //           <h2 className={style.itemName}>
        //             {center.fullfio.split(" ")[0]}
        //             <br />
        //             {center.fullfio.split(" ")[1]}
        //           </h2>
        //         </Squircle>
        //       </Squircle>
        //       <span className={style.lineNode + " " + style.lineBottom} />
        //       <span className={style.lineNode + " " + style.lineTop} />
        //       <span className={style.lineNode + " " + style.lineRight} />
        //       <span className={style.lineNode + " " + style.lineLeft} />
        //     </div>
        //     <Squircle cornerSmoothing={0.6} cornerRadius={12}>
        //       <button
        //         onClick={() => handleAddPerson(1, "spouce")}
        //         className={style.appendBtn}
        //       >
        //         Добавить
        //         <br />
        //         жену
        //       </button>
        //     </Squircle>
        //   </div>
        //   <Squircle
        //     cornerSmoothing={0.6}
        //     cornerRadius={12}
        //     className={style.appendChildren}
        //   >
        //     <button
        //       className={style.appendBtn}
        //       onClick={() => handleAddPerson(1, "son")}
        //     >
        //       Добавить <br />
        //       сына
        //     </button>
        //     <button
        //       className={style.appendBtn}
        //       onClick={() => handleAddPerson(2, "daughter")}
        //     >
        //       Добавить
        //       <br /> дочь
        //     </button>
        //   </Squircle>
        // </div>
      )}
    </div>
  );
};

export default TreeAppendBlock;
