import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import usericon from "../../../img/UserIcon.svg";
import { createTag } from "./../../../../function.js";
import PostsStyle from "./postsSwiper.module.css";
import { useNavigate } from "react-router-dom";

const PostsSwiper = ({ item }) => {
  const navigate = useNavigate();
  const [openUserMark, setOpenUserMark] = useState(false);
  const [postId, setPostId] = useState();
  const [markUserId, setMarkUserId] = useState();
  const handleOpenMarkUsers = (id) => {
    setPostId(id);
    let massMark = document.querySelector(`div[data-mark]`);
    if (!openUserMark) {
      setOpenUserMark(true);
    } else {
      setOpenUserMark(false);
    }
    massMark.remove();
  };
  const handleLookMark = (id, top, left, e) => {
    setMarkUserId(id);
    let postBlock = e.currentTarget.parentElement.parentElement;
    let imgBlock = postBlock.querySelector(`div[data-img]`);
    let massMark = document.querySelectorAll(`div[data-mark]`);
    for (let i = 0; i < massMark.length; i++) {
      massMark[i].remove();
    }
    let mark = createTag("div", { class: `${PostsStyle.mark__user}` });
    mark.dataset.mark = true;
    mark.style.left = left + "px";
    mark.style.top = top + "px";
    imgBlock.appendChild(mark);
  };
  const cutDownString = (nickname) => {
    if (nickname.length <= 20) {
      return nickname;
    } else {
      return nickname.split(" ", 2).join(" ") + "...";
    }
  };
  const handleDoubleClick = (id) => {
    navigate(`/account/${id}`);
  };
  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
        }}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        className={PostsStyle.swiper}
        navigation={true}
        modules={[Navigation, Pagination]}
      >
        <>
          {item.media === null ? (
            <></>
          ) : (
            item.media.map((itemImg, indexImg) => {
              return (
                <SwiperSlide key={indexImg} className={PostsStyle.swiperBlock}>
                  {itemImg.type === "image" ? (
                    <div
                      id="mark_id"
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        data-img
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          className={PostsStyle.img__content}
                          src={`https://gargalo.ru${itemImg.link}`}
                        />
                      </div>

                      <div className={PostsStyle.arrow__prev}></div>
                      {item.marked_users && (
                        <div
                          onClick={(e) => handleOpenMarkUsers(item.id, e)}
                          className={PostsStyle.mark__icon}
                        >
                          <img src={usericon} />
                        </div>
                      )}
                      {openUserMark && item.id === postId && (
                        <div className={PostsStyle.mark__container}>
                          {item.marked_users?.map((item, index) => {
                            if (item?.media_index === indexImg)
                              return (
                                <div
                                  key={index}
                                  onClick={(e) =>
                                    handleLookMark(
                                      item.user_id,
                                      item.left_pos,
                                      item.top_pos,
                                      e
                                    )
                                  }
                                  onDoubleClick={() =>
                                    handleDoubleClick(item.user_id)
                                  }
                                  style={
                                    markUserId === item.user_id
                                      ? {
                                          backgroundColor: "#cfe3e7",
                                        }
                                      : {
                                          backgroundColor: "#fff",
                                        }
                                  }
                                  className={PostsStyle.mark__content}
                                >
                                  <img
                                    src={`https://gargalo.ru${item.avatar}`}
                                  />
                                  <div>
                                    <span>{cutDownString(item.nickname)}</span>
                                  </div>
                                </div>
                              );
                          })}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div className={PostsStyle.arrow__next}></div>
                      <video
                        controls
                        className={PostsStyle.img__content}
                        src={`https://gargalo.ru${itemImg.link}`}
                      ></video>
                      <div className={PostsStyle.arrow__prev}></div>
                    </div>
                  )}
                </SwiperSlide>
              );
            })
          )}
        </>
      </Swiper>
    </>
  );
};

export default PostsSwiper;
