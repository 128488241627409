import React, { useContext, useEffect, useState } from "react";
import style from "./approval.module.css";
import ReTabs from "../../reusedComponents/ReTabs";
import Ok from "../img/Ok.svg";
import { useDetectClickOutside } from "react-detect-click-outside";
import SortApp from "../img/Sort.svg";
import Kebabmenu from "../img/Kebabmenu.svg";
import ExternalLink from "../img/Externallink.svg";
import EditApproved from "../img/EditApproved.svg";
import approvedAvatar from "../img/approvedAvatar.svg";
import close from "../img/CloseApp.svg";
import loaderIcon from "../img/loader.gif";
import Request from "../img/Request.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { useInView } from "react-intersection-observer";
import { RegistrationContext } from "../../context";

const filters = [
  { name: "name", text: "По имени" },
  { name: "date", text: "По дате заявки" },
];

const Approval = () => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [approvalTabs, setApprovalTabs] = useState({
    name: "Заявки",
    value: "",
  });
  const [approvalFilter, setApprovalFilter] = useState("name");
  const [openFilterBlock, setOpenFilterBlock] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState();
  const [page, setPage] = useState(2);
  const [approvalMass, setApprovalMass] = useState([]);
  const [loader, setLoader] = useState(true);

  //Получение списка заявок
  useEffect(() => {
    setLoader(true);
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/accounts/list${approvalTabs.value}?filter=${approvalFilter}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    })
      .then((res) => {
        setApprovalMass(res.data.data);
        setNextPageUrl(res.data.next_page_url);
        if (res.data.data.length === 0) {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  }, [approvalTabs, approvalFilter]);

  //Открытие блока с фильтрацией
  const activTypeText = filters.find(
    (filter) => filter.name === approvalFilter
  ).text;

  const handleCloseBlock = () => {
    setOpenFilterBlock(false);
  };

  const filterRef = useDetectClickOutside({ onTriggered: handleCloseBlock });

  //Создание нормальной даты
  const createDate = (value) => {
    if (!value) {
      return;
    }
    const monthNames = [
      "янв",
      "фев",
      "мар",
      "апр",
      "мая",
      "июня",
      "июля",
      "авг",
      "сен",
      "окт",
      "нояб",
      "дек",
    ];
    const dateApproved = new Date(value);
    let d = dateApproved;
    d = [
      d.getDate(),
      monthNames[d.getMonth() + 1],
      d.getFullYear() + "г",
      d.getHours(),
      d.getMinutes(),
    ];
    return " · " + d.slice(0, 3).join(" ") + " в " + d.slice(3).join(":");
  };

  //Создание нормальной даты рождения
  const createBorn = (date) => {
    if (!date) {
      return;
    }
    return date.split("-").reverse().join(".");
  };

  //Одобрение
  const handleApprovedClick = (user_id, node_id) => {
    axios
      .post(
        `https://gargalo.ru/api/accounts/list`,
        { user_id, node_id },
        {
          headers: {
            "content-type": "sign/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      )
      .then(() => {
        setLoader(false);
        setApprovalMass(
          approvalMass.filter((item) => item.user_id !== user_id)
        );
      });
  };

  //Отклонение одобрения
  const handleNotApprovedClick = (user_id) => {
    axios
      .post(
        `https://gargalo.ru/api/accounts/list/ignore`,
        { user_id },
        {
          headers: {
            "content-type": "sign/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      )
      .then(() => {
        setLoader(false);
        setApprovalMass(
          approvalMass.filter((item) => item.user_id !== user_id)
        );
      });
  };

  //Прогрузка новой страницы
  const handleNextPage = () => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/accounts/list${approvalTabs.value}?filter=${approvalFilter}page=${page}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setApprovalMass((prevState) => [...prevState, ...res.data.data]);
      setNextPageUrl(res.data.next_page_url);
      setPage(page + 1);
    });
  };

  //Вариации кнопок
  const ApprovedBtns = ({ item }) => {
    if (approvalTabs.value === "") {
      return (
        <>
          <button
            className={style.approvedBtn}
            onClick={() => handleApprovedClick(item.user_id, item.node_id)}
          >
            Одобрить
          </button>
          <button
            className={style.approvedRejectBtn}
            onClick={() => handleNotApprovedClick(item.user_id)}
          >
            Отклонить
          </button>
        </>
      );
    }
    if (approvalTabs.value === "/supposed") {
      return (
        <button
          className={style.approvedBtn}
          disabled={item.node_id < 0 && true}
          onClick={() =>
            item.node_id && handleApprovedClick(item.user_id, item.node_id)
          }
        >
          Одобрить
        </button>
      );
    }
    if (approvalTabs.value === "/accepted") {
      return (
        <button
          className={style.approvedRejectBtn}
          onClick={() => handleNotApprovedClick(item.user_id)}
        >
          Открепить
        </button>
      );
    }
  };

  //Вариации нижней панели карточки
  const ApprovedTyps = ({ item }) => {
    //Поиск людей при прикреплении
    const [peopleMass, setPeopleMass] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [nextPageUrlPeople, setNextPageUrlPeople] = useState();
    const { user } = useContext(RegistrationContext);
    const [openSearchBlock, setOpenSearchBlock] = useState(false);
    const { ref: paginRef, inView } = useInView({
      threshold: 1,
      initialInView: true,
    });

    useEffect(() => {
      if (searchText.length > 0) {
        const timer = setTimeout(() => {
          axios({
            method: "GET",
            url: `https://gargalo.ru/api/getid?fio=${searchText}&filter=persons&pol=0&teip=${user.teip}$free`,
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token[1]}`,
              "User-Token": 1,
            },
          }).then((res) => {
            setPeopleMass(res.data.data);
            setNextPageUrlPeople(res.data.next_page_url);
          });
        }, 200);
        return () => clearTimeout(timer);
      }
    }, [searchText]);

    //Прогрузка новой страницы
    useEffect(() => {
      if (inView && nextPageUrlPeople && searchText.length === 0) {
        axios({
          method: "GET",
          url: `${nextPageUrl}`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }).then((res) => {
          setPeopleMass((prevState) => [...prevState, ...res.data.data]);
          setNextPageUrlPeople(res.data.next_page_url);
        });
      }
    }, [inView]);

    //Поиск при первом нажатии
    const handleSearchClick = () => {
      if (!openSearchBlock) {
        axios({
          method: "GET",
          url: `https://gargalo.ru/api/getid?fio=&filter=persons&pol=0&teip=${user.teip}$free`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }).then((res) => {
          setPeopleMass(res.data.data);
          setNextPageUrlPeople(res.data.next_page_url);
          setOpenSearchBlock(true);
        });
      }
    };

    //Закрытие блока поиска
    const handleCloseSearchBlock = () => {
      setOpenSearchBlock(false);
    };

    const filterSearchRef = useDetectClickOutside({
      onTriggered: handleCloseSearchBlock,
    });

    if (approvalTabs.value === "") {
      return (
        <div className={style.connectionWrapp}>
          <h2 className={style.connectionZag}>Запрашиваемая связь</h2>
          <Link
            to={`/tree/node/${item.node_id}`}
            className={style.connectionLink}
          >
            {item.user_fio}
            <img
              src={ExternalLink}
              alt="Страница"
              style={{ marginLeft: 8 }}
              className={style.approvedBarIcon}
            />
          </Link>
        </div>
      );
    }

    if (approvalTabs.value === "/supposed") {
      return (
        <div className={style.supposedWrapp}>
          <h2 className={style.supposedZag}>Привязка к карточке</h2>
          {item.node_id < 0 ? (
            <div style={{ position: "relative" }} ref={filterSearchRef}>
              <input
                type="text"
                placeholder="Найти пользователя"
                className={style.inputSearch}
                onChange={(e) =>
                  setSearchText(e.target.value.replace(/[a-zA-Z,0-9]/, ""))
                }
                value={searchText}
                onClick={handleSearchClick}
              />
              {peopleMass.length > 0 && openSearchBlock && (
                <div className={style.userListWrapp}>
                  {peopleMass.map((user) => {
                    return (
                      <div
                        className={style.userBlock}
                        key={user.node_id}
                        onClick={() => {
                          setApprovalMass(
                            approvalMass.map((obj) => {
                              if (obj.user_id === item.user_id) {
                                obj.node_id = user.node_id;
                                obj.selected_node = user;
                              }
                              return obj;
                            })
                          );
                        }}
                      >
                        <img
                          src={`https://gargalo.ru/${user?.avatar}`}
                          alt="Avatar"
                          className={style.userAvatar}
                        />
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h2 className={style.userFio}>{user.fio}</h2>
                          <h2 className={style.userBorn}>
                            {createBorn(user.born)}
                          </h2>
                        </div>
                      </div>
                    );
                  })}
                  <span
                    ref={paginRef}
                    style={{ opacity: 0, height: 1, display: "block" }}
                  ></span>
                </div>
              )}
            </div>
          ) : (
            <div className={style.existingWrapp}>
              <Link
                to={`/tree/node/${item.selected_node?.node_id}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={`https://gargalo.ru${item.selected_node?.avatar}`}
                  alt="avatar"
                  className={style.userAvatar}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h2 className={style.userAddFio}>{item.selected_node.fio}</h2>
                  <h2 className={style.userAddDate}>
                    {createBorn(item.selected_node.born)}
                  </h2>
                </div>
              </Link>
              <img
                src={close}
                alt=""
                className={style.deleteExisting}
                onClick={() => {
                  setApprovalMass(
                    approvalMass.map((obj) => {
                      if (obj.user_id === item.user_id) {
                        obj.node_id = -1;
                        obj.selected_node = null;
                      }
                      return obj;
                    })
                  );
                }}
              />
            </div>
          )}
        </div>
      );
    }
  };

  //Шаблон карточки тейпа
  const ApprovedElement = ({ item }) => {
    return (
      <div className={style.Wrap_approved}>
        <div
          className={style.approvedBlock}
          style={{ marginBottom: approvalTabs.value === "/supposed" && 12 }}
        >
          <Link
            to={`/account/${item.user_id}`}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              className={style.approved_img}
              src={
                item.photo === null
                  ? approvedAvatar
                  : `https://gargalo.ru${item.avatar}`
              }
              alt="Аватар"
            />
            <div className={style.approved_members}>
              <h2 className={style.approved_name}>{item.user_fio}</h2>
              <span className={style.approved_count}>
                {createBorn(item.born)}{" "}
                {approvalTabs.value === "" && createDate(item.created_at)}
              </span>
            </div>
          </Link>
          <div className={style.approvedBtnWrapp}>
            <ApprovedBtns item={item} />
            <div className={style.approvedBarWrapp}>
              <img
                src={Kebabmenu}
                alt="Еще"
                className={style.approvedListDots}
              />
              <div className={style.approvedHoverBar}>
                <ul className={style.approvedBarBlock}>
                  <li>
                    <Link
                      to={`/account/${item.user_id}`}
                      className={style.approvedBarItem}
                    >
                      <img
                        src={ExternalLink}
                        alt="Страница"
                        className={style.approvedBarIcon}
                      />
                      Открыть профиль
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/settingsAccaunt/${item.user_id}`}
                      className={style.approvedBarItem}
                    >
                      <img
                        src={EditApproved}
                        alt="Редактирование"
                        className={style.approvedBarIcon}
                      />
                      Редактировать профиль
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ApprovedTyps item={item} />
      </div>
    );
  };

  //Шаблоны при отсутствии заявок
  const NotApprovedElevent = () => {
    if (loader) {
      return (
        <div className={style.notApprovedWrapp}>
          <img src={loaderIcon} alt="Loading..." className={style.loaderIcon} />
        </div>
      );
    }
    if (approvalTabs.value === "") {
      return (
        <div className={style.notApprovedWrapp}>
          <img src={Request} alt="Request" className={style.notApprovedImg} />
          <p className={style.notApprovedText} style={{ width: 271 }}>
            Заявок на одобрение нет. Здесь появятся заявки пользователей на
            добавление в тейп.
          </p>
        </div>
      );
    }
    if (approvalTabs.value === "/supposed") {
      return (
        <div className={style.notApprovedWrapp}>
          <img src={Request} alt="Request" className={style.notApprovedImg} />
          <p className={style.notApprovedText} style={{ width: 326 }}>
            Предполагаемых пользователей для тейпа нет. Здесь появятся
            неодобренные пользователи, принадлежащие этому тейпу.
          </p>
        </div>
      );
    }
    if (approvalTabs.value === "/accepted") {
      return (
        <div className={style.notApprovedWrapp}>
          <img src={Request} alt="Request" className={style.notApprovedImg} />
          <p className={style.notApprovedText} style={{ width: 277 }}>
            Одобренных пользователей в тейпе нет. Здесь появятся одобренные
            пользователи тейпа.
          </p>
        </div>
      );
    }
  };

  return (
    <div className={style.approvalWrapp}>
      <ReTabs
        width={371}
        text={approvalTabs.name}
        data={[
          {
            title: "Заявки",
            active: approvalTabs.value === "",
            handleClick: () => {
              setApprovalTabs({
                name: "Заявки",
                value: "",
              });
              setApprovalMass([]);
            },
          },
          {
            title: "Предполагаемые",
            active: approvalTabs.value === "/supposed",
            handleClick: () => {
              setApprovalTabs({
                name: "Предполагаемые",
                value: "/supposed",
              });
              setApprovalMass([]);
            },
          },
          {
            title: "Одобренные",
            active: approvalTabs.value === "/accepted",
            handleClick: () => {
              setApprovalTabs({
                name: "Одобренные",
                value: "/accepted",
              });
              setApprovalMass([]);
            },
          },
        ]}
      />
      <div className={style.countAndFilter}>
        <h2 className={style.appSearchZag}>Аккаунты: {approvalMass.length}</h2>
        {approvalTabs.value === "" && (
          <div className={style.approvedFilterWrapp}>
            <div
              onClick={() => setOpenFilterBlock(true)}
              ref={filterRef}
              className={style.approvedFilterBlock}
            >
              <img
                src={SortApp}
                alt="Фильтр"
                className={style.approvedFilter}
              />
              <h2 className={style.approvedFilterText}>{activTypeText}</h2>
            </div>
            {openFilterBlock && (
              <ul className={style.filterDropDown}>
                {filters.map((item) => {
                  return (
                    <li
                      className={style.dropItem}
                      key={item.name}
                      onClick={() => setApprovalFilter(item.name)}
                    >
                      <h3 className={style.approvedFilterTextDrop}>
                        {item.text}
                      </h3>
                      {item.name === approvalFilter.type && (
                        <img src={Ok} alt="Ok" className={style.filterIcon} />
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        )}
      </div>
      {approvalMass.length > 0 ? (
        approvalMass.map((item) => (
          <ApprovedElement key={item.user_id} item={item} />
        ))
      ) : (
        <NotApprovedElevent />
      )}
      {nextPageUrl !== null && (
        <div className={style.nextPageBtn} onClick={handleNextPage}>
          Показать еще
        </div>
      )}
    </div>
  );
};

export default Approval;
