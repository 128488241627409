import React from "react";
import EventsList from "./eventAndFilterEvent/EventsList";
import style from "../events/Events.module.css";

const Events = () => {
    return (
        <div className={style.band} style={{ flexDirection: "column" }}>
            <EventsList />
        </div>
    );
};

export default Events;
