import React from "react";
import style from "./postsModal.module.css";
import ReButton from "./../../../../../reusedComponents/ReButton";

const PostsDeleteModal = ({ setDeleteModal, handleClick, setActions }) => {
  // Закрытие модалки при нажатии на область вне модалки
  const handleClickDeleteModal = () => {
    setDeleteModal(false);
    setActions(false);
  };
  // Остановить закрытие модалки если нажато на модалку
  const handleClickDeleteModalContent = (e) => {
    e.stopPropagation();
  };
  // Удаление поста
  const handleClickDeleteTrue = () => {
    handleClickDeleteModal();
    handleClick();
  };
  //  НЕ удалить пост
  const handleClickDeleteFalse = () => {
    handleClickDeleteModal();
  };
  return (
    <div className={style.modal} onClick={handleClickDeleteModal}>
      <div
        className={style.modal_content}
        onClick={handleClickDeleteModalContent}
      >
        <h2 className={style.modal_suptitle}>Удаление момента</h2>
        <h4 className={style.modal_subtitle}>
          Вы уверены, что хотите удалить данный момент? Отменить это действие
          будет невозможно.
        </h4>
        <div className={style.modal_buttons}>
          <ReButton
            type={"button"}
            classes="third"
            handleClick={handleClickDeleteFalse}
            FWeight={600}
            FSize={14}
            text={"Отмена"}
            height={40}
            width={146}
            padding="12px 41px"
            mr={8}
          />
          <ReButton
            type={"button"}
            classes="main"
            handleClick={handleClickDeleteTrue}
            FWeight={600}
            FSize={14}
            height={40}
            width={146}
            text={"Удалить"}
            padding="12px 40px"
          />
        </div>
      </div>
    </div>
  );
};

export default PostsDeleteModal;
