import React, { useState, useEffect } from "react";
import Event from "./eventGroup/Event";
import styleCurrent from "./CurrentStyle.module.css";
import Loader from "../Loader";
import PostService from "../../../API/postService";
import { useParams } from "react-router-dom";
import Comments from "../comments/Comments";

const CurrentEventBlock = () => {
  const { commentId } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    PostService.getAllEvent(commentId).then((res) => setData(res));
  }, []);

  const [commentCount, setCommentCount] = useState(data && data.comments_count);

  return (
    <>
      <div className={styleCurrent.coms_div}>
        {data ? (
          <div className={styleCurrent.commentsWrapp}>
            <Event data={data} current={true} />
            <Comments
              commentType="event"
              commentID={commentId}
              commentCount={commentCount}
              setCommentCount={setCommentCount}
            />
          </div>
        ) : (
          <div className={styleCurrent.Loader}>
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

export default CurrentEventBlock;
