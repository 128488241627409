import React from "react";
import styleFollowers from "./followers.module.css";
import { Link } from "react-router-dom";
import PostService from "../../../../../API/postService";
import { useNavigate } from "react-router-dom";

const FollowersBlockList = ({
    changingLikesCounter,
    boolReused,
    user,
    item,
    index,
    setFollowers,
    setFollowersBool,
}) => {
    const navigate = useNavigate();
    // функция 4 в 1
    const handleAddAndDeleteFollow = () => {
        // Добавление и удаление подписчиков
        PostService.getAllUsersFollowersDelete(item.user.user_id).then((us) =>
            changingLikesCounter("followers", us.followers)
        );
        // получение новых подписчиков
        PostService.getAllUsersFollowers(
            "followers",
            user.id || user.user_id
        ).then((us) => setFollowers(us.data));
    };
    const handleClickUserNickname = () => {
        navigate(`/account/${item.user.user_id}`);
        setFollowersBool(false);
    };
    return (
        <>
            <div key={index} className={styleFollowers.follower__block}>
                <div className={styleFollowers.follow__info}>
                    <img
                        className={styleFollowers.follow__image}
                        src={`https://gargalo.ru/${item.user.avatar}`}
                    />
                    <h2
                        className={styleFollowers.follow__image__text}
                        onClick={handleClickUserNickname}
                    >
                        {item.user.nickname}
                    </h2>
                </div>

                {!boolReused && (
                    <button
                        onClick={() => handleAddAndDeleteFollow()}
                        className={styleFollowers.follow__button}
                    >
                        <span>Удалить</span>
                    </button>
                )}
            </div>
        </>
    );
};

export default FollowersBlockList;
