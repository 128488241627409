import React from "react";
import playR from "../../../img/playR.svg";
import pauseR from "../../../img/pauseR.svg";
import playL from "../../../img/playL.svg";
import pauseL from "../../../img/pauseL.svg";
import style from "./itemChat.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const TypeVoice = ({ type, audio, time }) => {
  const audioElem = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const togglePlay = (e) => {
    e.stopPropagation();
    if (!isPlaying) {
      audioElem.current.play();
    } else {
      audioElem.current.pause();
    }
  };

  useEffect(() => {
    audioElem.current.addEventListener(
      "playing",
      () => {
        setIsPlaying(true);
      },
      false
    );
    audioElem.current.addEventListener(
      "ended",
      () => {
        setIsPlaying(false);
        setProgress(0);
        setCurrentTime(0);
      },
      false
    );
    audioElem.current.addEventListener(
      "pause",
      () => {
        setIsPlaying(false);
      },
      false
    );
    audioElem.current.addEventListener("timeupdate", () => {
      const duration = (audioElem.current && audioElem.current.duration) || 0;
      setCurrentTime(audioElem.current.currentTime);
      setProgress((audioElem.current.currentTime / duration) * 100);
    });
  }, []);

  //Конвертация времени
  const convertCurrentTime = (number) => {
    const mins = Math.floor(number / 60);
    const secs = (number % 60).toFixed();
    return `${mins < 10 ? "0" : ""}${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className={style.audioWrapp}>
      <audio
        ref={audioElem}
        src={`https://gargalo.ru/${audio.path}`}
        preload="auto"
      />
      <div className={style.audioBlock}>
        <button onClick={togglePlay} className={style.audioBtn}>
          {isPlaying ? (
            <img
              src={type === "R" ? pauseR : pauseL}
              alt="Pause svg"
              style={{ width: 8, height: 12 }}
            />
          ) : (
            <img
              src={type === "R" ? playR : playL}
              alt="Play svg"
              style={{ width: 8, height: 12 }}
            />
          )}
        </button>
        <div
          className={style.audioBorder}
          style={{ backgroundColor: type === "R" ? "#FFFFFF" : "#0a6f86af" }}
        >
          <div
            className={style.audioBorderActive}
            style={{
              width: progress + "%",
              backgroundColor: type === "R" ? "#cccccc" : "#054857",
            }}
          />
        </div>
        <span
          className={style.durationText}
          style={{ color: type === "R" ? "#FFFFFF" : "#054857" }}
        >
          {convertCurrentTime(currentTime)}
        </span>
      </div>
      <h3
        className={style.timeText}
        style={{ color: type === "R" ? "#FFFFFF" : "#054857" }}
      >
        {time}
      </h3>
    </div>
  );
};

export default TypeVoice;
