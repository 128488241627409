import React, { useEffect, useState } from "react";
import Comments from "../../comments/Comments";
import { useParams } from "react-router-dom";
import Post from "./Post";
import PostService from "./../../../../API/postService";
import Loader from "../../Loader";

const OnePost = () => {
  const { commentId } = useParams();

  const [post, setPost] = useState();
  useEffect(() => {
    async function fetchData() {
      try {
        const content = await PostService.getAllOnePost(commentId);
        if (content) {
          setPost(content);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      {post ? (
        <div>
          <Post item={post} />
        </div>
      ) : (
        <div style={{ width: 710 }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default OnePost;
