import React from "react";
import styleFamily from "./familyInformation.module.css";
import { Link } from "react-router-dom";
const FamilySiblings = ({userInfo}) => {
    return (
        <>
            {userInfo.family?.siblings && (
                <div className={styleFamily.popupBlock}>
                    <div className={styleFamily.infoDiv}>
                        <h2 className={styleFamily.infoZagBlock}>
                            Братья и сестры
                        </h2>
                        {userInfo.family?.siblings.map((item, index) => {
                            return (
                                <Link
                                    key={index}
                                    className={styleFamily.itemBLock}
                                    to={`/tree/node/${item.id}`}
                                >
                                    <img
                                        src={`https://gargalo.ru/${item.avatar}`}
                                        alt=""
                                        className={styleFamily.itemAvatar}
                                    />
                                    <h2 className={styleFamily.infoText}>
                                        {item.fio}
                                    </h2>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default FamilySiblings;
