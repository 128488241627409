import React from "react";
import style from "../teyps/teypprofile.module.css";
import settingsStyle from "./user.module.css";
import UserSettings from "./userSettings/UserSettings";
import UserIcon from "./img/User.svg";
import WarningIcon from "./img/Warning.svg";
import LockIcon from "./img/Verification.svg";
import NoticeIcon from "./img/Notice.svg";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { RegistrationContext } from "../../context";

const UserSettingsBlock = () => {
  const [category, setCategory] = useState(1);
  const { user } = useContext(RegistrationContext);
  const { userId } = useParams();

  console.log(category);

  return (
    <div className={style.band}>
      <div style={{ marginLeft: 20 }}>
        <UserSettings category={category} />
      </div>
      {userId == user?.user_id && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={settingsStyle.settings__category__block}>
            <div
              onClick={() => setCategory(1)}
              className={`${settingsStyle.settings__category} ${category === 1 ? settingsStyle.settings__category_active : ''}`}
            >
              <img style={{ width: 24, height: 24 }} src={UserIcon} alt="" />
              <span>Основные данные</span>
            </div>
            <div
              onClick={() => setCategory(3)}
              className={`${settingsStyle.settings__category} ${category === 3 ? settingsStyle.settings__category_active : ''}`}
            >
              <img style={{ width: 24, height: 24 }} src={LockIcon} alt="" />
              <span>Безопасность</span>
            </div>
            <div
              onClick={() => setCategory(4)}
              className={`${settingsStyle.settings__category} ${category === 4 ? settingsStyle.settings__category_active : ''}`}
            >
              <img style={{ width: 24, height: 24 }} src={NoticeIcon} alt="" />
              <span>Уведомления</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSettingsBlock;
