import React from "react";
import { Link } from "react-router-dom";
import style from "../../messagPage.module.css";
import add from "../../../img/add.svg";
import HeaderMenu from "./HeaderMenu";
import { useState } from "react";

const HeaderChat = ({
  mySettings,
  setModalType,
  setModalChat,
  setModalMediaChat,
  setComplainChat,
  setSearchMessages,
  setMessageIdMass,
}) => {
  //   console.log(contactSettings);
  return (
    <div className={style.messagPageHead}>
      <Link to={`/account/${mySettings?.contact_id}`}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={style.SenderAvatar}>
            <img
              src={`https://gargalo.ru/${mySettings?.user?.avatar}`}
              alt="Avatar"
            />
          </div>
          <h2 className={style.NameUser}>{mySettings?.user?.nickname}</h2>
          <h2 className={style.messagUser}>был в сети 31 мар в 19:06</h2>
        </div>
      </Link>
      <div className={style.barMessageBox}>
        <img
          src={add}
          alt="add"
          style={{ width: 18, height: 4, cursor: "pointer" }}
        />
        <div className={style.barMessageWrap}>
          <div className={style.barMessage}>
            <HeaderMenu
              contact_id={mySettings?.contact_id}
              setModalType={setModalType}
              setModalChat={setModalChat}
              setModalMediaChat={setModalMediaChat}
              setComplainChat={setComplainChat}
              setSearchMessages={setSearchMessages}
              setMessageIdMass={setMessageIdMass}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderChat;
