import React from "react";
import style from "./templates.module.css";
import avatarDefault from "../../img/avatar.svg";

const FullElement = ({
  avatar,
  fio,
  die,
  born,
  full_born,
  full_die,
  pol,
  nodeId,
  id,
  f_id,
  teip,
  type,
  approved,
  setAppenedBlock,
  setCenter,
  handleSearfClick,
  setButtonType,
  setFormBlock,
  centerMan,
  addPersonInfo,
  setAddPersonInfo,
  searf,
  spouce,
}) => {
  const user_id = document.cookie.match(/id=(.+?)(;|$)/);

  //Стили в зависимости от объекта
  let nodeStyle;
  let textStyle;
  if (approved !== 1) {
    if (type === 2) {
      nodeStyle = {
        borderBottomLeftRadius: "0",
        borderTopLeftRadius: "0",
        border: "1px solid #AAC0C5",
      };
    } else if (type === 1) {
      nodeStyle = {
        borderBottomRightRadius: "0",
        borderTopRightRadius: "0",
        border: "1px solid #AAC0C5",
      };
    }
  } else {
    switch (type) {
      case 2:
        nodeStyle = {
          borderBottomLeftRadius: "0",
          borderTopLeftRadius: "0",
          border: centerMan === nodeId && pol === 2 && "1px solid #edaac2",
          backgroundColor:
            centerMan === nodeId && pol === 2
              ? "#edaac2"
              : centerMan === nodeId && pol === 1
              ? "#25899f"
              : "",
        };
        textStyle = {
          color: centerMan === nodeId && "white",
        };
        break;
      case 1:
        nodeStyle = {
          borderBottomRightRadius: "0",
          borderTopRightRadius: "0",
          border: centerMan === nodeId && pol === 2 && "1px solid #edaac2",
          backgroundColor:
            centerMan === nodeId && pol === 2
              ? "#edaac2"
              : centerMan === nodeId && pol === 1
              ? "#25899f"
              : "",
        };
        textStyle = {
          color: centerMan === nodeId && "white",
        };
        break;
      case 0:
        nodeStyle = {
          border: centerMan === nodeId && pol === 2 && "1px solid #edaac2",
          backgroundColor:
            centerMan === nodeId && pol === 2
              ? "#edaac2"
              : centerMan === nodeId && pol === 1
              ? "#25899f"
              : "",
        };
        textStyle = {
          color: centerMan === nodeId && "white",
        };
        break;
      default:
        break;
    }
  }

  //Открытие блока добавления
  const handleOpenAddBlock = (e, typeBtn) => {
    e.stopPropagation();
    setButtonType(typeBtn);
    setAppenedBlock(true);
    setCenter({
      id: id,
      fio: fio,
      avatar: avatar,
      nodeId: nodeId,
      born: born,
      die: die,
      full_born: full_born,
      full_die: full_die,
      type: type,
      pol: pol,
      f_id: f_id,
      teip: teip,
      spouce: spouce,
    });
  };

  //Открытие блока редактирования
  const handleOpenEditBlock = (e, typeBtn) => {
    e.stopPropagation();
    setButtonType(typeBtn);
    setFormBlock(true);
    setCenter({
      id: id,
      fio: fio,
      avatar: avatar,
      nodeId: nodeId,
      born: born,
      die: die,
      full_born: full_born,
      full_die: full_die,
      type: type,
      pol: pol,
      f_id: f_id,
      teip: teip,
      spouce: spouce,
    });
    setAddPersonInfo({
      ...addPersonInfo,
      pol: pol,
      fio_id: nodeId,
      fio: fio,
      die: die,
      born: born,
      full_born: full_born,
      full_die: full_die,
    });
  };

  return (
    <div
      className={
        pol === 1
          ? style.nodeWrapp
          : style.nodeWrapp + " " + style.nodeWrappWoman
      }
      onClick={() => {
        Number(nodeId) > 0 && searf && handleSearfClick(nodeId);
      }}
      style={nodeStyle}
      title={searf ? "Подгрузить родство" : ""}
    >
      {Number(nodeId) > 0 && (
        <div
          className={centerMan === nodeId ? style.searfActive : style.searf}
        ></div>
      )}
      <img
        src={`https://gargalo.ru${avatar}`}
        className={style.nodeAvatar}
        alt="Аватар"
      />
      <h2 className={style.nodeName} style={textStyle}>
        {fio}
      </h2>
      {born && die ? (
        <h2 className={style.nodeDate} style={textStyle}>
          {born.split("-")[0]}
          {die ? "-" + die.split("-")[0] : ""}
        </h2>
      ) : !born && die ? (
        <h2 className={style.nodeDate} style={textStyle}>
          {die}
        </h2>
      ) : born && !die ? (
        <h2 className={style.nodeDate} style={textStyle}>
          {born}
        </h2>
      ) : (
        <h2 className={style.nodeDate}></h2>
      )}
      <div
        className={style.gender}
        style={{ backgroundColor: pol === 2 && "#f8dce6" }}
      ></div>
      <div className={style.iconDiv}>
        <div
          className={
            pol === 2
              ? style.icon_one + " " + style.iconsBlock + " " + style.womanBlock
              : style.icon_one + " " + style.iconsBlock
          }
          onClick={(e) => handleOpenEditBlock(e, "edit")}
          title="Редактирование человека"
        ></div>
        <div
          className={
            pol === 2
              ? style.icon_who + " " + style.iconsBlock + " " + style.womanBlock
              : style.icon_who + " " + style.iconsBlock
          }
          onClick={(e) => handleOpenAddBlock(e, "add")}
          title="Добавление родста"
        ></div>
        {(id > 0 || Number(nodeId) > 0) && (
          <a
            href={
              id > 0 && Number(user_id[1]) === id
                ? `/profile/${id}`
                : id > 0 && Number(user_id[1]) !== id
                ? `/account/${id}`
                : `/tree/node/${nodeId}`
            }
            className={
              pol === 2
                ? style.icon_whree +
                  " " +
                  style.iconsBlock +
                  " " +
                  style.womanBlock
                : style.icon_whree + " " + style.iconsBlock
            }
            title="Перейти на профиль"
          ></a>
        )}
      </div>
    </div>
  );
};

export default FullElement;
