import React from "react";
import loaderStyle from "./Loader.module.css";
const LoaderBox = () => {
    return (
        <div>
            <div className={loaderStyle.box1}></div>
            <div className={loaderStyle.box2}></div>
            <div className={loaderStyle.box3}></div>
        </div>
    );
};

export default LoaderBox;
