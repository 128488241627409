import React, { useState, useRef } from "react";
import style from "./adding.module.css";
import smiley from "./img/Emoji.svg";

import Picker from "emoji-picker-react";
import { UseClosing } from "./../../../../hooks/useClosing";
import ReInput from "./../../../reusedComponents/ReInput";

const AddingTextField = ({
  postTitle,
  setPostTitle,
  setPickerVisible,
  top,
}) => {
  const [emoji, setEmoji] = useState(false);
  // Блокирование клавищи enter для инпута
  const onKeyDownForAddComment = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
    }
  };
  const handleChange = (e) => {
    setPostTitle(e.target.value);
  };
  const handleClick = () => {
    setEmoji((prev) => !prev);
  };

  const ref = useRef();
  UseClosing(ref, setEmoji);

  return (
    <div className={style.descWrap} ref={ref}>
      <div className={style.descBox}>
        <ReInput
          type="textarea"
          value={postTitle}
          disabled={false}
          iconEnd={smiley}
          location="end"
          handleChange={handleChange}
          handleClick2={handleClick}
          classesIcon={style.re_icon}
          classesDiv={style.re_input_div}
          placeholder="Описание момента... #тег"
        />
      </div>
      {emoji && (
        <div className={style.smileWrap} style={{ top: top }}>
          <Picker
            onEmojiClick={(e, emo) => setPostTitle((prev) => prev + emo.emoji)}
          />
        </div>
      )}
    </div>
  );
};

export default AddingTextField;
