import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "../../user.module.css";
import profileStyle from "./ProfilePanel.module.css";
import FamilyInformation from "../../userComponents/informationUserFamily/familyInformation";
import FollowBlock from "../followers/followBlock";
import FollowersBlock from "../followers/followersBlock";
import PostService from "../../../../../API/postService";
import ActionsTrio from "../actionsTrio/actionsTrio";
import { useParams } from "react-router-dom";
import { RegistrationContext } from "../../../../context";
import ReButton from "../../../../reusedComponents/ReButton";
import ReDropDown from "../../../../reusedComponents/ReDropDown";
import deleteImage from "../../img/Delete.svg";
import cameraIcon from "../../img/CameraIcon.svg";
import Tree from "../../img/Tree.svg";
import Edit from "../../img/Edit.svg";
import Stop from "../../img/Stop.svg";
import Attention from "../../img/Attention.svg";
import image from "../../img/Image.svg";
import { UseClosing } from "../../../../../hooks/useClosing";
import linkImage from "../../../img/linkIcon.svg";
import infoImage from "../../../img/Info.svg";
import PostsComplainModal from "../../../band/postComponents/postTopPanel/postsModal/PostsComplainModal";
import add from "../../../img/Meatballs menu.svg";

const ProfilePanel = ({ user, bool }) => {
  const bigUser = useContext(RegistrationContext);
  const { setUser } = useContext(RegistrationContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const btnRef = useRef(null);
  const [userInfo, setUserInfo] = useState();
  const [followers, setFollowers] = useState();
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const [followersBool, setFollowersBool] = useState(false);
  const [followBool, setFollowBool] = useState(false);
  const [reDropdownActive, setReDropdownActive] = useState(false);
  const [subscribeFollows, setSubscribeFollows] = useState();
  const [subscribeFollowers, setSubscribeFollowers] = useState();
  const [imageServ, setImageServ] = useState(user?.avatar);
  const [complainModal, setComplainModal] = useState(false);
  const [complain, setComplain] = useState();
  const [actions, setActions] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const inputRef = useRef();
  const dotsRef = useRef();
  //Закрытие модалки при клике вне области
  UseClosing(dotsRef, setActions);
  // запрос на информацию о пользователе
  useEffect(() => {
    if (user?.is_real) {
      const fetchData = async () => {
        const response = await PostService.getAllUsersInfo(id);
        setUserInfo(response);
      };
      fetchData();
    }
  }, [id]);

  // функция 2 в 1 (универсал)
  const handleOpenFoll = (state, setState, type, id) => {
    setFollowers();
    //get запрос на получение подписок и подписчиков
    if ((type, id)) {
      PostService.getAllUsersFollowers(type, id).then((us) =>
        setFollowers(us.data)
      );
    }
    // Открытие и закрытие окна
    if (state) {
      setState(false);
    } else {
      setState(true);
    }
  };
  const changingLikesCounter = (type, count) => {
    if (type === "followers") {
      setSubscribeFollowers(count);
    } else if (type === "follows") {
      setSubscribeFollows(count);
    }
  };
  const scrollBarFunction = () => {
    const scrollPos = document.getElementById("post__container");
    window.scroll({
      left: 0,
      top: scrollPos.offsetTop,
      behavior: "smooth",
    });
  };
  const handleClickEditUser = () => {
    navigate(`/settingsAccaunt/${id}`);
  };

  const handleClickChangeAvatar = (e) => {
    e.stopPropagation();
    inputRef.current.click();
  };

  const handleOnChange = async (element) => {
    console.log(element);
    await PostService.addAvatar(id, element).then(() =>
      setAvatar(URL.createObjectURL(element))
    );
    window.location.reload();
  };

  //Удаление аватарки
  const handleClickDeleteAvatar = () => {
    PostService.deleteAvatar(id).then(() => {
      setUser({
        ...user,
        avatar: "/storage/app/upload/default/avatar_medium.svg",
      });
      setImageServ("/storage/app/upload/default/avatar_medium.svg");
    });
    window.location.reload();
  };
  const handleClickCopyLink = () => {
    setActions(false);
    navigator.clipboard.writeText(`https://gargalo.ru/account/${user.user_id}`);
  };
  const handleClickComplain = async () => {
    // setComplainSuccessful(true);
    setComplainModal(true);
    try {
      const { reasons } = await PostService.getAllReasonsComplain();
      setComplain(reasons);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={style.profile_wrapp}>
      <div className={style.profile_maindiv}>
        <div
          className={style.profile_logo}
          onMouseEnter={() => setReDropdownActive(true)}
          onMouseLeave={() => setReDropdownActive(false)}
        >
          <img
            className={style.profile_logo_img}
            src={avatar ? avatar : `https://gargalo.ru${user?.avatar}`}
            alt="Аватарка"
          />
          <input
            ref={inputRef}
            type="file"
            id="inputImgProfile"
            onChange={(e) => handleOnChange(e.target.files[0])}
            className={style.inputFileImg}
          />
          <div>
            {reDropdownActive && (
              <ReDropDown
                data={[
                  {
                    title: "Заменить фото",
                    icon: cameraIcon,
                    handleClick: handleClickChangeAvatar,
                  },
                  {
                    title: "Удалить фото",
                    icon: deleteImage,
                    handleClick: handleClickDeleteAvatar,
                  },
                ]}
              />
            )}
          </div>
          <FollowersBlock
            changingLikesCounter={changingLikesCounter}
            boolReused={bool}
            user={user}
            followers={followers}
            setFollowers={setFollowers}
            followersBool={followersBool}
            setFollowersBool={setFollowersBool}
            btnRef={btnRef}
          />
          <FollowBlock
            changingLikesCounter={changingLikesCounter}
            boolReused={bool}
            user={user}
            followers={followers}
            setFollowers={setFollowers}
            followBool={followBool}
            setFollowBool={setFollowBool}
            btnRef={btnRef}
          />
        </div>

        <div className={style.profile_info}>
          <div className={style.name_and_menu}>
            <div className={style.name}>
              {user.is_real ? (
                <>
                  {userInfo ? (
                    <h2 className={style.nickname}>
                      {userInfo?.profile?.nickname}
                    </h2>
                  ) : (
                    <div className={style.nickname__plug}></div>
                  )}
                </>
              ) : (
                <>
                  <h2>{user?.nickname}</h2>
                </>
              )}
            </div>
            <div ref={dotsRef}>
              {bool && (
                <>
                  <img
                    src={add}
                    alt="dots"
                    style={{ width: 28, cursor: "pointer" }}
                    onClick={() => setActions((e) => !e)}
                  />
                  {actions && (
                    <div
                      className={profileStyle.reDropDown}
                      onClick={() => setActions((e) => !e)}
                    >
                      <ReDropDown
                        data={
                          window.userParam.is_admin
                            ? [
                                {
                                  title: "Копировать ссылку",
                                  icon: linkImage,
                                  handleClick: handleClickCopyLink,
                                },
                                {
                                  title: "Показать в древе",
                                  icon: Tree,
                                  handleClick: handleClickCopyLink,
                                },
                                {
                                  title: "Редактировать профиль",
                                  icon: Edit,
                                  handleClick: handleClickEditUser,
                                },
                                {
                                  title: "Заблокировать",
                                  icon: Stop,
                                  // handleClick: handleClickCopyLink,
                                },
                                {
                                  title: "Пожаловаться",
                                  icon: Attention,
                                  // handleClick: handleClickComplain,
                                },
                              ]
                            : [
                                {
                                  title: "Копировать ссылку",
                                  icon: linkImage,
                                  handleClick: handleClickCopyLink,
                                },
                                {
                                  title: "Показать в древе",
                                  icon: Tree,
                                  // handleClick: handleClickCopyLink,
                                },
                                {
                                  title: "Заблокировать",
                                  icon: Stop,
                                  // handleClick: handleClickCopyLink,
                                },
                                {
                                  title: "Пожаловаться",
                                  icon: Attention,
                                  // handleClick: handleClickComplain,
                                },
                              ]
                        }
                      />
                    </div>
                  )}
                  {complainModal && (
                    <PostsComplainModal
                      data={complain}
                      // item={item}
                      setComplainModal={setComplainModal}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className={style.subs_count}>
            <div
              onClick={() =>
                handleOpenFoll(
                  followersBool,
                  setFollowersBool,
                  "followers",
                  user.id || user.user_id
                )
              }
              className={style.followers}
            >
              {user.is_real ? (
                <>
                  {userInfo ? (
                    <h2>{userInfo?.profile?.followers}</h2>
                  ) : (
                    <div className={style.followers__plug}></div>
                  )}
                </>
              ) : (
                <h2>0</h2>
              )}
              <span>Подписчиков</span>
            </div>
            <div
              onClick={() =>
                handleOpenFoll(
                  followBool,
                  setFollowBool,
                  "follows",
                  user.id || user.user_id
                )
              }
              className={style.subs}
            >
              {user.is_real ? (
                <>
                  {userInfo ? (
                    <h2>{userInfo?.profile?.follows}</h2>
                  ) : (
                    <div className={style.followers__plug}></div>
                  )}
                </>
              ) : (
                <h2>0</h2>
              )}

              <span>Подписок</span>
            </div>
            <div
              onClick={scrollBarFunction}
              style={{ cursor: "pointer" }}
              className={style.posts}
            >
              {user.is_real ? (
                <>
                  {userInfo ? (
                    <h2>{userInfo?.profile?.posts}</h2>
                  ) : (
                    <div className={style.followers__plug}></div>
                  )}
                </>
              ) : (
                <h2>0</h2>
              )}

              <span>Публикаций</span>
            </div>
            {/* {bigUser?.user.is_admin && bigUser?.user.user_id != user.user_id && (
              <button
                className={profileStyle.settings_edit_btn}
                onClick={handleClickEditUser}
              >
                Редактировать
              </button>
            )} */}
          </div>

          {!bool ? (
            <>
              {bigUser?.user.is_real && (
                <div>
                  <ReButton
                    type={"button"}
                    classes="third"
                    handleClick={handleClickEditUser}
                    FWeight={600}
                    FSize={14}
                    text={"Настройки"}
                    height={40}
                    width={123}
                    padding="10px 20px"
                    mr={8}
                  />
                </div>
              )}

              <div
                onClick={() =>
                  handleOpenFoll(additionalInfo, setAdditionalInfo)
                }
                style={{ marginTop: 12 }}
                className={style.info__p}
                href=""
              >
                {additionalInfo && "Свернуть"}
              </div>
            </>
          ) : (
            <>
              <ActionsTrio
                userInfo={userInfo}
                changingLikesCounter={changingLikesCounter}
              />

              <div
                onClick={() =>
                  handleOpenFoll(additionalInfo, setAdditionalInfo)
                }
                style={{ marginTop: 10 }}
                className={style.info__p}
              >
                {additionalInfo && "Свернуть"}
              </div>
            </>
          )}
          {user.is_real && (
            <>
              <div
                className={style.info__p_div}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() =>
                    handleOpenFoll(additionalInfo, setAdditionalInfo)
                  }
                  className={style.info__p}
                >
                  {!additionalInfo && "Подробности"}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {additionalInfo && <FamilyInformation userInfo={userInfo} user={user} />}
      {/* <Storis/> */}
    </div>
  );
};

export default ProfilePanel;
