import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import style from "./connection.module.css";
import { useRef } from "react";
import { Canvas, Edge } from "reaflow";
import {
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import VacuumElement from "./connectionComponents/templates/VacuumElement";
import FullElement from "./connectionComponents/templates/FullElement";
import AppenedBLock from "./connectionComponents/forms/AppenedBLock";
import avatarDefault from "./img/avatar.svg";
import FormBlock from "./connectionComponents/forms/FormBlock";
import Helpers from "./connectionComponents/Helpers";
import { Link } from "react-router-dom";

const Connection = () => {
  const ref = useRef(null);
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [center, setCenter] = useState();
  const [centerMan, setCenterMan] = useState();
  const [appenedBlock, setAppenedBlock] = useState(false);
  const [formBlock, setFormBlock] = useState(false);
  const [helperBlock, setHelperBlock] = useState(false);
  const [settingsBlock, setSettingsBlock] = useState(false);
  const [opentBrother, setOpentBrother] = useState(false);
  const [activProfile, setActivProfile] = useState(false);
  const [addingType, setAddingType] = useState();
  const [buttonType, setButtonType] = useState();
  const [addPersonInfo, setAddPersonInfo] = useState({
    fio_id: "",
    fio: "",
    source_id: "",
    source: "",
    spouce_id: "",
    s_fio: "",
    f_id: "",
    f_fio: "",
    m_id: "",
    m_fio: "",
    die: "",
    born: "",
    full_born: "",
    full_die: "",
    pol: "",
    teip: "",
    phone: "",
    mail: "",
    info: "",
    imgUrl: avatarDefault,
    img: null,
  });

  //Прогрузка родства
  useEffect(() => {
    setNodes([]);
    setEdges([]);
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/v2/gargalo`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    })
      .then((res) => {
        setCenterMan(String(res.data.center));
        //Отделение братьев/сестер центрального элемента из древа
        if (!opentBrother) {
          let centerItem = null;
          Object.values(res.data.items).map((itemCenter) => {
            if (itemCenter.id === res.data.center) {
              centerItem = itemCenter;
            }
          });
          Object.values(res.data.items).map((item) => {
            if (
              (item?.f_id === centerItem?.f_id ||
                item?.m_id === centerItem?.m_id) &&
              item.id !== centerItem.id
            ) {
              item.id = "null";
            }
            return item;
          });
        }
        //Конец
        Object.values(res.data.items).map((item) => {
          item.height = 152;
          item.width = 125;
          if (item.spouce) {
            Object.values(res.data.items).map((itemSpouce) => {
              if (itemSpouce.id === item.spouce) {
                item.width = 250;
                item.spouceFio = itemSpouce.fullfio;
                item.spouceAvatar = itemSpouce.avatar;
                item.spouceBorn = itemSpouce.born;
                item.spouceDie = itemSpouce.die;
                item.spoucePol = itemSpouce.pol;
                item.spouce_full_born = itemSpouce.full_born;
                item.spouce_full_die = itemSpouce.full_die;
                item.spouce_user_id = itemSpouce.user_id;
                item.spouce_id = String(itemSpouce.id);
                item.spouce_f_id = String(itemSpouce.f_id);
                item.spouce_m_id = String(itemSpouce.m_id);
                item.spouce_current_id = itemSpouce.current_id;
                item.spouce_approved = itemSpouce.is_approved;
                itemSpouce.id = "null";
                return itemSpouce;
              }
            });
          }
          item.id = String(item.id);
          item.f_id = String(item.f_id);
          item.m_id = String(item.m_id);
          item.spouce = String(item.spouce);
          item.ports = [
            {
              id: `${item.id}-from`,
              side: "SOUTH",
            },
          ];
          return item;
        });

        setNodes(
          Object.values(res.data.items).filter((item) => item.id !== "null")
        );
        startCombain(
          Object.values(res.data.items),
          Object.keys(res.data.items),
          String(res.data.center)
        );
      })
      .catch((err) => {
        if (
          err.response.data.errors[0] === "Вы не привязаны ни к кому в дереве"
        ) {
          setActivProfile(true);
        }
      });
  }, [opentBrother]);

  //Серфинг по родству
  const handleSearfClick = (id) => {
    setNodes([]);
    setEdges([]);
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/v2/gargalo/0/${id}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setCenterMan(String(res.data.center));
      //Отделение братьев/сестер центрального элемента из древа
      if (!opentBrother) {
        let centerItem = null;
        Object.values(res.data.items).map((itemCenter) => {
          if (itemCenter.id === Number(res.data.center)) {
            centerItem = itemCenter;
          }
        });
        Object.values(res.data.items).map((item) => {
          if (
            (item?.f_id === centerItem?.f_id ||
              item?.m_id === centerItem?.m_id) &&
            item.id !== centerItem.id
          ) {
            item.id = "null";
          }
          return item;
        });
      }
      //Конец
      Object.values(res.data.items).map((item) => {
        item.height = 152;
        item.width = 125;
        if (item.spouce) {
          Object.values(res.data.items).map((itemSpouce) => {
            if (itemSpouce.id === item.spouce) {
              item.width = 250;
              item.spouceFio = itemSpouce.fullfio;
              item.spouceAvatar = itemSpouce.avatar;
              item.spouceBorn = itemSpouce.born;
              item.spouceDie = itemSpouce.die;
              item.spouce_full_born = itemSpouce.full_born;
              item.spouce_full_die = itemSpouce.full_die;
              item.spoucePol = itemSpouce.pol;
              item.spouce_user_id = itemSpouce.user_id;
              item.spouce_id = String(itemSpouce.id);
              item.spouce_f_id = String(itemSpouce.f_id);
              item.spouce_m_id = String(itemSpouce.m_id);
              item.spouce_approved = itemSpouce.is_approved;
              item.spouce_current_id = itemSpouce.current_id;
              itemSpouce.id = "null";
              return itemSpouce;
            }
          });
        }
        item.id = String(item.id);
        item.f_id = String(item.f_id);
        item.m_id = String(item.m_id);
        item.spouce = String(item.spouce);
        item.ports = [
          {
            id: `${item.id}-from`,
            side: "SOUTH",
          },
        ];
        return item;
      });
      setNodes(
        Object.values(res.data.items).filter((item) => item.id !== "null")
      );
      startCombain(Object.values(res.data.items), Object.keys(res.data.items));
    });
  };

  //Создание зависимостей
  const startCombain = (elementMass, items) => {
    // console.log(elementMass);
    elementMass.forEach((element) => {
      if (
        element.spouce_id !== "null" &&
        element.id !== "null" &&
        element.spouce_f_id !== "null" &&
        items.includes(element.spouce_f_id)
      ) {
        setEdges((prevState) => [
          ...prevState,
          {
            id: `${element.spouce_f_id}-${element.id}`,
            from: `${element.spouce_f_id}`,
            to: `${element.id}`,
            fromPort: `${element.spouce_f_id}-from`,
            toPort: `${element.id}-to`,
          },
        ]);
      }

      if (
        element.f_id !== "null" &&
        element.id !== "null" &&
        items.includes(element.f_id)
      ) {
        setEdges((prevState) => [
          ...prevState,
          {
            id: `${element.f_id}-${element.id}`,
            from: `${element.f_id}`,
            to: `${element.id}`,
            fromPort: `${element.f_id}-from`,
            toPort: `${element.id}-to`,
          },
        ]);
      }
    });
  };

  //Открытие блока с настройками
  const handleOpenSettingsBlock = () => {
    if (!settingsBlock) {
      setSettingsBlock(true);
    } else {
      setSettingsBlock(false);
    }
  };

  const TreeElement = (props) => {
    // console.log(props);
    return (
      <foreignObject>
        <div style={{ display: "flex" }}>
          {Number(props.id) > 0 ? (
            <FullElement
              avatar={props.avatar}
              fio={props.fullfio}
              die={props.die}
              full_born={props.full_born}
              full_die={props.full_die}
              born={props.born}
              pol={props.pol}
              nodeId={props.id}
              id={props.user_id}
              f_id={props.f_id}
              teip={props.teip}
              type={props.spouce_id ? 1 : 0}
              approved={props.is_approved}
              setAppenedBlock={setAppenedBlock}
              setCenter={setCenter}
              handleSearfClick={handleSearfClick}
              setButtonType={setButtonType}
              setFormBlock={setFormBlock}
              centerMan={centerMan}
              addPersonInfo={addPersonInfo}
              setAddPersonInfo={setAddPersonInfo}
              searf={true}
              spouce={props.spouce}
            />
          ) : (
            <VacuumElement
              avatar={props.avatar}
              fio={props.fullfio}
              die={props.die}
              born={props.born}
              full_born={props.full_born}
              full_die={props.full_die}
              pol={props.pol}
              nodeId={props.id}
              id={props.user_id}
              f_id={props.f_id}
              m_id={props.m_id}
              teip={props.teip}
              spouce_id={props.spouce_id}
              current_id={props.current_id}
              type={props.spouce_id ? 1 : 0}
              setFormBlock={setFormBlock}
              setButtonType={setButtonType}
              setCenter={setCenter}
              addPersonInfo={addPersonInfo}
              setAddPersonInfo={setAddPersonInfo}
            />
          )}
          {props.spouce_id && (
            <>
              {props.spouce_id > 0 ? (
                <FullElement
                  avatar={props.spouceAvatar}
                  fio={props.spouceFio}
                  die={props.spouceDie}
                  born={props.spouceBorn}
                  full_born={props.spouce_full_born}
                  full_die={props.spouce_full_die}
                  pol={props.spoucePol}
                  nodeId={props.spouce_id}
                  id={props.spouce_user_id}
                  f_id={props.spouce_f_id}
                  teip={props.teip}
                  type={2}
                  approved={props.spouce_approved}
                  setAppenedBlock={setAppenedBlock}
                  setCenter={setCenter}
                  handleSearfClick={handleSearfClick}
                  setButtonType={setButtonType}
                  setFormBlock={setFormBlock}
                  centerMan={centerMan}
                  addPersonInfo={addPersonInfo}
                  setAddPersonInfo={setAddPersonInfo}
                  searf={true}
                />
              ) : (
                <VacuumElement
                  avatar={props.spouceAvatar}
                  fio={props.spouceFio}
                  die={props.spouceDie}
                  born={props.spouceBorn}
                  full_born={props.spouce_full_born}
                  full_die={props.spouce_full_die}
                  pol={props.spoucePol}
                  nodeId={props.spouce_id}
                  id={props.spouce_user_id}
                  f_id={props.spouce_f_id}
                  m_id={props.spouce_m_id}
                  teip={props.teip}
                  spouce_id={props.id}
                  current_id={props.spouce_current_id}
                  type={2}
                  setFormBlock={setFormBlock}
                  setButtonType={setButtonType}
                  setCenter={setCenter}
                  addPersonInfo={addPersonInfo}
                  setAddPersonInfo={setAddPersonInfo}
                />
              )}
            </>
          )}
        </div>
      </foreignObject>
    );
  };

  return (
    <div className={style.band}>
      {activProfile && (
        <div className={style.activBlock}>
          <p className={style.activBlockText}>
            Вы не привязаны ни к кому в дереве.
          </p>
          {/* <Link to="/confirmUser" className={style.activBlockLink}>
            Активировать
          </Link> */}
        </div>
      )}
      {nodes.length > 0 && edges.length > 0 && (
        <TransformWrapper
          wheel={{ step: 0.2 }}
          initialScale={0.5}
          minScale={0.3}
          maxScale={3}
          initialPositionX={0}
          initialPositionY={0}
          limitToBounds={false}
          panning={{ velocityDisabled: true }}
          doubleClick={{ mode: "zoomOut" }}
        >
          <TransformComponent>
            <Canvas
              animated="true"
              ref={ref}
              nodes={nodes}
              edges={edges}
              zoomable={false}
              fit={false}
              arrow={null}
              className={style.canvasWrapp}
              width="max-content"
              height="100%"
              node={(props) => TreeElement(props.properties)}
              edge={<Edge interpolation="linear" className={style.edge} />}
            />
          </TransformComponent>
        </TransformWrapper>
      )}
      {appenedBlock && (
        <AppenedBLock
          center={center}
          setAppenedBlock={setAppenedBlock}
          setAddPersonInfo={setAddPersonInfo}
          addPersonInfo={addPersonInfo}
          setFormBlock={setFormBlock}
          setAddingType={setAddingType}
        />
      )}
      {formBlock && (
        <FormBlock
          center={center}
          setAppenedBlock={setAppenedBlock}
          addPersonInfo={addPersonInfo}
          setAddPersonInfo={setAddPersonInfo}
          setFormBlock={setFormBlock}
          setNodes={setNodes}
          nodes={nodes}
          setEdges={setEdges}
          edges={edges}
          addingType={addingType}
          buttonType={buttonType}
        />
      )}
      {settingsBlock && (
        <div className={style.settingsBlock}>
          {/* <div className={style.settingBlock}>
            <h2>Расположение дерева:</h2>
            <div className={style.settingBtn}>Горизонтальное</div>
            <div className={style.settingBtn}>Вертикальное</div>
          </div> */}
          <div className={style.settingBlock}>
            <h2>Показывать братьев:</h2>
            <div
              className={
                opentBrother
                  ? style.settingBtn + " " + style.settingBtnActiv
                  : style.settingBtn
              }
              onClick={() => {
                setOpentBrother(true);
                setSettingsBlock(false);
              }}
            >
              Да
            </div>
            <div
              className={
                !opentBrother
                  ? style.settingBtn + " " + style.settingBtnActiv
                  : style.settingBtn
              }
              onClick={() => {
                setOpentBrother(false);
                setSettingsBlock(false);
              }}
            >
              Нет
            </div>
          </div>
        </div>
      )}
      <div
        className={style.settingsBtn}
        title="Настройки"
        onClick={handleOpenSettingsBlock}
      ></div>
      <div
        className={style.helperBtn}
        title="Подсказка"
        onClick={() => setHelperBlock(true)}
      >
        ?
      </div>
      {helperBlock && <Helpers setHelperBlock={setHelperBlock} />}
    </div>
  );
};

export default Connection;
