import React, { useState, useEffect } from "react";
import VoteList from "./voteList";
import style from "./postsVote.module.css";
import VoteModal from "../mouseOverAndOut/vote/voteModal";

const PostsVote = ({ item }) => {
    const [data, setData] = useState();
    const [modal, setModal] = useState(false);
    useEffect(() => {
        setData(item.vote_info);
        setData((prev) => ({ ...prev, id: item.id }));
    }, [item]);

    useEffect(() => {
        item.vote_info = { ...data };
    }, [data]);

    const handleMouseOver = () => {
        setModal(true);
    };
    const handleMouseOut = () => {
        setModal(false);
    };

    return (
        <>
            {data?.vars && item.id === data.id && (
                <div className={style.vote__container}>
                    {data.vars.map((vote, index) => (
                        <VoteList key={index} data={data} setData={setData} vote={vote} />
                    ))}

                    <h2
                        className={style.vote__total}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                    >
                        Проголосовало {data.total}
                    </h2>
                    {data.total > 0 && modal && (
                        <VoteModal
                            modal={modal}
                            id={item.id}
                            count={data.total}
                            handleMouseOver={handleMouseOver}
                            handleMouseOut={handleMouseOut}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default PostsVote;
