import React, { useState, useEffect, useContext } from "react";
import style from "./postBottomPanel.module.css";
import likeIconImage from "./img/Like.svg";
import trueLikeImage from "./img/LikeTrue.svg";
import commentImage from "./img/Message.svg";
import uploadSimpleImage from "./img/Share.svg";
import viewImage from "./img/View.svg";
import PostService from "../../../../../API/postService";
import { useNavigate } from "react-router-dom";
import LikesModal from "./LikesModal";
import { RegistrationContext } from "../../../../context";

const PostBottomPanel = ({ item, commentCount }) => {
  const { user } = useContext(RegistrationContext);
  const navigate = useNavigate();
  const [overLikes, setOverLikes] = useState(false);
  const [likesEntity, setLikesEntity] = useState();
  useEffect(() => {
    setLikesEntity(item.is_liked);
  });
  // функция наведения
  const handleMouseOverLikes = () => {
    setOverLikes(true);
  };
  const handleMouseOutLikes = () => {
    setOverLikes(false);
  };

  // измена изначального состояния likes по item
  const addAndDeleteLikes = (ipType) => {
    if (user.is_real) {
      if (ipType === "like") {
        setLikesEntity(true);
        item.is_liked = true;
        item.likes += 1;
        PostService.sendUserLike(ipType, item.id).then((us) => {
          console.log(us);
        });
      } else {
        setLikesEntity(false);
        item.is_liked = false;
        item.likes -= 1;
        PostService.sendUserLike(ipType, item.id).then((us) => {
          console.log(us);
        });
      }
    }
  };

  const hanfleClickCommentImage = () => {
    navigate(`/wall/post/${item.id}`);
  };

  return (
    <>
      <div className={style.post_footer_icons}>
        <div className={style.icons}>
          <div
            className={style.icons_div}
            onMouseOver={handleMouseOverLikes}
            onMouseOut={handleMouseOutLikes}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {item.likes > 0 && overLikes && (
                <LikesModal
                  item={item}
                  modalActive={overLikes}
                  setOverLikes={setOverLikes}
                />
              )}

              <img
                className={style.icon}
                src={likesEntity ? trueLikeImage : likeIconImage}
                onClick={
                  item.is_liked
                    ? () => addAndDeleteLikes("unlike")
                    : () => addAndDeleteLikes("like")
                }
              />
            </div>
            <span className={style.span__icons}>{item.likes}</span>
          </div>

          {item.comments_on === 1 && (
            <div className={style.icons_div}>
              <img
                className={style.icon}
                onClick={hanfleClickCommentImage}
                src={commentImage}
              />
              <span className={style.span__icons}>{commentCount}</span>
            </div>
          )}
          <div className={style.icons_div}>
            <img className={style.icon} src={uploadSimpleImage} />
          </div>
        </div>
        {/* Возможно Джабраил захочет обратно вернуть :( */}
        {/* <div className={style.iconsViews}>
          <img className={style.icon} src={viewImage} />
          <span className={style.span__icons}>{item.views}</span>
        </div> */}
      </div>
    </>
  );
};

export default React.memo(PostBottomPanel);
