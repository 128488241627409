import React, { useContext, useEffect, useRef, useState } from "react";
import style from "./treeComponents.module.css";
import PostService from "../../../../../API/postService";
import { rootControls } from "../../../../../controllers/RootConroller";
import { Link } from "react-router-dom";
import { RegistrationContext } from "../../../../context";

const TreeItem = ({
  wrappRef,
  item,
  setButtonType,
  setAppenedBlock,
  setCenter,
  setFormBlock,
  setAddPersonInfo,
  addPersonInfo,
  setInfoBlock,
  setFirstAdd,
  firstAdd,
  moderatorId,
}) => {
  const elementRef = useRef(null);
  const { user } = useContext(RegistrationContext);

  //Приближение блока к центру
  const [startTransform, setStartTransform] = useState(false);

  useEffect(() => {
    if (startTransform) {
      const child = elementRef.current.getBoundingClientRect();
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const scale = wrappRef.state.scale;
      const translateX = wrappRef.state.positionX;
      const translateY = wrappRef.state.positionY;
      const transformX = translateX + (centerX - child.x - child.width / 2 - 5);
      const transformY =
        translateY + (centerY - child.y - child.height / 2 + 10);
      wrappRef.setTransform(transformX, transformY, 1);
      setAppenedBlock(true);
      setStartTransform(false);
    }
  }, [startTransform]);

  //Открытие блока добавления
  const handleOpenAddBlock = async (e, typeBtn) => {
    e.preventDefault();
    if (wrappRef.state.scale !== 1) {
      wrappRef.resetTransform(0);
    }
    setButtonType(typeBtn);
    setCenter(item);
    setStartTransform(true);
  };

  //Открытие блока редактирования
  const handleOpenEditBlock = (e, typeBtn) => {
    e.preventDefault();
    setButtonType(typeBtn);
    if (item.user_id > 0) {
      PostService.getAllUsersInfo(item.user_id).then((res) => {
        setCenter({ ...item, user_info: res.profile });
        setAddPersonInfo({
          ...addPersonInfo,
          f: item.fio.split(" ")[0] ? item.fio.split(" ")[0] : "",
          i: item.fio.split(" ")[1] ? item.fio.split(" ")[1] : "",
          o: item.fio.split(" ")[2] ? item.fio.split(" ")[2] : "",
          connect_user_id: item.user_id ? item.user_id : "",
          pol: item.pol ? item.pol : "",
          fio_id: item.id ? item.id : "",
          teip: item.teip ? item.teip : "",
          fio: item.fullfio ? item.fullfio : "",
          die: item.die ? item.die : "",
          born: item.born ? item.born : "",
          full_born: item.full_born ? item.full_born : "",
          full_die: item.full_die ? item.full_die : "",
          f_fio: res.family?.father ? res.family.father.fio : "",
          f_id: res.family?.father ? res.family.father.node_id : "",
          m_fio: res.family?.mather ? res.family.mather.fio : "",
          m_id: res.family?.mather ? res.family.mather.node_id : "",
          s_fio: res.family?.spouce ? res.family.spouce.fio : "",
          spouce_id: res.family?.spouce ? res.family.spouce.node_id : "",
          info: item.bio ? item.bio : "",
        });
        setFirstAdd({
          ...firstAdd,
          f: item.fio.split(" ")[0] ? item.fio.split(" ")[0] : "",
          i: item.fio.split(" ")[1] ? item.fio.split(" ")[1] : "",
          o: item.fio.split(" ")[2] ? item.fio.split(" ")[2] : "",
          connect_user_id: item.user_id ? item.user_id : "",
          pol: item.pol ? item.pol : "",
          fio_id: item.id ? item.id : "",
          teip: item.teip ? item.teip : "",
          fio: item.fullfio ? item.fullfio : "",
          die: item.die ? item.die : "",
          born: item.born ? item.born : "",
          full_born: item.full_born ? item.full_born : "",
          full_die: item.full_die ? item.full_die : "",
          f_fio: res.family?.father ? res.family.father.fio : "",
          f_id: res.family?.father ? res.family.father.node_id : "",
          m_fio: res.family?.mather ? res.family.mather.fio : "",
          m_id: res.family?.mather ? res.family.mather.node_id : "",
          s_fio: res.family?.spouce ? res.family.spouce.fio : "",
          spouce_id: res.family?.spouce ? res.family.spouce.node_id : "",
          info: item.bio ? item.bio : "",
        });
        setFormBlock(true);
      });
    } else if (item.id > 0) {
      PostService.getAllUsersTeyps(item.id).then((res) => {
        setCenter(item);
        setAddPersonInfo({
          ...addPersonInfo,
          f: item.fio.split(" ")[0] ? item.fio.split(" ")[0] : "",
          i: item.fio.split(" ")[1] ? item.fio.split(" ")[1] : "",
          o: item.fio.split(" ")[2] ? item.fio.split(" ")[2] : "",
          pol: item.pol ? item.pol : "",
          fio_id: item.id ? item.id : "",
          teip: item.teip ? item.teip : "",
          fio: item.fullfio ? item.fullfio : "",
          die: item.die ? item.die : "",
          born: item.born ? item.born : "",
          full_born: item.full_born ? item.full_born : "",
          full_die: item.full_die ? item.full_die : "",
          f_fio: res.family.father ? res.family.father.fio : "",
          f_id: res.family.father ? res.family.father.node_id : "",
          m_fio: res.family.mather ? res.family.mather.fio : "",
          m_id: res.family.mather ? res.family.mather.node_id : "",
          s_fio: res.family.spouce ? res.family.spouce.fio : "",
          spouce_id: res.family.spouce ? res.family.spouce.node_id : "",
          info: item.bio ? item.bio : "",
        });
        setFirstAdd({
          ...firstAdd,
          f: item.fio.split(" ")[0] ? item.fio.split(" ")[0] : "",
          i: item.fio.split(" ")[1] ? item.fio.split(" ")[1] : "",
          o: item.fio.split(" ")[2] ? item.fio.split(" ")[2] : "",
          pol: item.pol ? item.pol : "",
          fio_id: item.id ? item.id : "",
          teip: item.teip ? item.teip : "",
          fio: item.fullfio ? item.fullfio : "",
          die: item.die ? item.die : "",
          born: item.born ? item.born : "",
          full_born: item.full_born ? item.full_born : "",
          full_die: item.full_die ? item.full_die : "",
          f_fio: res.family.father ? res.family.father.fio : "",
          f_id: res.family.father ? res.family.father.node_id : "",
          m_fio: res.family.mather ? res.family.mather.fio : "",
          m_id: res.family.mather ? res.family.mather.node_id : "",
          s_fio: res.family.spouce ? res.family.spouce.fio : "",
          spouce_id: res.family.spouce ? res.family.spouce.node_id : "",
          info: item.bio ? item.bio : "",
        });
        setFormBlock(true);
      });
    }
  };

  return (
    <Link
      to={
        item.user_id > 0 && user.user_id === item.user_id
          ? `/profile/${item.user_id}`
          : item.user_id > 0
          ? `/account/${item.user_id}`
          : item.id > 0 && `/tree/node/${item.id}`
      }
      className={style.itemWrapp}
      ref={elementRef}
      id={item.id}
      title={item.fio}
    >
      {item.user_id > 0 && <span className={style.itemExisting}></span>}
      <img
        src={`https://gargalo.ru${item.avatar}`}
        alt="Avatar"
        className={style.itemAvatar}
      />
      <span className={style.itemFio}>
        {item.fio.split(" ")[0]}
        <br />
        {item.fio.split(" ")[1]}
      </span>
      {(rootControls("Administrator") ||
        rootControls("TeipModerator", moderatorId)) && (
        <span className={style.iconDiv}>
          <span
            className={style.icon_one + " " + style.iconsBlock}
            onClick={(e) => handleOpenAddBlock(e, "add")}
            title="Добавление человека"
          ></span>
          <span
            className={style.icon_who + " " + style.iconsBlock}
            onClick={(e) => handleOpenEditBlock(e, "edit")}
            title="Редактирование человека"
          ></span>
          <span
            onClick={(e) => {
              e.preventDefault();
              if (item.user_id > 0 || item.id > 0) {
                setCenter(item);
                setInfoBlock(true);
              }
            }}
            title="Информация о человеке"
            className={style.icon_whree + " " + style.iconsBlock}
          ></span>
        </span>
      )}
    </Link>
  );
};

export default TreeItem;
