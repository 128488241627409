import React, { useRef, useState } from "react";
import style from "./reTabs.module.css";
import PropTypes from "prop-types";
import { Squircle } from "corner-smoothing";

const ReTabs = ({
  data = [], // Массив данных
  text,
  width,
  mb,
}) => {
  const [summ, setSumm] = useState(0);
  const ref = useRef();
  const handleChooseClasses = (active) => {
    return style.tabs_btn + " " + (active && style.tabs_active);
  };

  const handleClick = (click, index) => {
    click();
    setSumm(ref.current.children[index].offsetLeft - 4);
  };

  return (
    <div
      className={style.tabs}
      ref={ref}
      style={{ width: width && width, marginBottom: mb && mb }}
    >
      {data.map((item, index) => (
        <div
          key={index}
          className={handleChooseClasses(item.active)}
          onClick={() => handleClick(item.handleClick, index)}
        >
          {item.title}
        </div>
      ))}
      <Squircle
        as="div"
        className={style.tabs_btn_item}
        cornerSmoothing={0.6}
        style={{ left: `${summ + 4}px` }}
      >
        {text}
      </Squircle>
    </div>
  );
};
ReTabs.propTypes = {
  data: PropTypes.array,
};

export default ReTabs;
