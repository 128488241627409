import React from "react";
import style from "./itemChat.module.css";

const TypeMedia = ({ media, time }) => {
  return (
    <div style={{position: "relative"}}>
      {media[0].type === "image" ? (
        <img src={`https://gargalo.ru/${media[0].path}`} alt="" />
      ) : (
        ""
      )}
      <h3
        className={style.mediaTimeText}
        style={{ color: "#FFFFFF", opacity: 1 }}
        // style={{ color: type === "R" ? "#FFFFFF" : "#054857" }}
      >
        {time}
      </h3>
    </div>
  );
};

export default TypeMedia;
