import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import style from "../../messagPage.module.css";

const IsAcceptDialog = ({
  contact_id,
  setContactSettings,
  setMySettings,
  setMessagesList,
}) => {
  const navigate = useNavigate();
  const token = document.cookie.match(/token=(.+?)(;|$)/);

  //Принять диалог
  const handleAcceptDialog = () => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/messages/acceptcontact/${contact_id}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then(() => {
      setMessagesList((prevState) =>
        prevState.map((item) => {
          if (item.contact_id === contact_id) {
            item.is_accept = true;
          }
          return item;
        })
      );
      setContactSettings((prevState) => ({
        ...prevState,
        is_accept: 1,
      }));
    });
  };

  return (
    <div className={style.blockedDisplay}>
      <div className={style.acceptDialogBlock}>
        <h2 className={style.acceptDialogZag}>
          Этот пользователь хочет отправить Вам личное сообщение
        </h2>
        <div className={style.acceptDialogBtnBlock}>
          <button
            className={style.acceptDialogBtn}
            onClick={() => {
              setMySettings((prevState) => ({ ...prevState, blacklist: 1 }));
              navigate("/messages");
            }}
          >
            Отклонить
          </button>
          <button
            className={style.acceptDialogBtn}
            onClick={handleAcceptDialog}
          >
            Принять
          </button>
        </div>
      </div>
    </div>
  );
};

export default IsAcceptDialog;
