import React from "react";
import reply from "../../../img/reply.svg";
import forward from "../../../img/forward.svg";
import Trash from "../../../img/Trash.svg";
import style from "./itemChat.module.css";

const MessagMenu = ({
  messageIdMass,
  setForwardChat,
  setForwardType,
  setDeleteMessages,
  setAnswerBlock,
  setParent_id,
}) => {
  return (
    <div className={style.clickMessagePage}>
      {messageIdMass.length === 1 && (
        <button
          className={style.clickMessagePageBtn}
          onClick={() => {
            setAnswerBlock(true);
            setParent_id(messageIdMass[0]);
          }}
        >
          <img
            src={reply}
            alt="Ответить"
            style={{ width: 20, height: 17, marginRight: 20 }}
          />
          Ответить
        </button>
      )}
      <button
        className={style.clickMessagePageBtn}
        onClick={() => setDeleteMessages(true)}
      >
        Удалить
        <img
          src={Trash}
          alt="Ответить"
          style={{ width: 14, height: 15, marginLeft: 20 }}
        />
      </button>
      <button
        className={style.clickMessagePageBtn}
        onClick={() => {
          setForwardChat(true);
          setForwardType("forwardlist?messages");
        }}
      >
        Переслать
        <img
          src={forward}
          alt="Ответить"
          style={{ width: 20, height: 17, marginLeft: 20 }}
        />
      </button>
    </div>
  );
};

export default MessagMenu;
