import React, { useState, useEffect, useContext } from "react";
import style from "./posts.module.css";
import PostService from "./../../../../API/postService";
import { RegistrationContext } from "../../../context";
import ReInput from "./../../../reusedComponents/ReInput";
import smileImage from "../../img/Emoji.svg";
import sendMessageImage from "../../img/Send.svg";
import Picker from "emoji-picker-react";
import UnderComment from "./UnderComment";
import loader from "../../../content/img/loader.gif";

const PostUnderComment = ({ source_id, comment_id, underCount }) => {
  const { user } = useContext(RegistrationContext);
  const [comments, setComments] = useState([]);
  const [newCommentTitle, setNewCommentTitle] = useState("");
  const [nextPageUrl, setNextPageUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [emoji, setEmoji] = useState();
  const [page, setPage] = useState(2);
  useEffect(() => {
    async function fetchData() {
      try {
        const { data, next_page_url } = await PostService.getAllComments(
          "post",
          source_id,
          1,
          comment_id
        );
        setNextPageUrl(next_page_url);
        setComments(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const onKeyDownForAddComment = (e, parentId) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSendComments();
    }
  };

  const handleSendComments = async () => {
    if (newCommentTitle.length > 0) {
      try {
        const content = await PostService.SendComments(
          newCommentTitle,
          comment_id,
          "post",
          source_id
        );
        setComments((prev) => [...prev, content]);
        setNewCommentTitle("");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    setNewCommentTitle((prevState) => prevState + emojiObject.emoji);
  };

  // пагинация с кнопкой, запрос новых комментов при нажатии на посмотреть еще
  const handleClickNewCommentsButton = async () => {
    setPage((prevState) => prevState + 1);
    setIsLoading(true);
    try {
      const { data, next_page_url } = await PostService.getAllComments(
        "post",
        source_id,
        page,
        comment_id
      );
      setIsLoading(false);
      setNextPageUrl(next_page_url);
      setComments((prev) => [...prev, ...data]);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div className={style.under_comments}>
      {comments.map((comment, index) => (
        <UnderComment
          comment={comment}
          setComments={setComments}
          comment_id={comment_id}
          index={index}
          key={index}
        />
      ))}
      {isLoading && (
        <div className={style.loader}>
          <img className={style.laoder_img} src={loader} alt="" />

          <label htmlFor="" className={style.loader_text}>
            Загрузка...
          </label>
        </div>
      )}
      {nextPageUrl && (
        <button
          className={style.under_comment_new_page}
          onClick={() => handleClickNewCommentsButton()}
        >
          Посмотреть еще ({underCount - comments.length})
        </button>
      )}

      <div className={style.re_input_div}>
        <img
          className={style.comment_image + " " + style.under_comment_image}
          src={`https://gargalo.ru/${user.avatar_small}`}
          alt=""
          style={{ marginRight: 12 }}
        />
        <ReInput
          autoFocus={true}
          location="start"
          location2="end"
          iconStart={smileImage}
          iconEnd={sendMessageImage}
          classesIcon={style.UnderclassesIcon}
          classesInput={style.classesInput}
          classesDiv={style.classesDiv}
          onKeyDown={(e) => onKeyDownForAddComment(e)}
          handleChange={(e) => setNewCommentTitle(e.target.value)}
          value={newCommentTitle}
          placeholder="Написать комментарий"
          onMouseOver={() => setEmoji(true)}
          onMouseOut={() => setEmoji(false)}
          handleClick2={() => handleSendComments()}
        />
        {emoji && (
          <div
            onMouseOver={() => setEmoji(true)}
            onMouseOut={() => setEmoji(false)}
            style={{ marginBottom: "346px" }}
            className={
              style.emoji_content +
              " " +
              (emoji ? style.emoji_content_active : " ")
            }
          >
            <Picker onEmojiClick={onEmojiClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PostUnderComment;
