import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import deleteX from "../../../../img/deleteX.svg";
import forward from "../../../img/forward.svg";
import style from "../../../messagPage.module.css";

const AnswerBlock = ({
  setAnswerBlock,
  setMessageIdMass,
  messages,
  setParent_id,
  parent_id,
}) => {
  const [parentMsg, setParentMsg] = useState();

  useEffect(() => {
    messages.forEach((element) => {
      if (element.id === parent_id) {
        setParentMsg({
          nick: element.user.nickname,
          audio: element.audio,
          media: element.media,
          content: element.content,
        });
      }
    });
  }, []);

  return (
    <>
      {parentMsg && (
        <div className={style.answerBlock}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={forward}
              alt=""
              style={{
                width: 20,
                height: 17,
                marginRight: 10,
              }}
            />
            <h2 className={style.NameAnswerUser}>{parentMsg.nick}</h2>
            {!parentMsg.audio && !parentMsg.media && parentMsg.content && (
              <h2 className={style.textAnwerUser}>{parentMsg.content}</h2>
            )}
            {!parentMsg.audio && parentMsg.media && !parentMsg.content && (
              <h2 className={style.textAnwerUser}>Медиа файл</h2>
            )}
            {!parentMsg.audio && parentMsg.media && parentMsg.content && (
              <h2 className={style.textAnwerUser}>{parentMsg.content}</h2>
            )}
            {parentMsg.audio && !parentMsg.media && !parentMsg.content && (
              <h2 className={style.textAnwerUser}>Голосовое сообщение</h2>
            )}
          </div>
          <img
            src={deleteX}
            alt=""
            style={{
              width: 30,
              height: 30,
              cursor: "pointer",
            }}
            onClick={() => {
              setAnswerBlock(false);
              setMessageIdMass([]);
              setParent_id(-1);
              setParentMsg();
            }}
          />
        </div>
      )}
    </>
  );
};

export default AnswerBlock;
