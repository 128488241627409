import React from "react";
import style from "./information.module.css";

const PersonalData = () => {
  return (
    <div className={style.band}>
      <div className={style.infoWrapp}>
        <div>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta httpEquiv="Content-Style-Type" content="text/css" />
          <meta name="generator" content="Aspose.Words for .NET 22.7.0" />
          <title />
          <div>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "right",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                Приложение № 1{" "}
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "right",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                к политике конфиденциальности проекта «gargalo.ru»
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "right",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                Дата публикации и вступления в силу: «01» июля 2022 г.
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "right",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span
                style={{
                  fontFamily: '"Montserrat"',
                  fontWeight: "bold",
                  AwImport: "ignore",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span
                style={{ fontFamily: '"Montserrat"', fontWeight: "bold" }}
              >
                СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span
                style={{ fontFamily: '"Montserrat"', AwImport: "ignore" }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                Используя интернет-сайт, доступный по уникальному доменному
                имени gargalo.ru (далее – Сайт) и (или) одноименное мобильное
                приложение «Gargalo» (далее – Приложение), совместно именуемые
                «Проект» я заявляю о том, что:
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginLeft: "28.35pt",
                marginBottom: "0pt",
                textIndent: "-14.15pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
                AwImport: "list-item",
                AwListLevelNumber: 0,
                AwListNumberFormat: '""',
                AwListNumberStyles: '"bullet"',
                AwListPaddingSml: "2.15pt",
              }}
            >
              <span style={{ AwImport: "ignore" }}>
                <span style={{ fontFamily: "Symbol" }}></span>
                <span
                  style={{
                    width: "2.15pt",
                    font: '7pt "Montserrat"',
                    display: "inline-block",
                  }}
                >
                  {" "}
                </span>
              </span>
              <span style={{ fontFamily: '"Montserrat"' }}>
                являюсь гражданином РФ, а если не являюсь таковым – обязуюсь
                дополнительно сообщить об этом Администрации Проекта по
                электронной почте{" "}
              </span>
              <a
                href="mailto:info@gagralo.ru"
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontFamily: '"Montserrat"',
                    textDecoration: "underline",
                    color: "#0000ff",
                  }}
                >
                  info@gagralo.ru
                </span>
              </a>
              <span style={{ fontFamily: '"Montserrat"' }}>;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginLeft: "28.35pt",
                marginBottom: "0pt",
                textIndent: "-14.15pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
                AwImport: "list-item",
                AwListLevelNumber: 0,
                AwListNumberFormat: '""',
                AwListNumberStyles: '"bullet"',
                AwListPaddingSml: "2.15pt",
              }}
            >
              <span style={{ AwImport: "ignore" }}>
                <span style={{ fontFamily: "Symbol" }}></span>
                <span
                  style={{
                    width: "2.15pt",
                    font: '7pt "Montserrat"',
                    display: "inline-block",
                  }}
                >
                  {" "}
                </span>
              </span>
              <span style={{ fontFamily: '"Montserrat"' }}>
                я согласен на обработку и распространение Администрацией
                следующих своих персональных данных:
              </span>
            </p>
            <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Аватар (реальное фото или картинка) Пользователя, доступная
                  всем посетителям Проекта;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Никнейм (псеводоним) Пользователя, выбранный им самим при
                  регистрации на Проекте;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Имя Пользователя (отображается в адресе профиля Пользователя);
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  ФИО Пользователя;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Адрес электронной почты Пользователя;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Пароль от Аккаунта Пользователя на Проекте;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Телефон Пользователя;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Дата рождения Пользователя;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Пол пользователя;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Информация о родственниках Пользователя, о его принадлежности
                  к определенному Тейпу;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Изображение Пользователя;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Файлы сookie;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Иные данные, указанные Пользователем на Проекте.
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Текстовые сообщения Пользователя, изображения и иные
                  электронные сообщения Пользователя Проекта;
                </span>
              </li>
              <li
                style={{
                  marginLeft: "49.65pt",
                  textAlign: "justify",
                  lineHeight: "150%",
                  fontFamily: "serif",
                  fontSize: "12pt",
                  AwFontFamily: '"Symbol"',
                  AwFontWeight: "normal",
                  AwNumberFormat: '""',
                }}
              >
                <span style={{ fontFamily: '"Montserrat"' }}>
                  Иные данные, необходимые для исполнения обязательств в рамках
                  Соглашения, сообщенные в процессе взаимодействия с
                  Пользователем Оператором, если необходимость возникла в
                  соответствии с обязательствами.
                </span>
              </li>
            </ul>
            <p
              style={{
                marginTop: "0pt",
                marginLeft: "28.35pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span
                style={{ fontFamily: '"Montserrat"', AwImport: "ignore" }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                Мне известно, и я согласен на предоставление третьим лицам
                идентификационных сведений о моем абонентском номере с целью
                исполнения законных требований об идентификации меня, как
                пользователя сервиса обмена мгновенными сообщениями, каковым
                является Проект в рамках своих некоторых функций. Совершение
                мною регистрации на Проекте является достаточным доказательством
                получения Администрацией моего согласия на предоставление
                третьим лицам идентификационных сведений о моем абонентском
                номере.
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginLeft: "39.15pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span
                style={{ fontFamily: '"Montserrat"', AwImport: "ignore" }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                Я осведомлен и согласен с тем, что мои текстовые сообщения,
                изображения иные электронные сообщения, направленные мной
                посредством Проекта, хранятся на сервере Администрации до 6
                (шести) месяцев с момента окончания их приема, передачи,
                доставки и (или) обработки, независимо от фактического
                использования мною Проекта и (или) отзыва согласия на обработку
                персональных данных. Кроме того, я согласен с тем, что
                информация о фактах приема, передачи, доставки и (или) обработки
                голосовой информации, письменного текста, изображений или иных
                моих электронных сообщений и информации обо мне, позволяющая
                меня идентифицировать (мой абонентский номер подвижной
                радиотелефонной связи), Администрация хранит в течение 1
                (одного) года с момента окончания осуществления таких действий,
                независимо от фактического использования мною Проекта и (или)
                отзыва согласия на обработку персональных данных.
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span
                style={{ fontFamily: '"Montserrat"', AwImport: "ignore" }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                Своей волей и в своих интересах выражаю согласие на
                осуществление Администрацией действий в отношении моих
                персональных данных, которые необходимы или желаемы для
                достижения целей, указанных в Соглашении со всеми его
                неотъемлемыми частями, в том числе выражаю согласие на сбор,
                систематизацию, накопление, хранение, уточнение (обновление,
                изменение), использование, запись на электронные носители и их
                хранение, в соответствии с требованиями действующего
                законодательства Российской Федерации и Федерального закона от
                27 июля 2006 г. № 152-ФЗ «О персональных данных».{" "}
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span
                style={{ fontFamily: '"Montserrat"', AwImport: "ignore" }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                Также я даю согласие на обработку иных своих персональных
                данных, необходимых Администрации для исполнения обязательств в
                рамках Соглашения, сообщенных мной в процессе взаимодействия с
                Администрацией, если необходимость возникла в соответствии с
                обязательствами.{" "}
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span
                style={{ fontFamily: '"Montserrat"', AwImport: "ignore" }}
              >
                &nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "12pt",
              }}
            >
              <span style={{ fontFamily: '"Montserrat"' }}>
                Настоящее согласие дано до момента моего отзыва согласия или
                завершения обязательств в рамках Соглашения или аналогичного
                договора (в зависимости от того, что наступит раньше).
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalData;
