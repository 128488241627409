import React from "react";
import styleFamily from "./familyInformation.module.css";
import { useNavigate } from "react-router-dom";
const UserInformation = ({userInfo, user}) => {
    const navigate = useNavigate()
    console.log(userInfo)
    const handleClickTeyps = (id) => {
        navigate('/profileTeyps/' + id)
    }
    return (
        <div className={styleFamily.popupBlock}>
            <div className={styleFamily.infoDiv} style={{ marginRight: 25 }}>
                <h2 className={styleFamily.infoZag}>Дата рождения:</h2>
                <h2 className={styleFamily.infoText}>{user?.born}</h2>
            </div>
            {userInfo.teip_item && (
                <div
                    className={styleFamily.infoDiv}
                    style={{ marginRight: 25 }}
                >
                    <h2 className={styleFamily.infoZag}>Тейп:</h2>
                    <h2 onClick={() => handleClickTeyps(userInfo.teip_item.moderator_id)} className={styleFamily.infoText}>
                        {userInfo.teip_item.title}
                    </h2>
                </div>
            )}

            <div className={styleFamily.infoDiv}>
                <h2 className={styleFamily.infoZag}>Регион:</h2>
                <h2 className={styleFamily.infoText}>Ингушетия</h2>
            </div>
        </div>
    );
};

export default UserInformation;
