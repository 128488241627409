import { useEffect, useState, useRef, useContext } from "react";
import { useDispatch } from "react-redux";
import { getUserInfo } from "../../../redux/features/users";
import Posts from "../band/postComponents/Posts";
import ProfilePanel from "./userComponents/profilePanel/profilePanel";
import styleUser from "./user.module.css";
import NoActivatedImage from "./img/userNoPost.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import loader from "../../content/img/loader.gif";
import PostService from "../../../API/postService";
import { useParams } from "react-router-dom";
import { RegistrationContext } from "../../context";
import AddPost from "../band/addPosts/AddPost";
import ReButton from "../../reusedComponents/ReButton";

const UserProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useContext(RegistrationContext);
  const [page, setPage] = useState(1);
  const { data, setData } = useContext(RegistrationContext);
  const [notPosts, setNotPosts] = useState(["true"]);
  const [newPosts, setNewPosts] = useState(false);
  const btnRef = useRef(null);
  const plugText =
    "Здесь появятся моменты, которые вы опубликуете. Делитесь важными событиями и сохраняйте памятные моменты с Gargalo.";
  useEffect(() => {
    setData();
    PostService.getAllPostsUser(id, page).then((res) => setData(res.data));
  }, [id]);

  useEffect(() => {
    if (page > 1) {
      setNewPosts(true);
      PostService.getAllPostsUser(id, page).then((res) => {
        setData((prev) => [...prev, ...res.data]);
        setNotPosts(res.data);
        setNewPosts(false);
      });
    }
  }, [page]);

  useEffect(() => {
    const btn = btnRef.current;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((page) => page + 1);
      }
    });
    if (btn) observer.observe(btn);
    return () => {
      if (btn) observer.unobserve(btn);
    };
  }, [btnRef.current, data]);

  const scrollBarFunction = () => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {user ? (
        <ProfilePanel user={user} />
      ) : (
        <div className={styleUser.plug}></div>
      )}
      {user.is_real ? (
        <AddPost
          whereTitle="Поделитесь моментом с близкими .."
          whereButton="Опубликовать"
          whereHeader={"Добавление публикации"}
        />
      ) : (
        ""
      )}
      {user && user.is_real ? (
        <>
          {data ? (
            <>
              <Posts data={data} typePlug={plugText} />

              {data.length > 9 && (
                <>
                  {notPosts.length > 0 ? (
                    <div>
                      <button style={{ opacity: 0 }} ref={btnRef}></button>
                    </div>
                  ) : (
                    <div className={styleUser.no_posts_plug}>
                      <button
                        className={styleUser.no_posts_plug_text}
                        onClick={scrollBarFunction}
                      >
                        Вы посмотрели все посты
                      </button>
                    </div>
                  )}
                </>
              )}
              {newPosts && (
                <div className={styleUser.loader_div}>
                  <div>
                    <img
                      style={{ width: 70, height: 70 }}
                      src={loader}
                      alt=""
                    />
                    <h3 className={styleUser.loader_text}>Загрузка...</h3>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </>
      ) : (
        <div className={styleUser.user_not_activated}>
          <div className={styleUser.user_not_activated_content}>
            <img
              className={styleUser.user_not_activated_image}
              src={NoActivatedImage}
              alt=""
            />
            <p className={styleUser.user_not_activated_head}>Ваш аккаунт не активирован</p>
            <p className={styleUser.user_not_activated_text}>
              Активируйте профиль любым удобным
              <br /> для Вас способом, чтобы использовать
              <br /> все функции сайта
            </p>
            <ReButton
              type={"button"}
              classes="main"
              height={40}
              handleClick={() => navigate("/confirmUser")}
              FWeight={600}
              FSize={14}
              width={147}
              ml={105}
              text={"Активировать"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
