import React from "react";
import style from "../../messagPage.module.css";
import smile from "../../img/smile.svg";
import addImage from "../../img/addImage.svg";
import Picker from "emoji-picker-react";
import deleteX from "../../../img/deleteX.svg";
import sendMesage from "../../img/sendMesage.svg";
import { useState } from "react";
import AudioMessage from "./formChatComponents/AudioMessage";
import axios from "axios";
import AnswerBlock from "./formChatComponents/AnswerBlock";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CameraBtn from "../../img/CameraBtn.svg";

const FormChat = ({
  setAddSelfie,
  contact_id,
  contact_blacklist,
  contact_is_accept,
  my_blacklist,
  my_whoasked,
  setMySettings,
  setContactSettings,
  parent_id,
  setParent_id,
  setAnswerBlock,
  answerBlock,
  messages,
  setMessages,
  messageIdMass,
  setMessageIdMass,
  setScroll,
  setModalChat,
  setModalType,
}) => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [startAudioState, setStartAudioState] = useState(false);
  const [sendMessag, setSendMessag] = useState(false);
  const [msg, setMsg] = useState("");
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const id = useParams();

  //Обнуление при переходе к другому диалогу
  useEffect(() => {
    setMsg("");
    setMessageIdMass([]);
    setMediaFiles([]);
    setParent_id(-1);
  }, [id.id]);

  // Смайлики и бар сообщений
  const onEmojiClick = (e, emojiObject) => {
    if (!startAudioState) {
      setMsg((prevState) => prevState + `${emojiObject.emoji}`);
    }
  };

  // Отправка сообщения на кнопку Enter
  const keyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey && msg.length > 0) {
      setSendMessag(true);
      e.preventDefault();
      sendMesageClick();
    }
    if (e.keyCode === 13 && msg.length === 0) {
      e.preventDefault();
    }
  };

  //Отправка текстового сообщения/медиа
  const sendMesageClick = () => {
    if (msg.trim().length > 0 && mediaFiles.length === 0) {
      setSendMessag(true);
      axios
        .post(
          "https://gargalo.ru/api/messages/send",
          { contact_id, msg: msg.trim(), parent_id },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token[1]}`,
              "User-Token": 1,
            },
          }
        )
        .then((res) => {
          setSendMessag(false);
          setMsg("");
          setMessageIdMass([]);
          setMediaFiles([]);
          setParent_id(-1);
          setAnswerBlock(false);
          setMessages((prevState) => [res.data, ...prevState]);
          setScroll(true);
        })
        .catch((error) => {
          if (
            error.response.data.errors[0] ===
            "Этот пользователь добавил вас в черный список"
          ) {
            setContactSettings((prevState) => ({ ...prevState, blacklist: 1 }));
          }
        });
    } else if (mediaFiles.length > 0) {
      setSendMessag(true);
      for (let i = 0; i < mediaFiles.length; i++) {
        let fd = new FormData();
        fd.append("media[]", mediaFiles[i].media);
        if (msg.trim().length > 0 && i === mediaFiles.length - 1) {
          fd.append("msg", msg);
        }
        fd.append("contact_id", contact_id);
        fd.append("_method", "PUT");
        fd.append("parent_id", parent_id);
        axios({
          method: "post",
          url: "https://gargalo.ru/api/messages/send",
          data: fd,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        })
          .then((res) => {
            setSendMessag(false);
            setMsg("");
            setMessageIdMass([]);
            setMediaFiles([]);
            setParent_id(-1);
            setAnswerBlock(false);
            setMessages((prevState) => [res.data, ...prevState]);
            setScroll(true);
          })
          .catch((error) => {
            if (
              error.response.data.errors[0] ===
              "Этот пользователь добавил вас в черный список"
            ) {
              setContactSettings((prevState) => ({
                ...prevState,
                blacklist: 1,
              }));
            }
          });
      }
    }
  };

  //Убрать из черного списка
  const handleUnbanned = () => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/messages/stopignorecontact/${contact_id}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then(() => {
      setMySettings((prevState) => ({ ...prevState, blacklist: 0 }));
    });
  };

  //Проверка на наличие камеры
  const handleSelfiModal = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        setAddSelfie(true);
      })
      .catch(() => {
        setModalType("microOff");
        setModalChat(true);
      });
  };

  if (contact_is_accept === 0) {
    return (
      <div className={style.messagPageInputFile}>
        <p className={style.formChatText}>
          {my_whoasked === contact_id
            ? "Одобрите этот контакт для начала переписки"
            : "Этот контакт вас еще не одобрил"}
        </p>
      </div>
    );
  }

  if (my_blacklist === 1) {
    return (
      <div className={style.messagPageInputFile}>
        <p className={style.formChatText}>
          Этот контакт занесён в Ваш черный список,{" "}
          <a
            style={{ color: "#25899F", fontWeight: 500, cursor: "pointer" }}
            onClick={handleUnbanned}
          >
            разблокируйте его
          </a>
          , чтобы снова начать писать ему
        </p>
      </div>
    );
  }

  if (contact_blacklist === 1) {
    return (
      <div className={style.messagPageInputFile}>
        <p className={style.formChatText}>
          Этот контакт добавил вас в черный список
        </p>
      </div>
    );
  }

  if (
    contact_blacklist === 0 &&
    my_blacklist === 0 &&
    contact_is_accept === 1
  ) {
    return (
      <div className={style.messagPageInputFile}>
        <div className={style.inputFile}>
          <div style={{ display: "flex" }}>
            {mediaFiles.length > 0 && (
              <>
                {mediaFiles.map((file) => {
                  return (
                    <div className={style.postImgBox} key={file.mediaUrl}>
                      <img src={file.mediaUrl} alt="" />
                      <div
                        className={style.appealUserBlock}
                        onClick={() =>
                          setMediaFiles(
                            mediaFiles.filter(
                              (item) => item.mediaUrl !== file.mediaUrl
                            )
                          )
                        }
                      >
                        <img src={deleteX} alt="" />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {answerBlock && (
            <AnswerBlock
              setAnswerBlock={setAnswerBlock}
              messageIdMass={messageIdMass}
              setMessageIdMass={setMessageIdMass}
              messages={messages}
              setParent_id={setParent_id}
              parent_id={parent_id}
            />
          )}
        </div>
        <div className={style.messagPageInput}>
          <input
            type="file"
            id="inputFile"
            onChange={(e) => {
              setMediaFiles([
                ...mediaFiles,
                {
                  mediaUrl: URL.createObjectURL(e.target.files[0]),
                  media: e.target.files[0],
                },
              ]);
            }}
            disabled={startAudioState && true}
            className={style.inputImg}
            accept="image/*,video/*"
          ></input>
          <label htmlFor="inputFile" className={style.inputWrapper}>
            <img src={addImage} alt="Выбрать файл" />
          </label>
          <img
            onClick={handleSelfiModal}
            src={CameraBtn}
            alt="Сделать фото"
            style={{
              width: 18,
              height: 18,
              paddingRight: 10,
              cursor: "pointer",
            }}
          />
          <div className={style.Comments_Input_Smile}>
            <img src={smile} style={{ width: 16, height: 16 }} alt="Smile" />
            <div className={style.smileWrap}>
              <div className={style.smileBlock}>
                <Picker onEmojiClick={onEmojiClick} />
              </div>
            </div>
          </div>
          <textarea
            className={style.messagInput}
            onChange={(e) => setMsg(e.target.value)}
            onKeyDown={keyDown}
            style={{ width: startAudioState ? 430 : 505 }}
            type="text"
            value={msg}
            disabled={startAudioState && true}
            placeholder="Напишите сообщение..."
          ></textarea>
          {msg.length === 0 && mediaFiles.length === 0 ? (
            <AudioMessage
              startAudioState={startAudioState}
              setStartAudioState={setStartAudioState}
              setContactSettings={setContactSettings}
              setMessageIdMass={setMessageIdMass}
              setParent_id={setParent_id}
              setAnswerBlock={setAnswerBlock}
              setMessages={setMessages}
              parent_id={parent_id}
              contact_id={contact_id}
              setScroll={setScroll}
              setModalChat={setModalChat}
              setModalType={setModalType}
            />
          ) : (
            <button
              className={style.audioBtn}
              onClick={sendMesageClick}
              disabled={sendMessag}
            >
              <img
                src={sendMesage}
                id="sendMesage"
                style={{
                  width: 14,
                  height: 15,
                  marginLeft: 14,
                }}
                alt="Send"
              />
            </button>
          )}
        </div>
      </div>
    );
  }
};

export default FormChat;
