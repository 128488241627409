import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const InputSearch = ({
  setAddPersonInfo,
  addPersonInfo,
  placeholder,
  valueKey,
  valueId,
  pol,
}) => {
  const [openBlock, setOpenBlock] = useState(false);
  const [peopleMass, setPeopleMass] = useState([]);
  const token = document.cookie.match(/token=(.+?)(;|$)/);

  useEffect(() => {
    if (addPersonInfo[valueKey].length > 3) {
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/getid?fio=${addPersonInfo[valueKey]}&filter=persons&pol=${pol}&from=0&to=150&years-from=1800&years-to=2022`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      }).then((res) => {
        setPeopleMass(res.data.data);
      });
    }
  }, [addPersonInfo[valueKey]]);

  return (
    <div style={{ position: "relative" }}>
      <input
        className="addingInfoDate"
        type="text"
        placeholder={placeholder}
        onChange={(e) =>
          setAddPersonInfo({
            ...addPersonInfo,
            [valueKey]: e.target.value.replace(/[a-zA-Z,0-9]/, ""),
          })
        }
        value={addPersonInfo[valueKey]}
        onClick={() => setOpenBlock(true)}
        autoComplete="off"
      />
      {addPersonInfo[valueKey].length > 3 && openBlock && (
        <div className="usersBox">
          {peopleMass.map((user, index) => {
            return (
              <div
                key={index}
                className="users"
                onClick={() => {
                  setOpenBlock(false);
                  setAddPersonInfo({
                    ...addPersonInfo,
                    [valueId]: user.id,
                    [valueKey]: user.fio,
                  });
                }}
              >
                <div className="userImg">
                  <img src={`https://gargalo.ru/${user?.avatar}`} alt="" />
                </div>
                <div className="userNameData">
                  <h2 className="userName">{user.fio}</h2>
                  <h2 className="userData">{user.born}</h2>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default InputSearch;
