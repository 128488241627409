import SignIn from "../components/sign/SignIn";
import Registrashion from "./../components/sign/Registrashion";
import BasePage from "./../components/sign/BasePage";
import ProfileActivation from "./../components/sign/ProfileActivation";
import ResetPassword from "./../components/sign/ResetPassword";
import Privacy from "./../components/information/Privacy";
import PersonalData from "./../components/information/PersonalData";
import Agreement from "./../components/information/Agreement";
import CookiePolicy from "./../components/information/CookiePolicy";
import Band from "./../components/content/band/Band";
import Notifications from "./../components/content/notifications/Notifications";
import UnapprovedEvents from "./../components/content/events/UnapprovedEvents";
import Complaints from "../components/content/complaints/Сomplaints";
import Teyp from "./../components/content/teyps/Teyp";
import TreePageInTeypBlock from "../components/content/teyps/TreePageInTeypBlock";
import Followers from "./../components/content/teyps/Followers";
import CurrentPost from "./../components/content/band/postComponents/CurrentPost";
import UserProfileBlock from "./../components/content/user/UserProfileBlock";
import UserSettingsBlock from "./../components/content/user/UserSettingsBlock";
import UserAccountBlock from "./../components/content/user/UserAccountBlock";
import Events from "./../components/content/events/Events";
import CurrentEvent from "./../components/content/events/CurrentEvent";
import ConfirmUserBlock from "./../components/content/user/userSettings/confirmUser/ConfirmUserBlock";
import Messages from "./../components/content/messages/Messages";
import Validation from "./../components/content/validation/Validation";
import Connection from "../components/content/connection/Connection";
import KinshipBlock from "../components/content/teyps/KinshipBlock";
import Tree from "../components/content/teyps/tree/Tree";
import TeypProfileBlock from "../components/content/teyps/TeypProfileBlock";
import EditPost from "../components/content/band/editPosts/EditPost";
import Approval from "../components/content/approval/Approval";

export const registerOffRoutes = [
  { path: "/", element: <SignIn /> },
  { path: "/registrashion", element: <Registrashion /> },
  { path: "/base", element: <BasePage /> },
  { path: "/activation", element: <ProfileActivation /> },
  { path: "/forgot-password", element: <ResetPassword /> },
  { path: "/privacy", element: <Privacy /> },
  { path: "/personal_data", element: <PersonalData /> },
  { path: "/agreement", element: <Agreement /> },
  { path: "/cookie_policy", element: <CookiePolicy /> },
];
export const registerOnRoutes = [
  { path: "/wall/user/:id", element: <UserAccountBlock /> },
  { path: "/wall/post/:commentId", element: <CurrentPost /> },
  { path: "/event/:commentId", element: <CurrentEvent /> },
  { path: "/settingsAccaunt/:userId", element: <UserSettingsBlock /> },
  { path: "/", element: <Band /> },
  { path: "/wall", element: <Band /> },
  { path: "/unapprovedEvents", element: <UnapprovedEvents /> },
  { path: "/notifications", element: <Notifications /> },
  { path: "/complaints", element: <Complaints /> },
  { path: "/privacy", element: <Privacy /> },
  { path: "/personal_data", element: <PersonalData /> },
  { path: "/agreement", element: <Agreement /> },
  { path: "/cookie_policy", element: <CookiePolicy /> },
  { path: "/teyps", element: <Teyp /> },
  { path: "/tree/node/:id", element: <TreePageInTeypBlock /> },
  { path: "/followersTeyps", element: <Followers /> },
  { path: "/profileTeyps/:id", element: <TeypProfileBlock /> },
  { path: "/post/:id", element: <CurrentPost /> },
  { path: "/profile/:id", element: <UserProfileBlock /> },
  { path: "/account/:id", element: <UserAccountBlock /> },
  { path: "/events", element: <Events /> },
  { path: "/event/:id", element: <CurrentEvent /> },
  { path: "/tree/:id", element: <Tree /> },
  { path: "/connection", element: <Connection /> },
  { path: "/confirmUser", element: <ConfirmUserBlock /> },
  { path: "messages/", element: <Messages /> },
  { path: `messages/chat/:id`, element: <Messages /> },
  { path: "/validation", element: <Validation /> },
  { path: "/approval", element: <Approval /> },
];
