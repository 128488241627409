import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import style from "./itemChatComponents/itemChat.module.css";
import RecipientMessag from "./itemChatComponents/RecipientMessag";
import SenderMessag from "./itemChatComponents/SenderMessag";
import { useInView } from "react-intersection-observer";
import loaderGif from "../../../img/loader.gif";
import CaretDown from "../../img/CaretDown.svg";
import MessagMenu from "./itemChatComponents/MessagMenu";
import SearchMessages from "./itemChatComponents/SearchMessages";

const ItemChat = ({
  nextPageUrl,
  setNextPageUrl,
  messages,
  setMessages,
  contact_id,
  page,
  setPage,
  messageIdMass,
  setMessageIdMass,
  setForwardChat,
  setForwardType,
  setDeleteMessages,
  setAnswerBlock,
  setParent_id,
  setScroll,
  scroll,
  searchMessages,
  setSearchMessages,
  currentMesag,
  setCurrentMesag,
}) => {
  let lastDataMessage;
  const skrollRef = useRef(null);
  const messagesRef = useRef(null);
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const { ref, inView } = useInView({
    threshold: 1,
    initialInView: true,
  });
  const { ref: scrollBollRef, inView: inViewBoll } = useInView({
    threshold: 0,
  });

  //Прогрузка новой страницы
  useEffect(() => {
    if (nextPageUrl && inView) {
      axios
        .post(
          `https://gargalo.ru/api/messages/showolder?page=${page + 1}`,
          {
            contact_id,
          },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token[1]}`,
              "User-Token": 1,
            },
          }
        )
        .then((res) => {
          setPage((prevState) => prevState + 1);
          setNextPageUrl(res.data.messages.next_page_url);
          setMessages((prevState) => [...prevState, ...res.data.messages.data]);
        });
    }
  }, [inView]);

  //Первая прокрутка до низа
  useEffect(() => {
    skrollRef.current.scrollIntoView();
  }, [contact_id]);

  //Нажатие по сообщению
  const handleAddMessagId = (messagId) => {
    if (!searchMessages) {
      if (messageIdMass.includes(messagId)) {
        setMessageIdMass(messageIdMass.filter((item) => item !== messagId));
      } else {
        setMessageIdMass((prevState) => [...prevState, messagId]);
      }
    }
  };

  //Прокрутка при добавлении нового сообщения
  useEffect(() => {
    if (scroll) {
      skrollRef.current.scrollIntoView();
      setScroll(false);
    }
  }, [scroll]);

  return (
    <div className={style.chatItemsWrapp}>
      {messageIdMass.length > 0 && (
        <MessagMenu
          messageIdMass={messageIdMass}
          setForwardChat={setForwardChat}
          setForwardType={setForwardType}
          setDeleteMessages={setDeleteMessages}
          setAnswerBlock={setAnswerBlock}
          setParent_id={setParent_id}
        />
      )}
      {searchMessages && (
        <SearchMessages
          setSearchMessages={setSearchMessages}
          contact_id={contact_id}
          messageIdMass={messageIdMass}
          setMessageIdMass={setMessageIdMass}
          messagesRef={messagesRef.current}
          setCurrentMesag={setCurrentMesag}
          setMessages={setMessages}
          messages={messages}
          setPage={setPage}
          page={page}
        />
      )}
      <div className={style.chatItemsBlock} ref={messagesRef}>
        <div
          ref={(node) => {
            skrollRef.current = node;
            scrollBollRef(node);
          }}
          style={{ height: 1 }}
        ></div>
        {messages.map((messag, index) => {
          return (
            <div
              key={messag.id}
              style={{ display: "flex", flexDirection: "column" }}
              onClick={() => handleAddMessagId(messag.id)}
              id={messag.id}
            >
              {lastDataMessage === undefined ? (
                <div style={{ display: "none" }}>
                  {(lastDataMessage = messages[0]?.date)}
                </div>
              ) : (
                <div style={{ display: "none" }}>
                  {(lastDataMessage = lastDataMessage)}
                </div>
              )}
              {messages.length > index ? (
                lastDataMessage !== messages[index + 1]?.date ? (
                  <>
                    <h2 className={style.dateText}>{messag.date}</h2>
                    <div style={{ display: "none" }}>
                      {(lastDataMessage = messages[index + 1]?.date)}
                    </div>
                  </>
                ) : (
                  ""
                )
              ) : (
                <>
                  <h2 className={style.dateText}>{messag.date}</h2>
                  <div style={{ display: "none" }}>
                    {(lastDataMessage = messag.date)}
                  </div>
                </>
              )}
              {messag.from_id !== contact_id ? (
                <RecipientMessag
                  messag={messag}
                  messageIdMass={messageIdMass}
                  searchMessages={searchMessages}
                  currentMesag={currentMesag}
                />
              ) : (
                <SenderMessag
                  messag={messag}
                  messageIdMass={messageIdMass}
                  searchMessages={searchMessages}
                  currentMesag={currentMesag}
                />
              )}
            </div>
          );
        })}
        {nextPageUrl && (
          <img
            ref={ref}
            className={style.loaderImg}
            src={loaderGif}
            alt="Сообщения загружаются..."
          />
        )}
        <div
          className={style.skrollDown}
          onClick={() => {
            skrollRef.current.scrollIntoView();
          }}
          style={{ display: !inViewBoll ? "flex" : "none" }}
        >
          <img
            src={CaretDown}
            alt="Down"
            style={{
              width: 15,
              height: 15,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ItemChat;
