import React from "react";
import style from "./complaints.module.css";
import ComplaintsPage from "./ComplaintsPage";

const Сomplaints = () => {
  return (
    <div className={style.band}>
      <ComplaintsPage />
    </div>
  );
};

export default Сomplaints;
