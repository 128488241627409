import React from "react";
import style from "./itemChat.module.css";

const TypeTextAndMedia = ({ media, content, time, type }) => {
  return (
    <>
      {media[0]?.type === "image" ? (
        <img
          src={`https://gargalo.ru/${media[0].path}`}
          alt=""
          style={{ maxWidth: 400, maxHeight: 280 }}
        />
      ) : (
        ""
      )}
      <div className={style.typeTextWrapp}>
        <p
          className={style.contentText}
          style={{ color: type === "R" ? "#FFFFFF" : "#042026" }}
        >
          {content}
        </p>
        <h3
          className={style.timeText}
          style={{ color: type === "R" ? "#FFFFFF" : "#054857" }}
        >
          {time}
        </h3>
      </div>
    </>
  );
};

export default TypeTextAndMedia;
