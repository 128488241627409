import axios from "axios";

const initialState = {
  loading: true,
  users: [],
  usersMessage: [],
  userPosts: [],
  userInTree: null,
  userInfo: null,
  user: null,
  userPedigree: null,
  error: null,
  password: null,
  settingsNotifications: null,
  followers: [],
  follows: [],
  currPageFollow: null,
  lastPageFollow: null,
  lastPageFollowers: null,
  currPageFollowers: null,
};

const token = document.cookie.match(/token=(.+?)(;|$)/);

const users = (state = initialState, action) => {
  switch (action.type) {
    case "users/pending":
      return {
        ...state,
        loading: true,
        users: [],
        error: null,
      };
    case "users/fullfilled":
      return {
        ...state,
        loading: false,
        users: [...state.users, action.payload.data],
        error: null,
      };
    case "users/rejected":
      return {
        ...state,
        loading: false,
        users: [],
        error: action.error,
      };
    case "usersMessage/pending":
      return {
        ...state,
        loading: true,
        usersMessage: [],
        error: null,
      };
    case "usersMessage/fullfilled":
      return {
        ...state,
        loading: false,
        usersMessage: [...state.usersMessage, action.payload.items],
        error: null,
      };
    case "usersMessage/rejected":
      return {
        ...state,
        loading: false,
        usersMessage: [],
      };
    case "user/pending":
      return {
        ...state,
        loading: true,
        user: null,
        error: null,
      };
    case "user/fullfilled":
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case "user/rejected":
      return {
        ...state,
        loading: false,
        user: null,
        error: action.error,
      };
    case "userInfo/pending":
      return {
        ...state,
        loading: true,
        userPedigree: [],
        error: null,
      };
    case "userInfo/fullfilled":
      return {
        ...state,
        loading: false,
        userPedigree: action.payload,
        error: null,
      };
    case "userInfo/rejected":
      return {
        ...state,
        loading: false,
        userPedigree: [],
        error: action.error,
      };
    case "userUserTree/pending":
      return {
        ...state,
        loading: true,
        userInTree: null,
        error: null,
      };
    case "userUserTree/fullfilled":
      return {
        ...state,
        loading: false,
        userInTree: action.payload,
        error: null,
      };
    case "userUserTree/rejected":
      return {
        ...state,
        loading: false,
        userInTree: null,
        error: action.error,
      };
    case "getUserPosts/pending":
      return {
        ...state,
        loading: true,
        userPosts: [],
        error: null,
      };
    case "getUserPosts/fullfiled":
      return {
        ...state,
        loading: false,
        userPosts: [...state.userPosts, action.payload.items.data],
        userInfo: action.payload.data,
        error: null,
      };
    case "getUserPosts/rejected":
      return {
        ...state,
        loading: false,
        userPosts: [],
        error: action.error,
      };
    case "acceptDialog/pending":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "acceptDialog/fullfiled":
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "acceptDialog/rejected":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "usersEdit/pending":
      return {
        ...state,
        // loading: true,
        error: null,
      };
    case "usersEdit/fulfilled":
      return {
        ...state,
        // loading: false,
        error: null,
      };
    case "usersEdit/rejected":
      return {
        ...state,
        // loading: false,
        error: action.error,
      };
    case "editPassword/pending":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "editPassword/fulfilled":
      return {
        ...state,
        loading: false,
        password: action.payload,
        error: null,
      };
    case "editPassword/rejected":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "getSettingsNotifications/pending":
      return {
        ...state,
        error: null,
      };
    case "getSettingsNotifications/fulfilled":
      return {
        ...state,
        loading: false,
        settingsNotifications: action.payload.settings,
        error: null,
      };
    case "getSettingsNotifications/rejected":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "sendSettingsNotifications/pending":
      return {
        ...state,
        error: null,
      };
    case "sendSettingsNotifications/fulfilled":
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "sendSettingsNotifications/rejected":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "sendFollowUser/pending":
      return {
        ...state,
        error: null,
      };
    case "sendFollowUser/fulfilled":
      return {
        ...state,
        error: null,
      };
    case "sendFollowUser/rejected":
      return {
        ...state,
        error: action.error,
      };
    case "getFollowers/pending":
      return {
        ...state,
        loading: true,
        followers: [],
        error: null,
      };
    case "getFollowers/fulfilled":
      return {
        ...state,
        loading: false,
        lastPageFollowers: action.payload.last_page,
        currPageFollowers: action.payload.current_page,
        followers: [...state.followers, ...action.payload.data],
        error: null,
      };
    case "getFollowers/rejected":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "getFollows/pending":
      return {
        ...state,
        follows: [],
        loading: true,
        error: null,
      };
    case "getFollows/fulfilled":
      return {
        ...state,
        loading: false,
        currPageFollow: action.payload.current_page,
        lastPageFollow: action.payload.last_page,
        follows: [...state.follows, ...action.payload.data],
        error: null,
      };
    case "getFollows/rejected":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const getUsers = (fio) => {
  return async (dispatch) => {
    dispatch({ type: "users/pending" });
    try {
      if (fio) {
        const res = await fetch("https://gargalo.ru/api/getid", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
          body: JSON.stringify({
            fio,
            filter: "all",
          }),
        });
        const json = await res.json();
        dispatch({ type: "users/fullfilled", payload: json });
      }
    } catch (error) {
      dispatch({ type: "users/rejected", error: error });
    }
  };
};

export const getUser = () => {
  return async (dispatch) => {
    dispatch({ type: "user/pending" });
    try {
      const res = await fetch("https://gargalo.ru/api/user", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      window.userParam = json;
      dispatch({ type: "user/fullfilled", payload: json });
    } catch (error) {
      dispatch({ type: "user/rejected", error: error });
    }
  };
};

export const getUserInfo = (id) => {
  return async (dispatch) => {
    dispatch({ type: "userInfo/pending" });
    try {
      const res = await fetch(`https://gargalo.ru/api/user/${id}/info`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      dispatch({ type: "userInfo/fullfilled", payload: json });
    } catch (error) {
      dispatch({ type: "userInfo/rejected", error: error });
    }
  };
};

export const getUserTree = (id) => {
  return async (dispatch) => {
    dispatch({ type: "userUserTree/pending" });
    try {
      const res = await fetch(`https://gargalo.ru/api/tree/node/${id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      dispatch({ type: "userUserTree/fullfilled", payload: json });
    } catch (error) {
      dispatch({ type: "userUserTree/rejected", error: error });
    }
  };
};

export const getUserPosts = (id, page) => {
  return async (dispatch) => {
    dispatch({ type: "getUserPosts/pending" });
    try {
      if (page) {
        const res = await fetch(
          `https://gargalo.ru/api/user/${id}/posts?page=${page}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token[1]}`,
              "User-Token": 1,
            },
          }
        );
        const json = await res.json();
        dispatch({ type: "getUserPosts/fullfiled", payload: json });
      }
    } catch (error) {
      dispatch({ type: "getUserPosts/rejected", error: error });
    }
  };
};

export const getUsersMessage = () => {
  return async (dispathch) => {
    dispathch({ type: "usersMessage/pending" });
    try {
      const res = await fetch("https://gargalo.ru/api/chats", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      dispathch({ type: "usersMessage/fullfilled", payload: json });
    } catch (error) {
      dispathch({ type: "usersMessage/rejected", error: error });
    }
  };
};

export const getAcceptDialog = (contactId) => {
  return async (dispathch) => {
    dispathch({ type: "acceptDialog/pending" });
    try {
      const res = await fetch(
        `https://gargalo.ru/api/messages/acceptcontact/${contactId}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      );
      const json = await res.json();
      dispathch({ type: "acceptDialog/fullfilled", payload: json });
    } catch (error) {
      dispathch({ type: "acceptDialog/rejected", error: error });
    }
  };
};

export const editUser = (
  id,
  userName,
  nickName,
  surname,
  name,
  lastname,
  email,
  phone,
  avatar,
  checkSettings,
  teyp,
  gender,
  dateInput,
  nodeId
) => {
  return async (dispatch) => {
    dispatch({ type: "usersEdit/pending" });
    const formData = new FormData();
    formData.append("user_id", id);
    formData.append("username", userName);
    formData.append("nickname", nickName);
    formData.append("f_name", surname);
    formData.append("name", name);
    formData.append("l_name", lastname);
    formData.append("email", email);
    formData.append("phone", phone);
    {
      avatar && formData.append("avatar", avatar);
    }
    {
      teyp && formData.append("teip", teyp.teip);
    }
    formData.append("gender", gender);
    formData.append("born", dateInput);

    {
      nodeId && formData.append("tree_number", nodeId);
    }
    try {
      axios({
        method: "post",
        url: "https://gargalo.ru/api/settings/profile",
        data: formData,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      })
        .then((res) => {
          res.data._status = 200;
          checkSettings(res.data);
          dispatch({
            type: "usersEdit/fulfilled",
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: "usersEdit/rejected",
            error: err.message,
          });
        });
    } catch (error) {
      dispatch({ type: "usersEdit/rejected", error: error });
    }
  };
};

export const editPassword = (oldPass, newPass, newPassTwo, checkPass) => {
  return async (dispatch) => {
    dispatch({ type: "editPassword/pending" });
    try {
      const res = await fetch("https://gargalo.ru/api/settings/security", {
        method: "POST",

        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
        body: JSON.stringify({
          old_password: oldPass,
          new_password: newPass,
          reply_password: newPassTwo,
        }),
      });
      const json = await res.json();
      dispatch({ type: "editPassword/fulfilled", payload: json });
      json._status = res.status;
      checkPass(json);
    } catch (error) {
      dispatch({ type: "editPassword/rejected", error: error });
    }
  };
};

export const getSettingsNotifications = () => {
  return async (dispatch) => {
    dispatch({ type: "getSettingsNotifications/pending" });
    try {
      const res = await fetch(
        "https://gargalo.ru/api/settings/get-notifications",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      );
      const json = await res.json();
      dispatch({
        type: "getSettingsNotifications/fulfilled",
        payload: json,
      });
    } catch (error) {
      dispatch({
        type: "getSettingsNotifications/rejected",
        error: error,
      });
    }
  };
};

export const sendSettingsNotifications = (settingsValue, checkSettings) => {
  return async (dispatch) => {
    dispatch({ type: "sendSettingsNotifications/pending" });
    try {
      const res = await fetch("https://gargalo.ru/api/settings/notifications", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
        body: JSON.stringify({
          set_like: settingsValue.message,
          set_repost: settingsValue.reaction,
          set_comment: settingsValue.share,
          set_comment_answer: settingsValue.mentions,
          set_sub: settingsValue.events,
          set_follow: settingsValue.posts,
        }),
      });
      const json = await res.json();
      checkSettings(json);
      dispatch({
        type: "sendSettingsNotifications/fulfilled",
        payload: json,
      });
    } catch (error) {
      dispatch({
        type: "sendSettingsNotifications/rejected",
        error: error,
      });
    }
  };
};

export const sendFollowUser = (id, checkFollow) => {
  return async (dispatch) => {
    dispatch({ type: "sendFollowUser/pending" });
    try {
      const res = await fetch(`https://gargalo.ru/api/follow/${id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      checkFollow(json);
      dispatch({ type: "sendFollowUser/fulfilled", payload: json });
    } catch (error) {
      dispatch({ type: "sendFollowUser/rejected", error: error });
    }
  };
};

export const sendMessage = (id, checkMessage) => {
  return async (dispatch) => {
    dispatch({ type: "sendMessage/pending" });
    try {
      const res = await fetch(`https://gargalo.ru/api/chats/addchat/${id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      json._status = res.status;
      checkMessage(json);
      dispatch({ type: "sendMessage/fulfilled", payload: json });
    } catch (error) {
      dispatch({ type: "sendMessage/rejected", error: error });
    }
  };
};

export const getFollowers = (id, page) => {
  return async (dispatch) => {
    dispatch({ type: "getFollowers/pending" });
    try {
      const res = await fetch(
        `https://gargalo.ru/api/users/followers/${id}?page=${page}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      );
      const json = await res.json();
      dispatch({
        type: "getFollowers/fulfilled",
        payload: json.followers,
      });
    } catch (error) {
      dispatch({ type: "getFollowers/rejected", error: error });
    }
  };
};

export const getFollows = (id, page) => {
  return async (dispatch) => {
    dispatch({ type: "getFollows/pending" });
    try {
      const res = await fetch(
        `https://gargalo.ru/api/users/follows/${id}?page=${page}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      );
      const json = await res.json();
      dispatch({
        type: "getFollows/fulfilled",
        payload: json.followers,
      });
    } catch (error) {
      dispatch({ type: "getFollows/rejected", error: error });
    }
  };
};

export default users;
