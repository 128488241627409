import React, { useEffect, useState } from "react";
import style from "./sign.module.css";
import down from "./img/down.svg";
import { useRef } from "react";
import axios from "axios";

const TeypSearchPage = ({ teipProps, setRegInfo, regInfo, changingWidth }) => {
  const [teypSearchBlock, setTeypSearchBlock] = useState(false);
  const [teypList, setTeypList] = useState([]);
  const [page, setPage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState();
  const [inputValue, setInputValue] = useState("");
  const [teypListSearch, setTeypListSearch] = useState([]);
  const [teypName, setTeypName] = useState(teipProps ? teipProps + "ы" : "");
  const btnRef = useRef(null);

  //Получение первой страницы
  useEffect(() => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/teips?page=1&paginate`,
      headers: {
        "content-type": "application/json",
        "User-Token": 1,
      },
    }).then((res) => {
      setTeypList(res.data.data);
      setNextPageUrl(res.data.next_page_url);
    });
  }, []);

  //Получение новой страницы
  useEffect(() => {
    if (nextPageUrl) {
      axios({
        method: "GET",
        url: `${nextPageUrl}&paginate`,
        headers: {
          "content-type": "application/json",
          "User-Token": 1,
        },
      }).then((res) => {
        setNextPageUrl(res.data.next_page_url);
        setTeypList((prevState) => [...prevState, ...res.data.data]);
      });
    }
  }, [page]);

  //Прогрузка новой страницы
  useEffect(() => {
    if (teypSearchBlock) {
      const btn = btnRef.current;
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((page) => page + 1);
        }
      });
      if (btn) observer.observe(btn);
      return () => {
        if (btn) observer.unobserve(btn);
      };
    }
  }, [btnRef.current, teypSearchBlock]);

  //Поиск по тейпам
  const ValueInputSearche = (e) => {
    setInputValue(e.target.value);
    if (e.target.value.length > 3) {
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/teips/search?title=${inputValue}`,
        headers: {
          "content-type": "application/json",
          "User-Token": 1,
        },
      }).then((res) => {
        setTeypListSearch(res.data.data);
      });
    }
  };

  //Открытие блока с поиском
  const handleOpenBlockCLick = (e) => {
    e.stopPropagation();
    if (teypSearchBlock) {
      setTeypSearchBlock(false);
    } else {
      setTeypSearchBlock(true);
      window.addEventListener("click", handleCloseList);
    }
  };

  //Закрытие блока с поиском при клике на другое место
  const handleCloseList = () => {
    setTeypSearchBlock(false);
    return window.removeEventListener("click", handleCloseList);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className={style.signInput + " " + style.searchTeypBlock}
          onClick={handleOpenBlockCLick}
          style={{ width: changingWidth && 302 }}
        >
          <input
            type="text"
            placeholder="Ваш тейп"
            value={teypName}
            disabled
            className={style.searchTextInput}
          />
          <img src={down} alt="Список" style={{ width: 12, height: 6 }} />
        </div>
        {teypSearchBlock && (
          <div
            className={style.searchListBlock}
            style={{ width: changingWidth && 326 }}
          >
            <input
              type="text"
              className={style.signInput + " " + style.searchInput}
              placeholder="Поиск"
              onChange={ValueInputSearche}
              onClick={(e) => e.stopPropagation()}
              value={inputValue}
              style={{ width: changingWidth && 263 }}
            />
            <div className={style.searchList}>
              {inputValue && inputValue.length > 0
                ? teypListSearch.map((item, index) => {
                    return (
                      <div
                        className={style.teypItem}
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();
                          setRegInfo({
                            ...regInfo,
                            teip: item.id,
                          });
                          setTeypName(item.title);
                          setTeypSearchBlock(false);
                        }}
                      >
                        <h2>{item.title}</h2>
                      </div>
                    );
                  })
                : teypList.map((item, index) => {
                    return (
                      <div
                        className={style.teypItem}
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();
                          setRegInfo({
                            ...regInfo,
                            teip: item.id,
                          });
                          setTeypName(item.title);
                          setTeypSearchBlock(false);
                        }}
                      >
                        <h2>{item.title}</h2>
                      </div>
                    );
                  })}
              {nextPageUrl && (
                <button
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    opacity: 0,
                  }}
                  ref={btnRef}
                >
                  Подгрузка...
                </button>
              )}
            </div>
            <button
              className={style.signBtn + " " + style.noneTeypBtn}
              onClick={(e) => {
                e.preventDefault();
                setRegInfo({
                  ...regInfo,
                  teip: -1,
                });
                setTeypName("Нет моего тейпа");
                setTeypSearchBlock(false);
              }}
            >
              Нет моего тейпа
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default TeypSearchPage;
