import React from "react";
import style from "../band.module.css";
import OnePost from "./OnePost";

const CurrentPost = () => {
    return (
        <div className={style.band}>
            <OnePost />
        </div>
    );
};

export default CurrentPost;
