import React, { Fragment, useEffect } from "react";
import style from "./treeComponents.module.css";
import PostService from "../../../../../API/postService";
import logoGif from "../../../img/loader.gif";
import { useState } from "react";
import ReButton from "../../../../reusedComponents/ReButton";
import linkIcon from "../img/Externallink.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { RegistrationContext } from "../../../../context";
import defaultAvatar from "../img/defaultAvatar.svg";
import { rootControls } from "../../../../../controllers/RootConroller";

const TreeInfoBlock = ({
  setCenter,
  setInfoBlock,
  infoBlock,
  center,
  setFormBlock,
  setButtonType,
  setAddPersonInfo,
  addPersonInfo,
  setFirstAdd,
  firstAdd,
}) => {
  const [info, setInfo] = useState();
  const [loader, setLoader] = useState(true);
  const { user } = useContext(RegistrationContext);

  useEffect(() => {
    if (infoBlock && center) {
      if (center.user_id > 0) {
        PostService.getAllUsersInfo(center.user_id).then((res) => {
          setLoader(false);
          setInfo(res);
        });
      } else if (center.id > 0) {
        PostService.getAllUsersTeyps(center.id).then((res) => {
          setLoader(false);
          setInfo(res);
        });
      } else {
        setInfoBlock(false);
      }
    }
  }, [infoBlock]);

  //Закрытие блока
  const handleCloseBlock = (e) => {
    e.stopPropagation();
    setInfoBlock(false);
    setInfo();
    setTimeout(() => {
      setLoader(true);
    }, 500);
  };

  //Кнопка редактирования
  const handleEditClick = (e) => {
    if (center.user_id > 0) {
      setCenter({ ...center, user_info: info.profile });
    }
    setAddPersonInfo({
      ...addPersonInfo,
      f: center.fio.split(" ")[0] ? center.fio.split(" ")[0] : "",
      i: center.fio.split(" ")[1] ? center.fio.split(" ")[1] : "",
      o: center.fio.split(" ")[2] ? center.fio.split(" ")[2] : "",
      connect_user_id: center.user_id ? center.user_id : "",
      pol: center.pol ? center.pol : "",
      fio_id: center.id ? center.id : "",
      teip: center.teip ? center.teip : "",
      fio: center.fullfio ? center.fullfio : "",
      die: center.die ? center.die : "",
      born: center.born ? center.born : "",
      full_born: center.full_born ? center.full_born : "",
      full_die: center.full_die ? center.full_die : "",
      f_fio: info.family.father ? info.family.father.fio : "",
      f_id: info.family.father ? info.family.father.node_id : "",
      m_fio: info.family.mather ? info.family.mather.fio : "",
      m_id: info.family.mather ? info.family.mather.node_id : "",
      s_fio: info.family.spouce ? info.family.spouce.fio : "",
      spouce_id: info.family.spouce ? info.family.spouce.node_id : "",
      info: center.bio ? center.bio : "",
    });
    setFirstAdd({
      ...firstAdd,
      f: center.fio.split(" ")[0] ? center.fio.split(" ")[0] : "",
      i: center.fio.split(" ")[1] ? center.fio.split(" ")[1] : "",
      o: center.fio.split(" ")[2] ? center.fio.split(" ")[2] : "",
      connect_user_id: center.user_id ? center.user_id : "",
      pol: center.pol ? center.pol : "",
      fio_id: center.id ? center.id : "",
      teip: center.teip ? center.teip : "",
      fio: center.fullfio ? center.fullfio : "",
      die: center.die ? center.die : "",
      born: center.born ? center.born : "",
      full_born: center.full_born ? center.full_born : "",
      full_die: center.full_die ? center.full_die : "",
      f_fio: info.family.father ? info.family.father.fio : "",
      f_id: info.family.father ? info.family.father.node_id : "",
      m_fio: info.family.mather ? info.family.mather.fio : "",
      m_id: info.family.mather ? info.family.mather.node_id : "",
      s_fio: info.family.spouce ? info.family.spouce.fio : "",
      spouce_id: info.family.spouce ? info.family.spouce.node_id : "",
      info: center.bio ? center.bio : "",
    });
    setFormBlock(true);
    setButtonType("edit");
    handleCloseBlock(e);
  };

  return (
    <>
      {info && (
        <div className={style.appendForm}>
          <div
            style={{ width: "100%", height: "100vh" }}
            onClick={handleCloseBlock}
          ></div>
          <div className={style.infoWrapp}>
            <div className={style.infoBlock}>
              <div
                className={style.closeAppendBlock}
                onClick={handleCloseBlock}
                style={{ left: -48, top: 20 }}
              ></div>
              <h2 className={style.infoName} style={{ marginBottom: 28 }}>
                {info.item.fio}
              </h2>
              {center.user_id > 0 && (
                <Link
                  to={
                    user.user_id === info.profile?.user_id
                      ? `/profile/${info.profile?.user_id}`
                      : `/account/${info.profile?.user_id}`
                  }
                >
                  <div
                    className={style.existingWrapp}
                    style={{ paddingRight: 12 }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={`https://gargalo.ru${info.profile?.avatar_small}`}
                        alt="avatar"
                        className={style.userAvatar}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h2 className={style.userFio}>{info.item?.fio}</h2>
                        <h2 className={style.userDate}>
                          {info.item.born
                            ? info.item.born.split("-")[2] +
                              "." +
                              info.item.born.split("-")[1] +
                              "." +
                              info.item.born.split("-")[0]
                            : ""}
                        </h2>
                      </div>
                    </div>
                    <img
                      src={linkIcon}
                      alt="Link"
                      className={style.deleteExisting}
                    />
                  </div>
                </Link>
              )}
              <div style={{ display: "flex", marginBottom: 20 }}>
                <img
                  src={
                    info.item?.avatar.split("/")[4] === "default"
                      ? defaultAvatar
                      : `https://gargalo.ru/${info.item.avatar}`
                  }
                  alt="Avatar"
                  className={style.infoAvatar}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", marginBottom: 16 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 20,
                      }}
                    >
                      <h3 className={style.infoZag}>Дата рождения</h3>
                      <h3
                        className={style.infoText}
                        style={{ color: !info.item.born && "#848383" }}
                      >
                        {info.item.born
                          ? info.item.born.split("-")[2] +
                            "." +
                            info.item.born.split("-")[1] +
                            "." +
                            info.item.born.split("-")[0]
                          : "Неизвестно"}
                      </h3>
                    </div>
                    {info.item.die && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: 20,
                        }}
                      >
                        <h3 className={style.infoZag}>Дата смерти</h3>
                        <h3 className={style.infoText}>
                          {info.item.die.split("-")[2] +
                            "." +
                            info.item.die.split("-")[1] +
                            "." +
                            info.item.die.split("-")[0]}
                        </h3>
                      </div>
                    )}
                  </div>
                  {info?.teip_item?.title && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 20,
                      }}
                    >
                      <h3 className={style.infoZag}>Тейп</h3>
                      <h3 className={style.infoText}>{info.teip_item.title}</h3>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h3 className={style.infoZag}>Пол</h3>
                    <h3
                      className={style.infoText}
                      style={{ color: !info.item.pol && "#848383" }}
                    >
                      {info.item.pol === 1
                        ? "Мужской"
                        : info.item.pol === 2
                        ? "Женский"
                        : "Не указан"}
                    </h3>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 12,
                }}
              >
                <h3 className={style.infoZag}>Отец</h3>
                <h3
                  className={style.infoText}
                  style={{ color: !info.family.father && "#848383" }}
                >
                  {info.family.father ? info.family.father.fio : "Неизвестно"}
                </h3>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 12,
                }}
              >
                <h3 className={style.infoZag}>Мать</h3>
                <h3
                  className={style.infoText}
                  style={{ color: !info.family.mather && "#848383" }}
                >
                  {info.family.mather ? info.family.mather.fio : "Неизвестно"}
                </h3>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 12,
                }}
              >
                <h3 className={style.infoZag}>Супруг(а)</h3>
                <h3
                  className={style.infoText}
                  style={{ color: !info.family.spouce && "#848383" }}
                >
                  {info.family.spouce ? info.family.spouce.fio : "Неизвестно"}
                </h3>
              </div>
              {info.family.childs && info.family.childs.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 12,
                  }}
                >
                  <h3 className={style.infoZag}>Дети</h3>
                  <div className={style.childsWrapp}>
                    <p className={style.infoText}>
                      {info.family.childs.map((item) => {
                        return (
                          <Fragment key={item.fio}>
                            {info.family.childs.length > 1 ? (
                              <>{item.fio},&nbsp;</>
                            ) : (
                              <>{item.fio}</>
                            )}
                          </Fragment>
                        );
                      })}
                    </p>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                <h3 className={style.infoZag}>Краткая биография</h3>
                <p
                  className={style.infoTextBio}
                  style={{ color: !info.item.bio && "#848383" }}
                >
                  {info.item.bio ? info.item.bio : "Отсутствует"}
                </p>
              </div>
            </div>
            <div className={style.formBtnWrapp}>
              <div style={{ display: "flex" }}>
                <ReButton
                  text={"Закрыть"}
                  height={40}
                  mr={8}
                  handleClick={handleCloseBlock}
                />
                {(rootControls("Administrator") ||
                  rootControls("TeipModerator", user.teip_moderator_id)) && (
                  <ReButton
                    text={"Редактировать"}
                    height={40}
                    classes={"third"}
                    handleClick={handleEditClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {loader && (
        <div
          className={style.appendWrapp}
          style={{
            visibility: infoBlock ? "visible" : "hidden",
            opacity: infoBlock ? "1" : "0",
          }}
        >
          <img src={logoGif} alt="logoGif" className={style.logoGif} />
        </div>
      )}
    </>
  );
};

export default TreeInfoBlock;
