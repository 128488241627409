import React from "react";
import styleCurrent from "./CurrentStyle.module.css";
import CurrentEventBlock from "./CurrentEventBlock";

const CurrentEvent = () => {
  return (
    <div className={styleCurrent.band}>
      <CurrentEventBlock commentType="event" />
    </div>
  );
};

export default CurrentEvent;
