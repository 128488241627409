import React from "react";
import style from "../notifications/notifications.module.css";
import NotificationsList from "./NotificationsList";

const Notifications = () => {
  return (
    <div className={style.band}>
      <NotificationsList />
    </div>
  );
};

export default Notifications;
