import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompainCategories } from "../../../.././redux/features/post";
import style from "./posts.module.css";
import Loader from "../../Loader";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import PostsVote from "./vote/postsVote";
import PostBottomPanel from "./postBottomPanel/postBottomPanel";
import Hashtags from "./postsHashtags/hashtags";
import ReasonForComplaint from "./reasonForComplaint/reasonForComplaint";
import PostsSwiper from "./postsSwiper/postsSwiper";
import PostTopPanel from "./postTopPanel/PostTopPanel";
import plugImage from "../../img/plugImage.svg";
import Post from "./Post";

const Posts = ({ data, typePlug, proposed=false, deviation=false, where}) => {
  const dispatch = useDispatch();
  const [complain, setComplain] = useState(false);
  const [postId, setPostId] = useState();
  let post__Id;
  const loading = useSelector((state) => state.post.loading);

  

  useEffect(() => {
    dispatch(getCompainCategories());
  }, [complain]);
  const scrollBarFunction = () => {
    window.scroll({
      left: 0,
      top: 0,
    });
  };
  useEffect(() => {
    scrollBarFunction();
  }, []);

  return (
    <>
      {data.length !== 0 ? (
        <>
          {data?.map((item,index) => {
            let tags = [];
            if (item.text !== null) {
              tags = item.text.match(/(?:^|\W)#([а-яa-z]+)(?![а-я])/giu);
            }

            return (
              <div
              key={index}>
                <Post where={where} deviation={deviation} proposed={proposed} item={item} type="manyPosts" />
                {/* post-container */}
              </div> //Оболочка key - index
            );
          })}
          <div style={{ height: 20 }}></div>
        </> //Оболочка1
      ) : (
        <>
          {typePlug ? (
            <div className={style.plug}>
              <img className={style.plug__image} src={plugImage} alt="" />
              <div className={style.plug__text}>{typePlug}</div>
            </div>
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </>
      )}
      {/* подгрузка страниц */}
    </> // оболочка
  );
};

export default Posts;
