import React from "react";
import { useState } from "react";
import style from "../../messagPage.module.css";
import VectorForCheckbox from "../../../img/VectorForCheckbox.svg";
import { useEffect } from "react";
import axios from "axios";
import { useRef } from "react";

const ForwardChat = ({
  chatList,
  messageIdMass,
  setMessageIdMass,
  setForwardChat,
  forwardType,
}) => {
  const [forwardUserList, setForwardUserList] = useState([]);
  const [chatUserMass, setChatUserMass] = useState(chatList.data);
  const [nextPageUrl, setNextPageUrl] = useState(chatList.next_page_url);
  const btnRef = useRef(null);
  const token = document.cookie.match(/token=(.+?)(;|$)/);

  //Прогрузка новой страницы
  useEffect(() => {
    const btn = btnRef.current;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (nextPageUrl) {
          axios({
            method: "GET",
            url: `${nextPageUrl}`,
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token[1]}`,
              "User-Token": 1,
            },
          }).then((res) => {
            setNextPageUrl(res.data.next_page_url);
            setChatUserMass((prevState) => [...prevState, ...res.data.data]);
          });
        }
      }
    });
    if (btn) observer.observe(btn);
    return () => {
      if (btn) observer.unobserve(btn);
    };
  }, [btnRef.current]);

  //Переслать
  const hadleForwardClick = () => {
    axios({
      method: "POST",
      url: `https://gargalo.ru/api/messages/${forwardType}=${String(
        messageIdMass
      )}&contacts=${String(forwardUserList)}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
      data: {},
    }).then(() => {
      setForwardUserList([]);
      setMessageIdMass([]);
      setForwardChat(false);
    });
  };

  return (
    <div className={style.blockedDisplay}>
      <div
        className={style.forwardWrapMedia}
        style={{
          marginLeft: forwardType === "forwardlist?messages" ? -191 : "-70%",
        }}
      >
        <div className={style.forwardBlock}>
          {chatUserMass?.map((users) => {
            return (
              <div
                className={style.forwardUserList}
                key={users?.contact_id}
                onClick={() => {
                  if (forwardUserList.includes(users?.contact_id)) {
                    setForwardUserList(
                      forwardUserList.filter(
                        (item) => item !== users?.contact_id
                      )
                    );
                  } else {
                    setForwardUserList([...forwardUserList, users?.contact_id]);
                  }
                }}
              >
                {users.is_accept !== 0 && (
                  <>
                    <div
                      style={{
                        backgroundImage:
                          forwardUserList.includes(users?.contact_id) &&
                          `url(${VectorForCheckbox})`,
                      }}
                      className={style.forwardCheckBox}
                    ></div>
                    <img
                      src={`https://gargalo.ru/${users?.user?.avatar}`}
                      alt="Avatar"
                      className={style.forwardUserImage}
                    />
                    <h2 className={style.forwardUserName}>
                      {users?.user?.nickname}
                    </h2>
                  </>
                )}
              </div>
            );
          })}
          {nextPageUrl !== null && (
            <button style={{ opacity: 0 }} ref={btnRef}>
              Показать еще
            </button>
          )}
          <div className={style.forwardButtonBlock}>
            <button
              onClick={hadleForwardClick}
              disabled={forwardUserList.length === 0 ? true : false}
            >
              Переслать
            </button>
            <button
              onClick={() => {
                setForwardUserList([]);
                setMessageIdMass([]);
                setForwardChat(false);
              }}
            >
              Отменить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForwardChat;
