import axios from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import ReButton from "../../../../reusedComponents/ReButton";
import style from "./treeComponents.module.css";
import { Fragment } from "react";

const TreeSearchInput = ({
  setExistingUser,
  setAddPersonInfo,
  addPersonInfo,
  placeholder,
  valueKey,
  valueId,
  formBlock,
  classes,
  defaultValue,
  pol,
  teip,
  addingType,
  type,
  searchType,
  firstAdd,
  filterUser,
}) => {
  const [searchText, setSearchText] = useState("");
  const [teipId, setTeipId] = useState("");
  const [peopleMass, setPeopleMass] = useState([]);
  const [focusInput, setFocusInput] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [selectedFio, setSelectedFio] = useState("");
  const [nextPageUrl, setNextPageUrl] = useState();
  const [resetModal, setResetModal] = useState(false);
  const [page, setPage] = useState(2);
  const [addingUser, setAddingUser] = useState();
  const modalRef = useRef(null);
  const inputRef = useRef(null);
  const { ref: paginRef, inView } = useInView({
    threshold: 1,
    initialInView: true,
  });
  const token = document.cookie.match(/token=(.+?)(;|$)/);

  //Изначальное заполнение
  useEffect(() => {
    if (defaultValue) {
      setSearchText(defaultValue);
      setSelectedFio(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (teip) {
      setTeipId(teip);
    }
  }, [teip]);

  //Поиск
  useEffect(() => {
    if (searchText.length > 0 && openBlock) {
      const timer = setTimeout(() => {
        axios({
          method: "GET",
          url: `https://gargalo.ru/api/getid?fio=${searchText}&filter=${searchType}&pol=${pol}&teip=${teipId}&${filterUser}`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }).then((res) => {
          setPeopleMass(res.data.data);
        });
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [searchText]);

  //Очистка
  useEffect(() => {
    if (!formBlock) {
      setSearchText("");
      setSelectedFio("");
      setPeopleMass([]);
    }
  }, [formBlock]);

  //Проверка на заполнение
  useEffect(() => {
    if (!openBlock && searchText !== selectedFio) {
      setSearchText(selectedFio);
    }
  }, [openBlock]);

  //Закрытие блока при клике на пустую область
  const closeBlock = () => {
    setFocusInput(false);
    setOpenBlock(false);
  };

  useEffect(() => {
    if (modalRef.current) {
      const onClick = (e) =>
        modalRef.current.contains(e.target) || closeBlock();
      document.addEventListener("click", onClick);
      return () => document.removeEventListener("click", onClick);
    }
  }, [modalRef.current]);

  //Прогрузка новой страницы
  useEffect(() => {
    if (inView && nextPageUrl && searchText.length === 0) {
      setNextPageUrl();
      axios({
        method: "GET",
        url: `https://gargalo.ru/api/getid?fio=&filter=${searchType}&pol=${pol}&teip=${teipId}&${filterUser}&page=${page}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      }).then((res) => {
        setPeopleMass((prevState) => [...prevState, ...res.data.data]);
        setPage(page + 1);
        setNextPageUrl(res.data.next_page_url);
      });
    }
  }, [inView]);

  //Поиск при первом клике
  const handleSearchClick = () => {
    if (!addingType || (addingType !== "son" && addingType !== "brother")) {
      if (focusInput) {
        setFocusInput(false);
      } else {
        setFocusInput(true);
      }
      if (openBlock) {
        setOpenBlock(false);
      } else {
        setOpenBlock(true);
      }
      if (searchText.length === 0) {
        axios({
          method: "GET",
          url: `https://gargalo.ru/api/getid?fio=&filter=${searchType}&pol=${pol}&teip=${teipId}&${filterUser}`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }).then((res) => {
          setPeopleMass(res.data.data);
          setNextPageUrl(res.data.next_page_url);
        });
      } else {
        axios({
          method: "GET",
          url: `https://gargalo.ru/api/getid?fio=${searchText}&filter=${searchType}&pol=${pol}&teip=${teipId}&${filterUser}`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }).then((res) => {
          setPeopleMass(res.data.data);
        });
      }
    }
  };

  //Удаление человека
  const handleDeleteUser = () => {
    if (!addingType || (addingType !== "son" && addingType !== "brother")) {
      setAddPersonInfo({
        ...addPersonInfo,
        [valueId]: "",
        [valueKey]: "",
      });
      setSearchText("");
      setSelectedFio("");
      setPeopleMass([]);
    }
  };

  return (
    <div className={style.searchWrapp}>
      <>
        {resetModal && (
          <div
            className={style.appendWrapp}
            style={{
              visibility: resetModal ? "visible" : "hidden",
              opacity: resetModal ? "1" : "0",
              zIndex: 15,
            }}
          >
            <div className={style.modalWrapp}>
              <h2 className={style.modalZag}>Прикрепление пользователя</h2>
              <p className={style.modalText}>
                Вы уверены, что хотите прикрепить пользователя? Некоторые
                введенные данные будут перезаписаны.
              </p>
              <div style={{ display: "flex" }}>
                <ReButton
                  width={152}
                  height={40}
                  text={"Отмена"}
                  classes={"third"}
                  mr={12}
                  handleClick={() => setResetModal(false)}
                />
                <ReButton
                  width={152}
                  height={40}
                  text={"Прикрепить"}
                  handleClick={() => {
                    setAddPersonInfo({
                      ...addPersonInfo,
                      [valueId]: addingUser.user_id,
                      [valueKey]: addingUser.user_fio,
                      pol: addingUser.pol ? addingUser.pol : "",
                      full_born:
                        addingUser.born && addingUser.born !== "--.--.----"
                          ? addingUser.born
                          : "",
                      born:
                        addingUser.born && addingUser.born !== "--.--.----"
                          ? addingUser.born
                          : "",
                    });
                    setExistingUser({
                      fio: addingUser.user_fio,
                      avatar: addingUser.avatar,
                      born:
                        addingUser.born && addingUser.born !== "--.--.----"
                          ? addingUser.born
                          : "",
                    });
                    setSearchText(addingUser.user_fio);
                    setSelectedFio(addingUser.user_fio);
                    setPeopleMass([]);
                    setOpenBlock(false);
                    setResetModal(false);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
      {type === "drop-down" ? (
        <div
          className={style.dropWrapp}
          ref={modalRef}
          onClick={() => inputRef.current.focus()}
          style={{
            border:
              focusInput && "1px solid var(--border-color-light-greenishBlue)",
            backgroundColor:
              addingType &&
              (addingType === "son" || addingType === "brother") &&
              "#E6E6E6",
            opacity:
              addingType &&
              (addingType === "son" || addingType === "brother") &&
              0.5,
          }}
        >
          <input
            ref={inputRef}
            type="text"
            onFocus={handleSearchClick}
            placeholder={placeholder}
            className={style.dropInput}
            onChange={(e) =>
              setSearchText(e.target.value.replace(/[a-zA-Z,0-9]/, ""))
            }
            disabled={
              addingType &&
              (addingType === "son" || addingType === "brother") &&
              true
            }
            value={searchText}
          />
          {searchText.length === 0 ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 9L12 15L6 9"
                stroke="#AAC0C5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleDeleteUser}
            >
              <path
                d="M17.9996 6.59961L6.59961 17.9996"
                stroke="#AAC0C5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.9996 17.9996L6.59961 6.59961"
                stroke="#AAC0C5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      ) : (
        <input
          ref={modalRef}
          type="text"
          placeholder={placeholder}
          className={style[classes]}
          onChange={(e) =>
            setSearchText(e.target.value.replace(/[a-zA-Z,0-9]/, ""))
          }
          disabled={
            addingType &&
            (addingType === "son" || addingType === "brother") &&
            true
          }
          value={searchText}
          onClick={handleSearchClick}
        />
      )}
      {peopleMass.length > 0 && openBlock && (
        <div className={style.userListWrapp}>
          {peopleMass.map((user) => {
            return (
              <Fragment key={user.user_id}>
                {addPersonInfo.fio === user.user_fio && valueId === "f_id" ? (
                  ""
                ) : (
                  <div
                    className={style.userBlock}
                    onClick={() => {
                      if (
                        valueId === "connect_user_id" &&
                        JSON.stringify(firstAdd) !==
                          JSON.stringify(addPersonInfo)
                      ) {
                        setResetModal(true);
                        setAddingUser(user);
                      } else if (valueId === "connect_user_id") {
                        setExistingUser({
                          fio: user.user_fio,
                          avatar: user.avatar,
                          born:
                            user.born && user.born !== "--.--.----"
                              ? user.born
                              : "",
                        });
                        setAddPersonInfo({
                          ...addPersonInfo,
                          [valueId]: user.user_id,
                          [valueKey]: user.user_fio,
                          pol: user.pol ? user.pol : "",
                          full_born:
                            user.born && user.born !== "--.--.----"
                              ? user.born
                              : "",
                          born:
                            user.born && user.born !== "--.--.----"
                              ? user.born
                              : "",
                        });
                      } else {
                        setAddPersonInfo({
                          ...addPersonInfo,
                          [valueId]: user.id,
                          [valueKey]: user.fio,
                        });
                      }
                      setSearchText(user.fio);
                      setSelectedFio(user.fio);
                      setPeopleMass([]);
                      setOpenBlock(false);
                    }}
                  >
                    <img
                      src={`https://gargalo.ru/${user?.avatar}`}
                      alt="Avatar"
                      className={style.userAvatar}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h2 className={style.userFio}>
                        {valueId === "connect_user_id"
                          ? user.user_fio
                          : user.fio}
                      </h2>

                      <h2 className={style.userBorn}>
                        {user.born
                          ? user.born.split("-")[2] +
                            "." +
                            user.born.split("-")[1] +
                            "." +
                            user.born.split("-")[0]
                          : ""}
                      </h2>
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
          <span
            ref={paginRef}
            style={{ opacity: 0, height: 1, display: "block" }}
          ></span>
        </div>
      )}
    </div>
  );
};

export default TreeSearchInput;
