import React from "react";
import Microphone from "../../../img/Microphone.svg";
import closeMsg from "../../../img/close.svg";
import check from "../../../img/check.svg";
import style from "../../../messagPage.module.css";
import useRecorder from "../../../../../utils/useRecorder";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const AudioMessage = ({
  setStartAudioState,
  startAudioState,
  parent_id,
  contact_id,
  setMessageIdMass,
  setContactSettings,
  setParent_id,
  setAnswerBlock,
  setMessages,
  setScroll,
  setModalChat,
  setModalType,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [timer, setTimer] = useState({ minute: 0, seconds: 0 });
  const [duration, setDuration] = useState(0);
  const [sendAudio, setSendAudio] = useState(false);
  let [voice, isRecording, startRecording, stopRecording] = useRecorder();
  const id = useParams();

  //Обнуление при переходе к другому диалогу
  useEffect(() => {
    setMessageIdMass([]);
    setParent_id(-1);
    handleStopAudio();
  }, [id.id]);

  //Начало записи
  const handleStartAudio = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        startRecording();
        setStartAudioState(true);
      })
      .catch(() => {
        setModalType("microOff");
        setModalChat(true);
      });
  };

  //Отправка записи
  const handleSendAudio = () => {
    if (duration > 0) {
      stopRecording();
      setSendAudio(true);
    }
  };

  useEffect(() => {
    if (voice.length > 0 && sendAudio) {
      const date = new Date();
      let mark =
        date.getHours() +
        "" +
        date.getMinutes() +
        "" +
        date.getSeconds() +
        "" +
        date.getMilliseconds() +
        "" +
        Math.random().toString(36).substring(2);
      const voiceBlob = new Blob(voice, {
        type: "audio/wav",
      });
      let fd = new FormData();
      fd.append("voice", voiceBlob);
      fd.append("mark", mark);
      fd.append("duration", duration);
      fd.append("contact_id", contact_id);
      fd.append("_method", "PUT");
      fd.append("parent_id", parent_id);
      axios({
        method: "post",
        url: "https://gargalo.ru/api/messages/send",
        data: fd,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      })
        .then((res) => {
          setSendAudio(false);
          setStartAudioState(false);
          setTimer({ ...timer, seconds: 0, minute: 0 });
          setDuration(0);
          setMessageIdMass([]);
          setParent_id(-1);
          setAnswerBlock(false);
          setMessages((prevState) => [res.data, ...prevState]);
          setScroll(true);
        })
        .catch((error) => {
          if (
            error.response.data.errors[0] ===
            "Этот пользователь добавил вас в черный список"
          ) {
            setContactSettings((prevState) => ({ ...prevState, blacklist: 1 }));
          }
        });
    }
  }, [voice, sendAudio]);

  //Отмена записи
  const handleStopAudio = () => {
    stopRecording();
    setStartAudioState(false);
    setTimer({ ...timer, seconds: 0, minute: 0 });
    setDuration(0);
  };

  //Таймер
  useEffect(() => {
    if (startAudioState) {
      const timerFun = setTimeout(() => {
        if (timer.seconds < 59) {
          setTimer({ ...timer, seconds: timer.seconds + 1 });
        } else {
          setTimer({ ...timer, seconds: 0, minute: timer.minute + 1 });
        }
        setDuration(duration + 1);
      }, 1000);
      return () => clearTimeout(timerFun);
    }
  }, [startAudioState, duration]);

  return (
    <div>
      {!startAudioState ? (
        <button
          className={style.audioBtn}
          onClick={handleStartAudio}
          disabled={isRecording}
        >
          <img
            src={Microphone}
            style={{
              width: 12,
              height: 17,
              marginLeft: 16,
            }}
            alt="Micro"
          />
        </button>
      ) : (
        <div className={style.messageAudioBox}>
          <button
            className={style.audioBtn}
            onClick={handleStopAudio}
            disabled={!isRecording}
          >
            <img
              src={closeMsg}
              alt=""
              style={{
                width: 13,
                height: 13,
              }}
            />
          </button>
          <h3 className={style.second}>
            {timer.minute}:{timer.seconds < 10 && "0"}
            {timer.seconds}
          </h3>
          <button
            className={style.audioBtn}
            onClick={handleSendAudio}
            disabled={!isRecording}
          >
            <img
              src={check}
              alt=""
              style={{
                width: 17,
                height: 11,
              }}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default AudioMessage;
