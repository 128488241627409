import React, { useState } from "react";
import style from "./treeComponents.module.css";
import close from "../img/Close.svg";
import { useEffect } from "react";

const TreeNotifModal = ({ notif, setNotif }) => {
  const [hoverNotif, setHoverNotif] = useState(false);

  //Скрытие уведомления спустя 5 сек
  useEffect(() => {
    if (notif && !hoverNotif) {
      const nitifTime = setTimeout(() => {
        setNotif();
      }, 5000);
      return () => clearTimeout(nitifTime);
    }
  }, [notif, hoverNotif]);

  return (
    <div
      className={style.notifWrapp}
      onMouseOver={() => setHoverNotif(true)}
      onMouseOut={() => setHoverNotif(false)}
      style={{
        visibility: notif || hoverNotif ? "visible" : "hidden",
        opacity: notif || hoverNotif ? "1" : "0",
      }}
    >
      <p className={style.notifText}>
        {notif?.target}{" "}
        {notif?.event === "add"
          ? "добавлен в древо тейпа"
          : notif?.event === "edit"
          ? "отредактирован(а) в древе тейпа"
          : "был(а) удален(а) из древа тейпа"}
      </p>
      <img
        src={close}
        alt="Close"
        className={style.closeNitif}
        onClick={() => setNotif()}
      />
    </div>
  );
};

export default TreeNotifModal;
