export function dateConverter(value) {
  const date = value.split("-");
  const dateSecond = date[2].split(" ");
  const dateTime = dateSecond[1].split(":");
  const time = new Date();

  const years = Number(date[0]);
  const month = Number(date[1]);
  const day = Number(dateSecond[0]);
  const hours = Number(dateTime[0]);
  const minutes = Number(dateTime[1]);
  const seconds = Number(dateTime[2]);
  function getAllMonth() {
    switch (month) {
      case 1:
        return "Янв";

      case 2:
        return "Фев";

      case 3:
        return "Мар";

      case 4:
        return "Апр";

      case 5:
        return "Мая";

      case 6:
        return "Июня";

      case 7:
        return "Июля";

      case 8:
        return "Авг";

      case 9:
        return "Сен";

      case 10:
        return "Окт";

      case 11:
        return "Нояб";

      case 12:
        return "Дек";
    }
  }

  if (time.getDate() - day > 1) {
    return `${day} ${getAllMonth()} в ${hours}:${
      minutes > 9 ? minutes : "0" + minutes
    }`;
  } else if (time.getDate() - day === 1) {
    return `вчера в ${hours}:${minutes} `;
  } else {
    if (time.getHours() - hours <= 3 && time.getHours() - hours > 1) {
      return time.getHours() - hours + " часа назад";
    } else if (time.getHours() - hours === 1) {
      return time.getHours() - hours + " час назад";
    } else if (time.getHours() - hours > 3) {
      return `сегодня в ${hours}:${minutes} `;
    } else {
      if (time.getMinutes() - minutes >= 1) {
        return time.getMinutes() - minutes + " мин назад";
      } else {
        if (time.getSeconds() - seconds > 0) {
          return time.getSeconds() - seconds + " секунд назад";
        }
      }
    }
  }
}
