import axios from "axios";
import config from "./http.config.json";
import { toast } from "react-toastify";
axios.defaults.baseURL = config.apiEndpoint;

const token = document.cookie.match(/token=(.+?)(;|$)/);

export default class PostService {
  // Получение user
  static async getUser() {
    try {
      const response = await axios({
        method: "GET",
        url: `/user`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      window.userParam = response.data;
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить информацию о пользователе");
    }
  }
  //Удаление аватара
  static async deleteAvatar(id) {
    try {
      const response = await axios({
        method: "delete",
        url: `/settings/avatar/delete?user_id=${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось удалить аватар");
    }
  }
  //Удаление аватара в карточке
  static async deleteNodeAvatar(id) {
    try {
      const response = await axios({
        method: "delete",
        url: `/settings/avatar/delete?node_id=${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось удалить аватар");
    }
  }
  //Добавление аватара
  static async addAvatar(id, element) {
    const formData = new FormData();
    formData.append("user_id", id);
    formData.append("avatar", element);
    try {
      const response = await axios({
        method: "post",
        url: `/settings/avatar`,
        data: formData,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось удалить аватар");
    }
  }
  //Добавление поста
  static async addPost(
    media,
    title,
    postVisible,
    commVisible,
    voteCount,
    post_id,
    medias,
    teipId
  ) {
    const formData = new FormData();
    media.map((res) => {
      if (res) {
        return formData.append("media[]", res);
      }
    });
    title && formData.append("content", title);
    Object.entries(postVisible).map((res) => {
      if (res[1]) {
        formData.append("permissions[]", res[0]);
      }
    });
    formData.append("comments_on", commVisible.value);
    formData.append("marked_users[]", null);
    {
      post_id && formData.append("post_id", post_id);
    }
    {
      medias &&
        medias.map((res) => {
          if (res) {
            return formData.append("medias[]", res.link);
          }
        });
    }
    voteCount &&
      Object.values(voteCount).map((res) => {
        if (res.value) {
          formData.append("votes[]", res.value);
        }
      });

    formData.append("proportions", "{ width: 700, height: 360 }");
    {
      teipId && formData.append("recommendate", teipId);
    }
    try {
      console.log({
        media: media,
        title: title,
        postVisible: postVisible,
        commVisible: commVisible,
        voteCount: voteCount,
        post_id: post_id,
        medias: medias,
        teipId: teipId,
      });
      const response = await axios({
        method: "POST",
        url: `/wall/store`,
        data: formData,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось добавить пост");
    }
  }
  // Удаление поста
  static async deletePost(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/wall/delete/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      toast.error(e.response.data.errors[0]);
    }
  }
  // Принять предложенный пост
  static async proposePost(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/wall/recommendate/accept/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      toast.error(e.response.data.errors[0]);
    }
  }
  // Отклонить предложенный пост
  static async deviatePost(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/wall/recommendate/ignore/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      toast.error(e.response.data.errors[0]);
    }
  }
  // Получение поста
  static async getAllOnePost(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/wall/post/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      toast.error(e.response.data.errors[0]);
    }
  }
  // получение
  static async getAllPosts(page) {
    try {
      const response = await axios({
        method: "GET",
        url: `/wall?page=${page}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить список лайкнувших");
    }
  }
  // список лайкнувших
  static async getAll(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/wall/like/${id}/list`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить список лайкнувших");
    }
  }
  // Полная информация о пользователе по ID с постами
  static async getAllUsersID(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/user/${id}/posts`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить информацию о пользователе");
    }
  }
  // Полная информация о пользователе по ID
  static async getAllUser(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/user/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить информацию о пользователе ");
    }
  }
  // Полная информация о пользователе по ID
  static async getAllUsersInfo(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/user/${id}/info`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить информацию о пользователе ");
    }
  }
  // Получение публикаций пользователя
  static async getAllPostsUser(id, page) {
    try {
      const response = await axios({
        method: "GET",
        url: `/wall/user/${id}?page=${page}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);

      toast.error("Не удалось получить информацию о пользователе ");
    }
  }
  // Получение предложенных публикаций на странице тейпа
  static async getWaitTeipPosts(type) {
    try {
      const response = await axios({
        method: "GET",
        url: `/wall/recommendate/${type}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить информацию о пользователе ");
    }
  }
  // Информация об элементе в дереве
  static async getAllUsersTeyps(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/node/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить информацию об элементе в древе");
    }
  }
  // добавление лайка и удаление лайка
  static async sendUserLike(ipType, id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/wall/${ipType}/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось поставить/удалить лайк");
    }
  }
  // Список проголосовавших за пост
  static async voteUsersList(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/wall/vote/${id}/list`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить список проголосовавших");
    }
  }
  // Список проголосовавших за пост
  static async addVote(id) {
    try {
      const response = await axios({
        method: "POST",
        url: `/wall/vote/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить список проголосовавших");
    }
  }
  // Закрыть/открыть аккаунт
  static async closedAndOpenAccount(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/user/change-status`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить список проголосовавших");
    }
  }
  // Подписчики и подписки
  static async getAllUsersFollowers(type, id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/users/${type}/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить список подписчиков и подписок");
    }
  }
  // Подписаться/отписаться
  static async getAllUsersSubscribeAndUnsubscribe(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/follow/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось подписаться/отписаться");
    }
  }
  // Отправка запроса на разрешение писать сообщения
  static async getSendMessages(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/chats/addchat/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      toast.error(e.message);
      toast.error("Не удалось отправить запрос");
    }
  }
  // Удалить подписчика
  static async getAllUsersFollowersDelete(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/follow/delete/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось удалить подписчика");
    }
  }
  // Поиск человека
  static async searchUsers(name, type) {
    try {
      const response = await axios({
        method: "GET",
        url: type
          ? `/getid?fio=${name}` + type
          : `/getid?fio=${name}&users_only`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }
  // Получение настроек уведомлений
  static async getAllNotifications() {
    try {
      const response = await axios({
        method: "GET",
        url: `/settings/get-notifications`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить настроек уведомлений");
    }
  }
  // Получение группы уведомлений
  static async getAllNotificationsListGroup() {
    try {
      const response = await axios({
        method: "GET",
        url: `/notifications/groups`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить настроек уведомлений");
    }
  }
  // Изменение настроек уведомлений
  static async sendNotifications(nots) {
    try {
      const response = await axios({
        method: "POST",
        url: `/settings/notifications`,
        data: nots,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось отправить список уведомлений");
    }
  }
  // Получение списка событий
  static async getAllEventList(type, page, filters) {
    try {
      const response = await axios({
        method: "GET",
        url: `/events?type=${type}&page=${page}&${filters.join("")}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить список событий");
    }
  }
  // Добавление события
  static async sendEvents(event, keys) {
    try {
      const response = await axios({
        method: "POST",
        url: `/events/store`,
        data: {
          price: event.price,
          info: event.event || event.purpose,
          organizer: event.organizerId,
          contacts: event.contacts,
          born_fio: event.child,
          mather_id: event.motherId,
          father_id: event.fatherId,
          funeral: event.deceasedId,
          groom: event.groomId,
          bride: event.wifeId,
          address: event.adress,
          details: event.details,
          date: event.date,
          title: keys.title,
          permission: keys.permission,
          action: keys.action,
        },

        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось добавить событие");
    }
  }
  //Подписка на события
  static async EventSubscribe(id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/subscribe/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось подписаться/отписаться");
    }
  }
  // Получение списка событий по фильтру
  static async getAllEventListFilter(req = "") {
    try {
      const response = await axios({
        method: "GET",
        url: `/events?` + req,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить список событий");
    }
  }
  // Получение жалоб на события
  static async getAllEventListReason() {
    try {
      const response = await axios({
        method: "GET",
        url: `/complaint/reasons/events`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось получить список жалоб");
    }
  }
  // Отправка списка жалоб
  static async sendComplaint(value, reasonId, reason, details) {
    try {
      const response = await axios({
        method: "POST",
        url: `/complaint`,
        data: {
          reason: value,
          source_id: reasonId,
          source: reason,
          text: details,
        },

        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось отправить жалобу");
    }
  }
  static async addAndRemoveComplaints(eventId) {
    try {
      const response = await axios({
        method: "PUT",
        url: `/events/${eventId}/remind`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }
  static async getAllEvent(eventId) {
    try {
      const response = await axios({
        method: "GET",
        url: `/events/${eventId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }
  // Получение комментариев
  static async getAllComments(type, id, pageNumber, underCommId) {
    try {
      const response = await axios({
        method: "GET",
        url: `/comments/${id}/${type}${
          underCommId ? "/" + underCommId : ""
        }?page=${pageNumber}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }
  // Добавление комментариев
  static async SendComments(comment, parent_id, source, source_id, action, id) {
    try {
      const response = await axios({
        method: "POST",
        url: `/comments/submit`,
        data: {
          content: comment,
          parent_id: parent_id,
          source: source,
          source_id: source_id,
          action: action,
          id: id,
        },
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  } // Удаление комментариев
  static async deleteComments(id, source) {
    try {
      const response = await axios({
        method: "POST",
        url: `/comments/delete`,
        data: {
          id: id,
          source: source,
        },
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }
  // добавление лайка и удаление лайка на комментарии
  static async sendCommentLike(type, id) {
    try {
      const response = await axios({
        method: "GET",
        url: `/comment/${type}/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      toast.error("Не удалось поставить/удалить лайк");
    }
  }
  // отправка кода
  static async sendNumberOfCode(type, number) {
    try {
      const response = await axios({
        method: "POST",
        url: `/v2/sms/send-code`,
        data: { source: type, phone: number },
        headers: {},
      });
      return response.data;
    } catch (e) {
      toast.error(e.response.data.errors[0]);
      return e.response.data.errors[0];
    }
  }
  // Проверка кода
  static async sendCodeVerify(type, codeForReset, number) {
    try {
      const response = await axios({
        method: "POST",
        url: `/v2/sms/verify-code`,
        data: { source: type, code: codeForReset, phone: number },
        headers: {},
      });
      return response.data;
    } catch (e) {
      toast.error(e.response.data.errors[0]);
    }
  }
  // Сброс пароля
  static async throwPassword(token, value, number) {
    try {
      const response = await axios({
        method: "POST",
        url: `/v2/password/reset`,
        data: {
          token: token,
          password: value.pass1,
          password_confirmation: value.pass2,
          phone: number,
        },
        headers: {},
      });
      return response.data;
    } catch (e) {
      toast.error(e.response.data.errors[0]);
    }
  }
  // Востановление пароля
  static async throwPassword(token, value, number) {
    try {
      const response = await axios({
        method: "POST",
        url: `/v2/password/reset`,
        data: {
          token: token,
          password: value.pass1,
          password_confirmation: value.pass2,
          phone: number,
        },
        headers: {},
      });
      return response.data;
    } catch (e) {
      toast.error(e.response.data.errors[0]);
    }
  }
  // Запрос списка жалоб
  static async getAllReasonsComplain() {
    try {
      const response = await axios({
        method: "GET",
        url: `/complaint/reasons/events`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      return response.data;
    } catch (e) {
      toast.error(e.response.data.errors[0]);
    }
  }
}
