import React from "react";
import style from "./reswitch.module.css";

const ReSwitch = ({ zag, setState, state, stateProps }) => {
  //Переключатель
  const handleSwitch = () => {
    if (state[stateProps]) {
      setState({ ...state, [stateProps]: false });
    } else {
      setState({ ...state, [stateProps]: true });
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
      {zag !== undefined && <h2 className={style.switchZag}>{zag}</h2>}
      <div
        className={style.switchWrapp}
        onClick={handleSwitch}
        style={{
          backgroundColor: state[stateProps] ? "#567F88" : "#E6E6E6",
        }}
      >
        <div
          className={style.switchBoll}
          style={{
            transform: state[stateProps] ? "translateX(100%)" : "translateX(0)",
          }}
        ></div>
      </div>
    </div>
  );
};

export default ReSwitch;
