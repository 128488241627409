import React, { useState, useEffect } from "react";
import style from "./adding.module.css";
import AddindImage from "./Adding.image";
import deleteImage from "./img/DeleteImg.svg";
import addimage from "../../img/Addimage.svg";
import AddingPostVisible from "./Adding.postVisible";
import AddingVote from "./Adding.vote";
import AddingTextField from "./Adding.textField";
import PostService from "./../../../../API/postService";
import Loader from "./../../Loader";
import { rootControls } from "./../../../../controllers/RootConroller";
import ReButton from "../../../reusedComponents/ReButton";
import { useParams } from "react-router-dom";

const Adding = ({
  handleClickOpen,
  handleAddPosts,
  open,
  setErrors,
  whereButton,
  where,
  whereHeader,
  teipId,
}) => {
  const [mediaState, setMediaState] = useState([]);
  const [globalMediaState, setGlobalMediaState] = useState();
  const [postTitle, setPostTitle] = useState("");
  const [pickerVisible, setPickerVisible] = useState(false);
  const [postVisible, setPostVisible] = useState({
    all: rootControls("ClosedAccount") ? true : false,
    followers: rootControls("ClosedAccount") ? false : true,
    family: false,
    teip: false,
  });
  const [commentVisible, setCommentVisible] = useState({
    title: "Комментарии вкл.",
    value: "1",
  });
  const [voteVisible, setVoteVisible] = useState(false);
  const [voteCount, setVoteCount] = useState([
    { title: "Ответ 1", value: "", id: 1 },
    { title: "Ответ 2", value: "", id: 2 },
  ]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setErrors(mediaState.length + postTitle.length);
  }, [mediaState, postTitle]);
  useEffect(() => {
    setGlobalMediaState(mediaState[mediaState.length - 1]);
  }, [mediaState]);
  const handleClickPostAdd = async () => {
    setLoading(true);
    try {
      const data = await PostService.addPost(
        mediaState,
        postTitle,
        postVisible,
        commentVisible,
        voteCount,
        null,
        null,
        teipId
      );
      window.location.reload();
      if (data) {
        handleAddPosts(data);
      }
      setMediaState([]);
      setGlobalMediaState();
      setPostTitle("");
      setPickerVisible(false);
      setPostVisible({
        all: rootControls("ClosedAccount") ? true : false,
        followers: rootControls("ClosedAccount") ? false : true,
        family: false,
        teip: false,
      });
      setCommentVisible({
        title: "Комментарии вкл.",
        value: "1",
      });
      setVoteCount([
        { title: "Ответ 1", value: "", id: 1 },
        { title: "Ответ 2", value: "", id: 2 },
      ]);
      setVoteVisible(false);
      handleClickOpen();
      setLoading(false);
    } catch (error) {
      handleClickOpen();
      setLoading(false);
    }
  };
  const handleChangeMedia = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      setMediaState((prev) => [...prev, e.target.files[0]]);
    }
  };

  return (
    <div
      className={style.wrap}
      style={{ display: open ? "" : "none" }}
      id="addingWrap"
    >
      <form
        action="https://gargalo.ru/wall/store"
        method="post"
        encType="multipart/form-data"
        id="formPost"
      >
        <div className={style.wrap1}>{whereHeader}</div>
        {loading && (
          <div style={{ marginBottom: 100 }}>
            <Loader />
          </div>
        )}
        {!loading && (
          <>
            {globalMediaState && (
              <img
                src={URL.createObjectURL(globalMediaState)}
                className={style.global_image}
              />
            )}
            <input
              type="file"
              name="mediaInput[]"
              id="inputFile"
              className={style.inputImg}
              style={{ height: 10 }}
              accept="image/*,video/*"
              onChange={(e) => handleChangeMedia(e)}
            ></input>
            <div className={style.imageBox}>
              {mediaState.map((item, index) => (
                <AddindImage
                  key={index}
                  index={index}
                  srcId={item}
                  data={mediaState}
                  setMediaState={setMediaState}
                  setGlobalMediaState={setGlobalMediaState}
                  globalMediaState={globalMediaState}
                />
              ))}

              {mediaState.length < 10 && (
                <label
                  htmlFor="inputFile"
                  style={{
                    marginTop: mediaState.length === 9 ? 12 : "",
                  }}
                  className={style.inputWrapper}
                >
                  <img
                    className={style.inputIcon}
                    src={addimage}
                    title="Выбрать файл"
                    alt="Выбрать файл"
                  />
                </label>
              )}
            </div>

            <AddingTextField
              postTitle={postTitle}
              setPostTitle={setPostTitle}
              pickerVisible={pickerVisible}
              setPickerVisible={setPickerVisible}
            />
            <div className={style.vote_visible}>
              {voteVisible ? (
                <div className={style.vote_visible_div}>
                  <h2 className={style.vote_visible_title}>Опрос</h2>
                  <img
                    className={style.vote_visible_image}
                    src={deleteImage}
                    alt=""
                    onClick={() => setVoteVisible((prev) => !prev)}
                  />
                </div>
              ) : (
                <ReButton
                  type={"button"}
                  classes="third"
                  height={32}
                  handleClick={() => setVoteVisible((prev) => !prev)}
                  FWeight={500}
                  font="small"
                  text={"Добавить опрос"}
                />
              )}
            </div>

            {voteVisible && (
              <AddingVote voteCount={voteCount} setVoteCount={setVoteCount} />
            )}
            <div className={style.sendBox}>
              <AddingPostVisible
                postVisible={postVisible}
                commentVisible={commentVisible}
                setPostVisible={setPostVisible}
                setCommentVisible={setCommentVisible}
                where={where}
              />

              <ReButton
                type={"button"}
                classes="main"
                height={40}
                handleClick={() => handleClickPostAdd()}
                disabled={!(mediaState.length !== 0)}
                FWeight={600}
                FSize={14}
                text={whereButton}
              />
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default Adding;
