import React, { useState } from "react";
import commentStyle from "./Comment.module.css";
import likeFalseImage from "./img/LikeDefault.svg";
import likeHoverImage from "./img/LikeHover.svg";
import likeTrueImage from "./img/LikeTrue.svg";
import PostService from "../../../API/postService";
import { useNavigate } from "react-router-dom";
import { dateConverter } from "../../utils/dateСonverter";
import ReDropDown from "../../reusedComponents/ReDropDown";
import ImageKebabMenu from "../img/Kebab menu.svg";
import editImage from "../img/Edit.svg";
import deleteImage from "../img/Delete.svg";
import CloseImage from "../img/close.svg";
import CheckImage from "../img/Check.svg";
import ReInput from "../../reusedComponents/ReInput";
import smileImage from "../img/Emoji.svg";
import Picker from "emoji-picker-react";

const UnderComment = ({
  content,
  setUnderComments,
  user,
  setCommentCount,
  setValue,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(content);
  const [emoji, setEmoji] = useState(false);
  const [likeHover, setLikeHover] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [likes, setLikes] = useState({
    isLiked: data.is_liked,
    likesCount: data.likes,
  });
  const [editComment, setEditComment] = useState(false);
  const [editCommentTitle, setEditCommentTitle] = useState("");

  // добавление и удаление лайка
  const handleAddAndDeleteLikes = (type) => {
    data.is_liked = !data.is_liked;
    data.is_liked ? (data.likes += 1) : (data.likes -= 1);
    setLikes((prevState) => ({
      ...prevState,
      isLiked: !prevState.isLiked,
      likesCount: prevState.isLiked
        ? prevState.likesCount - 1
        : prevState.likesCount + 1,
    }));

    PostService.sendCommentLike(type, data.id);
  };

  // Перекинуть на другйо роут при нажатии на никнейм
  const handleClickCommentNickname = (commId) => {
    navigate("/account/" + commId);
  };
  // Удаление подкомента
  const handleClickDeleteComment = () => {
    PostService.deleteComments(data.id, "post");
    setCommentCount((prev) => prev - 1);
    setValue((prev) => ({ ...prev, childs_count: prev.childs_count - 1 }));
    setUnderComments((prev) => [...prev].filter((item) => item.id !== data.id));
  };
  const handleClickEditComment = () => {
    setEditComment(true);
    setEditCommentTitle(data.text);
  };
  const handleClickEditCommentTrue = () => {
    setEditComment(false);
    setData((prev) => ({ ...prev, text: editCommentTitle }));
  };

  const onEmojiClick = (event, emojiObject) => {
    setEditCommentTitle((prevState) => prevState + emojiObject.emoji);
  };
  return (
    <>
      {editComment ? (
        <div
          className={commentStyle.comment_edit}
          style={{ marginTop: 20, marginLeft: 0 }}
        >
          <div className={commentStyle.comment_edit_second}>
            <img
              className={
                commentStyle.comment_image +
                " " +
                commentStyle.under_comment_image
              }
              src={`https://gargalo.ru/${user.avatar_small}`}
              alt=""
              style={{ marginRight: 12 }}
            />
            <ReInput
              location="start"
              iconStart={smileImage}
              classesIcon={commentStyle.UnderclassesIcon}
              classesInput={commentStyle.classesInput}
              classesDiv={commentStyle.classesDiv}
              handleChange={(e) => setEditCommentTitle(e.target.value)}
              value={editCommentTitle}
              placeholder="Написать комментарий"
              onMouseOver={() => setEmoji(true)}
              onMouseOut={() => setEmoji(false)}
            />
          </div>
          <div className={commentStyle.comment_edit_div}>
            <img
              className={commentStyle.comment_edit_image}
              onClick={() => setEditComment(false)}
              src={CloseImage}
              alt=""
            />
            <img
              style={{ marginLeft: 8 }}
              onClick={handleClickEditCommentTrue}
              className={commentStyle.comment_edit_image}
              src={CheckImage}
              alt=""
            />
          </div>
          {emoji && (
            <div
              onMouseOver={() => setEmoji(true)}
              onMouseOut={() => setEmoji(false)}
              className={commentStyle.emoji_content_edit}
            >
              <Picker onEmojiClick={onEmojiClick} />
            </div>
          )}
        </div>
      ) : (
        <div key={data.id} className={commentStyle.under_comment}>
          <img
            onClick={() => handleClickCommentNickname(data.user.user_id)}
            src={"https://gargalo.ru" + data.user.avatar_small}
            className={
              commentStyle.comment_image +
              " " +
              commentStyle.under_comment_image
            }
            alt=""
          />
          <div
            className={
              commentStyle.comment_content +
              " " +
              commentStyle.under_comment_content
            }
          >
            <div style={{ display: "flex" }}>
              <p
                className={commentStyle.comment_name}
                onClick={() => handleClickCommentNickname(data.user.user_id)}
              >
                {data.user.nickname}
              </p>
              {user.user_id === data.user.user_id && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "start",
                    position: "relative",
                    height: 20,
                    width: 40,
                  }}
                  onMouseOver={() => setDropDown(true)}
                  onMouseOut={() => setDropDown(false)}
                >
                  <img
                    className={commentStyle.comment_kebab_menu_hover}
                    src={ImageKebabMenu}
                    alt=""
                  />
                  {dropDown && (
                    <div
                      className={commentStyle.ReDropDown}
                      onMouseOver={() => setDropDown(true)}
                      onMouseOut={() => setDropDown(false)}
                    >
                      <ReDropDown
                        data={[
                          {
                            title: "Редактировать",
                            icon: editImage,
                            handleClick: handleClickEditComment,
                          },
                          {
                            title: "Удалить",
                            icon: deleteImage,
                            handleClick: handleClickDeleteComment,
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            <p className={commentStyle.comment_text}>
              <span
                className={
                  commentStyle.comment_name +
                  " " +
                  commentStyle.comments_suptitle_text
                }
                onClick={() =>
                  handleClickCommentNickname(data.parent_comment_user.user_id)
                }
              >
                {data.parent_comment_user.nickname}
              </span>
              {data.text}
            </p>
            <div className={commentStyle.comment_btn}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {user.is_real && (
                  <button className={commentStyle.comment_button}>
                    Ответить
                  </button>
                )}
                <p className={commentStyle.comment_time}>
                  {dateConverter(data.created_at)}
                </p>
              </div>
              <div className={commentStyle.comment_likes_div}>
                {likes.likesCount > 0 ? (
                  <>
                    <img
                      onClick={() =>
                        handleAddAndDeleteLikes(
                          likes.isLiked ? "unlike" : "like"
                        )
                      }
                      src={likes.isLiked ? likeTrueImage : likeHoverImage}
                      className={commentStyle.comment_likes}
                      alt=""
                    />
                    <p className={commentStyle.comments_likes_count}>
                      {likes.likesCount}
                    </p>
                  </>
                ) : (
                  <img
                    onClick={() =>
                      handleAddAndDeleteLikes(likes.isLiked ? "unlike" : "like")
                    }
                    onMouseOver={() => setLikeHover(true)}
                    onMouseOut={() => setLikeHover(false)}
                    src={
                      likes.isLiked
                        ? likeTrueImage
                        : likeHover
                        ? likeHoverImage
                        : likeFalseImage
                    }
                    className={commentStyle.comment_likes}
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UnderComment;
