import React, { useEffect, useRef } from "react";
import { useState } from "react";
import style from "./calendar.module.css";
import * as calendar from "../../utils/calendar";

const Calendar = ({
  calendarStyle,
  defaultDate,
  state,
  stateProps,
  extraProps,
  setState,
  setCalendarBlock,
}) => {
  const defaultProps = {
    years: Array.from(
      { length: new Date().getFullYear() - 1899 },
      (_, index) => index + 1900
    ),
    monthNames: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    weekDayNames: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
  };

  const [date, setDate] = useState({
    date: defaultDate
      ? new Date(defaultDate.year, defaultDate.mounth, defaultDate.day)
      : new Date(),
    day: defaultDate ? defaultDate.day : new Date().getDate(),
    mounth: defaultDate
      ? defaultProps.monthNames[defaultDate.mounth]
      : defaultProps.monthNames[new Date().getMonth()],
    mounthIndex: defaultDate ? defaultDate.mounth : new Date().getMonth(),
    years: defaultDate ? defaultDate.year : new Date().getFullYear(),
  });

  const monthData = calendar.getMonthData(date.years, date.mounthIndex);

  const arrow = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7.5L10 12.5L5 7.5"
        stroke="#567F88"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  //Заполнение при клике по числу
  const handleDayClick = (dateNew) => {
    const dateNow = new Date(date.years, date.mounthIndex, dateNew.getDate());
    // const date = new Date(dateNow.toISOString());
    const newdate = dateNow.toLocaleDateString().split(".").reverse().join("-");
    setDate({ ...date, date: newdate, day: dateNew.getDate() });
    setState({
      ...state,
      [stateProps]: newdate,
      [extraProps]: newdate,
    });
    setCalendarBlock(false);
  };

  //Выпадающие списки месяца/года
  const ReSelect = ({ massItems, state, stateProps, setState, width }) => {
    const [openSelect, setOpenSelect] = useState(false);
    const listRef = useRef(null);

    //Открытие списка месяца/года
    const handleOpenSelect = () => {
      setOpenSelect(!openSelect);
    };

    //Заполнение месяца/года
    const handleSelectChange = (e, name, index) => {
      e.stopPropagation();
      if (stateProps === "mounth") {
        const dateNew = new Date(state.years, index, state.day);
        setState({
          ...state,
          date: dateNew,
          mounth: defaultProps.monthNames[index],
          mounthIndex: index,
        });
      } else {
        const dateNew = new Date(name, state.mounthIndex, state.day);
        setState({ ...state, date: dateNew, years: name });
      }
    };

    //Скролл до текущего месяца/года
    useEffect(() => {
      if (!openSelect) {
        return;
      }
      if (stateProps === "mounth") {
        listRef.current.scrollTo(
          0,
          document.getElementById(`${date.mounth}`).offsetTop - 8
        );
      } else {
        listRef.current.scrollTo(
          0,
          document.getElementById(`${date.years}`).offsetTop - 8
        );
      }
    }, [openSelect, listRef.current]);

    //Закрытие селекта при клике на пустую область
    const selectRef = useRef(null);

    const closeSelect = () => {
      setOpenSelect(false);
    };

    useEffect(() => {
      if (selectRef.current) {
        const onClick = (e) =>
          selectRef.current.contains(e.target) || closeSelect();
        document.addEventListener("click", onClick);
        return () => document.removeEventListener("click", onClick);
      }
    }, [selectRef.current]);

    return (
      <div className={style.calendarSelect} ref={selectRef}>
        <div className={style.selectHead} onClick={handleOpenSelect}>
          <span className={style.selectHeadZag}>{state[stateProps]}</span>
          {arrow}
        </div>
        {openSelect && (
          <div
            className={style.selectOption}
            ref={listRef}
            style={{
              right: stateProps === "years" && 0,
              left: stateProps === "mounth" && 0,
              width: width,
            }}
          >
            {massItems.map((item, index) => {
              return (
                <span
                  style={{
                    backgroundColor:
                      ((stateProps === "mounth" && item === date.mounth) ||
                        item === date.years) &&
                      "var(--back-color-light-blue)",
                  }}
                  id={item}
                  key={item}
                  className={style.optionItem}
                  onClick={(e) => handleSelectChange(e, item, index)}
                >
                  {item}
                </span>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={style.calendar} style={calendarStyle}>
      <div className={style.calendarHead}>
        <ReSelect
          massItems={defaultProps.monthNames}
          state={date}
          stateProps={"mounth"}
          setState={setDate}
          width={108}
        />
        <ReSelect
          massItems={defaultProps.years}
          state={date}
          stateProps={"years"}
          setState={setDate}
          width={69}
        />
      </div>
      <table>
        <thead>
          <tr className={style.weekDayBlock}>
            {defaultProps.weekDayNames.map((name) => {
              return (
                <th key={name} className={style.weekDayZag}>
                  {name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {monthData.map((week, index) => (
            <tr key={index} className={style.weekWrapp}>
              {week.map((dates, index) =>
                dates ? (
                  <td
                    onClick={() => handleDayClick(dates)}
                    key={index}
                    className={style.dateBlock}
                    style={{
                      backgroundColor:
                        defaultDate &&
                        defaultDate.day === dates.getDate() &&
                        defaultDate.mounth === dates.getMonth() &&
                        defaultDate.year === dates.getFullYear() &&
                        "var(--back-color-light-blue)",
                    }}
                  >
                    {dates.getDate()}
                  </td>
                ) : (
                  <td key={index} className={style.dateBlockNull} />
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Calendar;
