import React, { useState, useContext } from "react";
import style from "./postsModal.module.css";
import checkImage from "./img/check.svg";
import PostService from "./../../../../../../API/postService";
import ReButton from "../../../../../reusedComponents/ReButton";

const PostsComplainModal = ({
  data,
  item,
  setComplainModal,
  handleDeleteAnotherPost,
}) => {
  const [complain, setComplain] = useState({
    span: false,
    symbol: false,
    18: false,
  });

  // Закрытие модалки при нажатии на область вне модалки
  const handleClickDeleteModal = () => {
    setComplainModal(false);
  };

  // Остановить закрытие модалки если нажато на модалку
  const handleClickDeleteModalContent = (e) => {
    e.stopPropagation();
  };
  // Нажатие на категорию жалобы
  const handleClickComplain = (type) => {
    setComplain((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
    data.map((item) => {
      if (item.type !== type) {
        setComplain((prev) => ({
          ...prev,
          [item.type]: false,
        }));
      }
    });
  };
  // Выбор класса для radio
  const chooseClasses = (type) => {
    return type
      ? style.modal_check_radio + " " + style.modal_check_radio_active
      : style.modal_check_radio;
  };
  const handleClickSendComplain = async () => {
    let complainKey;
    Object.entries(complain).map((item) => {
      if (item[1]) {
        complainKey = item[0];
      }
    });

    try {
      const response = await PostService.sendComplaint(
        complainKey,
        item.id,
        item.theme === "post" ? "wall" : ""
      );
      if (response) {
        handleDeleteAnotherPost();
      }
      handleClickDeleteModal();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={style.modal} onClick={handleClickDeleteModal}>
      <div
        className={style.modal_content + " " + style.modal_complain_content}
        onClick={handleClickDeleteModalContent}
      >
        <h2
          className={style.modal_suptitle + " " + style.modal_suptitle_complain}
        >
          Причина жалобы
        </h2>

        {data ? (
          <>
            {data.map((item) => (
              <div
                className={style.modal_check_div}
                onClick={() => handleClickComplain(item.type)}
              >
                <div className={chooseClasses(complain[item.type])}>
                  {complain[item.type] && (
                    <img
                      src={checkImage}
                      className={style.modal_check_image}
                      alt=""
                    />
                  )}
                </div>
                <p className={style.modal_check_title}>{item.text}</p>
              </div>
            ))}
          </>
        ) : (
          <div>
            <div className={style.modal_plug_div} style={{ width: 230 }}></div>
            <div className={style.modal_plug_div} style={{ width: 300 }}></div>
            <div className={style.modal_plug_div} style={{ width: 230 }}></div>
          </div>
        )}
        <div className={style.modal_buttons + " " + style.modal_buttons_second}>
          <ReButton
            type={"button"}
            classes="main"
            handleClick={handleClickSendComplain}
            FWeight={600}
            FSize={14}
            height={40}
            text={"Отправить"}
            padding="12px 40px"
          />
          <ReButton
            type={"button"}
            classes="third"
            handleClick={handleClickDeleteModal}
            FWeight={600}
            FSize={14}
            text={"Отмена"}
            height={40}
            padding="12px 41px"
          />
        </div>
      </div>
    </div>
  );
};

export default PostsComplainModal;
