const initialState = {
  loading: true,
  posts: [],
  likes: null,
  vote: null,
  post: null,
  error: null,
  lastPage: null,
  currentPage: null,
  compainCategories: null,
  follow: null,
};

const token = document.cookie.match(/token=(.+?)(;|$)/);

const posts = (state = initialState, action) => {
  switch (action.type) {
    case "posts/pending":
      return {
        ...state,
        loading: true,
        posts: [],
        error: null,
      };
    case "posts/fullfilled":
      return {
        ...state,
        loading: false,
        posts: [...state.posts, ...action.payload.data],
        lastPage: action.payload.last_page,
        currentPage: action.payload.current_page,
        error: null,
      };
    case "posts/rejected":
      return {
        ...state,
        loading: false,
        posts: [],
        error: action.error,
      };
    case "onePosts/pending":
      return {
        ...state,
        loading: true,
        post: [],
        error: null,
      };
    case "onePosts/fullfilled":
      return {
        ...state,
        loading: false,
        post: action.payload,
        error: null,
      };
    case "onePosts/rejected":
      return {
        ...state,
        loading: false,
        post: [],
        error: action.error,
      };
    case "createPost/pending":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "createPost/fullfilled":
      return {
        ...state,
        loading: false,

        error: null,
      };
    case "createPost/rejected":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "pushLikePost/pending":
      return {
        ...state,
        // loading: true,
        error: null,
      };
    case "pushLikePost/fullfilled":
      return {
        ...state,
        // loading: false,
        likes: action.payload,
        error: null,
      };
    case "pushLikePost/rejected":
      return {
        ...state,
        // loading: false,
        error: action.error,
      };
    case "pullLikePost/pending":
      return {
        ...state,
        // loading: true,
        error: null,
      };
    case "pullLikePost/fullfilled":
      return {
        ...state,
        // loading: false,
        likes: action.payload,
        error: null,
      };
    case "pullLikePost/rejected":
      return {
        ...state,
        // loading: false,
        error: action.error,
      };
    case "sendVote/pending":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "sendVote/fullfilled":
      return {
        ...state,
        loading: false,
        vote: action.payload,
        error: null,
      };
    case "sendVote/rejected":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "deletePost/pending":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "deletePost/fullfilled":
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "deletePost/rejected":
      return {
        ...state,
        error: action.error,
      };
    case "getCompainCategories/pending":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "getCompainCategories/fullfilled":
      return {
        ...state,
        loading: false,
        compainCategories: action.payload,
        error: null,
      };
    case "getCompainCategories/rejected":
      return {
        ...state,
        error: action.error,
      };
    case "sendCompain/pending":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "sendCompain/fullfilled":
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "sendCompain/rejected":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "followAndUnFollow/pending":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "followAndUnFollow/fullfilled":
      return {
        ...state,
        loading: false,
        follow: action.payload,
        error: null,
      };
    case "followAndUnFollow/rejected":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

// const POST_TYPES = {
//   GET_POST_LOADING: "GET_POST_LOADING",
// };

export const getPosts = (page) => {
  return async (dispathch) => {
    dispathch({ type: "posts/pending" });
    try {
      const res = await fetch(`https://gargalo.ru/api/wall?page=${page}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      dispathch({ type: "posts/fullfilled", payload: json });
    } catch (error) {
      dispathch({ type: "posts/rejected", error: error });
    }
  };
};

export const getOnePost = () => {
  let query = String(document.location);
  const id = parseInt(query.match(/\d+$/));
  return async (dispathch) => {
    dispathch({ type: "onePosts/pending" });
    try {
      if (id) {
        const res = await fetch(`https://gargalo.ru/api/wall/post/${id}`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        });
        const json = await res.json();
        dispathch({ type: "onePosts/fullfilled", payload: json });
      }
    } catch (error) {
      dispathch({ type: "onePosts/rejected", error: error });
    }
  };
};

export const pushLikePost = (id, checkAddLick) => {
  return async (dispathch) => {
    dispathch({ type: "pushLikePost/pending" });
    try {
      const res = await fetch(`https://gargalo.ru/api/wall/like/${id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      json._status = res.status;
      checkAddLick(json);
      dispathch({ type: "pushLikePost/fullfilled", payload: json });
    } catch (error) {
      dispathch({ type: "pushLikePost/rejected", error: error });
    }
  };
};

export const pullLikePost = (id, checkDeleteLike) => {
  return async (dispathch) => {
    dispathch({ type: "pullLikePost/pending" });
    try {
      const res = await fetch(`https://gargalo.ru/api/wall/unlike/${id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      json._status = res.status;
      checkDeleteLike(json);
      dispathch({ type: "pullLikePost/fullfilled", payload: json });
    } catch (error) {
      dispathch({ type: "pullLikePost/rejected", error: error });
    }
  };
};

export const sendVote = (id, checkVote) => {
  return async (dispathch) => {
    dispathch({ type: "sendVote/pending" });
    try {
      const res = await fetch(`https://gargalo.ru/api/wall/vote/${id}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      json._status = res.status;
      checkVote(json);
      dispathch({ type: "sendVote/fullfilled", payload: json });
    } catch (error) {
      dispathch({ type: "sendVote/rejected", error: error });
    }
  };
};

export const deletePost = (id) => {
  return async (dispathch) => {
    dispathch({ type: "deletePost/pending" });
    try {
      const res = await fetch(`https://gargalo.ru/api/wall/delete/${id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      dispathch({ type: "deletePost/fullfilled", payload: json });
    } catch (error) {
      dispathch({ type: "deletePost/rejected", error: error });
    }
  };
};

export const getCompainCategories = () => {
  return async (dispathch) => {
    dispathch({ type: "getCompainCategories/pending" });
    try {
      const res = await fetch(
        `https://gargalo.ru/api/complaint/reasons/events`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      );
      const json = await res.json();

      dispathch({ type: "getCompainCategories/fullfilled", payload: json });
    } catch (error) {
      dispathch({ type: "getCompainCategories/rejected", error: error });
    }
  };
};

export const sendCompain = (id, reason, type, text) => {
  return async (dispathch) => {
    dispathch({ type: "sendCompain/pending" });
    try {
      const res = await fetch(`https://gargalo.ru/api/complaint`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
        body: JSON.stringify({
          reason: reason,
          source_id: id,
          source: type,
          text,
        }),
      });
      const json = await res.json();
      dispathch({ type: "sendCompain/fullfilled", payload: json });
    } catch (error) {
      dispathch({ type: "sendCompain/rejected", error: error });
    }
  };
};

export const followAndUnFollow = (id, checkFollow) => {
  return async (dispathch) => {
    dispathch({ type: "followAndUnFollow/pending" });
    try {
      const res = await fetch(`https://gargalo.ru/api/follow/${id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token[1]}`,
          "User-Token": 1,
        },
      });
      const json = await res.json();
      checkFollow(json);
      dispathch({ type: "followAndUnFollow/fullfilled", payload: json });
    } catch (error) {
      dispathch({ type: "followAndUnFollow/rejected", error: error });
    }
  };
};

export default posts;
