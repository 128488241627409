import React from "react";
import { useSelector } from "react-redux";
import X from "../../img/X.svg";
import styleFollowers from "./followers.module.css";
import PaginationScroll from "../paginationScroll/paginationScroll";
import FollowBlockList from "./followBlockList";

const FollowBlock = ({
    changingLikesCounter,
    boolReused,
    user,
    followers,
    setFollowers,
    followBool,
    setFollowBool,
    btnRef,
}) => {
    const currentPageFollow = useSelector(
        (state) => state.users.currPageFollow
    );
    const lastPageFollow = useSelector((state) => state.users.lastPageFollow);

    return (
        <>
            {followBool && (
                <div className={styleFollowers.follow__main}>
                    <div className={styleFollowers.follow__block}>
                        <div className={styleFollowers.head}>
                            <h2 className={styleFollowers.follow_name}>
                                Подписки
                            </h2>
                            <img
                                onClick={() => setFollowBool(false)}
                                className={styleFollowers.close__follow__block}
                                src={X}
                            />
                        </div>
                        {followers ? (
                            <>
                                {followers.length !== 0 ? (
                                    <div
                                        className={
                                            styleFollowers.follow__scroll__div
                                        }
                                    >
                                        {followers.map((item, index) => {
                                            return (
                                                <FollowBlockList
                                                    changingLikesCounter={
                                                        changingLikesCounter
                                                    }
                                                    boolReused={boolReused}
                                                    user={user}
                                                    item={item}
                                                    index={index}
                                                    setFollowers={setFollowers}
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <h2 className={styleFollowers.loading}></h2>
                                )}
                            </>
                        ) : (
                            <h2 className={styleFollowers.loading}>
                                Loading...
                            </h2>
                        )}
                        <PaginationScroll
                            btnRef={btnRef}
                            page1={lastPageFollow}
                            page2={currentPageFollow}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default FollowBlock;
