import React, { useState, useEffect, useRef, useContext } from "react";
import sendMessageImage from "../img/Send.svg";
import smileImage from "../img/Emoji.svg";
import filterImage from "../img/Sort.svg";
import PostService from "../../../API/postService";
import commentStyle from "./Comment.module.css";
import CommentsList from "./CommentsList";
import Picker from "emoji-picker-react";
import loader from "../../content/img/loader.gif";
import ReInput from "./../../reusedComponents/ReInput";
import { RegistrationContext } from "../../context";
import ReDropDown from "../../reusedComponents/ReDropDown";

const Comments = ({
  commentType,
  commentID,
  commentCount,
  setCommentCount,
}) => {
  const { user } = useContext(RegistrationContext);
  const [comments, setComments] = useState();
  const [commentsCount, setCommentsCount] = useState();
  const [commentsNextPageUrl, setCommentsNextPageUrl] = useState();
  const [commentsRequest, setCommentsRequest] = useState();
  const [newCommentTitle, setNewCommentTitle] = useState("");
  const [newUnderComments, setNewUnderComments] = useState();
  const [newCommentsLoader, setNewCommentsLoader] = useState(false);
  const [emoji, setEmoji] = useState();
  const [page, setPage] = useState(1);
  const [filterTitle, setFilterTitle] = useState("popularity");
  const [filterState, setFilterState] = useState(false);
  const btnRef = useRef(null);

  // получение коментов
  useEffect(() => {
    PostService.getAllComments(commentType, commentID, page).then((res) => {
      if (res) {
        setComments(res.data);
        setComments((prev) => [...prev].sort((a, b) => b.likes - a.likes));
        setCommentsNextPageUrl(res.next_page_url);
        res.data.map(
          (item) =>
            item.childs_count > 0 &&
            setCommentCount((prev) => prev - item.childs_count)
        );
      }
    });
  }, []);

  useEffect(() => {
    if (comments) {
      setCommentsCount(commentCount - comments.length);
    }
  }, [comments]);

  useEffect(() => {
    if (comments) {
      setNewCommentsLoader(true);
      PostService.getAllComments(commentType, commentID, page).then((res) => {
        setNewCommentsLoader(false);
        if (res) {
          setCommentsRequest(res.data);
          setCommentsNextPageUrl(res.next_page_url);
          setComments((prevState) => [...prevState, ...res?.data]);
          res.data.map(
            (item) =>
              item.childs_count > 0 &&
              setCommentCount((prev) => prev - item.childs_count)
          );
        }
      });
    }
  }, [page]);

  useEffect(() => {
    const btn = btnRef.current;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((page) => page + 1);
      }
    });
    if (btn) observer.observe(btn);
    return () => {
      if (btn) observer.unobserve(btn);
    };
  }, [btnRef.current, comments]);

  // написание текста
  const handleChangeComment = ({ target }) => {
    const { value } = target;
    setNewCommentTitle(value);
  };

  // добавление комента
  const handleSendComments = async () => {
    if (newCommentTitle.length > 0) {
      const content = await PostService.SendComments(
        newCommentTitle,
        0,
        commentType,
        commentID
      );
      setCommentCount((prev) => prev + 1);
      setComments((prevState) => [content, ...prevState]);
      setNewCommentTitle("");
    }
  };

  // добавление эмодзи в текст
  const onEmojiClick = (event, emojiObject) => {
    setNewCommentTitle((prevState) => prevState + emojiObject.emoji);
  };
  // Отправить сообшение при клике на enter, а так же если зажать shift то ничего не выйдет
  const onKeyDownForAddComment = (e, parentId) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSendComments(parentId);
    }
  };

  const handleClickFilterBtn = (type) => {
    setFilterTitle(type);
    if (type === "popularity") {
      setComments((prev) => [...prev].sort((a, b) => b.likes - a.likes));
    } else {
      setComments((prev) => [...prev].sort((a, b) => b.id - a.id));
    }
    console.log(comments);
  };

  return (
    <>
      {comments ? (
        <>
          {comments.length !== 0 && (
            <div
              className={commentStyle.comments}
              style={{ marginTop: commentStyle === "event" && 0 }}
            >
              <div
                className={commentStyle.comments_filter}
                onClick={() => setFilterState((prev) => !prev)}
              >
                <img
                  className={commentStyle.comments_filter_image}
                  src={filterImage}
                  alt=""
                />
                <p className={commentStyle.comments_filter_title}>
                  {filterTitle === "popularity" ? "По популярности" : "По дате"}
                </p>
                <div className={commentStyle.ReDropDown_filter}>
                  {filterState && (
                    <ReDropDown
                      data={[
                        {
                          title: "По популярности",
                          handleClick: () => handleClickFilterBtn("popularity"),
                        },
                        {
                          title: "По дате",
                          handleClick: () => handleClickFilterBtn("date"),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>

              {comments?.map((comm) => (
                <CommentsList
                  key={comm.id + comm.text}
                  user={user}
                  setComments={setComments}
                  setCommentCount={setCommentCount}
                  content={comm}
                  newUnderComments={newUnderComments}
                  onKeyDownForAddComment={onKeyDownForAddComment}
                  commentID={commentID}
                  commentType={commentType}
                  setNewUnderComments={setNewUnderComments}
                />
              ))}

              {newCommentsLoader && commentsRequest?.length !== 0 && (
                <div className={commentStyle.loader}>
                  <img
                    className={commentStyle.laoder_img}
                    src={loader}
                    alt=""
                  />

                  <label htmlFor="" className={commentStyle.loader_text}>
                    Загрузка...
                  </label>
                </div>
              )}
              {commentsRequest?.length !== 0 ? (
                <>
                  {comments && commentsNextPageUrl && (
                    <button
                      className={commentStyle.comment_button}
                      style={{ marginBottom: 20 }}
                      onClick={() => setPage((prev) => prev + 1)}
                    >
                      Посмотреть другие комментарии ({commentsCount})
                    </button>
                  )}
                </>
              ) : (
                <h4 className={commentStyle.loader_plug}></h4>
              )}
            </div>
          )}
          <div className={commentStyle.comments_div}>
            {user?.is_real && (
              <div className={commentStyle.comments_input_div}>
                <img
                  className={commentStyle.comment_image}
                  src={`https://gargalo.ru/${user.avatar_small}`}
                  alt="1"
                  style={{ marginRight: 12 }}
                />
                <ReInput
                  location="start"
                  location2="end"
                  iconStart={smileImage}
                  iconEnd={sendMessageImage}
                  classesIcon={commentStyle.classesIcon}
                  onKeyDown={onKeyDownForAddComment}
                  handleChange={(e) => handleChangeComment(e)}
                  value={newCommentTitle}
                  placeholder="Написать комментарий"
                  onMouseOver={() => setEmoji(true)}
                  onMouseOut={() => setEmoji(false)}
                  handleClick2={handleSendComments}
                />
                <div
                  onMouseOver={() => setEmoji(true)}
                  onMouseOut={() => setEmoji(false)}
                  className={
                    commentStyle.emoji_content +
                    " " +
                    (emoji ? commentStyle.emoji_content_active : " ")
                  }
                >
                  <Picker onEmojiClick={onEmojiClick} />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={commentStyle.plug}>
          <h2 style={{ color: "#054857" }}>Загрузка комментариев...</h2>
        </div>
      )}
    </>
  );
};

export default Comments;
