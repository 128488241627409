import React from "react";
import style from "./complaints.module.css";
import axios from "axios";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { RegistrationContext } from "../../context";

const ComplaintsPage = () => {
  const [complaints, setComplaints] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState();
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [page, setPage] = useState(2);
  const [type, setType] = useState("considered=0&");

  useEffect(() => {
    axios({
      method: "GET",
      url: "https://gargalo.ru/api/complaints?considered=0",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setComplaints(res.data.data);
      setNextPageUrl(res.data.next_page_url);
    });
  }, []);

  //Фильтрация жалоб
  const btnPolColorAll = (e, text) => {
    let bntNode = document.querySelectorAll("[data-distfil]");
    bntNode.forEach((element) => {
      element.style.backgroundColor = "white";
      element.style.color = "#567f88";
    });
    let btnActiv = e.currentTarget;
    btnActiv.style.backgroundColor = "#2199cc";
    btnActiv.style.color = "#ffffff";
    setPage(2);
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/complaints?${text}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setComplaints(res.data.data);
      setNextPageUrl(res.data.next_page_url);
    });
  };

  //Прогрузка новой страницы
  const handleNextPage = () => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/complaints?${type}page=${page}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then((res) => {
      setComplaints((prevState) => [...prevState, ...res.data.data]);
      setNextPageUrl(res.data.next_page_url);
      setPage(page + 1);
    });
  };

  return (
    <div className={style.complaintsWrapp}>
      <div className={style.complaintsBtnBlock}>
        <button
          className={style.complaintsdBtn}
          data-distfil
          onClick={(e) => {
            btnPolColorAll(e, "");
            setType("");
          }}
        >
          Все
        </button>
        <button
          className={style.complaintsdBtn}
          data-distfil
          onClick={(e) => {
            btnPolColorAll(e, "considered=0");
            setType("considered=0&");
          }}
        >
          На рассмотрении
        </button>
        <button
          className={style.complaintsdBtn}
          data-distfil
          onClick={(e) => {
            btnPolColorAll(e, "considered=1");
            setType("considered=1&");
          }}
        >
          Рассмотреные
        </button>
      </div>
      <div className={style.complaintsBLock}>
        {complaints.length > 0 ? (
          <>
            {complaints.map((item, index) => {
              return (
                <ComplaintsItem
                  key={index}
                  myKey={index}
                  item={item}
                  complaints={complaints}
                  setComplaints={setComplaints}
                />
              );
            })}
            {nextPageUrl !== null && (
              <div className={style.nextPageBtn} onClick={handleNextPage}>
                Показать еще
              </div>
            )}
          </>
        ) : (
          <div className={style.notComplaints}>
            <h2 className={style.notComplaintsText}>
              Здесь появятся жалобы. Ваша задача проверять источник жалобы и
              основываясь на источник делать вердикт, подкрепляя его
              комментарием.
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

function ComplaintsItem({ item, myKey, complaints, setComplaints }) {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const [comment, setComment] = useState("");
  const { setComplaintsCount } = useContext(RegistrationContext);

  //Отправка рассмотренной заявки
  const appendComplaints = (id) => {
    axios
      .post(
        `https://gargalo.ru/api/complaints/considered/${id}`,
        { comment: comment },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token[1]}`,
            "User-Token": 1,
          },
        }
      )
      .then(() => {
        setComment("");
        setComplaints(complaints.filter((item) => item !== complaints[myKey]));
        setComplaintsCount((prevState) => prevState - 1);
      });
  };

  return (
    <div className={style.complaintsBLocks}>
      <div className={style.complaintsBLocksVer}>
        <h2 className={style.infoZagText}>Список жалоб</h2>
        <div className={style.userList}>
          {item.users.map((obj, index) => {
            return (
              <div className={style.usersWrapp} key={index}>
                <div className={style.usersInfoBlock}>
                  <div className={style.usersBox} style={{ marginBottom: 10 }}>
                    <Link
                      to={
                        window.userParam &&
                        window.userParam.id !== obj.user.user_id
                          ? `/account/${obj.user.user_id}`
                          : `/profile/${obj.user.user_id}`
                      }
                      className={style.userBox}
                    >
                      <img
                        src={`https://gargalo.ru${obj.user.avatar}`}
                        alt=""
                        className={style.userAvatar}
                      />
                      <h2 className={style.infoText}>{obj.user.nickname}</h2>
                    </Link>
                  </div>
                  <div className={style.usersBox}>
                    <h2 className={style.infoZag}>Тип жалобы:</h2>
                    <h2 className={style.infoZag} style={{ color: "black" }}>
                      {obj.reason_text}
                    </h2>
                  </div>
                </div>
                <div className={style.usersComplaintsBox}>
                  <h2 className={style.infoZag}>Дата отправки жалобы:</h2>
                  <h2 className={style.infoZag} style={{ color: "black" }}>
                    {obj.when}
                  </h2>
                </div>
              </div>
            );
          })}
        </div>
        <h2 className={style.infoText}>{item.reason_text}</h2>
        <div className={style.infoBLock}>
          <Link
            to={
              item.source === "wall"
                ? `/${item.source}/post/${item.source_id}`
                : `/${item.source}/${item.source_id}`
            }
            className={style.infoText}
          >
            Контент
          </Link>
        </div>
        <h2 className={style.infoZagText}>Результат жалобы</h2>
        {item.is_close === 0 ? (
          <div className={style.infoBLock}>
            <textarea
              className={style.complaintsInput}
              type="text"
              placeholder="Введите результат..."
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />
            {comment.length > 3 ? (
              <button
                className={style.complaintsBtn}
                onClick={() => appendComplaints(item.id)}
              >
                Рассмотрено
              </button>
            ) : (
              <button className={style.complaintsBtn} disabled>
                Рассмотрено
              </button>
            )}
          </div>
        ) : (
          <div className={style.infoBLock}>
            <div className={style.userBox}>
              <h2 className={style.infoZag}>Комментарий:</h2>
              <h2 className={style.infoZag} style={{ color: "black" }}>
                {item.comment}
              </h2>
            </div>
            <div className={style.userBox}>
              <h2 className={style.infoZag}>Дата рассмотрения:</h2>
              <h2 className={style.infoZag} style={{ color: "black" }}>
                {item.considered_date}
              </h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ComplaintsPage;
