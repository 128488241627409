import React from "react";
import style from "../events/Events.module.css";
import UnapprovedEventsPage from "./UnapprovedEventsPage";

const UnapprovedEvents = () => {
  return (
    <div className={style.band}>
      <UnapprovedEventsPage />
    </div>
  );
};

export default UnapprovedEvents;
