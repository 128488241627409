import React, { useState } from 'react'
import style from "../teyps/followers.module.css";
import Img from '../../sign/img/profileImg.svg'
import x from "../teyps/img/x.svg";
import check from "../teyps/img/check.svg";

const FollowersList = () => {

    const [text, setText] = useState("Подписаться")
    const [img, setImg] = useState(check)
    const handleChangeButton = () => {
        setText("Отписаться")
        setImg(x)
    }
    return (
        <div className={style.maindiv}>
            <h3 className={style.text2} style={{marginBottom: 5}}>8 человек</h3>
            <div className={style.people}>
                <div className={style.peopleBox}>
                    <div className={style.imgPeople}><img src={Img} alt="" /></div>
                    <h2 className={style.namePeople}>Евлоев Джабраил</h2>
                </div>
                <button onClick={() => handleChangeButton()} className={style.buttonSend}>
                    <h2 style={{color: text === "Отписаться" ? '#567F88' : '#054857'}}>{text}</h2>
                    <img style={{width: img === check ? 16 : 24, height: img === check ? 12 : 24}} src={img} alt=""/>
                </button>
            </div>
        </div>
    );
};

export default FollowersList;