import React from "react";
import style from "../../messages.module.css";
import inputSearch from "../../img/inputSearch.svg";
import axios from "axios";

const SearchInput = ({ setMessagesSearchList, setSearchText }) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);

  const searchMessage = (e) => {
    if (e.target.value.length) {
      axios
        .post(
          `https://gargalo.ru/api/messages/search`,
          { text: e.target.value },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token[1]}`,
              "User-Token": 1,
            },
          }
        )
        .then((res) => {
          setMessagesSearchList(res.data);
        });
    }
  };

  return (
    <div className={style.searchBox}>
      <input
        type="text"
        placeholder="Поиск"
        className={style.messagSearch}
        onChange={(e) => {
          searchMessage(e);
          setSearchText(e.target.value);
        }}
        autoComplete="off"
      />
      <img
        src={inputSearch}
        alt="Search"
        style={{ width: 24, height: 24, marginRight: 16}}
      />
    </div>
  );
};

export default SearchInput;
