import React, { useContext, useState } from "react";
import { RegistrationContext } from "../../../context";
import style from "./StyleAddEvent.module.css";
import ReusedInput from "../../../reusedComponents/ReusedInput";
import PostService from "../../../../API/postService";
import ReusedDropDownList from "../../../reusedComponents/ReusedDropDownList";
import { useDetectClickOutside } from "react-detect-click-outside";

const EventsCollecting = ({
  keys,
  setOpen,
  setEventList,
  setModeratorReview,
}) => {
  const [event, setEvent] = useState({
    organizerId: "",
    organizer: "",
    purpose: "",
    price: "",
    date: "",
    contacts: "",
    adress: "",
    details: "",
  });
  const [organizer, setOrganizer] = useState([]);

  const handleChange = (target) => {
    const { name } = target;
    const { value } = target;
    setEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value.length > 0) {
      if (name === "organizer") {
        PostService.searchUsers(value, `&filter[]=persons`).then((res) =>
          setOrganizer(res?.data)
        );
      }
    }
  };

  //Закрытие выпадающего списка
  const handleOpenBlock = (state, setState) => {
    if (!state) {
      PostService.searchUsers("", `&filter[]=persons`).then((res) => {
        setOrganizer(res?.data);
        setState(true);
      });
    }
  };
  const [openListBlock, setOpenListBlock] = useState(true);

  const handleCloseListBlock = () => {
    setOpenListBlock(false);
  };

  const filterListRef = useDetectClickOutside({
    onTriggered: handleCloseListBlock,
  });

  // задаваемые стили для переиспользуемых компонентов
  const styleForReusedInputAdress = {
    width: 634,
  };
  const styleReusedDropDownList = {
    width: 400,
  };
  const { user } = useContext(RegistrationContext);

  const handleClick = async (e) => {
    e.preventDefault();
    if (
      user.is_admin ||
      user.is_moder ||
      keys.permission === "family" ||
      keys.permission === "followers"
    ) {
      const data = await PostService.sendEvents(event, keys);
      setEventList((prev) => [data, ...prev]);
      setOpen(false);
    } else {
      PostService.sendEvents(event, keys);
      setOpen(false);
      setModeratorReview(true);
    }
  };

  //Проверка на заполнение
  const isButtonDisabled =
    Object.values(event).some((value) => value === "") || keys.title === "";

  return (
    <div className={style.wedding}>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Цель</h2>
        <ReusedInput
          name="purpose"
          type="text"
          reusedInputStyle={styleForReusedInputAdress}
          placeholder="Введите цель сбора"
          value={event.purpose}
          onChange={handleChange}
        />
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Сумма сбора</h2>
        <ReusedInput
          name="price"
          type="number"
          reusedInputStyle={styleForReusedInputAdress}
          placeholder="Введите сумму сбора в рублях"
          value={event.price}
          onChange={handleChange}
        />
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Организатор</h2>
        <div
          style={{ position: "relative" }}
          ref={filterListRef}
          onClick={() => {
            handleOpenBlock(openListBlock, setOpenListBlock);
          }}
        >
          <ReusedInput
            name="organizer"
            type="text"
            reusedInputStyle={styleForReusedInputAdress}
            placeholder="Введите ФИО организатора"
            value={event.organizer}
            onChange={handleChange}
          />
          {organizer?.length > 0 && openListBlock && (
            <div className={style.groom__list}>
              <ReusedDropDownList
                reusedDropDownListStyle={styleReusedDropDownList}
                data={organizer}
                id="organizerId"
                name="organizer"
                setState={setEvent}
                setOpenComp={setOrganizer}
              />
            </div>
          )}
        </div>
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Контактная информация</h2>
        <ReusedInput
          name="contacts"
          type="text"
          reusedInputStyle={styleForReusedInputAdress}
          placeholder="Введите контактные данные"
          value={event.contacts}
          onChange={handleChange}
        />
      </div>
      <div className={style.content}>
        <h2 className={style.content__suptitle}>Подробности</h2>
        <textarea
          name="details"
          id="details"
          className={style.content__textarea}
          placeholder="Напишите подробности мероприятия"
          value={event.details}
          onChange={(e) =>
            handleChange({ name: e.target.name, value: e.target.value })
          }
        ></textarea>
      </div>
      <div className={style.content}>
        <button
          onClick={() => setOpen(false)}
          className={style.content__button__cancel}
        >
          Отмена
        </button>
        <button
          onClick={handleClick}
          className={style.content__button}
          disabled={isButtonDisabled}
        >
          Добавить
        </button>
        <h4 className={style.content__moder__text}>
          * Событие для тейпа будет опубликовано после расмотрения модератором
        </h4>
      </div>
    </div>
  );
};

export default EventsCollecting;
