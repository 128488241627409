import React, { useContext } from "react";
import style from "./postsModal.module.css";

const PostsDisturbModal = ({ setDisturbModal }) => {
    // Закрытие модалки при нажатии на область вне модалки
    const handleClickDeleteModal = () => {
        setDisturbModal(false);
    };

    // Остановить закрытие модалки если нажато на модалку
    const handleClickDeleteModalContent = (e) => {
        e.stopPropagation();
    };
    // Отмена при нажатии на отмена :)
    const handleClickDisturbFalse = () => {
        setDisturbModal(false);
    };
    // Скрыть пост при нажатии на скрыть
    const handleClickDisturbTrue = () => {
        setDisturbModal(false);
    };
    return (
        <div className={style.modal} onClick={handleClickDeleteModal}>
            <div
                className={style.modal_content}
                onClick={handleClickDeleteModalContent}
            >
                <h2 className={style.modal_suptitle}>Вы уверены?</h2>
                <h4 className={style.modal_subtitle}>
                    Все моменты данного
                    <br /> пользователя будут скрыты.
                </h4>

                <h4 className={style.modal_subtitle}>
                    Вы сможете просмотреть их <br /> перейдя на его профиль.
                </h4>
                <div className={style.modal_buttons}>
                    <button
                        className={style.modal_button}
                        onClick={handleClickDisturbTrue}
                    >
                        Скрыть
                    </button>
                    <button
                        className={
                            style.modal_button + " " + style.button_second
                        }
                        onClick={handleClickDisturbFalse}
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </div>
    );
};
export default PostsDisturbModal;
