import React, { useEffect, useState, useContext } from "react";
import AddingTextField from "../addPosts/Adding.textField";
import style from "./EditPost.module.css";
import deleteImage from "../addPosts/img/Delete.svg";
import voteDeleteImage from "../addPosts/img/DeleteImg.svg";
import addImage from "./addImage.svg";
import AddingVote from "../addPosts/Adding.vote";
import ReButton from "../../../reusedComponents/ReButton";
import { rootControls } from "../../../../controllers/RootConroller";
import AddingPostVisible from "../addPosts/Adding.postVisible";
import closeImage from "./Mini buttons.svg";
import PostService from "../../../../API/postService";
import { RegistrationContext } from "../../../context";

const EditPost = ({ item, setOpenPost, where }) => {
  const chooseValueForVote = () => {
    if (item.vote_info?.vars) {
      const newArray = item.vote_info.vars.map((elem, index) => ({
        title: `Ответ ${index + 1}`,
        id: index + 1,
        value: elem.title,
        deleted: index + 1 > 2 ? true : false,
      }));
      return newArray;
    } else {
      return [
        { title: "Ответ 1", value: "", id: 1 },
        { title: "Ответ 2", value: "", id: 2 },
      ];
    }
  };
  const { setData } = useContext(RegistrationContext);
  const [post, setPost] = useState(item);
  const [postTitle, setPostTitle] = useState(item.text);
  const [globalImage, setGlobalImage] = useState({
    link: post.media.length > 0 ? post.media[post.media.length - 1].link : "",
    type: "ordinary",
  });
  const [newImage, setNewImage] = useState([]);
  const [voteVisible, setVoteVisible] = useState(
    item.vote_info.vars ? true : false
  );
  // console.log(globalImage);
  const [voteCount, setVoteCount] = useState(chooseValueForVote());
  const [postVisible, setPostVisible] = useState({
    all: rootControls("ClosedAccount") ? true : false,
    followers: rootControls("ClosedAccount") ? false : true,
    family: false,
    teip: false,
  });
  const [commentVisible, setCommentVisible] = useState(
    item.comments_on === 1
      ? {
          title: "Комментарии вкл.",
          value: "1",
        }
      : {
          title: "Комментарии выкл.",
          value: "0",
        }
  );

  useEffect(() => {
    const handler = (e) => {
      if (e.code === "Escape") {
        setOpenPost(false);
      }
    };
    window.addEventListener("keydown", handler);
  }, []);

  const handleClickDeleteImage = (link) => {
    setPost((prev) => ({
      ...prev,
      media: post.media.filter((elem) => elem.link != link),
    }));
    setGlobalImage((prev) => ({
      ...prev,
      link:
        post.media.length > 1
          ? post.media[post.media.length - 2].link
          : newImage[0],
      // link: newImage[0],
      type: post.media.length > 1 ? "ordinary" : "now",
    }));
    // console.log(globalImage,newImage);
  };
  const handleChangeAddImage = (e) => {
    // console.log(e.target.files[0]);
    setNewImage((prev) => [...prev, e.target.files[0]]);
    setGlobalImage((prev) => ({
      ...prev,
      link: e.target.files[0],
      type: "now",
    }));
  };

  const handleClickDeleteNowImage = (elem) => {
    // console.log(elem);
    setNewImage((prev) => [...prev].filter((item) => item !== elem));
    // console.log(globalImage);
    if (post.media.length > 0) {
      setGlobalImage((prev) => ({
        ...prev,
        link:
          newImage.length === 1
            ? post.media[post.media.length - 1].link
            : newImage[newImage.length - 2],
        type: newImage.length === 1 ? "ordinary" : "now",
      }));
    }
  };

  const handleClickPostAdd = async () => {
    try {
      const response = await PostService.addPost(
        newImage,
        postTitle,
        postVisible,
        commentVisible,
        voteCount,
        item.id,
        post.media
      );
      console.log(response);
      if (response) {
        setData((prev) => {
          const newData = prev.map((elem) =>
            elem.id === item.id ? response : elem
          );
          return newData;
        });
        setOpenPost(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div onClick={() => setOpenPost(false)} className={style.edit_post}>
      <div style={{ display: "flex" }} onClick={(e) => e.stopPropagation()}>
        <div className={style.post}>
          <div
            className={style.post_container}
            style={{
              overflow:
                (voteVisible || post.media.length + newImage.length > 7) &&
                "auto",
            }}
          >
            <h3 className={style.post_title}>Редактирование момента</h3>

            {newImage.length + post.media.length > 0 && (
              <img
                className={style.post_global_image}
                src={
                  globalImage.type === "ordinary"
                    ? `https://gargalo.ru${globalImage.link}`
                    : URL.createObjectURL(globalImage.link)
                }
                alt=""
              />
            )}

            <div className={style.image_div}>
              {post.media.map((elem, index) => (
                <div className={style.image_content} key={index + elem}>
                  <img
                    onClick={() => handleClickDeleteImage(elem.link)}
                    className={style.image_delete}
                    src={deleteImage}
                    alt=""
                  />
                  <img
                    onClick={() =>
                      setGlobalImage((prev) => ({
                        ...prev,
                        link: elem.link,
                        type: "ordinary",
                      }))
                    }
                    className={
                      style.post_image +
                      " " +
                      (globalImage.link === elem.link &&
                        style.post_image_outline)
                    }
                    src={`https://gargalo.ru${elem.link}`}
                    alt=""
                  />
                </div>
              ))}
              {newImage &&
                newImage.map((elem, index) => (
                  <div className={style.image_content} key={index + elem}>
                    <img
                      onClick={() => handleClickDeleteNowImage(elem)}
                      className={style.image_delete}
                      src={deleteImage}
                      alt=""
                    />
                    <img
                      onClick={() =>
                        setGlobalImage((prev) => ({
                          ...prev,
                          link: elem,
                          type: "now",
                        }))
                      }
                      className={
                        style.post_image +
                        " " +
                        (globalImage.link === elem && style.post_image_outline)
                      }
                      src={URL.createObjectURL(elem)}
                      alt=""
                    />
                  </div>
                ))}
              {newImage.length + post.media.length < 10 && (
                <div>
                  <input
                    className={style.add_image + " " + style.add_image_input}
                    type="file"
                    onChange={(e) => handleChangeAddImage(e)}
                  />
                  <img className={style.add_image} src={addImage} alt="" />
                </div>
              )}
            </div>

            <AddingTextField
              postTitle={postTitle}
              setPostTitle={setPostTitle}
              top="-322px"
            />
            <div className={style.vote_visible}>
              {voteVisible ? (
                <div className={style.vote_visible_div}>
                  <h2 className={style.vote_visible_title}>Опрос</h2>
                  <img
                    className={style.vote_visible_image}
                    src={voteDeleteImage}
                    alt=""
                    onClick={() => {
                      setVoteVisible(false);
                      setVoteCount(null);
                    }}
                  />
                </div>
              ) : (
                <ReButton
                  type={"button"}
                  classes="third"
                  height={32}
                  handleClick={() => setVoteVisible((prev) => !prev)}
                  FWeight={500}
                  font="small"
                  text={"Добавить опрос"}
                />
              )}
            </div>
            {voteVisible && (
              <AddingVote voteCount={voteCount} setVoteCount={setVoteCount} />
            )}

            <div className={style.sendBox}>
              <AddingPostVisible
                where={where}
                postVisible={postVisible}
                commentVisible={commentVisible}
                setPostVisible={setPostVisible}
                setCommentVisible={setCommentVisible}
                position="top"
              />
              <div style={{ display: "flex" }}>
                <button
                  className={style.button_third}
                  onClick={() => setOpenPost(false)}
                >
                  Отмена
                </button>

                <button
                  className={style.button_main}
                  onClick={handleClickPostAdd}
                  style={{
                    opacity: newImage.length + post.media.length === 0 && 0.5,
                  }}
                  disabled={newImage.length + post.media.length === 0}
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>{" "}
        <img
          onClick={() => setOpenPost(false)}
          className={style.close_image}
          src={closeImage}
          alt=""
        />
      </div>
    </div>
  );
};

export default EditPost;
