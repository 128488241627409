import React, { useState } from "react";
import style from "./teyp.module.css";
import subscribe from ".././user/img/profile.svg";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from ".././Loader";
import PostService from "../../../API/postService";

const TreePageInTeyp = () => {
  const { id } = useParams();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    PostService.getAllUsersTeyps(id).then((res) => {
      setUser(res);
      setLoading(false);
    });
  }, [id]);

  return (
    <>
      {!loading ? (
        <div className={style.tree__teyp}>
          <div>
            <h2 className={style.title__name}>{user?.item?.fio}</h2>
          </div>
          <div className={style.teyp__block}>
            <div className={style.teyp__info}>
              <div>
                <img
                  style={{ width: 210, height: 210 }}
                  src={`https://gargalo.ru${user?.item?.avatar}`}
                  alt=""
                />
              </div>
              <div style={{ marginLeft: 10 }}>
                <h2 className={style.name}>{user?.item?.fio}</h2>
                <div style={{ display: "flex" }}>
                  <h2>Дата рождения:</h2>
                  <h2 className={style.teyp__name}>10.02.1999</h2>
                </div>
                <div style={{ display: "flex" }}>
                  <h2>тейп:</h2>
                  <h2 className={style.teyp__name}>
                    <Link to="/">{user?.teip_item?.title}</Link>
                  </h2>
                </div>
              </div>
            </div>
            <div>
              <img style={{ width: 20, height: 20 }} src={subscribe} alt="" />
            </div>
          </div>
          <div className={style.teyp__relatives}>
            <div>
              <div className={style.teyp__relatives__block}>
                <h2 className={style.relatives__title}>Родственники</h2>
                <h2>{user?.family.father?.fio ? "Отец:" : ""}</h2>
                <Link to={`/tree/node/${user?.family.father?.id}`}>
                  {user?.family.father?.fio}
                </Link>
              </div>
              <div>
                <h2>
                  {user?.family.childs
                    ? "Дети:"
                    : user?.family.spouce?.fio
                    ? "Супруг"
                    : ""}
                </h2>
                {user?.family.childs
                  ? user?.family.childs.map((item) => {
                      return (
                        <h2 key={item.id}>
                          <Link to={`/tree/node/${item.id}`}>{item.fio}</Link>
                        </h2>
                      );
                    })
                  : user?.family.spouce?.fio}
              </div>
            </div>
            <div>
              <div>
                <h2 className={style.relatives__title}>
                  {user?.family.siblings ? "Браться и сестры" : ""}
                </h2>
                {user?.family.siblings &&
                  user?.family.siblings.map((item) => {
                    return (
                      <h2 key={item.id}>
                        <Link to={`/tree/node/${item.id}`}>{item.fio}</Link>
                      </h2>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: 710 }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default TreePageInTeyp;
