import React from "react";
import style from "./rebutton.module.css";
import PropTypes from "prop-types";
import { Squircle } from "corner-smoothing";
import { Link } from "react-router-dom";

const ReButton = ({
  width, //Ширина кнопки
  height, //Высота кнопки
  CRadius, //corner radius аналогичен border radius
  CSmoothing, //corner smoothing степень закругления от 0.1 до 1
  type, //тип элемента(кнопка, div и т.д.)
  text, //текст кнопки
  icon, //иконка внитри кнопки
  IWidth, //ширина иконки
  IHeight, //высота иконки
  IRight, //отступ справа у иконки
  handleClick, //функция
  font, //тип шрифта small/big
  clickProps, //пропсы в функции
  classes, //название класса
  padding, //padding кнопки
  to, //путь
  disabled, //disabled кнопки
  active, //active кнопки
  margin, //margin кнопки в виде 0px 0px 0px 0px
  mt, //margin top кнопки
  mr, //margin right кнопки
  mb, //margin bottom кнопки
  ml, //margin left кнопки
  borderWidth=0, // borderWidth наличие border
}) => {
  const handleChooseClassesButtons = () => {
    return (
      style[classes] +
      " " +
      (disabled ? style.disabled : style[classes + "_hover"])
    );
  };


  if (type === "Link") {
    return (
      <Link to={to}>
        <Squircle
          cornerRadius={CRadius}
          cornerSmoothing={CSmoothing}
          as="div"
          className={
            handleChooseClassesButtons() +
            " " +
            (active && style[classes + "_active"])
          }
          style={{
            width: width && width,
            height: height && height,
            fontWeight: font === "small" ? 500 : 600,
            fontSize: font === "small" ? 12 : 14,
            lineHeight: font === "small" ? 16 : 20,
            padding: padding ? padding : icon && "10px 20px",
            margin: margin && margin,
            marginTop: mt && mt,
            marginRight: mr && mr,
            marginBottom: mb && mb,
            marginLeft: ml && ml,
          }}
        >
          {icon && (
            <img
              src={icon}
              style={{
                width: IWidth ? IWidth : "auto",
                height: IHeight ? IHeight : "auto",
                marginRight: IRight,
              }}
              alt="Icon"
            />
          )}
          {text}
        </Squircle>
      </Link>
    );
  }
  return (
    <Squircle
      cornerRadius={CRadius}
      cornerSmoothing={CSmoothing}
      borderWidth={borderWidth}
      preserveSmoothing={true}
      as={type}
      className={
        handleChooseClassesButtons() +
        " " +
        (active && style[classes + "_active"])
      }
      onClick={(e) => handleClick && handleClick(e, ...clickProps)}
      style={{
        width: width && width,
        height: height && height,
        fontWeight: font === "small" ? 500 : 600,
        fontSize: font === "small" ? 12 : 14,
        lineHeight: font === "small" ? 16 : 20,
        padding: padding ? padding : icon && "8px 20px",
        margin: margin && margin,
        marginTop: mt && mt,
        marginRight: mr && mr,
        marginBottom: mb && mb,
        marginLeft: ml && ml,
        cursor: "pointer",
      }}
      disabled={disabled}
    >
      {icon && (
        <img
          src={icon}
          style={{
            width: IWidth ? IWidth : "auto",
            height: IHeight ? IHeight : "auto",
            marginRight: IRight,
          }}
          alt="Icon"
        />
      )}
      {text}
    </Squircle>
  );
};

ReButton.defaultProps = {
  CRadius: 8,
  CSmoothing: 0.6,
  type: "button",
  text: "",
  icon: null,
  IRight: 0,
  font: "big",
  clickProps: [],
  classes: "main",
  to: "",
  disabled: false,
};

ReButton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  CRadius: PropTypes.number,
  CSmoothing: PropTypes.number,
  type: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  IWidth: PropTypes.number,
  IHeight: PropTypes.number,
  IRight: PropTypes.number,
  handleClick: PropTypes.func,
  font: PropTypes.string,
  clickProps: PropTypes.array,
  classes: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  to: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ReButton;
