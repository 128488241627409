import React, { useContext, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import style from "./sign.module.css";
import axios from "axios";
import { RegistrationContext } from "../context";
import { useNavigate } from "react-router-dom";
import PostService from "./../../API/postService";

const SendCodeBLock = ({
    codeForResetPass,
    numberForResetPas,
    codeNumber,
    setCodeNumber,
    setStep,
    setCodeRes,
    setToken,
}) => {
    const { regInfo, setRegInfo } = useContext(RegistrationContext);
    const [timer, setTimer] = useState(60);
    const [errorCode, setErrorCode] = useState();
    const [incorrectCode, setIncorrectCode] = useState();
    let navigate = useNavigate();

    //Перекидывание инпутов
    const handleNextInput = (e, number) => {
        if (
            e.target.value.length === 1 &&
            e.target.nextSibling &&
            e.code !== "Backspace"
        ) {
            e.target.nextSibling.focus();
        } else if (e.code === "Backspace" && e.target.previousSibling) {
            e.preventDefault();
            switch (number) {
                case 1:
                    setCodeNumber({
                        ...codeNumber,
                        number1: "",
                    });
                    break;
                case 2:
                    setCodeNumber({
                        ...codeNumber,
                        number2: "",
                    });
                    break;
                case 3:
                    setCodeNumber({
                        ...codeNumber,
                        number3: "",
                    });
                    break;
                case 4:
                    setCodeNumber({
                        ...codeNumber,
                        number4: "",
                    });
                    break;
            }
            e.target.previousSibling.focus();
        }
    };

    useEffect(() => {
        if (timer > 0) {
            const timerFun = setTimeout(() => {
                setTimer((prevState) => prevState - 1);
            }, 1000);
            return () => clearTimeout(timerFun);
        }
    }, [timer]);

    //Отправка кода повторно
    const handleReSendCode = () => {
        axios
            .post(
                `https://gargalo.ru/api/sms/send-code`,
                { phone: regInfo.phone, email: regInfo.email },
                {
                    headers: {
                        "content-type": "application/json",
                        "User-Token": 1,
                    },
                }
            )
            .then(() => {
                setTimer(34);
            })
            .catch(() => {
                alert(
                    "Произошла непредвиденная ошибка, повторите операцию позже."
                );
            });
    };

    //Регистрация
    const registrashionFun = () => {
        axios
            .post(
                `https://gargalo.ru/api/register`,
                {
                    f_name: regInfo.f_name,
                    name: regInfo.name,
                    l_name: regInfo.l_name,
                    teip: regInfo.teip,
                    born:
                        regInfo.born.split("-")[2] +
                        "-" +
                        regInfo.born.split("-")[1] +
                        "-" +
                        regInfo.born.split("-")[0],
                    phone: regInfo.phone,
                    gender: regInfo.gender,
                    email: regInfo.email,
                    password: regInfo.password,
                    password_confirmation: regInfo.password_confirmation,
                    submit: regInfo.submit,
                    tpl_tree_number: -1,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        "User-Token": 1,
                    },
                }
            )
            .then((res) => {
                navigate("/activation", { state: res.data });
            })
            .catch(() => {
                alert(
                    "Произошла непредвиденная ошибка, повторите операцию позже."
                );
            });
    };

    //Подтверждение
    const handleConfirmClick = () => {
        const code =
            codeNumber.number1 +
            codeNumber.number2 +
            codeNumber.number3 +
            codeNumber.number4;

        axios
            .post(
                `https://gargalo.ru/api/sms/verify-code`,
                { phone: regInfo.phone, code: code },
                {
                    headers: {
                        "content-type": "application/json",
                        "User-Token": 1,
                    },
                }
            )
            .then(() => {
                setErrorCode();
                const fio =
                    regInfo.f_name + "|" + regInfo.name + "|" + regInfo.l_name;
                axios({
                    method: "GET",
                    url: `https://gargalo.ru/api/content/getregid?term=${fio}&page=1`,
                    headers: {
                        "Content-Type": "application/json",
                        "User-Token": 1,
                    },
                }).then((res) => {
                    if (res.data.data.length === 0) {
                        setRegInfo({
                            ...regInfo,
                            tpl_tree_number: -1,
                        });
                        registrashionFun();
                    } else {
                        navigate("/base", { state: res.data.data });
                    }
                });
            })
            .catch(() => {
                setErrorCode("Неправильный код");
                setTimer(34);
            });
    };
    const handleConfirmClickForResetPas = () => {
        const code =
            codeNumber.number1 +
            codeNumber.number2 +
            codeNumber.number3 +
            codeNumber.number4;
        PostService.sendCodeVerify("reset", code, numberForResetPas)
            .then((res) => {
                if (res !== undefined) {
                    setStep(2);
                    setCodeRes(false);
                    console.log("Какого фига", res);
                    setToken(res.token);
                } else {
                    setIncorrectCode(true);
                }
            })
            .catch(() => setIncorrectCode(true));
    };
    const handleReSendCodeForResetPas = () => {
        setTimer(60);
        PostService.sendNumberOfCode("reset", numberForResetPas);
    };

    return (
        <>
            <div className={style.sendCodeWrap}>
                <div className={style.sendCodeBlock}>
                    {!codeForResetPass ? (
                        <>
                            {" "}
                            <h2 className={style.sendCodeZag}>
                                Подтвердите номер
                            </h2>
                            <h2 className={style.sendCodeZagText}>
                                Зачем мне это нужно?
                            </h2>
                            <h2 className={style.sendCodeText}>
                                Подтверждение нужно, что бы вы могли
                                восстановить доступ к аккаунту, если вы забудете
                                пароль.
                            </h2>
                        </>
                    ) : (
                        <>
                            {" "}
                            <h2 className={style.sendCodeZag}>Введите код</h2>
                        </>
                    )}
                    <div className={style.inputCodeBLock}>
                        <InputMask
                            mask="9"
                            maskChar=""
                            type="text"
                            onChange={(e) =>
                                setCodeNumber({
                                    ...codeNumber,
                                    number1: e.target.value,
                                })
                            }
                            value={codeNumber.number1}
                            onKeyDown={(e) => handleNextInput(e, 1)}
                            className={style.signInput + " " + style.codeInput}
                        />
                        <InputMask
                            mask="9"
                            maskChar=""
                            type="text"
                            onChange={(e) =>
                                setCodeNumber({
                                    ...codeNumber,
                                    number2: e.target.value,
                                })
                            }
                            value={codeNumber.number2}
                            onKeyDown={(e) => handleNextInput(e, 2)}
                            className={style.signInput + " " + style.codeInput}
                        />
                        <InputMask
                            mask="9"
                            maskChar=""
                            type="text"
                            onChange={(e) =>
                                setCodeNumber({
                                    ...codeNumber,
                                    number3: e.target.value,
                                })
                            }
                            value={codeNumber.number3}
                            onKeyDown={(e) => handleNextInput(e, 3)}
                            className={style.signInput + " " + style.codeInput}
                        />
                        <InputMask
                            mask="9"
                            maskChar=""
                            type="text"
                            onChange={(e) =>
                                setCodeNumber({
                                    ...codeNumber,
                                    number4: e.target.value,
                                })
                            }
                            value={codeNumber.number4}
                            onKeyDown={(e) => handleNextInput(e, 4)}
                            className={style.signInput + " " + style.codeInput}
                        />
                    </div>
                    {errorCode && (
                        <h2
                            className={style.signText}
                            style={{ color: "#EB5757", marginBottom: 10 }}
                        >
                            {errorCode}
                        </h2>
                    )}
                    {incorrectCode && (
                        <span style={{ color: "#FF4B1F", marginTop: 5 }}>
                            Неправильный код
                        </span>
                    )}
                    <div className={style.reSendCode}>
                        {timer === 0 ? (
                            <>
                                {!codeForResetPass ? (
                                    <h2
                                        className={style.reSendCodeBtn}
                                        onClick={handleReSendCode}
                                    >
                                        Отправить еще раз
                                    </h2>
                                ) : (
                                    <h2
                                        className={style.reSendCodeBtn}
                                        onClick={handleReSendCodeForResetPas}
                                    >
                                        Отправить еще раз
                                    </h2>
                                )}
                            </>
                        ) : (
                            <h2
                                className={style.reSendCodeBtn}
                                style={{ color: "#567f88" }}
                            >
                                Отправить еще раз
                            </h2>
                        )}
                        {timer !== 0 && (
                            <h2 className={style.reSendCodeTimer}>
                                {timer} сек
                            </h2>
                        )}
                    </div>
                    {!codeForResetPass ? (
                        <button
                            className={style.signBtn + " " + style.confirmBtn}
                            onClick={handleConfirmClick}
                        >
                            Подтвердить
                        </button>
                    ) : (
                        <button
                            className={style.signBtn + " " + style.confirmBtn}
                            onClick={handleConfirmClickForResetPas}
                        >
                            Далее
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default SendCodeBLock;
