import React, { useContext, useState } from "react";
import style from "./posts.module.css";
import PostsSwiper from "./postsSwiper/postsSwiper";
import Hashtags from "./postsHashtags/hashtags";
import PostBottomPanel from "./postBottomPanel/postBottomPanel";
import PostTopPanel from "./postTopPanel/PostTopPanel";
import PostsVote from "./vote/postsVote";

import PostOneComments from "./PostOneComment";
import ReButton from "../../../reusedComponents/ReButton";
import PostService from "../../../../API/postService";
import { RegistrationContext } from "../../../context";

const Post = ({ item, type, proposed = false, deviation = false, where}) => {
  const [commentCount, setCommentCount] = useState(item.comments_count);
  const { setData } = useContext(RegistrationContext);
  let tags = [];
  // console.log(item.text)
  if (item.text !== null) {
    tags = item.text.match(/(?:^|\W)#([а-яa-z]+)(?![а-я])/giu);
  }

  const handlePropose = async () => {
    try {
      const response = await PostService.proposePost(item.id);
      if (response) {
        setData((prev) => prev.filter((element) => element.id !== item.id));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeviation = async () => {
    try {
      const response = await PostService.deviatePost(item.id);
      if (response) {
        setData((prev) => prev.filter((element) => element.id !== item.id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={style.post__container}>
      <div className={style.post__content}>
        {/* Верхняя панель */}
        <PostTopPanel where={where} item={item} type={type} />
        {/* Слайдер */}
        <PostsSwiper item={item} />
        {/* Хештеги */}
        <Hashtags item={item} tags={tags} />
        {/* Голосование */}
        <PostsVote item={item} />
        {/* Нижняя панель */}
        {!proposed && !deviation ? (
          <PostBottomPanel item={item} commentCount={commentCount} />
        ) : null}
        {proposed ? (
          <div style={{ display: "flex" }}>
            <ReButton
              classes={"main"}
              handleClick={handlePropose}
              height={40}
              text={"Принять публикацию"}
              mr={8}
            />
            {!deviation ? (
              <ReButton
                classes={"fourth"}
                type={"div"}
                borderWidth={1}
                CRadius={8}
                CSmoothing={0.1}
                width={120}
                handleClick={handleDeviation}
                height={40}
                text={"Отклонить"}
              />
            ) : ""}
          </div>
        ) : (
          ""
        )}
      </div>
      {!proposed
        ? item.comments_on === 1 && (
            <PostOneComments
              item={item}
              type={type}
              commentCount={commentCount}
              setCommentCount={setCommentCount}
            />
          )
        : null}
    </div>
  );
};

export default Post;
