import React, { useState, useRef, useContext, useEffect } from "react";
import style from "./actions.module.css";
import { UseClosing } from "../../../../../hooks/useClosing";
import add from "../../../img/Meatballs menu.svg";
import AnotherActionsList from "./AnotherActionsList";
import MyActionsList from "./MyActionsList";
import { RegistrationContext } from "../../../../context";

const Actions = ({ item, type, where }) => {
  const { user } = useContext(RegistrationContext);
  const [actions, setActions] = useState();

  // Открытие поста
  const handleClickOpen = () => {
    setActions((prev) => !prev);
  };

  //Закрытие блока поиска при клике на пустую область
  const modalRef = useRef(null);

  const closeBlock = () => {
    setActions(false);
  };

  useEffect(() => {
    if (modalRef.current) {
      const onClick = (e) =>
        modalRef.current.contains(e.target) || closeBlock();
      document.addEventListener("click", onClick);
      return () => document.removeEventListener("click", onClick);
    }
  }, [modalRef.current]);

  return (
    <div
      ref={modalRef}
      className={style.actions_container}
      onMouseEnter={() => setActions(true)}
      onMouseLeave={() => setActions(false)}
    >
      <img src={add} className={style.actions_open_image} alt="" />
      {actions && (
        <>
          {user?.user_id === item?.user.user_id ||
          user?.user_id === item?.for_user ? (
            <MyActionsList
              where={where}
              item={item}
              setActions={setActions}
              type={type}
            />
          ) : (
            <AnotherActionsList
              item={item}
              setActions={setActions}
              type={type}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Actions;
