import React, { useState, useContext } from "react";
import PlugImage from "../../img/avatar_original.svg";
import likeFalseImage from "../../comments/img/LikeDefault.svg";
import likeHoverImage from "../../comments/img/LikeHover.svg";
import likeTrueImage from "../../comments/img/LikeTrue.svg";
import style from "./posts.module.css";
import { RegistrationContext } from "../../../context";
import PostService from "../../../../API/postService";
import { dateConverter } from "../../../utils/dateСonverter";
import ImageKebabMenu from "../../img/Kebab menu.svg";
import ReDropDown from "../../../reusedComponents/ReDropDown";
import editImage from "../../img/Edit.svg";
import deleteImage from "../../img/Delete.svg";
import smileImage from "../../img/Emoji.svg";
import CloseImage from "../../img/close.svg";
import CheckImage from "../../img/Check.svg";
import ReInput from "../../../reusedComponents/ReInput";
import Picker from "emoji-picker-react";
import { useNavigate } from "react-router-dom";

const UnderComment = ({ comment, setComments, comment_id, index }) => {
  const navigate = useNavigate();
  const { user } = useContext(RegistrationContext);
  const [likeHover, setLikeHover] = useState(false);
  const [emoji, setEmoji] = useState();
  const [dropDown, setDropDown] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [editCommentTitle, setEditCommentTitle] = useState("");

  const dataDeclination = (number) => {
    if (number === 1) {
      return number + " ответ";
    } else if (number > 1 && number <= 4) {
      return number + " ответа";
    } else if (number > 4) {
      return number + " ответов";
    }
  };

  const handleAddAndDeleteLikes = (type) => {
    try {
      PostService.sendCommentLike(type, comment.id);
      setComments((prev) => {
        type === "like" ? (prev[index].likes += 1) : (prev[index].likes -= 1);
        prev[index].is_liked = !prev[index].is_liked;
        return [...prev];
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickDeleteComment = () => {
    try {
      PostService.deleteComments(comment.id, "post");
      setComments((prev) => [...prev].filter((item) => item.id !== comment.id));
    } catch (error) {}
  };

  const handleClickEditComment = () => {
    setEditComment(true);
    setEditCommentTitle(comment.text);
  };

  const handleClickEditCommentTrue = () => {
    setEditComment(false);
    setComments((prev) => {
      prev[index].text = editCommentTitle;
      return [...prev];
    });
    PostService.SendComments(
      editCommentTitle,
      0,
      "post",
      comment_id,
      "update",
      comment.id
    );
  };
  const onEmojiClick = (event, emojiObject) => {
    setEditCommentTitle((prevState) => prevState + emojiObject.emoji);
  };
  const handleClickCommentNickname = (id) => {
    navigate("/account/" + id);
  };

  return (
    <>
      {editComment ? (
        <div style={{ marginTop: 20 }}>
          <div className={style.comment_edit}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <img
                className={
                  style.comment_image + " " + style.under_comment_image
                }
                src={`https://gargalo.ru/${user.avatar_small}`}
                alt=""
                style={{ marginRight: 12 }}
              />
              <ReInput
                location="start"
                iconStart={smileImage}
                classesIcon={style.UnderclassesIcon}
                classesInput={style.classesInput}
                classesDiv={style.classesDiv}
                handleChange={(e) => setEditCommentTitle(e.target.value)}
                value={editCommentTitle}
                placeholder="Написать комментарий"
                onMouseOver={() => setEmoji(true)}
                onMouseOut={() => setEmoji(false)}
              />
            </div>
            <div className={style.comment_edit_div}>
              <img
                className={style.comment_edit_image}
                onClick={() => setEditComment(false)}
                src={CloseImage}
                alt=""
              />
              <img
                style={{ marginLeft: 8 }}
                onClick={handleClickEditCommentTrue}
                className={style.comment_edit_image}
                src={CheckImage}
                alt=""
              />
            </div>
            {emoji && (
              <div
                onMouseOver={() => setEmoji(true)}
                onMouseOut={() => setEmoji(false)}
                style={{ top: "-320px" }}
                className={
                  style.emoji_content +
                  " " +
                  (emoji ? style.emoji_content_active : " ")
                }
              >
                <Picker onEmojiClick={onEmojiClick} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={style.under_comment}>
          <img className={style.under_comment_image} src={PlugImage} alt="" />
          <div className={style.comment_content} style={{ maxWidth: 585 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              
              <p className={style.comment_name}>{comment.user.nickname}</p>
              {user.user_id === comment.user.user_id && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "start",
                    position: "relative",
                    height: 20,
                    width: 40,
                  }}
                  onMouseOver={() => setDropDown(true)}
                  onMouseOut={() => setDropDown(false)}
                >
                  <img
                    className={style.comment_kebab_menu_hover}
                    src={ImageKebabMenu}
                    alt=""
                  />
                  {dropDown && (
                    <div
                      className={style.ReDropDown}
                      onMouseOver={() => setDropDown(true)}
                      onMouseOut={() => setDropDown(false)}
                    >
                      <ReDropDown
                        data={[
                          {
                            title: "Редактировать",
                            icon: editImage,
                            handleClick: handleClickEditComment,
                          },
                          {
                            title: "Удалить",
                            icon: deleteImage,
                            handleClick: handleClickDeleteComment,
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            <p className={style.comment_text}>
              <span
                className={
                  style.comment_name + " " + style.comments_suptitle_text
                }
                style={{ display: "inline" }}
                onClick={() =>
                  handleClickCommentNickname(
                    comment.parent_comment_user.user_id
                  )
                }
              >
                {comment.parent_comment_user.nickname}
              </span>
              {comment.text}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 12,
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {user?.is_real && (
                  <button
                    className={style.comment_button}
                    // onClick={() => handleClickUnderButton()}
                  >
                    Ответить
                  </button>
                )}
                {comment.childs_count > 0 && (
                  <p
                    style={{ cursor: "pointer" }}
                    className={style.comment_time}
                    // onClick={() => handleAddUnderComments()}
                  >
                    {dataDeclination(comment.childs_count)}
                  </p>
                )}
                <p className={style.comment_time}>
                  {dateConverter(comment.created_at)}
                </p>
              </div>

              <div className={style.comment_likes_div}>
                {comment.likes > 0 ? (
                  <>
                    <img
                      onClick={() =>
                        handleAddAndDeleteLikes(
                          comment.is_liked ? "unlike" : "like",
                          comment.id
                        )
                      }
                      src={comment.is_liked ? likeTrueImage : likeHoverImage}
                      className={style.comment_likes}
                      alt=""
                    />
                    <p className={style.comments_likes_count}>
                      {comment.likes}
                    </p>
                  </>
                ) : (
                  <img
                    onClick={() =>
                      handleAddAndDeleteLikes(
                        comment.is_liked ? "unlike" : "like"
                      )
                    }
                    onMouseOver={() => setLikeHover(true)}
                    onMouseOut={() => setLikeHover(false)}
                    src={
                      comment.is_liked
                        ? likeTrueImage
                        : likeHover
                        ? likeHoverImage
                        : likeFalseImage
                    }
                    className={style.comment_likes}
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UnderComment;
