import React from "react";
import style from "./templates.module.css";
import avatarDefault from "../../img/avatar.svg";
import axios from "axios";

const VacuumElement = ({
  avatar,
  fio,
  die,
  born,
  full_born,
  full_die,
  pol,
  nodeId,
  id,
  f_id,
  m_id,
  teip,
  spouce_id,
  current_id,
  type,
  setFormBlock,
  setButtonType,
  setCenter,
  addPersonInfo,
  setAddPersonInfo,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  let nodeStyle;
  if (type === 2) {
    nodeStyle = {
      borderBottomLeftRadius: "0",
      borderTopLeftRadius: "0",
      border: "1px solid #AAC0C5",
      cursor: "pointer",
    };
  } else if (type === 1) {
    nodeStyle = {
      borderBottomRightRadius: "0",
      borderTopRightRadius: "0",
      border: "1px solid #AAC0C5",
      cursor: "pointer",
    };
  } else {
    nodeStyle = {
      border: "1px solid #AAC0C5",
      cursor: "pointer",
    };
  }

  //Открытие формы редактирования
  const handleOpenForm = () => {
    if (current_id > 0) {
      setButtonType("editVacuum");
      setCenter({
        id: id,
        fio: fio,
        avatar: avatar,
        nodeId: nodeId,
        born: born,
        die: die,
        full_born: full_born,
        full_die: full_die,
        type: type,
        pol: pol,
        f_id: f_id,
        teip: teip,
      });
      setAddPersonInfo({
        ...addPersonInfo,
        source: "parent",
        source_id: current_id,
        pol: pol,
        teip: teip,
      });
      setFormBlock(true);
    }
  };

  return (
    <div
      className={style.nodeWrapp + " " + style.nodeWrappVacuum}
      style={nodeStyle}
      title={
        current_id > 0
          ? "Добавить человека"
          : "Прежде чем добавить этого человека, заполните пропуски снизу."
      }
      onClick={() => current_id > 0 && handleOpenForm()}
    >
      <img src={avatarDefault} className={style.nodeAvatar} alt="Аватар" />
      <h2 className={style.nodeName} style={{ color: "#AAC0C5" }}>
        Фамилия Имя Отчество
      </h2>
      <h2 className={style.nodeDate} style={{ color: "#AAC0C5" }}>
        Дата
      </h2>
      <div
        className={style.gender}
        style={{ backgroundColor: "#AAC0C5" }}
      ></div>
    </div>
  );
};

export default VacuumElement;
