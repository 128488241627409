import axios from "axios";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import style from "./tree.module.css";
import { useParams } from "react-router-dom";
import TreeItem from "./treeComponents/TreeItem";
import treeIcon from "./img/Tree.svg";
import TreeAppendBlock from "./treeComponents/TreeAppendBlock";
import TreeFormBlock from "./treeComponents/TreeFormBlock";
import TreeInfoBlock from "./treeComponents/TreeInfoBlock";
import ReButton from "../../../reusedComponents/ReButton";
import TreeModal from "./treeComponents/TreeModal";
import TreeNotifModal from "./treeComponents/TreeNotifModal";
import { rootControls } from "../../../../controllers/RootConroller";

const Tree = () => {
  const { id } = useParams();
  const [treeMass, setTreeMass] = useState([]);
  const [treeIdMass, setTreeIdMass] = useState([]);
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const wrappRef = useRef(null);
  const treeRef = useRef(null);

  const [center, setCenter] = useState();
  const [appenedBlock, setAppenedBlock] = useState(false);
  const [infoBlock, setInfoBlock] = useState(false);
  const [formBlock, setFormBlock] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [render, setRender] = useState(false);
  const [buttonType, setButtonType] = useState();
  const [addingType, setAddingType] = useState();
  const [moderatorId, setModeratorId] = useState();
  const [notif, setNotif] = useState();
  const [firstAdd, setFirstAdd] = useState({
    f: "",
    i: "",
    o: "",
    connect_user_id: "",
    fio_id: "",
    fio: "",
    source_id: "",
    source: "",
    spouce_id: "",
    s_fio: "",
    f_id: "",
    f_fio: "",
    m_id: "",
    m_fio: "",
    die: "",
    born: "",
    full_born: "",
    full_die: "",
    pol: "",
    teip: "",
    phone: "",
    mail: "",
    info: "",
    imgUrl: null,
    img: null,
  });
  const [addPersonInfo, setAddPersonInfo] = useState({
    f: "",
    i: "",
    o: "",
    connect_user_id: "",
    fio_id: "",
    fio: "",
    source_id: "",
    source: "",
    spouce_id: "",
    s_fio: "",
    f_id: "",
    f_fio: "",
    m_id: "",
    m_fio: "",
    die: "",
    born: "",
    full_born: "",
    full_die: "",
    pol: "",
    teip: "",
    phone: "",
    mail: "",
    info: "",
    imgUrl: null,
    img: null,
  });

  //Запрет скролла на странице деревьев находится в sidebar

  //Прогрузка древа тейпа
  useEffect(() => {
    axios({
      method: "GET",
      url: `https://gargalo.ru/api/v2/tree/${id}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    })
      .then((res) => {
        setTreeMass(res.data.data);
        setModeratorId(res.data.moderator_id);
        res.data.data.map((item) => {
          setTreeIdMass((prevState) => [...prevState, item.id]);
        });
      })
      .catch((err) => {});
    setRender(false);
  }, [id, render]);

  //Алгоритм создания нужного объекта
  const dataObj = treeMass.reduce((acc, item) => {
    item.children = [];
    acc[item.id] = item;
    return acc;
  }, {});

  const result = treeMass.reduce((acc, item) => {
    if (!item.f_id || (item.f_id && !treeIdMass.includes(item.f_id)))
      acc.push(dataObj[item.id]);
    else if (dataObj[item.f_id] && dataObj[item.id])
      dataObj[item.f_id].children.push(dataObj[item.id]);
    return acc;
  }, []);

  const treeRendering = (result) => {
    if (result.length > 0) {
      return (
        <ul>
          {result.map((item) => (
            <li key={item.id}>
              <TreeItem
                wrappRef={wrappRef?.current}
                item={item}
                setButtonType={setButtonType}
                setAppenedBlock={setAppenedBlock}
                setCenter={setCenter}
                setFormBlock={setFormBlock}
                setAddPersonInfo={setAddPersonInfo}
                addPersonInfo={addPersonInfo}
                setInfoBlock={setInfoBlock}
                setFirstAdd={setFirstAdd}
                firstAdd={firstAdd}
                moderatorId={moderatorId}
              />
              {item.children.length > 0 && treeRendering(item.children)}
            </li>
          ))}
        </ul>
      );
    }

    return (
      <ul>
        <li>
          <TreeItem
            wrappRef={wrappRef?.current}
            item={result}
            setButtonType={setButtonType}
            setAppenedBlock={setAppenedBlock}
            setCenter={setCenter}
            setFormBlock={setFormBlock}
            setAddPersonInfo={setAddPersonInfo}
            addPersonInfo={addPersonInfo}
            setInfoBlock={setInfoBlock}
            setFirstAdd={setFirstAdd}
            firstAdd={firstAdd}
            moderatorId={moderatorId}
          />
          {result.children.length > 0 && treeRendering(result.children)}
        </li>
      </ul>
    );
  };

  //Закрытие всех окон при нажатии на Esc
  useEffect(() => {
    if (treeRef.current) {
      treeRef.current.focus();
    }
  }, [treeRef]);

  const handleCloseBlocks = (e) => {
    if (e.key === "Escape") {
      setInfoBlock(false);
      setFormBlock(false);
      setAppenedBlock(false);
      setModal(false);
      setAddPersonInfo({
        ...addPersonInfo,
        f: "",
        i: "",
        o: "",
        connect_user_id: "",
        fio_id: "",
        fio: "",
        source_id: "",
        source: "",
        spouce_id: "",
        s_fio: "",
        f_id: "",
        f_fio: "",
        m_id: "",
        m_fio: "",
        die: "",
        born: "",
        full_born: "",
        full_die: "",
        pol: "",
        teip: "",
        phone: "",
        mail: "",
        info: "",
        imgUrl: null,
        img: null,
      });
      setFirstAdd({
        ...addPersonInfo,
        f: "",
        i: "",
        o: "",
        connect_user_id: "",
        fio_id: "",
        fio: "",
        source_id: "",
        source: "",
        spouce_id: "",
        s_fio: "",
        f_id: "",
        f_fio: "",
        m_id: "",
        m_fio: "",
        die: "",
        born: "",
        full_born: "",
        full_die: "",
        pol: "",
        teip: "",
        phone: "",
        mail: "",
        info: "",
        imgUrl: null,
        img: null,
      });
    }
  };

  const [stoppedDisplay, setStoppedDisplay] = useState(false);

  return (
    <div
      className={style.band}
      onKeyDown={handleCloseBlocks}
      tabIndex={0}
      ref={treeRef}
    >
      <div className={style.tree}>
        <TransformWrapper
          ref={wrappRef}
          wheel={{ step: 0.2 }}
          initialScale={1}
          minScale={0.1}
          maxScale={3}
          initialPositionX={0}
          initialPositionY={0}
          limitToBounds={false}
          panning={{ velocityDisabled: true }}
          doubleClick={{ mode: "zoomOut" }}
          onPanning={() => {
            setStoppedDisplay(true);
          }}
          onPanningStop={() => {
            setStoppedDisplay(false);
          }}
        >
          <TransformComponent
            wrapperStyle={{
              overflow: "visible",
              minWidth: "100vw",
            }}
          >
            {treeMass.length > 0 && (
              <>
                {result
                  .sort((a, b) => {
                    const aJ = JSON.stringify(a);
                    const bJ = JSON.stringify(b);
                    if (aJ.length < bJ.length) {
                      return -1;
                    }
                    if (aJ.length > bJ.length) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item) => {
                    return (
                      <div key={item.id} className={style.branchWrapp}>
                        {stoppedDisplay && (
                          <div className={style.stoppedDisplay}></div>
                        )}
                        {treeRendering(item)}
                      </div>
                    );
                  })}
              </>
            )}
          </TransformComponent>
        </TransformWrapper>
      </div>
      <TreeAppendBlock
        center={center}
        setAppenedBlock={setAppenedBlock}
        setAddPersonInfo={setAddPersonInfo}
        addPersonInfo={addPersonInfo}
        setFormBlock={setFormBlock}
        appenedBlock={appenedBlock}
        setAddingType={setAddingType}
        setFirstAdd={setFirstAdd}
        firstAdd={firstAdd}
      />
      {infoBlock && (
        <TreeInfoBlock
          setCenter={setCenter}
          setInfoBlock={setInfoBlock}
          infoBlock={infoBlock}
          center={center}
          setFormBlock={setFormBlock}
          setButtonType={setButtonType}
          setAddPersonInfo={setAddPersonInfo}
          addPersonInfo={addPersonInfo}
          setFirstAdd={setFirstAdd}
          firstAdd={firstAdd}
        />
      )}
      <TreeModal
        setRender={setRender}
        firstAdd={firstAdd}
        setFirstAdd={setFirstAdd}
        setNotif={setNotif}
        setModal={setModal}
        modalType={modalType}
        modal={modal}
        center={center}
        setTreeMass={setTreeMass}
        treeMass={treeMass}
        setFormBlock={setFormBlock}
        setAddingType={setAddingType}
        setAddPersonInfo={setAddPersonInfo}
        addPersonInfo={addPersonInfo}
      />
      {formBlock && (
        <TreeFormBlock
          setRender={setRender}
          wrappRef={wrappRef?.current}
          setNotif={setNotif}
          setCenter={setCenter}
          center={center}
          formBlock={formBlock}
          addPersonInfo={addPersonInfo}
          setAddPersonInfo={setAddPersonInfo}
          setFormBlock={setFormBlock}
          setTreeMass={setTreeMass}
          treeMass={treeMass}
          buttonType={buttonType}
          addingType={addingType}
          setAddingType={setAddingType}
          setModal={setModal}
          setModalType={setModalType}
          setFirstAdd={setFirstAdd}
          firstAdd={firstAdd}
        />
      )}
      <TreeNotifModal setNotif={setNotif} notif={notif} />
      {rootControls("Administrator") ||
      rootControls("TeipModerator", moderatorId) ? (
        <>
          {treeMass.length === 0 ? (
            <div className={style.addNewBranchNull}>
              <img src={treeIcon} alt="Tree" className={style.newBranchIcon} />
              <p className={style.newBranchZag}>
                В древе пока нет людей. Начните новую ветку для формирования
                древа по кнопке ниже.
              </p>
              <ReButton
                height={40}
                handleClick={() => {
                  setFormBlock(true);
                  setButtonType("add");
                  setAddingType("newBranch");
                  setAddPersonInfo({
                    ...addPersonInfo,
                    teip: id,
                  });
                  setFirstAdd({
                    ...firstAdd,
                    teip: id,
                  });
                }}
                padding={"8px 20px"}
                text={"Добавить ветку"}
                classes={"third"}
                type={"div"}
              />
            </div>
          ) : (
            <div
              className={style.addNewBranch}
              onClick={() => {
                setFormBlock(true);
                setButtonType("add");
                setAddingType("newBranch");
                setAddPersonInfo({
                  ...addPersonInfo,
                  teip: id,
                });
                setFirstAdd({
                  ...firstAdd,
                  teip: id,
                });
              }}
            >
              <ReButton
                height={40}
                padding={"8px 20px"}
                text={"Добавить ветку"}
                classes={"third"}
                type={"div"}
              />
            </div>
          )}
        </>
      ) : (
        treeMass.length === 0 && (
          <div className={style.addNewBranchNull}>
            <img src={treeIcon} alt="Tree" className={style.newBranchIcon} />
            <p className={style.newBranchZag}>В древе пока нет людей.</p>
          </div>
        )
      )}
    </div>
  );
};

export default Tree;
