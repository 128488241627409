import React, { useState, useEffect, useRef } from "react";
import PostService from "./../../../API/postService";
import likeFalseImage from "./img/LikeDefault.svg";
import likeHoverImage from "./img/LikeHover.svg";
import likeTrueImage from "./img/LikeTrue.svg";
import commentStyle from "./Comment.module.css";
import UnderComment from "./UnderComment";
import loader from "../../content/img/loader.gif";
import { useNavigate } from "react-router-dom";
import ReInput from "./../../reusedComponents/ReInput";
import sendMessageImage from "../img/Send.svg";
import smileImage from "../img/Emoji.svg";
import Picker from "emoji-picker-react";
import ImageKebabMenu from "../img/Kebab menu.svg";
import CloseImage from "../img/close.svg";
import CheckImage from "../img/Check.svg";
import editImage from "../img/Edit.svg";
import deleteImage from "../img/Delete.svg";
import { dateConverter } from "../../utils/dateСonverter";
import ReDropDown from "../../reusedComponents/ReDropDown";

const CommentsList = ({
  user,
  content,
  setComments,
  setUnderComms,
  newUnderComments,
  commentType,
  commentID,
  setNewUnderComments,
  setCommentCount,
}) => {
  const navigate = useNavigate();
  const [emoji, setEmoji] = useState(false);
  const [data, setData] = useState(content);
  const [likes, setLikes] = useState({
    isLiked: data.is_liked,
    likesCount: data.likes,
  });
  const [likeHover, setLikeHover] = useState(false);
  const [underState, setUnderState] = useState(false);
  const [underNextPageUrl, setUnderNextPageUrl] = useState(false);
  const [underComments, setUnderComments] = useState([]);
  const [loaderForSeeNewComment, setLoaderForSeeNewComment] = useState(false);
  const [newUnderCommentTitle, setNewUnderCommentTitle] = useState("");
  const [page, setPage] = useState(1);
  const [dropDown, setDropDown] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [editCommentTitle, setEditCommentTitle] = useState("");
  const [focusState, setFocusState] = useState(false);

  //добавление комента в массив under-комментов при каждом изменении newUnderComments(как только добавится новый комм)
  useEffect(() => {
    if (newUnderComments && data.id === newUnderComments.parent_id) {
      setUnderComments((prevState) => [...prevState, newUnderComments]);

      data.childs_count += 1;
      setUnderState(true);
    }
  }, [newUnderComments]);

  // добавление и удаление лайка

  const handleAddAndDeleteLikes = (type) => {
    setLikes((prevState) => ({
      ...prevState,
      isLiked: !prevState.isLiked,
      likesCount: prevState.isLiked
        ? prevState.likesCount - 1
        : prevState.likesCount + 1,
    }));
    PostService.sendCommentLike(type, data.id);
  };

  const handleClickUnderButton = () => {
    setUnderState(true);
    setFocusState(true);
  };
  // получение массива under-комментов при нажатии на посмотреть ответы
  const handleAddUnderComments = async () => {
    setUnderState((prevState) => !prevState);

    if (page === 1) {
      setPage((prevState) => prevState + 1);
      const content = await PostService.getAllComments(
        data.source,
        data.source_id,
        page,
        data.id
      );

      setUnderNextPageUrl(content.next_page_url);
      setUnderComments(content.data);
    }
  };

  const onKeyDownForAddComment = (e, parentId) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSendComments(parentId);
    }
  };
  // Нажатие на никнейм комента
  const handleClickCommentNickname = (commId) => {
    navigate("/account/" + commId);
  };

  // пагинация с кнопкой, запрос новых комментов при нажатии на посмотреть еще
  const handleClickNewCommentsButton = async () => {
    setLoaderForSeeNewComment(true);
    setPage((prevState) => prevState + 1);
    try {
      const content = await PostService.getAllComments(
        data.source,
        data.source_id,
        page,
        data.id
      );
      setLoaderForSeeNewComment(false);
      if (content) {
        setUnderComments((prevState) => [...prevState, ...content?.data]);
        setUnderNextPageUrl(content.next_page_url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendComments = async (parentId) => {
    if (newUnderCommentTitle.trim().length > 0) {
      const content = await PostService.SendComments(
        newUnderCommentTitle,
        parentId,
        commentType,
        commentID
      );
      setNewUnderComments(content);
      setNewUnderCommentTitle("");
    }
    setUnderComms((prevState) => ({
      ...prevState,
      comId: false,
    }));
  };

  const onEmojiClick = (event, emojiObject) => {
    setNewUnderCommentTitle((prevState) => prevState + emojiObject.emoji);
  };
  const dataDeclination = (number) => {
    if (number === 1) {
      return number + " ответ";
    } else if (number > 1 && number <= 4) {
      return number + " ответа";
    } else if (number > 4) {
      return number + " ответов";
    }
  };
  const handleClickDeleteComment = () => {
    try {
      PostService.deleteComments(data.id, "post");
      setCommentCount((prev) => prev - 1);
      setComments((prev) => [...prev].filter((item) => item.id !== data.id));
    } catch (error) {}
  };
  const handleClickEditComment = () => {
    setEditComment(true);
    setEditCommentTitle(data.text);
  };
  const handleClickEditCommentTrue = () => {
    setEditComment(false);
    setData((prev) => ({ ...prev, text: editCommentTitle }));
    PostService.SendComments(
      editCommentTitle,
      0,
      "post",
      commentID,
      "update",
      data.id
    );
  };
  const onKeyDownForEditComment = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleClickEditCommentTrue();
    }
  };

  return (
    <>
      <div key={data.id} className={commentStyle.comment}>
        <img
          onClick={() => handleClickCommentNickname(data.user.user_id)}
          src={"https://gargalo.ru" + data.user.avatar}
          className={commentStyle.comment_image}
          alt=""
        />
        {editComment ? (
          <div className={commentStyle.comment_edit}>
            <ReInput
              location="start"
              iconStart={smileImage}
              classesIcon={commentStyle.UnderclassesIcon}
              classesInput={commentStyle.classesInput}
              classesDiv={commentStyle.classesDiv}
              onKeyDown={(e) => onKeyDownForEditComment(e)}
              handleChange={(e) => setEditCommentTitle(e.target.value)}
              value={editCommentTitle}
              placeholder="Написать комментарий"
              onMouseOver={() => setEmoji(true)}
              onMouseOut={() => setEmoji(false)}
            />
            <div className={commentStyle.comment_edit_div}>
              <img
                className={commentStyle.comment_edit_image}
                onClick={() => setEditComment(false)}
                src={CloseImage}
                alt=""
              />
              <img
                style={{ marginLeft: 8 }}
                onClick={handleClickEditCommentTrue}
                className={commentStyle.comment_edit_image}
                src={CheckImage}
                alt=""
              />
            </div>
            {emoji && (
              <div
                onMouseOver={() => setEmoji(true)}
                onMouseOut={() => setEmoji(false)}
                className={
                  commentStyle.emoji_content +
                  " " +
                  (emoji ? commentStyle.emoji_content_active : " ")
                }
              >
                <Picker onEmojiClick={onEmojiClick} />
              </div>
            )}
          </div>
        ) : (
          <div className={commentStyle.comment_content}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p
                className={commentStyle.comment_name}
                onClick={() => handleClickCommentNickname(data.user.user_id)}
              >
                {data.user.nickname}
              </p>
              {user.user_id === data.user.user_id && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "start",
                    position: "relative",
                    width: 40,
                    height: 20,
                  }}
                  onMouseOver={() => setDropDown(true)}
                  onMouseOut={() => setDropDown(false)}
                >
                  <img
                    className={commentStyle.comment_kebab_menu}
                    src={ImageKebabMenu}
                    alt=""
                  />
                  {dropDown && (
                    <div
                      className={commentStyle.ReDropDown}
                      onMouseOver={() => setDropDown(true)}
                      onMouseOut={() => setDropDown(false)}
                    >
                      <ReDropDown
                        data={[
                          {
                            title: "Редактировать",
                            icon: editImage,
                            handleClick: handleClickEditComment,
                          },
                          {
                            title: "Удалить",
                            icon: deleteImage,
                            handleClick: handleClickDeleteComment,
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <p className={commentStyle.comment_text}>{data.text}</p>
            <div className={commentStyle.comment_btn}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {user?.is_real && (
                  <button
                    style={{ fontSize: 12 }}
                    className={commentStyle.comment_button}
                    onClick={() => handleClickUnderButton()}
                  >
                    Ответить
                  </button>
                )}
                {data.childs_count > 0 && (
                  <p
                    style={{ cursor: "pointer" }}
                    className={commentStyle.comment_time}
                    onClick={() => handleAddUnderComments()}
                  >
                    {dataDeclination(data.childs_count)}
                  </p>
                )}

                <p className={commentStyle.comment_time}>
                  {dateConverter(data.created_at)}
                </p>
              </div>

              <div className={commentStyle.comment_likes_div}>
                {likes.likesCount > 0 ? (
                  <>
                    <img
                      onClick={() =>
                        handleAddAndDeleteLikes(
                          likes.isLiked ? "unlike" : "like"
                        )
                      }
                      src={likes.isLiked ? likeTrueImage : likeHoverImage}
                      className={commentStyle.comment_likes}
                      alt=""
                    />
                    <p className={commentStyle.comments_likes_count}>
                      {likes.likesCount}
                    </p>
                  </>
                ) : (
                  <img
                    onClick={() =>
                      handleAddAndDeleteLikes(likes.isLiked ? "unlike" : "like")
                    }
                    onMouseOver={() => setLikeHover(true)}
                    onMouseOut={() => setLikeHover(false)}
                    src={
                      likes.isLiked
                        ? likeTrueImage
                        : likeHover
                        ? likeHoverImage
                        : likeFalseImage
                    }
                    className={commentStyle.comment_likes}
                    alt=""
                  />
                )}
              </div>
            </div>

            {underState && (
              <div className={commentStyle.under_comment_div}>
                {underComments.length > 0 &&
                  underComments.map((content) => (
                    <UnderComment
                      key={content.id}
                      user={user}
                      content={content}
                      setCommentCount={setCommentCount}
                      setUnderComments={setUnderComments}
                      setValue={setData}
                    />
                  ))}
                {loaderForSeeNewComment && (
                  <div className={commentStyle.loader}>
                    <img
                      className={commentStyle.laoder_img}
                      src={loader}
                      alt=""
                    />

                    <label htmlFor="" className={commentStyle.loader_text}>
                      Загрузка...
                    </label>
                  </div>
                )}
                {underNextPageUrl && (
                  <button
                    className={commentStyle.under_comment_new_page}
                    onClick={() => handleClickNewCommentsButton()}
                  >
                    Посмотреть еще ({data.childs_count - underComments.length})
                  </button>
                )}

                <div className={commentStyle.re_input_div}>
                  <img
                    className={
                      commentStyle.comment_image +
                      " " +
                      commentStyle.under_comment_image
                    }
                    src={`https://gargalo.ru/${user.avatar_small}`}
                    alt=""
                    style={{ marginRight: 12 }}
                  />
                  <ReInput
                    autoFocus={focusState}
                    location="start"
                    location2="end"
                    iconStart={smileImage}
                    iconEnd={sendMessageImage}
                    classesIcon={commentStyle.UnderclassesIcon}
                    classesInput={commentStyle.classesInput}
                    classesDiv={commentStyle.classesDiv}
                    onKeyDown={(e) => onKeyDownForAddComment(e, content.id)}
                    handleChange={(e) =>
                      setNewUnderCommentTitle(e.target.value)
                    }
                    value={newUnderCommentTitle}
                    placeholder="Написать комментарий"
                    onMouseOver={() => setEmoji(true)}
                    onMouseOut={() => setEmoji(false)}
                    handleClick2={() => handleSendComments(content.id)}
                  />
                  <div
                    onMouseOver={() => setEmoji(true)}
                    onMouseOut={() => setEmoji(false)}
                    className={
                      commentStyle.emoji_content +
                      " " +
                      (emoji ? commentStyle.emoji_content_active : " ")
                    }
                  >
                    <Picker onEmojiClick={onEmojiClick} />
                  </div>
                </div>
                {underComments.length > 0 && (
                  <div
                    style={{ marginTop: 15 }}
                    className={commentStyle.under_comment_close}
                    onClick={() => setUnderState(false)}
                  ></div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(CommentsList);
