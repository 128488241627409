import React from "react";
import PropTypes from "prop-types";
import styleReused from "./ReusedInput.module.css";

const ReusedInput = ({
  label,
  name,
  type,
  error,
  value,
  onChange,
  placeholder,
  reusedInputStyle,
}) => {
  const handleChange = ({ target }) => {
    onChange({ name: target.name, value: target.value });
  };
  const getInputClasses = () => {
    return (
      styleReused.reused__input + " " + (error ? styleReused.error__on : "")
    );
  };

  return (
    <>
      <label htmlFor={name}> {label}</label>
      <input
        style={reusedInputStyle}
        id={name}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        className={getInputClasses()}
      />
      {error && <div className={styleReused.input__error__text}>{error}</div>}
    </>
  );
};
ReusedInput.defaultProps = {
  type: "text",
};
ReusedInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

export default ReusedInput;
