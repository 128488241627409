import React, { useState, useEffect } from "react";
import ReSwitch from "../../../reusedComponents/ReSwitch";
import Group from "../img/Group.svg";
import GroupTwo from "../img/GroupTwo.svg";
import style from "../user.module.css";

const UserNotificationsGroup = ({ notifiList, notifi }) => {
    const [notifiState, setNotifiState] = useState({switch: false});

    // console.log(notifi,notifiList);

    useEffect(() => {
        Object.entries(notifi).forEach((nots) => {
            if (nots[0] === notifiList.name) {
                setNotifiState({switch: nots[1]})
            }
        });
    }, []);
    const handleClickNotifi = (property) => {
        Object.entries(notifi).forEach((nots) => {
            if (nots[0] === notifiList.name) {
                if(notifiState.switch){
                    notifi[property] = false
                    // setNotifiState(false)
                }else{
                    notifi[property] = true
                    // setNotifiState(true)
                }
            }
        });
    };

    return (
        <div className={style.notifications__category}>
            <div>
                <h2>{notifiList?.title}</h2>
                <span>{notifiList?.description}</span>
            </div>
            <div onClick={() => handleClickNotifi(notifiList.name, notifiState)}>
                {notifiState && <ReSwitch state={notifiState} setState={setNotifiState} stateProps={"switch"} />}
            </div>
        </div>
    );
};

export default UserNotificationsGroup;
