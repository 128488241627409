import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import style from "../../messagPage.module.css";

const ModalChat = ({
  setModalChat,
  contact_id,
  modalType,
  setMySettings,
  setMessages,
}) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/);
  const navigate = useNavigate();

  //Заблокировать/Удалить чат
  const handleConfirmClick = () => {
    axios({
      method: "GET",
      url:
        modalType === "block"
          ? `https://gargalo.ru/api/messages/ignorecontact/${contact_id}`
          : modalType === "deleteChat"
          ? `https://gargalo.ru/api/messages/deletechat/${contact_id}`
          : "",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token[1]}`,
        "User-Token": 1,
      },
    }).then(() => {
      if (modalType === "deleteChat") {
        setMessages([]);
        navigate("/messages");
      } else {
        setMySettings((prevState) => ({ ...prevState, blacklist: 1 }));
      }
      setModalChat(false);
    });
  };

  return (
    <div className={style.blockedDisplay}>
      <div className={style.confirmBlock}>
        <h2 className={style.confirmBlockZag}>
          {modalType === "microOff" ? "Внимание" : "Вы уверены?"}
        </h2>
        <h2
          className={style.confirmBlockText}
          style={{ maxWidth: modalType === "microOff" && 330 }}
        >
          {modalType === "deleteChat"
            ? "Удалить без возможности восстановления?"
            : modalType === "block"
            ? "Пользователь будет занесён в черный список."
            : modalType === "microOff"
            ? "Разрешение на использование микрофона/камеры заблокировано. Чтобы изменить, перейдите в настройки браузера."
            : ""}
        </h2>
        <div className={style.confirmBtnBlock}>
          {modalType === "microOff" ? (
            <button
              className={style.confirmBlockBtn}
              onClick={() => setModalChat(false)}
            >
              Ок
            </button>
          ) : (
            <>
              <button
                className={style.confirmBlockBtn}
                onClick={handleConfirmClick}
              >
                Да
              </button>
              <button
                className={style.confirmBlockBtn}
                onClick={() => setModalChat(false)}
              >
                Нет
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalChat;
