//Общая проверка пользователя
export const rootControls = (rootType, id) => {
  if (!window.userParam || !rootType) {
    return false;
  }
  switch (rootType) {
    case "ActiveUser":
      return ActiveUser();
    case "ActiveUserInTree":
      return ActiveUserInTree();
    case "TeipModerator":
      return TeipModerator(id);
    case "Administrator":
      return Administrator();
    case "ClosedAccount":
      return ClosedAccount();
    default:
      return false;
  }
};

//Активированный пользователь
const ActiveUser = () => {
  if (!window.userParam.is_real) {
    return false;
  }
  return true;
};

//Активированный пользователь в древе
const ActiveUserInTree = () => {
  if (!window.userParam.is_real || !(window.userParam.node_id > 0)) {
    return false;
  }
  return true;
};

//Модератор тейпа
const TeipModerator = (id) => {
  if (!window.userParam.is_moder || window.userParam.user_id !== Number(id)) {
    return false;
  }
  return true;
};

//Администратор
const Administrator = () => {
  if (!window.userParam.is_admin) {
    return false;
  }
  return true;
};

//Закрытый аккаунт
const ClosedAccount = () => {
  if (!window.userParam.is_open) {
    return false;
  }
  return true;
};
