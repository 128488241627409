import React from "react";
import style from "../connection.module.css";
import help1 from "../img/help1.svg";
import help2 from "../img/help2.svg";
import help3 from "../img/help3.svg";
import help4 from "../img/help4.svg";
import helpBtn1 from "../img/helpBtn1.svg";
import helpBtn2 from "../img/helpBtn2.svg";
import helpBtn3 from "../img/helpBtn3.svg";
import moving from "../img/moving.svg";
import zoom from "../img/zoom.svg";

const Helpers = ({ setHelperBlock, type }) => {
  return (
    <div className={style.helperWrapp} onClick={() => setHelperBlock(false)}>
      <div className={style.helpersBlock}>
        <h2 className={style.helpZag}>Что это и для чего?</h2>
        <div className={style.sectionTextBlock}>
          {type === "teyp" ? (
            <>
              <p>
                Вы попали на страницу тейпового древа, где будет отображаться
                все члены тейпа.
              </p>
              <p>Также вы можете заполнять ваше тейповое древо.</p>
              <p>
                Не бойтесь допустить ошибку, ведь всегда есть возможность
                отредактировать добавленного человека!
              </p>
            </>
          ) : (
            <>
              <p>
                Вы попали во вкладку "Родство", где будет отображаться ваши
                ближайшие родственники.
              </p>
              <p>Также вы можете заполнять ваше родственное древо.</p>
              <p>
                Не бойтесь допустить ошибку, ведь всегда есть возможность
                отредактировать добавленного человека!
              </p>
              <p>
                После прогрузки древа вы будете отмечены как центральный
                элемент, но это не значит, что вы не можете посмотреть чужие
                линии родства, достаточно просто кликнуть по другому элементу
                после чего уже этот элемент станет центром и подгрузится его
                родство(если оно есть)!
              </p>
            </>
          )}
        </div>
        {type !== "teyp" && (
          <>
            <h2 className={style.helpZag}>Типы карточек</h2>
            <div className={style.sectionBlock}>
              <div className={style.helpersBlocks}>
                <img src={help1} alt="" />
                <h2 className={style.helpText}>
                  Пустоты которые нужно заполнить.
                </h2>
              </div>
              <div className={style.helpersBlocks}>
                <img src={help2} alt="" />
                <ul className={style.helpText}>
                  <li>Нет в дереве.</li>
                  <li>Не зарегестрирован.</li>
                  <li>Добавлен самим пользователем.</li>
                </ul>
              </div>
              <div className={style.helpersBlocks}>
                <img src={help3} alt="" />
                <ul className={style.helpText} style={{ color: "#21CC6B" }}>
                  <li>Есть в дереве.</li>
                  <li>Пользователь.</li>
                </ul>
              </div>
              <div className={style.helpersBlocks}>
                <img src={help4} alt="" />
                <ul className={style.helpText}>
                  <li>Нет в дереве.</li>
                  <li style={{ color: "#21CC6B" }}>Пользователь.</li>
                </ul>
              </div>
            </div>
          </>
        )}
        <h2 className={style.helpZag}>Типы иконок</h2>
        <div className={style.sectionBlock}>
          <div
            className={style.helpersBlocks}
            style={{ width: 260, alignItems: "flex-start" }}
          >
            <div className={style.genderBlock}>
              <span className={style.gender1}></span> - Мужчина
            </div>
            <div className={style.genderBlock}>
              <span className={style.gender2}></span> - Женщина
            </div>
            <div className={style.genderBlock}>
              <img src={helpBtn1} alt="" /> - Редактирование человека
            </div>
            <div className={style.genderBlock}>
              <img src={helpBtn2} alt="" /> - Добавление человека
            </div>
            <div className={style.genderBlock}>
              <img src={helpBtn3} alt="" /> - Перейти на профиль человека
            </div>
          </div>
        </div>
        <h2 className={style.helpZag}>Масштабирование и перемещение</h2>
        <div className={style.sectionTextBlock}>
          <div className={style.genderBlock}>
            <img src={zoom} alt="" style={{ height: 45 }} /> - Для
            масштабирования достаточно покрутить колесеко мыши вперед/назад
          </div>
          <div className={style.genderBlock}>
            <img src={moving} alt="" style={{ height: 45 }} /> - Для перемещения
            достаточно зажать левую клавишу мыши и начать перетаскивать древо
          </div>
        </div>
      </div>
    </div>
  );
};

export default Helpers;
