import React, { useState, useContext, useRef } from "react";
import closepost from "../../../img/closepost.svg";
import style from "./actions.module.css";
import PostService from "../../../../../API/postService";
import linkImage from "../../../img/linkIcon.svg";
import editImage from "../../../img/Edit.svg";
import deleteImage from "../../../img/Delete.svg";

import PostsDeleteModal from "./postsModal/PostsDeleteModal";
import { RegistrationContext } from "../../../../context";
import { useNavigate } from "react-router-dom";
import ReDropDown from "./../../../../reusedComponents/ReDropDown";
import EditPost from "../../editPosts/EditPost";
const MyActionsList = ({ item, setActions, type, where }) => {
  const navigate = useNavigate();
  const { setData } = useContext(RegistrationContext);
  const [dropDown, setDropDown] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openPost, setOpenPost] = useState(false);
  // Открытие модалки для удаление поста
  const handleClickDeleteModal = (e) => {
    e.stopPropagation();
    setDeleteModal(true);
    // setActions(false)
  };
  // Удаление постов
  const handleDeletePost = async () => {
    try {
      const response = await PostService.deletePost(item.id);
      if (response) {
        if (type === "manyPosts") {
          setData((prev) => prev.filter((element) => element.id !== item.id));
          setActions(false);
        } else {
          navigate("/wall");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Копирование ссылки
  const handleClickCopyLink = (e) => {
    e.stopPropagation();
    console.log(e)
    navigator.clipboard.writeText(`https://gargalo.ru/wall/post/${item.id}`);
    setActions(false);
    setDropDown(false);
  };
  const handleClickEditPost = (e) => {
    e.stopPropagation();
    setOpenPost(true);
    setDropDown(false);
  };
  return (
    <div className={style.edit_post_container}>
      {openPost && <EditPost where={where} item={item} setOpenPost={setOpenPost} />}
      {dropDown && (
        <ReDropDown
          data={[
            {
              title: "Скопировать ссылку",
              icon: linkImage,
              handleClick: handleClickCopyLink,
            },
            {
              title: "Редактировать",
              icon: editImage,
              handleClick: handleClickEditPost,
            },
            {
              title: "Удалить",
              icon: deleteImage,
              handleClick: handleClickDeleteModal,
            },
          ]}
        />
      )}
      {deleteModal && (
        <PostsDeleteModal
          setDeleteModal={setDeleteModal}
          handleClick={handleDeletePost}
          setActions={setActions}
        />
      )}
    </div>
  );
};

export default MyActionsList;
