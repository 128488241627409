import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const token = document.cookie.match(/token=(.+?)(;|$)/);

const firebaseConfig = {
  apiKey: "AIzaSyDXEs5QLcctMAi2yrTtJcmGb58YHV7emTY",
  authDomain: "gargalo-5caeb.firebaseapp.com",
  projectId: "gargalo-5caeb",
  storageBucket: "gargalo-5caeb.appspot.com",
  messagingSenderId: "626800456635",
  appId: "1:626800456635:web:a4b8261e5e507f32bd0a9e",
  measurementId: "G-D1RVBYHL7E",
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging();
// onMessage(messaging, (payload) => {
//   console.log("Message received. ", payload);
//   // ...
// });

export const examinationNotification = async () => {
  switch (Notification.permission.toLowerCase()) {
    case "granted":
      subscribe();
      break;
    case "denied":
      break;
    case "default":
      Notification.requestPermission(function (state) {
        if (state == "granted") subscribe();
        if (state == "default") setTimeout(examinationNotification, 3000);
      });
      break;
  }
};

const subscribe = async () => {
  return getToken(messaging, {
    vapidKey:
      "BPU0cQGwcBzBikWPu8o7L0J6P8GPkP9U-N9aNYOLn21euKp6jh29J_B1iTiacBBdDq1ECiNNhwpKt0JiRYtHZac",
  })
    .then((currentToken) => {
      if (currentToken) {
        window.fireBaseToken = currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
