import React from "react";
import styleMouse from "../mouseOverWindow.module.css";
import likeIcon from "../../../../img/like.svg";
import trueLike from "../../../../img/TrueLike.svg";
import deleteModal from "./Mini buttons.svg";
import { useNavigate } from "react-router-dom";

const MouseModal = ({ count, classes, users, title, setOpen }) => {
  console.log("users", users);
  const navigate = useNavigate();
  const handleClickUsers = (id) => {
    navigate(`/account/${id}`);
  };

  const getAddClassesFromMouseModal = () => {
    return classes
      ? styleMouse.mouse_modal + " " + styleMouse.active
      : styleMouse.mouse_modal;
  };
  return (
    <>
      <div className={styleMouse.modal_container}>
        <div className={styleMouse.modal_del}>
          <div className={getAddClassesFromMouseModal()}>
            <div className={styleMouse.mouse_over_text}>
              <span className={styleMouse.mouse_over_span}>{title}</span>
              <span className={styleMouse.mouse_over_span_number}>{count}</span>
            </div>
            <div>
              <div className={styleMouse.mouse_modal_div}>
                {users &&
                  users.map((user, index) => {
                    return (
                      <div
                        onClick={() => handleClickUsers(user.user.user_id)}
                        key={index}
                        className={styleMouse.mouse_modal_icons}
                      >
                        <img
                          className={styleMouse.mouse_modal_image}
                          src={`https://gargalo.ru${user.user.avatar}`}
                        />

                        <div className={styleMouse.mouse_modal_name}>
                          {user.user.nickname.split(" ")[0]}{" "}
                          {user.user.nickname.split(" ")[1]}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <img
            src={deleteModal}
            className={styleMouse.deleteModal}
            alt=""
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(MouseModal);
