import React, { useState } from "react";
import logo from "./img/logo.svg";
import style from "./sign.module.css";
import Chekbox from "./img/Chekbox.svg";
import ChekboxAct from "./img/ChekboxAct.svg";
import Support from "./img/Support.svg";
import PhoneCall from "./img/PhoneCall.svg";
import MailSup from "./img/MailSup.svg";
import axios from "axios";
import { Link } from "react-router-dom";

const SignIn = () => {
  const [checked, setChecked] = useState(Chekbox);
  const [supportBlock, setSupportBlock] = useState(false);
  const [error, setError] = useState();
  const [loader, setLoader] = useState(false);
  const [rememberMy, setRememberMy] = useState(false);
  const [authorizationValue, setAuthorizationValue] = useState({
    email: "",
    password: "",
    device_name: "desctop",
  });

  const hadleCheckedClick = () => {
    if (checked === Chekbox) {
      setChecked(ChekboxAct);
      setRememberMy(true);
    } else {
      setChecked(Chekbox);
      setRememberMy(false);
    }
  };

  const handleAuthorizationClick = (e, email, password, device_name) => {
    e.preventDefault();
    setLoader(true);
    if (!email.includes("@")) {
      email = email.replace(/\D/g, "").replace(/^8/, "7");
    }
    axios
      .post(
        `https://gargalo.ru/api/sanctum/token`,
        { email, password, device_name },
        {
          headers: {
            "content-type": "sign/json",
            "User-Token": 1,
          },
        }
      )
      .then(async (res) => {
        if (window.fireBaseToken) {
          await axios.post(
            `https://gargalo.ru/api/user/savetoken`,
            {
              token: window.fireBaseToken,
            },
            {
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${res.data.token}`,
                "User-Token": 1,
              },
            }
          );
        }
        if (rememberMy) {
          let date = new Date(Date.now() + 86400e3 * 7);
          date = date.toUTCString();
          document.cookie = `token=${res.data.token}; secure; expires=` + date;
          document.cookie =
            `nickname=${res.data.nickname}; secure; expires=` + date;
          document.cookie = `id=${res.data.user_id}; secure; expires=` + date;
        } else {
          document.cookie = `token=${res.data.token}; secure;`;
          document.cookie = `nickname=${res.data.nickname}; secure;`;
          document.cookie = `id=${res.data.user_id}; secure;`;
        }
        window.location.reload();
      })
      .catch((error) => {
        setError(error.response.data.errors[0]);
        setLoader(false);
      });
  };

  const handleSupport = () => {
    if (supportBlock) {
      setSupportBlock(false);
    } else {
      setSupportBlock(true);
    }
  };

  //Согласие на обработку куки
  const [cookieBLock, setCookieBLock] = useState();
  const cookie = document.cookie.match(/cookie=(.+?)(;|$)/);

  const cookieAgreement = () => {
    if (!cookie || cookie[1] !== "yes") {
      setCookieBLock(true);
    }
  };

  const cookieAgreementAppend = () => {
    let date = new Date(Date.now() + 86400e3 * 7);
    date = date.toUTCString();
    document.cookie = "cookie=yes; secure; expires=" + date;
    setCookieBLock(false);
  };

  return (
    <div className={style.signWrap} onLoad={cookieAgreement}>
      {cookieBLock && (
        <div className={style.cookieBlock}>
          <h2 className={style.cookieZag}>
            Согласие на обработку файлов cookie
          </h2>
          <h2 className={style.cookieText}>
            Сайт gargalo.ru использует файлы «cookie» с целью персонализации
            сервисов и повышения удобства пользования сайтом.
          </h2>
          <div className={style.agreementBlock}>
            <button className={style.cookieBtn} onClick={cookieAgreementAppend}>
              Ок
            </button>
          </div>
        </div>
      )}
      {supportBlock && (
        <div
          className={style.supportWrap}
          onClick={() => setSupportBlock(false)}
        >
          <div className={style.supportBlock}>
            <h2 className={style.supportZag}>Мы можем помочь!</h2>
            <h2 className={style.supportZagText}>
              Если у вас возникли сложности с использованием системы, свяжитесь
              с нами по номеру или Email, которые указаны ниже.
            </h2>
            <div className={style.supportLinkBlocks}>
              <a href="tel:+79289390606">
                <img src={PhoneCall} alt="" />
                <h2>+7 (928) 939 06 06</h2>
              </a>
              <a href="mailto:gargalofond@mail.ru">
                <img src={MailSup} alt="" />
                <h2>gargalofond@mail.ru</h2>
              </a>
            </div>
          </div>
        </div>
      )}
      <img
        src={logo}
        alt="Логотип"
        style={{ width: 225, height: 30, marginBottom: 56 }}
      />
      <form style={{ display: "flex", flexDirection: "column" }}>
        <input
          type="email"
          className={style.signInput + " " + style.autInput}
          placeholder="Телефон, или эл. адрес"
          style={{ marginBottom: 16 }}
          autoComplete="on"
          onChange={(e) =>
            setAuthorizationValue({
              ...authorizationValue,
              email: e.target.value,
            })
          }
          value={authorizationValue.email}
        />
        <input
          type="password"
          className={style.signInput + " " + style.autInput}
          placeholder="Пароль"
          autoComplete="on"
          onChange={(e) =>
            setAuthorizationValue({
              ...authorizationValue,
              password: e.target.value,
            })
          }
          value={authorizationValue.password}
        />
        {error && (
          <h2
            className={style.signText}
            style={{ color: "#EB5757", marginTop: 10 }}
          >
            {error}
          </h2>
        )}
        {loader ? (
          <button className={style.signBtn + " " + style.autBtn}>
            <svg className={style.spinnerSign} viewBox="0 0 50 50">
              <circle
                className={style.pathSign}
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </button>
        ) : (
          <button
            className={style.signBtn + " " + style.autBtn}
            onClick={(e) =>
              handleAuthorizationClick(
                e,
                authorizationValue.email,
                authorizationValue.password,
                authorizationValue.device_name
              )
            }
          >
            Войти
          </button>
        )}
      </form>
      <div className={style.signBlock} style={{ width: 360, marginBottom: 32 }}>
        <div
          className={style.signItemsBLock}
          onClick={hadleCheckedClick}
          style={{ cursor: "pointer" }}
        >
          <img
            src={checked}
            alt=""
            style={{ width: 18, height: 18, marginRight: 11 }}
          />
          <h2 className={style.signText}>Запомнить меня</h2>
        </div>
        <Link to={"/forgot-password"} className={style.signLink}>
          Я забыл пароль
        </Link>
      </div>
      <div className={style.signItemsBLock} style={{ marginBottom: 32 }}>
        <h2 className={style.signText}>У вас нет аккаунта?</h2>
        <Link to={"/registrashion"} className={style.signLink}>
          Зарегистрируйтесь
        </Link>
      </div>
      <div
        className={style.signItemsBLock}
        style={{ marginBottom: 32, cursor: "pointer" }}
        onClick={handleSupport}
      >
        <img
          src={Support}
          alt=""
          style={{ width: 15, height: 16, marginRight: 11 }}
        />
        <h2 className={style.signText}>Поддержка</h2>
      </div>
    </div>
  );
};

export default SignIn;
